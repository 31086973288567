import React from 'react'
import { LayoutGroup } from 'framer-motion'
import { useQuery } from '@apollo/client'
import { Theme, useTheme } from '@emotion/react'
import { useSearchParams } from 'react-router-dom'

import Actions from '../../components/Actions'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Info from '../../components/Info'
import MainLayoutLink from '../../components/layout/MainLayoutLink'
import ExpandablePhysicalCard from '../../components/ExpandablePhysicalCard'
import DrawerCard from '../../components/drawers/DrawerCard'
import UserItem from '../../components/lists/items/UserItem'
import Text from '../../components/typography/Text'

import { useIsValidSubscription } from '../../hooks/useSubscriptions'
import useCurrentUser from '../../hooks/useCurrentUser'
import useWaitForNotification from '../../hooks/useWaitForNotification'
import useIsSidekick from '../../hooks/useIsSidekick'
import useTeamMembers from '../../hooks/useTeamMembers'

import {
  getCards,
  openCardOrders,
  teamMembers as getTeamMembers,
} from '../../queries'
import OrderedCardStatusNotification from '../../components/OrderedCardStatusNotification'

const CardsScene = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [search] = useSearchParams()
  const [drawer, setDrawer] = React.useState(false)
  const { teamMembers } = useTeamMembers()
  const isSK = useIsSidekick()

  const fetchPolicy = search.has('refresh') ? 'network-only' : 'cache-first'

  const {
    data: cardsData,
    loading: cardsLoading,
    error: cardsError,
    refetch,
  } = useQuery(getCards, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  })

  const {
    data: teamMembersData,
    loading: teamMembersLoading,
    error: teamMembersError,
  } = useQuery(getTeamMembers)

  const {
    data: openCardOrdersData,
    loading: openCardOrdersLoading,
    error: openCardOrdersError,
    refetch: refetchOpenCardOrders,
  } = useQuery(openCardOrders, {
    fetchPolicy,
  })

  const { data: foundNotification } = useWaitForNotification([
    'CardRegistered',
    'CardReplaced',
  ])

  const { data: subscriptionsData, loading: loadingSubscription } =
    useIsValidSubscription()

  const { data: user, loading: userLoading } = useCurrentUser()

  React.useEffect(() => {
    if (foundNotification) refetch()
  }, [foundNotification])

  const loading =
    cardsLoading ||
    teamMembersLoading ||
    openCardOrdersLoading ||
    loadingSubscription ||
    userLoading
  const error = cardsError || teamMembersError || openCardOrdersError

  const cards = cardsData?.cards

  const orderedCards = openCardOrdersData?.openCardOrders
  const sidekicksWithAccount =
    teamMembers?.filter((member) => member?.bankAccounts?.length > 0) || []

  const isValidSubscription = subscriptionsData?.isValid
  const canOrderCards = user?.settings?.canOrderCards && isValidSubscription
  const canActivateCards = !!teamMembersData.teamMembers?.find?.(
    (sk) => sk.bankAccounts?.length > 0
  )

  return (
    <MainLayoutLink loading={loading} error={error} {...rest}>
      <Grid gap="1rem">
        <Actions>
          {!isSK && (
            <Button
              button
              token="ACTIVATE"
              icon="card"
              size={1}
              iconColor="white"
              onClick={() => setDrawer(true)}
              disabled={!canActivateCards}
            />
          )}

          {isSK && (
            <Button
              token="ACTIVATE"
              icon="card"
              size={1}
              iconColor="white"
              disabled={!canActivateCards}
              to={`/cards/activate/${user?.userProfile?.id}`}
            />
          )}

          <Button
            to="/cards/order"
            token="ORDER"
            icon="plus"
            iconColor="white"
            size={1}
            disabled={!canOrderCards}
          />
        </Actions>

        <Grid gap="1rem">
          <LayoutGroup>
            {orderedCards &&
              orderedCards.map((cardOrder, index) => (
                <OrderedCardStatusNotification
                  key={`card_order_${index}`}
                  cardOrder={cardOrder}
                  onCancel={refetchOpenCardOrders}
                />
              ))}

            {cards &&
              cards.map((card) => {
                const allowExpand =
                  card.status?.statusCode !== 'Activating' &&
                  card.status?.statusCode !== 'Replacing'

                return (
                  <ExpandablePhysicalCard
                    card={card}
                    key={card.id}
                    layout
                    allowExpand={allowExpand}
                  />
                )
              })}

            {!isSK && !canOrderCards && <Info token="ORDER_CARD_INFO" />}

            {!isSK && !canOrderCards && (
              <Info
                token="YOU_NEED_SUBSCRIPTION_TO_ORDER_CARD"
                actions={[
                  <Button
                    css={styles.link}
                    token="MANAGE_SUBSCRIPTIONS"
                    to="/settings/subscriptions?from=/cards"
                  />,
                ]}
              />
            )}
          </LayoutGroup>
        </Grid>

        <DrawerCard
          handleClickOutside={() => setDrawer(false)}
          isOpened={drawer}
          label={<Text token="WHICH_ACCOUNT_IS_THIS_CARD_FOR" uppercase />}
        >
          {sidekicksWithAccount?.map(({ userProfile }) => (
            <UserItem
              key={userProfile.id}
              user={userProfile}
              to={`/cards/activate/${userProfile.id}`}
              size={56}
              css={styles.sk}
            />
          ))}
        </DrawerCard>
      </Grid>
    </MainLayoutLink>
  )
}

const computeStyles = ({ LINK }: Theme['theme']) => ({
  root: {},
  link: {
    background: 'none',
    color: LINK,
    width: 'min-content',
    padding: 0,
  },
  sk: {
    padding: '16px 24px 16px 16px',
  },
})

export default CardsScene
