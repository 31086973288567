import React from 'react'

import Flow from '../../components/flows/Flow'
import FlowOutlet from '../../components/flows/FlowOutlet'
import CardLayoutLink from '../../components/layout/CardLayoutLink'

export { FlowStep } from '../../components/flows/Flow'

const FlowCardLayout = ({ children, ...rest }) => {
  return (
    <Flow stepChildren={children} {...rest}>
      <CardLayoutLink {...rest}>
        <FlowOutlet />
      </CardLayoutLink>
    </Flow>
  )
}

export default FlowCardLayout
