import React from 'react'
import { useMutation } from '@apollo/client'
import { Theme, useTheme } from '@emotion/react'
import Button from './Button'

import Banner, { Props as BannerProps } from './Banner'
import { resendSideKickInvitation } from '../queries'
import HeroSpinner from './Spinner'
import Text from './typography/Text'
import { getMinutesFromSeconds } from '../utils/functions'

export type ResendEmailNotificationProps = {
  user: any
}

export type Props = ResendEmailNotificationProps & BannerProps

const ResendEmailNotification: React.FC<Props> = ({ user, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [canSend, setCanSend] = React.useState(true)
  const [formatedTime, setFormatedTime] = React.useState('')
  const [remaining, setRemaining] = React.useState(0)
  const resendTimer = React.useRef<any>()

  const [resendInvitation, { loading }] = useMutation(resendSideKickInvitation)

  const handleResendInvitation = async () => {
    setCanSend(false)
    await resendInvitation({ variables: { id: user.id } })
  }
  React.useEffect(() => {
    if (!canSend) {
      setRemaining(120)
      setTimeout(() => {
        setCanSend(true)
        clearInterval(resendTimer.current)
      }, 120000)
    }
  }, [canSend])

  React.useEffect(() => {
    setFormatedTime(getMinutesFromSeconds(remaining))
    if (remaining > 0) {
      setTimeout(() => {
        setRemaining(remaining - 1)
      }, 1000)
    }
  }, [remaining])

  const resendAction = loading ? (
    <HeroSpinner size={26} />
  ) : canSend ? (
    <Button
      button
      css={styles.resend}
      token="RESEND_INVITATION"
      onClick={handleResendInvitation}
    />
  ) : (
    <Text
      css={styles.tryAgain}
      token={'TRY_AGAIN_IN'}
      interpolation={{ remainingTime: formatedTime }}
    />
  )
  return (
    <Banner
      icon="info"
      css={styles.root}
      color={theme.INVITATION_RESEND_NOTIFICATION}
      token={'info_code_SideKickInvited'}
      interpolation={{ givenName: user.givenName, familyName: user.familyName }}
      actions={[resendAction]}
      {...rest}
    />
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    justifyContent: 'center',
  },
  resend: {
    background: 'none',
    color: theme.LINK,
    width: 'min-content',
    padding: 0,
  },
  spinnerContainer: {
    height: '100%',
    width: '100%',
  },
  tryAgain: {
    fontSize: '14px',
  },
})

export default ResendEmailNotification
