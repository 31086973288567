import React from 'react'
import { TComputeStyles, useTheme } from '@emotion/react'

import Flex from './Flex'
import Icon, { IconProps } from './Icon'
import { ColorType } from '../utils/themes'
import { Variants } from 'framer-motion'

const DEFAULT_ICON_COLOR = 'WHITE'

export type IconCircleProps = {
  iconColor?: ColorType | string
  scale?: number
  iconWidth?: number
  iconHeight?: number
  css?: any
  variants?: Variants
} & IconProps

const IconCircle: React.FC<IconCircleProps> = ({
  size = 100,
  scale = 1,
  color,
  icon,
  iconColor = DEFAULT_ICON_COLOR,
  iconWidth,
  iconHeight,
  ...rest
}) => {
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () =>
      computeStyles({ size, color, colors: Object.assign({}, colors, theme) }),
    [size, color, theme]
  )

  return (
    <Flex centerX centerY css={styles.root} {...rest}>
      <Icon
        width={iconWidth}
        height={iconHeight}
        icon={icon}
        color={colors[iconColor] || iconColor}
        size={scale || size / 2}
      />
    </Flex>
  )
}

const computeStyles: TComputeStyles = ({ size, color, colors }) => ({
  root: {
    width: size,
    height: size,
    borderRadius: '100%',
    backgroundColor: colors[color] || color,
  },
})

export default IconCircle
