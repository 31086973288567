import React from 'react'
import { useMutation } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Button from './Button'

import { cancelCardOrder as cancelCardOrderMutation } from '../queries'
import HeroSpinner from './Spinner'
import Info from './Info'
import { SHIPPING_METHOD } from './form/forms/OrderCardSummaryForm'
import InfoSuccess from './InfoSuccess'
import ConfirmModal from './modals/ConfirmModal'
import Flex from './Flex'

export type OrderedCardStatusNotificationProps = {
  cardOrder: any
  onCancel?: Function
}

export type Props = OrderedCardStatusNotificationProps

export enum OrderedCardStatus {
  Ordered = 'Ordered',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
  CancellationRequested = 'CancellationRequested',
}

const OrderedCardStatusNotification: React.FC<Props> = ({
  cardOrder,
  onCancel,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [isCancelOpen, setIsCancelOpen] = React.useState(false)

  const [cancelCardOrder, { loading: cancelling }] = useMutation(
    cancelCardOrderMutation
  )

  const { id, userProfile, status, shippingMethod = '' } = cardOrder || {}
  const { id: ownerId } = userProfile || {}

  const handleCancelCard = async () => {
    await cancelCardOrder({ variables: { cardOrderId: id } })
    setIsCancelOpen(false)
    onCancel?.()
  }

  const cancelAction = cancelling ? (
    <Flex css={styles.spinnerContainer}>
      <HeroSpinner size={26} />
    </Flex>
  ) : (
    <Button
      button
      css={styles.link}
      token="CANCEL"
      onClick={() => setIsCancelOpen(true)}
    />
  )

  const activateAction = (
    <Button
      nav
      token="ACTIVATE_CARD"
      to={`/cards/activate/${ownerId}`}
      css={styles.link}
    />
  )

  const isRush = shippingMethod === SHIPPING_METHOD.RUSH_TRACK

  const fullNameInterpolation = {
    givenName: userProfile?.givenName,
    familyName: userProfile?.familyName,
  }

  const renderNotification = () => {
    let notificationComponent
    switch (status) {
      case OrderedCardStatus.Ordered:
        notificationComponent = (
          <>
            <Info
              label="CARD_ORDERED"
              token={
                isRush
                  ? 'PERSONALIZED_SIDEKICK_CARD_HAS_BEEN_ORDERED_RUSH'
                  : 'PERSONALIZED_SIDEKICK_CARD_HAS_BEEN_ORDERED_STANDARD'
              }
              interpolation={fullNameInterpolation}
              actions={[activateAction, cancelAction]}
              {...rest}
            />
            <ConfirmModal
              title="CONFIRM_CARD_ORDER_CANCELATION"
              content="CONFIRM_CARD_ORDER_CANCELATION_DETAIL"
              titleInterpolation={fullNameInterpolation}
              contentInterpolation={fullNameInterpolation}
              confirmLabel="CANCEL_CARD"
              cancelLabel="DONT_CANCEL_CARD"
              confirm={handleCancelCard}
              cancel={() => setIsCancelOpen(false)}
              handleClickOutside={() => setIsCancelOpen(false)}
              isOpened={isCancelOpen}
            />
          </>
        )
        break
      case OrderedCardStatus.Shipped:
        notificationComponent = (
          <InfoSuccess
            label="CARD_SHIPPED"
            token={
              isRush
                ? 'PERSONALIZED_SIDEKICK_CARD_IS_ON_THE_WAY_RUSH'
                : 'PERSONALIZED_SIDEKICK_CARD_IS_ON_THE_WAY_STANDARD'
            }
            interpolation={fullNameInterpolation}
            actions={[activateAction]}
            {...rest}
          />
        )
        break
      case OrderedCardStatus.CancellationRequested:
        notificationComponent = (
          <Info
            token="WE_ARE_PROCESSING_YOUR_CANCELATION_REQUEST"
            interpolation={fullNameInterpolation}
            dismissable
            {...rest}
          />
        )
        break
      case OrderedCardStatus.Cancelled:
        notificationComponent = (
          <Info
            label="CARD_CANCELED"
            token="PERSONALIZED_SIDEKICK_CARD_HAS_BEEN_CANCELED"
            interpolation={fullNameInterpolation}
            dismissable
            {...rest}
          />
        )
        break
      default:
        notificationComponent = null
    }
    return notificationComponent
  }

  return renderNotification()
}

const computeStyles: TComputeStyles = ({ LINK }: TTheme) => ({
  root: {
    justifyContent: 'center',
  },
  spinnerContainer: {
    padding: '0 1rem',
  },
  link: {
    background: 'none',
    color: LINK,
    marginLeft: '1rem',
    padding: 0,
    width: 'min-content',
    '&.is-disabled': {
      background: 'none',
    },
  },
})

export default OrderedCardStatusNotification
