import React from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import ComponentCard from '../../components/cards/ComponentCard'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import NotificationCard from '../../components/cards/NotificationCard'

import { notifications } from '../../queries'

type NotificationType = {
  id?: string | number
  code?: string
  notificationType?: string
  timestamp?: number
}

const NotificationsScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const newNotifications = useSelector(
    (state: { notifications: Array<{ notification: NotificationType }> }) =>
      state.notifications
  )

  const { loading, data, error } = useQuery(notifications, {
    fetchPolicy: 'no-cache',
  })

  React.useEffect(
    () => () => {
      dispatch.notifications.CLEAR()
    },
    []
  )

  return (
    <CardLayoutLink loading={loading} error={error} grayed {...rest}>
      {newNotifications.length > 0 && (
        <ComponentCard token="UNSEEN">
          {newNotifications
            .map((notification) => notification.notification)
            .sort((notification) => -notification.timestamp)
            .map(({ id, code, notificationType, timestamp }) => (
              <NotificationCard
                key={id}
                notificationType={notificationType}
                code={code}
                timestamp={timestamp}
                transparent
              />
            ))}
        </ComponentCard>
      )}
      {data && (
        <ComponentCard token="SEEN" css={{ backgroundColor: 'transparent' }}>
          {data.notifications?.edges
            .map((edge) => edge.node)
            .filter(
              (node) =>
                !newNotifications.find(
                  (notification) => notification.notification.id === node.id
                )
            )
            .sort((node) => -node.timestamp)
            .map(({ id, code, notificationType, timestamp }) => (
              <NotificationCard
                key={id}
                notificationType={notificationType}
                code={code}
                timestamp={timestamp}
                transparent
              />
            ))}
        </ComponentCard>
      )}
    </CardLayoutLink>
  )
}

export default NotificationsScene
