import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import AdditionalInfoForm from '../../../components/form/forms/AdditionalInfoForm'
import FlowContext from '../../../context/FlowContext'

const CompleteProfile: React.FC<any> = ({
  data = {},
  submit,
  disabled,
  loading,
  error,
  asd,
  ...rest
}) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async () => {
    await submit({
      ...currentData.data,
    })
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout>
      <AdditionalInfoForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default CompleteProfile
