import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const TransactionIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 24
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2L21 6L17 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6H21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 22L3 18L7 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 18H3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TransactionIcon
