import { useMutation } from '@apollo/client'
import useSubscriptionsAllowance from './useSubscriptionsAllowance'

import {
  moveFunds,
  allocateFundsToSideKickCategories,
  allocateFunds,
} from '../queries/mutations'

const useAllocate = (move) => {
  const { allow } = useSubscriptionsAllowance()
  const mutation = allow?.categoryAllocate
    ? allocateFundsToSideKickCategories
    : allocateFunds
  return useMutation(move ? moveFunds : mutation)
}

export default useAllocate
