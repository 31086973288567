import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Form from '../Form'

import Header from '../../typography/Header'
import Detail from '../../typography/Detail'
import SwitchField from '../fields/SwitchField'
import Info from '../../Info'

type CardFreezeFormProps = {
  loading?: boolean
  submit?: Function
  status?: boolean
  error?: boolean
  handleFieldUpdate?: Function
  innerRef?: any
}

const schema = Yup.object().shape({
  status: Yup.boolean(),
})

const CardFreezeForm: React.FC<CardFreezeFormProps> = ({
  loading,
  submit,
  status,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form
      initialValues={{
        status: status,
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
      {...rest}
    >
      <Header css={styles.headline} token="TEMP_FREEZE_CARD" />

      <Detail token="TEMP_FREEZE_CARD_INFO" capitalize={false} />

      <SwitchField
        label={<Header token="CARD_FROZEN" css={styles.sectionToggleLabel} />}
        name="status"
        css={styles.sectionToggle}
        labelIsSufix
      />

      <Info token="LOST_YOUR_CARD_INFO" />
    </Form>
  )
}

const computeStyles: TComputeStyles = (theme: TTheme) => ({
  root: { width: '100%' },
  headline: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  sectionToggle: {
    border: 'none',
  },
  sectionToggleLabel: {
    color: theme.TEXT_PRIMARY,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1.71,
    margin: '0 9px',
  },
})

export default CardFreezeForm
