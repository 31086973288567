import React from 'react'

import Flex from './Flex'

import withFeature from '../hocs/withFeature'

const Actions = ({ ...rest }) => {
  return <Flex css={styles.root} gap="1rem" {...rest} />
}

const styles = {
  root: {
    height: 'min-content',
  },
}

export default withFeature(Actions)
