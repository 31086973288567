import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

type NoSkType = {
  size?: number
  width?: number
  height?: number
}

const NoSk: React.FC<NoSkType> = ({ size = 1, width, height }) => {
  const defaultWidth = 201
  const defaultHeight = 153
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 201 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 138.105C0.5 138.272 0.634411 138.407 0.801789 138.407H200.198C200.366 138.407 200.5 138.272 200.5 138.105C200.5 137.938 200.366 137.803 200.198 137.803H0.801789C0.634411 137.803 0.5 137.938 0.5 138.105Z"
        fill="#3F3D56"
      />
      <path
        d="M110.068 52.7233L112.125 39.8641L98.2372 34.2061L96.1797 53.752L110.068 52.7233Z"
        fill="#A0616A"
      />
      <path
        d="M106.558 43.998C114.379 43.998 120.72 37.6573 120.72 29.8357C120.72 22.014 114.379 15.6733 106.558 15.6733C98.7362 15.6733 92.3955 22.014 92.3955 29.8357C92.3955 37.6573 98.7362 43.998 106.558 43.998Z"
        fill="#A0616A"
      />
      <path
        d="M105.427 10.7953C105.837 11.0345 106.386 10.6726 106.518 10.2166C106.649 9.76065 106.496 9.27655 106.344 8.82697C106.089 8.07255 105.835 7.31813 105.58 6.56371C105.038 4.95856 104.463 3.29732 103.289 2.0756C101.518 0.231597 98.7022 -0.23751 96.1679 0.102341C92.9132 0.538774 89.702 2.30114 88.1887 5.21543C86.6753 8.12972 87.3196 12.1962 90.0541 14.0144C86.1569 18.4813 84.7983 23.4594 85.0129 29.3836C85.2274 35.3077 91.6832 40.7598 95.8932 44.9331C96.8334 44.3632 97.6882 41.6922 97.1712 40.722C96.6541 39.7517 97.3949 38.6276 96.7546 37.7338C96.1143 36.8401 95.5786 38.2632 96.2259 37.3745C96.6344 36.8138 95.0401 35.5237 95.6473 35.1881C98.5841 33.5647 99.5609 29.9041 101.405 27.1009C103.63 23.7197 107.438 21.4299 111.468 21.05C113.687 20.8408 116.032 21.2198 117.851 22.5085C119.671 23.7972 120.849 26.1001 120.427 28.2894C121.52 27.1799 122.064 25.554 121.858 24.0103C121.653 22.4667 120.704 21.0392 119.359 20.2536C120.177 17.5501 119.476 14.4401 117.579 12.348C115.681 10.2559 107.983 10.6122 105.213 11.1628"
        fill="#2F2E41"
      />
      <path
        d="M104.997 21.1323C101.328 21.5284 98.6788 24.7067 96.4418 27.6419C95.1524 29.3337 93.8017 31.202 93.8341 33.3289C93.8668 35.4792 95.3045 37.3236 95.9916 39.3614C97.1147 42.6924 96.0201 46.6554 93.3467 48.9377C95.9884 49.439 98.8441 47.4583 99.3002 44.8084C99.831 41.7236 97.4926 38.7464 97.7696 35.6285C98.0136 32.8816 100.178 30.7676 102.018 28.7138C103.859 26.6599 105.587 23.9346 104.74 21.3101"
        fill="#2F2E41"
      />
      <path
        d="M105.7 150.846C105.7 150.846 75.0152 153.889 72.7329 150.338C70.4506 146.788 103.872 143.238 103.872 143.238L105.7 150.846Z"
        fill="#A0616A"
      />
      <path
        d="M86.1349 93.9556L85.501 97.3212C87.2276 99.8695 87.3928 102.564 86.3885 105.367L121.384 105.621C121.008 101.829 120.596 97.7675 122.037 94.041L86.1349 93.9556Z"
        fill="#A0616A"
      />
      <path
        d="M143.456 118.978L130.764 108.824C129.078 107.475 127.986 105.469 127.768 103.32L125.812 83.9776L137.464 80.0938L140.273 99.2458L147.275 115.159L143.456 118.978V118.978Z"
        fill="#A0616A"
      />
      <path
        d="M94.8841 51.479L111.875 50.7183L129.88 59.2944L122.526 94.336L85.2476 97.1255L80.1758 58.326L94.8841 51.479Z"
        fill="#49DCB1"
      />
      <path
        d="M122.037 102.41C124.59 105.775 125.662 113.877 126.748 121.936C126.748 121.936 79.5797 121.683 79.3261 121.683C79.0726 121.683 86.5345 104.692 86.5345 104.692L122.037 102.41H122.037Z"
        fill="#2F2E41"
      />
      <path
        d="M123.578 119.787L126.748 121.936C126.748 121.936 171.634 118.893 167.83 133.094C164.026 147.295 152.107 149.578 152.107 149.578L103.925 152.367L103.417 144.506C103.417 144.506 57.0102 154.903 52.6992 133.602C48.3881 112.3 79.3263 121.683 79.3263 121.683L123.578 119.787L123.578 119.787V119.787Z"
        fill="#2F2E41"
      />
      <path
        d="M104.638 136.644C104.638 136.644 105.699 131.065 113.307 133.348C120.915 135.63 125.226 138.673 125.226 138.673L106.967 143.238L104.638 136.644Z"
        fill="#A0616A"
      />
      <path
        d="M123.667 58.7063C123.667 58.7063 132.289 55.6632 135.585 65.5532C138.882 75.4433 138.121 83.5582 138.121 83.5582L125.442 87.6157L123.667 58.7063H123.667V58.7063Z"
        fill="#49DCB1"
      />
      <path
        d="M64.5431 120.521L61.7769 115.647L67.7533 100.769L70.5627 81.6152L82.2141 85.4991L80.2346 105.075C80.0323 107.076 79.016 108.943 77.4463 110.199L64.5431 120.521H64.5431Z"
        fill="#A0616A"
      />
      <path
        d="M84.3602 58.7063C84.3602 58.7063 75.7381 55.6632 72.4414 65.5532C69.1447 75.4433 69.9055 83.5582 69.9055 83.5582L82.585 87.6157L84.3602 58.7063V58.7063Z"
        fill="#49DCB1"
      />
      <path
        d="M147.124 121.217C150.485 121.217 153.21 117.186 153.21 112.214C153.21 107.242 150.485 103.212 147.124 103.212C143.762 103.212 141.038 107.242 141.038 112.214C141.038 117.186 143.762 121.217 147.124 121.217Z"
        fill="#A0616A"
      />
      <path
        d="M61.6633 122.738C65.0246 122.738 67.7495 118.708 67.7495 113.736C67.7495 108.764 65.0246 104.733 61.6633 104.733C58.302 104.733 55.5771 108.764 55.5771 113.736C55.5771 118.708 58.302 122.738 61.6633 122.738Z"
        fill="#A0616A"
      />
      <path
        d="M84.9053 128.783L106.968 135.123L111.279 142.731L103.034 144.506"
        fill="#2F2E41"
      />
    </svg>
  )
}

export default NoSk
