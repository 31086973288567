import find from 'lodash/find'

const states = [
  { key: 'alberta', pretty: 'Alberta', abbreviation: 'AB' },
  { key: 'britishColumbia', pretty: 'British Columbia', abbreviation: 'BC' },
  { key: 'manitoba', pretty: 'Manitoba', abbreviation: 'MB' },
  { key: 'newBrunswick', pretty: 'New Brunswick', abbreviation: 'NB' },
  {
    key: 'newfoundlandAndLabrador',
    pretty: 'Newfoundland and Labrador',
    abbreviation: 'NL',
  },
  {
    key: 'northwestTerritories',
    pretty: 'Northwest Territories',
    abbreviation: 'NT',
  },
  { key: 'novaScotia', pretty: 'Nova Scotia', abbreviation: 'NS' },
  { key: 'nunavut', pretty: 'Nunavut', abbreviation: 'NU' },
  { key: 'ontario', pretty: 'Ontario', abbreviation: 'ON' },
  {
    key: 'princeEdwardIsland',
    pretty: 'Prince Edward Island',
    abbreviation: 'PE',
  },
  { key: 'quebec', pretty: 'Quebec', abbreviation: 'QC' },
  { key: 'saskatchewan', pretty: 'Saskatchewan', abbreviation: 'SK' },
  { key: 'yukon', pretty: 'Yukon', abbreviation: 'YT' },
]

export const getStateAbbreviationByPretty = (pretty) => {
  if (!pretty) return

  const foundState = find(states, (state) => pretty === state.pretty)

  return foundState?.abbreviation
}

export default states
