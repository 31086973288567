import React from 'react'

import SelectField from './SelectField'
import Text from '../../typography/Text'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

export enum AllowanceFrequency {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

const FrecuencySelectField = ({
  label = <Text token="FREQUENCY" />,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <SelectField css={styles.root} label={label} hideDefaultOption {...rest}>
      <option value={AllowanceFrequency.Monthly}>
        <Text token="MONTHLY" />
      </option>
      <option value={AllowanceFrequency.Weekly}>
        <Text token="WEEKLY" />
      </option>
    </SelectField>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default FrecuencySelectField
