import React from 'react'
import Form from '../Form'

import SwitchField from '../fields/SwitchField'
import Header from '../../typography/Header'
import Text from '../../typography/Text'
import { Theme, useTheme } from '@emotion/react'
import FieldCard from '../../cards/FieldCard'

const NotificationsSettingsForm = ({ submit, loading, disabled, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      initialValues={{
        pushNotificationsGroup: false,
        pushNotifications: {
          transactional: false,
          system: false,
          warnings: false,
          balanceReminders: false,
        },
        emailNotificationsGroup: false,
        emailNotifications: {
          transactional: false,
          system: false,
          warnings: false,
          warningsAndReminders: false,
        },
        remindersGroup: false,
        reminders: {
          lowBalance: false,
          SKLowBalance: false,
          warningsAndReminders: false,
        },
      }}
      {...rest}
    >
      <SwitchField
        label={
          <Header token="PUSH_NOTIFICATIONS" css={styles.sectionToggleLabel} />
        }
        name="pushNotificationsGroup"
        css={styles.sectionToggle}
        disabled
      />

      <FieldCard css={styles.card} gap={0}>
        <SwitchField
          label={<Text token="TRANSACTIONAL" />}
          name="pushNotifications.transactional"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="SYSTEM" />}
          name="pushNotifications.system"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="WARNINGS" />}
          name="pushNotifications.warnings"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="BALANCE_REMINDERS" />}
          name="pushNotifications.balanceReminders"
          css={styles.field}
          small
          disabled
        />
      </FieldCard>

      <SwitchField
        label={
          <Header token="EMAIL_NOTIFICATIONS" css={styles.sectionToggleLabel} />
        }
        name="emailNotificationsGroup"
        css={styles.sectionToggle}
        disabled
      />

      <FieldCard css={styles.card} gap={0}>
        <SwitchField
          label={<Text token="TRANSACTIONAL" />}
          name="emailNotifications.transactional"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="SYSTEM" />}
          name="emailNotifications.system"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="WARNINGS_AND_REMINDERS" />}
          name="emailNotifications.warningsAndReminders"
          css={styles.field}
          small
          disabled
        />
      </FieldCard>

      <SwitchField
        label={<Header token="REMINDERS" css={styles.sectionToggleLabel} />}
        name="remindersGroup"
        css={styles.sectionToggle}
        disabled
      />

      <FieldCard css={styles.card} gap={0}>
        <SwitchField
          label={<Text token="BALANCE_IS_TOO_LOW" />}
          name="reminders.lowBalance"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="SK_BALANCE_IS_TOO_LOW" />}
          name="reminders.SKLowBalance"
          css={styles.field}
          small
          disabled
        />
        <SwitchField
          label={<Text token="WARNINGS_AND_REMINDERS" />}
          name="reminders.warningsAndReminders"
          css={styles.field}
          small
          disabled
        />
      </FieldCard>
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {},
  card: {
    borderRadius: 16,
    gridGap: 0,
    overflow: 'hidden',
    padding: 0,
  },
  sectionToggle: {
    border: 'none',
  },
  sectionToggleLabel: {
    color: theme.SECTION_TITLE,
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '28px',
    margin: 0,
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
    borderRadius: 0,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
})

export default NotificationsSettingsForm
