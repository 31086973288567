import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const ShoppingIcon = ({ size = 1, color = '#464E58', width, height }) => {
    const defaultWidth = 16
    const defaultHeight = 16
    let factor = getIconSizeFactor({
        size,
        width,
        height,
        defaultWidth,
        defaultHeight,
    })

    return (
        <svg
            width={defaultWidth * factor} 
            height={defaultHeight * factor}
            viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.46659 0.933293C3.59249 0.765422 3.79008 0.666626 3.99992 0.666626H11.9999C12.2098 0.666626 12.4073 0.765422 12.5333 0.933293L14.5333 3.59996C14.6198 3.71536 14.6666 3.85571 14.6666 3.99996V13.3333C14.6666 13.8637 14.4559 14.3724 14.0808 14.7475C13.7057 15.1226 13.197 15.3333 12.6666 15.3333H3.33325C2.80282 15.3333 2.29411 15.1226 1.91904 14.7475C1.54397 14.3724 1.33325 13.8637 1.33325 13.3333V3.99996C1.33325 3.85571 1.38004 3.71536 1.46659 3.59996L3.46659 0.933293ZM4.33325 1.99996L2.66659 4.22218V13.3333C2.66659 13.5101 2.73682 13.6797 2.86185 13.8047C2.98687 13.9297 3.15644 14 3.33325 14H12.6666C12.8434 14 13.013 13.9297 13.138 13.8047C13.263 13.6797 13.3333 13.5101 13.3333 13.3333V4.22218L11.6666 1.99996H4.33325Z"
                fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33325 4.00004C1.33325 3.63185 1.63173 3.33337 1.99992 3.33337H13.9999C14.3681 3.33337 14.6666 3.63185 14.6666 4.00004C14.6666 4.36823 14.3681 4.66671 13.9999 4.66671H1.99992C1.63173 4.66671 1.33325 4.36823 1.33325 4.00004Z"
                fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33341 6C5.7016 6 6.00008 6.29848 6.00008 6.66667C6.00008 7.1971 6.21079 7.70581 6.58587 8.08088C6.96094 8.45595 7.46965 8.66667 8.00008 8.66667C8.53051 8.66667 9.03922 8.45595 9.41429 8.08088C9.78937 7.70581 10.0001 7.1971 10.0001 6.66667C10.0001 6.29848 10.2986 6 10.6667 6C11.0349 6 11.3334 6.29848 11.3334 6.66667C11.3334 7.55072 10.9822 8.39857 10.3571 9.02369C9.73198 9.64881 8.88414 10 8.00008 10C7.11603 10 6.26818 9.64881 5.64306 9.02369C5.01794 8.39857 4.66675 7.55072 4.66675 6.66667C4.66675 6.29848 4.96522 6 5.33341 6Z"
                fill={color} />
        </svg>

    )
}

export default ShoppingIcon
