import React from 'react'

import Flex from '../../components/Flex'
import TeachKids from '../../components/TeachKids'

const ProductsScene = () => {
  return (
    <Flex>
      <TeachKids />
    </Flex>
  )
}

export default ProductsScene
