import React from 'react'
import { useQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'

import List from '../lists/List'
import TransferActivityItem from '../lists/items/TransferActivityItem'

import { teamHoldingAccount } from '../../queries'
import useCurrentUser from '../../hooks/useCurrentUser'
import ComponentCard from './ComponentCard'

import { sortTransactionsByDate } from '../../utils/functions'

const RecentTransfersCard = () => {
  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
  } = useCurrentUser(false)

  const {
    data: teamHoldingAccountData,
    loading: teamHoldingAccountLoading,
    refetch: teamHoldingAccountRefetch,
  } = useQuery(teamHoldingAccount, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  })

  const loading = userLoading || teamHoldingAccountLoading
  const transactions = userData?.bankAccounts?.[0]?.transactions?.edges

  const holdingAccountTransactions =
    teamHoldingAccountData?.teamHoldingAccount?.holdingAccountTransactions
      ?.edges

  const refetch = async () =>
    await Promise.all([userRefetch(), teamHoldingAccountRefetch()])

  const mergedTransactions = holdingAccountTransactions
    ? transactions?.concat(holdingAccountTransactions)
    : transactions

  const empty = isEmpty(mergedTransactions)

  return (
    <ComponentCard
      token="RECENT_ACTIVITY"
      loading={loading}
      empty={empty}
      retry={() => refetch()}
    >
      <List vertical gap="1rem">
        {mergedTransactions
          ?.sort(sortTransactionsByDate)
          .slice(0, 5)
          .map((transaction) => (
            <TransferActivityItem
              key={transaction.cursor}
              transaction={transaction}
            />
          ))}
      </List>
    </ComponentCard>
  )
}

export default RecentTransfersCard
