import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import React from 'react'
import { usePopper } from 'react-popper'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Placement } from '@popperjs/core'

import Flex from './Flex'

const DEFAULT_PLACEMENT: Placement = 'bottom-start'
const DEFAULT_OFFSET: [number, number] = [0, 0]

export const DropdownContext = React.createContext<{
  selectedElement?: React.ReactNode
  setSelectedElement?: Function
  open?: boolean
  setOpen?: Function
  toggle?: Function
  referenceElement?: any
  setReferenceElement?: Function
  value?: string
  setValue?: Function
  handleSelect?: Function
}>({})

export type DropdownTriggerProps = {
  className?: string
} & React.PropsWithChildren

export const DropdownTrigger = ({
  children,
  className,
}: DropdownTriggerProps) => {
  const { toggle } = React.useContext(DropdownContext)
  const { theme } = useTheme()

  const customStyles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Flex
      css={customStyles.trigger}
      centerX
      centerY
      onClick={(e) => {
        e.stopPropagation()
        toggle()
      }}
      className={className}
    >
      {children}
    </Flex>
  )
}

export type DropdownOutletProps = {
  placement?: Placement
  offset?: [number, number]
  rowHeight?: number
  className?: string
} & React.PropsWithChildren

export const DropdownOutlet = ({
  placement = DEFAULT_PLACEMENT,
  offset = DEFAULT_OFFSET,
  children,
  className,
}: DropdownOutletProps) => {
  const { open, referenceElement, setOpen } = React.useContext(DropdownContext)

  const [popperElement, setPopperElement] = React.useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  })

  const blurRef = useDetectClickOutside({
    onTriggered: () => {
      setOpen(false)
    },
    disableTouch: true,
  })

  const { theme } = useTheme()

  const customStyles = React.useMemo(
    () => computeStyles(theme, referenceElement?.offsetWidth),
    [theme, referenceElement?.offsetWidth]
  )

  return (
    open && (
      <div
        ref={setPopperElement}
        css={{ ...styles.popper, ...customStyles.popper }}
        {...attributes.popper}
        className={className}
      >
        <div ref={blurRef}>{children}</div>
      </div>
    )
  )
}

export type DropdownProps = {
  forceOpen?: boolean
  className?: string
} & React.PropsWithChildren

const Dropdown: React.FC<DropdownProps> = ({
  children,
  className,
}): JSX.Element => {
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState()
  const toggle = () => setOpen(!open)
  const handleSelect = (value) => {
    setValue(value)
    toggle()
  }

  let escapeKeyListener

  React.useEffect(() => {
    escapeKeyListener = document.addEventListener(
      'keyup',
      ({ key }: KeyboardEvent) => {
        if (key === 'Escape') setOpen(false)
      }
    )

    return () => {
      document.removeEventListener('keyup', escapeKeyListener)
    }
  }, [])

  return (
    <DropdownContext.Provider
      value={{
        open,
        setOpen,
        toggle,
        referenceElement,
        setReferenceElement,
        value,
        setValue,
        handleSelect,
      }}
    >
      <div ref={setReferenceElement} className={className}>
        {children}
      </div>
    </DropdownContext.Provider>
  )
}

const computeStyles: TComputeStyles = (
  { BACKGROUND_ALT }: TTheme,
  popperWidth = 100
) => ({
  trigger: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    height: '100%',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'transparent',
    },
  },
  popper: {
    background: BACKGROUND_ALT,
    borderRadius: '8px',
    minWidth: popperWidth || 100,
    zIndex: 100,
  },
})

export default Dropdown
