import React from 'react'

import { useMutation } from '@apollo/client'
import set from 'lodash/set'

import DevButton from '../../../../../components/dev/DevButton'
import NewMemberInputDataForm from '../../../../../components/form/forms/NewMemberInputDataForm'

import FlowContext from '../../../../../context/FlowContext'
import { addSideKick } from '../../../../../queries'
import useRandomUser from '../../../../../hooks/useRandomUser'

const MemberInformationStep = () => {
  const { currentData, next, setFlowDataAttribute } =
    React.useContext(FlowContext)

  const [randomUser, setRandomUser] = React.useState({})

  const [{ data: randomUserData, loading: randomUserLoading }, getRandomUser] =
    useRandomUser()

  React.useEffect(() => {
    if (randomUserData) setRandomUser(randomUserData)
  }, [randomUserData])

  const [addSidekick, { loading }] = useMutation(addSideKick)

  const handleSubmit = async (formData) => {
    await setFlowDataAttribute('userData', formData)
    await addSidekick({ variables: formData })

    next()
  }

  const handleFieldUpdate = async (attribute, value) =>
    await setFlowDataAttribute(
      'userData',
      set({ ...currentData?.data?.userData }, attribute, value)
    )

  return (
    <>
      <DevButton
        label="Fill with Random User"
        onClick={getRandomUser}
        loading={randomUserLoading}
        type="button"
        button
      />

      <NewMemberInputDataForm
        submit={handleSubmit}
        enableReinitialize
        initialValues={{
          ...randomUser,
          ...currentData?.data?.userData,
        }}
        loading={loading}
        handleFieldUpdate={handleFieldUpdate}
      />
    </>
  )
}

export default MemberInformationStep
