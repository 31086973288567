import React from 'react'
import { Theme, useTheme } from '@emotion/react'
import clsx from 'clsx'

import Icon from './Icon'
import { IconType } from './icons'
import { TStatus } from './flows/FlowStatus'

type StatusIconProps = {
  status: TStatus
  className?: string
}

const statusIcons: { [key: string]: IconType } = {
  success: 'check',
  error: 'error',
  pending: 'cog',
}

const StatusIcon: React.FC<StatusIconProps> = ({ status, className }) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    [status]: status,
  })

  return (
    <div css={styles.root} className={classNames}>
      <Icon
        icon={statusIcons[status]}
        color={theme.TEXT_SECONDARY}
        width={40}
      />
    </div>
  )
}

const computeStyles = ({ ERROR, PENDING, SUCCESS }: Theme['theme']) => ({
  root: {
    width: 60,
    height: 60,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '100%',

    '&.success': {
      backgroundColor: SUCCESS,
    },

    '&.error': {
      backgroundColor: ERROR,
    },

    '&.pending': {
      backgroundColor: PENDING,
    },
  },
})

export default StatusIcon
