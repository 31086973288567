import useCurrentUser from './useCurrentUser'

export type transactionType = Function | Array<any> | boolean | any

const useTransactions = (id: string): Array<transactionType> => {
  const { data: userData, loading, refetch } = useCurrentUser(false, id)

  const transactions = userData?.bankAccounts?.[0]?.transactions?.edges

  return [transactions, loading, refetch]
}

export default useTransactions
