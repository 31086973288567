import React, { Children } from 'react'

import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { Pagination, A11y } from 'swiper'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Element from './Element'

import { getNumbersFromString } from '../utils/functions'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/a11y'

// Default values
const DEFAULT_SLIDE_PREVIEW_MOBILE = 1.5
const DEFAULT_SLIDE_PREVIEW_DESKTOP = 3.5
const DEFAULT_SPACE_BETWEEN = 20
const DEFAULT_HASH_KEY = 'slide'
const DEFAULT_CENTERED_SLIDES = true

// Types
type CarouselProps = {
  hashKey?: string
  children?: Array<any> | any
  slidesPerViewMobile?: number
  slidesPerViewDesktop?: number
  modules: Array<any>
  maxWidthForOnlyChild?: boolean
} & SwiperProps

/*
 * Component: Carousel (JSX.Element)
 * Responsive: Yes
 *
 * Usage:
 * <Carousel props>
 *  <Element> Slide n </Element>
 *  <Element> Slide n </Element>
 *  <Element> Slide n </Element>
 * </Carousel>
 */

const Carousel: React.FC<CarouselProps> = ({
  children,
  hashKey = DEFAULT_HASH_KEY,
  spaceBetween = DEFAULT_SPACE_BETWEEN,
  centeredSlides = DEFAULT_CENTERED_SLIDES,
  slidesPerViewMobile = DEFAULT_SLIDE_PREVIEW_MOBILE,
  slidesPerViewDesktop = DEFAULT_SLIDE_PREVIEW_DESKTOP,
  modules = [],
  maxWidthForOnlyChild,
  ...rest
}): JSX.Element => {
  const { breakpoints, theme } = useTheme()
  const DESKTOP_BREAKPOINT = getNumbersFromString(
    breakpoints.BREAKPOINT_DESKTOP
  )

  const styles = React.useMemo(
    () => computeStyles(theme, children?.length <= 1),
    [theme, children]
  )

  const isOneSlide = maxWidthForOnlyChild && Children.count(children) === 1

  return (
    <Element css={styles.root}>
      <Swiper
        modules={[Pagination, A11y, ...modules]}
        spaceBetween={spaceBetween}
        slidesPerView={isOneSlide ? 1 : slidesPerViewMobile}
        centeredSlides={centeredSlides}
        breakpoints={{
          [DESKTOP_BREAKPOINT]: {
            slidesPerView: isOneSlide ? 1 : slidesPerViewDesktop,
            spaceBetween: spaceBetween * 2,
          },
        }}
        pagination={{ clickable: true }}
        {...rest}
      >
        {children?.length
          ? children.map((e, i) => (
              <SwiperSlide
                key={`swiper_slide_${i}`}
                {...(e.props.key ? { key: e.props.key } : {})}
                data-hash={hashKey + i}
                data-history={e.props.to}
              >
                {e}
              </SwiperSlide>
            ))
          : children && (
              <SwiperSlide data-hash={hashKey} data-history={children.props.to}>
                {children}
              </SwiperSlide>
            )}
      </Swiper>
    </Element>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme, small) => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    '.swiper-pagination-bullet': {
      backgroundColor: BACKGROUND_ALT,
      opacity: 0.4,
    },
    '.swiper-pagination-bullet-active': {
      opacity: 1,
      backgroundColor: BACKGROUND_ALT,
    },

    '.swiper-wrapper': {
      paddingBottom: small ? 0 : 40,
    },
  },
})

export default Carousel
