import React from 'react'
import Text from '../../../../components/typography/Text'
import Flex from '../../../../components/Flex'
import isMobile from '../../../../mobile/utils/isMobile'
import { useTheme } from '@emotion/react'
import Icon from '../../../../components/Icon'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { generateOneTimeLoginToken } from '../../../../queries'
import Element from '../../../../components/Element'
import Button from '../../../../components/Button'

const SubscriptionMobile: React.FC<any> = () => {
  const [mutate, { loading }] = useMutation(generateOneTimeLoginToken)
  const username = useSelector(
    (state: { auth: { [key: string]: string } }) => state.auth.user?.username
  )
  const baseUrl =
    process.env.REACT_APP_WEB_DOMAIN || 'https://portal.herofinancials.app'

  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const handleOpenBrowser = async () => {
    const { data } = await mutate()
    const token = data?.generateOneTimeLoginToken?.token

    const inAppBrowser = InAppBrowser.create(
      `${baseUrl}/auth/tokenLogin?token=${token}&username=${username}`,
      '_system'
    )
    await inAppBrowser.show()
  }

  if (!isMobile()) {
    return null
  }

  return (
    <Flex vertical verticalAlignment="center">
      <Element css={styles.wrapper}>
        <Element css={styles.icon}>
          {<Icon size={2} color="PRIMARY" icon="switch"></Icon>}
        </Element>
        <Element>
          <Text token="NEED_SETUP_SUBSCRIPTION_ON_WEBSITE"></Text>
          <Button
            css={styles.btn}
            onClick={handleOpenBrowser}
            token="LAUNCH_PORTAL"
            loading={loading}
          />
        </Element>
      </Element>
    </Flex>
  )
}

const computeStyles = () => ({
  wrapper: {
    maxWidth: 630,
  },
  btn: {
    marginTop: 15,
  },
  icon: {
    margin: 20,
  },
})

export default SubscriptionMobile
