import React from 'react'

import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
} from '@recurly/react-recurly'

import Field from '../Field'

import Text from '../../typography/Text'
import Flex from '../../Flex'

export type PaymentFormProps = {
  user: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
}

type RecurlyFormType = {
  form: any
  onSubmit: Function
}

const resetErrorOnChange = (field, form) => {
  form?.current?.validateField(field)
}

const PaymentForm: React.FC<RecurlyFormType> = ({ form, onSubmit }) => {
  return (
    <>
      <Field
        label={<Text token="CREDIT_CARD_NUMBER" />}
        name={'number'}
        component={() => (
          <CardNumberElement
            onChange={() => resetErrorOnChange('number', form)}
            onSubmit={onSubmit}
          />
        )}
      />
      <Flex gap={10} stretchChildrenX>
        <Field
          label={<Text token="EXPIRATION_MONTH" />}
          name={'month'}
          component={() => (
            <CardMonthElement
              onChange={() => resetErrorOnChange('month', form)}
              onSubmit={onSubmit}
            />
          )}
        />
        <Field
          label={<Text token="EXPIRATION_YEAR" />}
          name={'year'}
          component={() => (
            <CardYearElement
              onSubmit={onSubmit}
              onChange={() => resetErrorOnChange('year', form)}
            />
          )}
        />
      </Flex>
      <Field
        label={<Text token="CVV" />}
        name={'cvv'}
        component={() => (
          <CardCvvElement
            onSubmit={onSubmit}
            onChange={() => resetErrorOnChange('csv', form)}
          />
        )}
      />
    </>
  )
}

export default PaymentForm
