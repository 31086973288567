import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const CheckIcon = ({ size = 2, color = '#398D8D', width, height }) => {
  const defaultWidth = 8
  const defaultHeight = 6
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 4.73744L1.0125 2.99993L0.420837 3.58744L2.75 5.9166L7.75 0.916602L7.1625 0.329102L2.75 4.73744Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckIcon
