import React from 'react'
import { useParams, useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import CardActivationForm from '../../../components/form/forms/CardActivationForm'

import { openCardOrders, registerCard, replaceCard } from '../../../queries'

import useCardByUser from '../../../hooks/useCardByUser'
import useWaitForNotification from '../../../hooks/useWaitForNotification'
import { toast } from 'react-hot-toast'
import { TPlainStyle } from '@emotion/react'

const EnterActivationCodeScene = ({ ...rest }) => {
  const { id } = useParams()
  const [processing, setProcessing] = React.useState(false)
  const navigate = useNavigate()

  const { refetch: refetchCardOrders } = useQuery(openCardOrders, {})

  const { data: foundCard, loading: foundCardLoading } = useCardByUser(id)
  const mutation = foundCard ? replaceCard : registerCard

  const [doRegisterCard, { data: registeredCard, loading: registering }] =
    useMutation(mutation)

  const { data: foundNotification } = useWaitForNotification([
    'InvalidActivationCode',
    'CardRegistered',
    'CardReplaced',
  ])

  React.useEffect(() => {
    switch (foundNotification?.code) {
      case 'CardRegistered':
        navigate(`/cards/activate/${id}/success`)
        break

      case 'CardReplaced':
        navigate(`/cards/activate/${id}/success`)

        break

      case 'InvalidActivationCode':
        setProcessing(false)

        break
    }
  }, [foundNotification])

  React.useEffect(() => {
    if (registeredCard) setProcessing(true)
  }, [registeredCard])

  const handleSubmit = async (formData) => {
    const variables = {
      ...formData,
      forUser: id,
    }
    const safeword = setTimeout(() => {
      toast.error('SOMETHING_WENT_WRONG_TRY_AGAIN_LATER')
      navigate(`/cards`)
    }, 30000)
    await doRegisterCard({ variables })
    await refetchCardOrders()
    clearTimeout(safeword)
  }

  const loading = foundCardLoading
  const submitLabel = foundCard ? 'REPLACE' : 'NEXT'
  const titleToken = foundCard ? 'REPLACE_CARD' : 'ACTIVATE_CARD'
  const processingToken = foundCard ? 'REPLACING_CARD' : 'ACTIVATING_CARD'

  return (
    <CardLayoutLink
      title={<Text token={titleToken} />}
      subtitle={<Text token="CARDS" />}
      loading={loading}
      processing={processing || registering}
      processingLabel={<Text token={processingToken} />}
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        <CardActivationForm
          submit={handleSubmit}
          loading={registering}
          submitLabel={submitLabel}
        />
      </Flex>
    </CardLayoutLink>
  )
}

const styles: TPlainStyle = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default EnterActivationCodeScene
