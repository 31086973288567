import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Icon from '../../components/Icon'
import Grid from '../../components/Grid'
import VerifiedEmail from '../../assets/svg/VerifiedEmail'
import Text from '../../components/typography/Text'
import Button from '../../components/Button'
import { useSearchParams } from 'react-router-dom'
import StatusIcon from '../../components/StatusIcon'
import { isWebBrowserMobile } from '../../mobile/utils/isMobile'
import { useNavigate } from 'react-router-dom'

const EmailConfirmationScene = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [search] = useSearchParams()
  const errorCode = search.get('error')
  const email = encodeURIComponent(search.get('email') || '')

  const toPath = !errorCode
    ? `/auth/login/${email ? `?&email=${email}` : ''}`
    : '/auth/reset-password'

  const btnProps = !isWebBrowserMobile() ? { to: toPath } : {}

  const handleMobileDeeplinking = () => {
    if (!isWebBrowserMobile() || errorCode) return
    const deeplinkURL: URL = new URL(
      process.env.REACT_APP_WEB_DOMAIN || 'https://portal.herofinancials.app'
    )
    const url = `hero://${deeplinkURL.hostname}`
    window.location.assign(url)
    // Deeplinking fallback
    setTimeout(() => {
      navigate(toPath)
    }, 1000)
  }

  return (
    <Grid gap="2rem" padding="1rem" centerX css={styles.root}>
      <Icon icon="brand_gradient" height={40.14} css={styles.brand} />
      {!errorCode && <VerifiedEmail size={1.2} />}
      {errorCode && <StatusIcon status="error" />}

      <Text
        css={styles.legend}
        token={
          !errorCode
            ? 'YOUR_EMAIL_HAS_BEEN_VERIFIED_SUCCESSFULLY'
            : 'INVALID_EMAIL_VERIFICATION_CODE'
        }
      />
      <Button
        css={styles.button}
        onClick={handleMobileDeeplinking}
        token={!errorCode ? 'PROCEED_TO_LOGIN' : 'RESET_PASSWORD'}
        {...btnProps}
      />
    </Grid>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY_DARK }: TTheme) => ({
  root: {
    maxWidth: 450,
    width: '100%',
  },
  brand: {
    padding: 8,
  },
  legend: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.5px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  button: {
    width: '100%',
  },
})

export default EmailConfirmationScene
