import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { cleanNullProps } from '../../../utils/functions'
import RadioField from '../fields/RadioField'
import Text from '../../typography/Text'

export type CitizenshipProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
}

const DEFAULT_CITIZENSHIP = 'Canada'

const schema = Yup.object().shape({
  citizenship: Yup.string().required(),
})

const CitizenshipForm: React.FC<CitizenshipProps> = ({
  data = {},
  submit,
  loading,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { citizenship } = data
  const defaultInitialValues = {
    citizenship: citizenship || DEFAULT_CITIZENSHIP,
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <RadioField
        label={<Text token="PERMANENT_RESIDENT_OR_CITIZEN" />}
        name="citizenship"
        value="Canada"
        css={styles.field}
      />
      <RadioField
        label={
          <>
            <Text token="INTERNATIONAL_STUDENT" /> (Coming Soon)
          </>
        }
        name="citizenship"
        value="International"
        css={styles.field}
        disabled
      />
      <RadioField
        label={
          <>
            <Text token="INTERNATIONAL_PARENT_OR_GUARDIAN" /> (Coming Soon)
          </>
        }
        name="citizenship"
        value="Guardian"
        css={styles.field}
        disabled
      />

      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  BACKGROUND,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default CitizenshipForm
