import React from 'react'
import set from 'lodash/set'
import NewMemberInputDataForm from '../../../components/form/forms/NewMemberInputDataForm'

import FlowContext from '../../../context/FlowContext'
import useAddSideKick from '../../../hooks/useAddSidekick'

import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import Text from '../../../components/typography/Text'
import useRandomUser from '../../../hooks/useRandomUser'
import DevButton from '../../../components/dev/DevButton'

const AddMemberStep = () => {
  const { currentData, next, setFlowDataAttribute } =
    React.useContext(FlowContext)

  const [randomUser, setRandomUser] = React.useState({})

  const [{ data: randomUserData, loading: randomUserLoading }, getRandomUser] =
    useRandomUser()

  React.useEffect(() => {
    if (randomUserData) setRandomUser(randomUserData)
  }, [randomUserData])

  const [addSidekick, { loading }] = useAddSideKick()

  const handleSubmit = async (formData) => {
    await setFlowDataAttribute('userData', formData)

    if (currentData.data.showSubscription) {
      next()
    } else {
      const newSk = await addSidekick({ variables: formData })
      const id = newSk?.data?.addSideKick?.userProfile?.id
      await setFlowDataAttribute('owner', id)
      // We do something with the newSidekick, like saving its id as owner
      next()
    }
  }

  const handleFieldUpdate = async (attribute, value) =>
    await setFlowDataAttribute(
      'userData',
      set({ ...currentData?.data?.userData }, attribute, value)
    )

  return (
    <FlowStepLayout>
      <DevButton
        label="Fill with Random User"
        onClick={getRandomUser}
        loading={randomUserLoading}
        type="button"
        button
      />
      <Text token="SIDEKICK_DETAILS_INFO" />
      <NewMemberInputDataForm
        submit={handleSubmit}
        enableReinitialize
        initialValues={{
          ...randomUser,
          ...currentData?.data?.userData,
        }}
        loading={loading}
        handleFieldUpdate={handleFieldUpdate}
      />
    </FlowStepLayout>
  )
}

export default AddMemberStep
