import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { Link } from 'react-router-dom'
import FormButton from '../Button'
import Form from '../Form'

import Field from '../Field'

import Flex from '../../Flex'
import Text from '../../typography/Text'
import { validators } from '../../../utils/constants'
import PasswordField from '../fields/PasswordField'
import { getCredentials } from '../../../mobile/biometric/biometric'

type LoginFormProps = {
  loading?: boolean
  submit: Function
  data?: { email?: string }
}

const LoginForm: React.FC<LoginFormProps> = ({ loading, submit, data }) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string()
      .test((value) => validators.PASSWORD.test(value))
      .required(),
  })

  const innerFormik = React.useRef(null)

  React.useEffect(() => {
    ;(async () => {
      const credentials = await getCredentials()
      if (credentials) {
        const { username: email, password } = credentials
        await innerFormik?.current?.setFieldValue('email', email)
        await innerFormik?.current?.setFieldValue('password', password)
        setTimeout(() => {
          innerFormik?.current?.handleSubmit()
        }, 100)
      }
    })()
  }, [innerFormik])

  return (
    <Form
      innerRef={innerFormik}
      initialValues={{
        email: data.email || '',
        password: '',
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
    >
      <Field
        label={<Text token="EMAIL_ADDRESS" />}
        name="email"
        type="email"
        css={styles.field}
        required
      />
      <PasswordField
        label={<Text token="PASSWORD" />}
        name="password"
        css={styles.field}
        required
      />
      <Flex vertical>
        <Link to="/auth/register" css={styles.link}>
          <Text token="DO_NOT_HAVE_AN_ACCOUNT" />
        </Link>
        <Link to="/auth/reset-password" css={styles.link}>
          <Text token="FORGOT_PASSWORD" />
        </Link>
      </Flex>

      <FormButton token="NEXT" css={styles.submit} loading={loading} />
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  PRIMARY,
}: TTheme) => ({
  link: {
    fontSize: 14,
    color: PRIMARY,
    textDecoration: 'none',
    padding: '12px 16px',
  },
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  submit: { marginTop: 30 },
})

export default LoginForm
