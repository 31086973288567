import React from 'react'
import { useMutation, useQuery } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'

import {
  getRecurringAllowance as getRecurringAllowanceQuery,
  recurringAllowances,
  updateRecurringAllowance as updateRecurringAllowanceMutation,
} from '../../../queries'
import useIsSidekick from '../../../hooks/useIsSidekick'
import useTeamMembers from '../../../hooks/useTeamMembers'
import { TPlainStyle } from '@emotion/react'
import RecurringAllowanceForm from '../../../components/form/forms/RecurringAllowanceForm'
import {
  getAmountPayload,
  sumCategoriesAllocate,
} from '../../../utils/allocate'
import useSubscriptionsAllowance from '../../../hooks/useSubscriptionsAllowance'
import { useNavigate, useParams } from 'react-router-dom'
import useTeamHoldingAccount from '../../../hooks/useTeamHoldingsAccount'

type RecurringAllowanceSceneProps = {
  title: JSX.Element
  subtitle?: JSX.Element
  back: string
}

const EditRecurringAllowanceScene: React.FC<RecurringAllowanceSceneProps> = ({
  ...rest
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { teamMembers, loading: teamMembersLoading } = useTeamMembers(
    true,
    true
  )
  const { data: holdingAccount, loading: holdingLoading } =
    useTeamHoldingAccount(true)

  const accounts = [holdingAccount?.teamHoldingAccount, ...teamMembers]

  const [updateRecurringAllowance, { loading: updating }] = useMutation(
    updateRecurringAllowanceMutation
  )
  const { refetch: refetchAllowances } = useQuery(recurringAllowances)
  const { data, loading: recurringAllowanceLoading } = useQuery(
    getRecurringAllowanceQuery,
    {
      variables: { id },
    }
  )

  const { allow, loading: allowanceLoading } = useSubscriptionsAllowance()

  const loading =
    recurringAllowanceLoading ||
    teamMembersLoading ||
    allowanceLoading ||
    holdingLoading

  const categoryAllocate =
    typeof allow === 'object' ? allow?.categoryAllocate : false

  const isSidekick = useIsSidekick()

  const handleSubmit = async (values) => {
    const {
      fromAllocate,
      toAllocate,
      frequency,
      repeatsEvery,
      amount,
      endsAfter,
      neverEnds,
      ...categories
    } = values
    const categoriesAmounts = Array(6)
      .fill(0)
      .map((_, index) => {
        const categoryKey = `cat_00${index + 1}`
        return {
          categoryKey,
          ammountAllocated: {
            ...getAmountPayload(categories[categoryKey] || 0),
          },
        }
      })
    const variables = {
      id,
      targetBankAccount: toAllocate?.bankAccounts?.[0]?.bankAccountId,
      frequency,
      repeatsEvery,
      amount: amount
        ? getAmountPayload(amount)
        : sumCategoriesAllocate(categories),
      endsAfter: neverEnds === true ? null : endsAfter,
      categories: categoriesAmounts,
    }

    await updateRecurringAllowance({ variables })
    await refetchAllowances()
    navigate('/settings/recurring-allowance')
  }

  return (
    <CardLayoutLink
      loading={loading}
      processing={updating}
      processingLabel={<Text token={'SAVING'} />}
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        {data && (
          <RecurringAllowanceForm
            loading={loading || updating}
            disabled={isSidekick}
            teamMembers={accounts}
            submit={handleSubmit}
            categoryAllocate={categoryAllocate}
            data={data?.recurringAllowance}
          />
        )}
      </Flex>
    </CardLayoutLink>
  )
}

const styles: TPlainStyle = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
}

export default EditRecurringAllowanceScene
