import React from 'react'
import { useNavigate } from 'react-router-dom'

import { StatusProps } from './Status'
import Button from '../Button'
import DelayedRedirect from '../DelayedRedirect'

const StatusServerError: React.FC<StatusProps> = ({
  error,
  action,
  loading,
  redirect = true,
  label,
}) => {
  const navigate = useNavigate()

  const handleAction = () => {
    if (action) return action()

    return navigate(-1)
  }

  const defaultLabel = <>Go Back {redirect && <DelayedRedirect back />}</>

  return (
    <div css={styles.root}>
      <div css={styles.header}>
        We are having issues right now. Try again later, please.
      </div>

      <Button
        label={label || defaultLabel}
        button
        onClick={handleAction}
        css={{ marginTop: '1rem' }}
      />
    </div>
  )
}

const styles = {
  root: {
    maxWidth: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  header: {
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
}

export default StatusServerError
