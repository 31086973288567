import React from 'react'
import clsx from 'clsx'
import ComponentCard from './ComponentCard'
import Detail from '../typography/Detail'
import Flex from '../Flex'
import Grid from '../Grid'
import Icon from '../Icon'
import IconCircle from '../IconCircle'
import Text from '../typography/Text'
import { getAmount } from '../../utils/functions'
import { useTheme } from '@emotion/react'

import { getCategoryDictByKey } from '../../utils/categories'

type CategoryCardType = {
  bottomElement?: JSX.Element
  category?: any
  className?: string
  disabled?: boolean
  loading?: boolean
}

const CategoryCard: React.FC<CategoryCardType> = ({
  bottomElement,
  category,
  className,
  disabled,
  loading,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const CurrentCategory = React.useMemo(
    () => getCategoryDictByKey(category?.key),
    [category]
  )

  const funds = getAmount(category?.fundsAvailable)

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  return (
    <ComponentCard
      className={classNames}
      loading={loading}
      css={styles.textColor}
      {...rest}
    >
      <Flex centerY gap="1rem">
        <IconCircle
          icon={CurrentCategory?.icon}
          iconColor={CurrentCategory?.iconColor}
          color={CurrentCategory?.bgColor || 'RED_400'}
          size={56}
          scale={1.5}
        />
        <Grid gap={5} vertical stretch>
          <Text token={CurrentCategory?.displayName} />
          <Flex vertical>
            <Detail token="AVAILABLE" css={styles.detail} uppercase />
            <Text currency={funds} />
          </Flex>
        </Grid>
        {!bottomElement && <Icon icon="chevron" color="BLACK_700" />}
        {bottomElement}
      </Flex>
    </ComponentCard>
  )
}

const computeStyles = ({ TEXT_PRIMARY_DARK, TEXT_PRIMARY_LIGHT }) => ({
  detail: {
    fontSize: 12,
    letterSpacing: 1,
    color: TEXT_PRIMARY_LIGHT,
  },
  textColor: {
    color: TEXT_PRIMARY_DARK,
    '&.is-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
})

export default CategoryCard
