import React, { useMemo } from 'react'
import { useTheme } from '@emotion/react'
import Form from '../Form'
import FormButton from '../Button'
import Field from '../Field'
import Text from '../../typography/Text'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const FIELD_NAME: string = 'unsubscribe'

type UnsubscribeFormProps = {
  submit: Function
  loading?: boolean
  disabled?: boolean
}

const UnsubscribeForm: React.FC<UnsubscribeFormProps> = ({
  submit = () => {},
  loading,
  ...rest
}) => {
  const defaultInitialValues = {
    unsubscribe: undefined,
  }
  const { t } = useTranslation()
  const { theme } = useTheme()

  const [disabled, setDisabled] = React.useState<boolean>(true)

  const CONFIRM_WORD = t('UNSUBSCRIBE').toUpperCase()

  const styles = useMemo(() => computeStyles(theme), [theme])
  const schema = useMemo(
    () =>
      Yup.object().shape({
        [FIELD_NAME]: Yup.string().equals([CONFIRM_WORD]).required(),
      }),
    [CONFIRM_WORD]
  )

  const checkConfirmation = (name, value) => {
    if (name === FIELD_NAME) {
      setDisabled(value !== CONFIRM_WORD)
    }
  }

  return (
    <Form
      onSubmit={submit}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      handleFieldUpdate={checkConfirmation}
      {...rest}
    >
      <Field
        css={styles.field}
        name={FIELD_NAME}
        label={<Text token="CONFIRM_UNSUBSCRIBE" />}
      />
      <FormButton
        loading={loading}
        disabled={disabled}
        styleType="error"
        token="UNSUBSCRIBE"
      />
    </Form>
  )
}

const computeStyles = ({ BACKGROUND_ALT }) => ({
  field: {
    background: BACKGROUND_ALT,
  },
})

export default UnsubscribeForm
