import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { storeVerificationTransaction } from '../queries/mutations'

export const ID_VERIFICATION_STATUS = {
  PENDING: 'PENDING',
  MATCH: 'MATCH',
  NOMATCH: 'NOMATCH',
}

const useTrulioo = (callback = (status) => {}) => {
  const [trulioo, setTrulioo] = useState()
  const [status, setStatus] = useState()
  const [mutateVerification, { loading, error }] = useMutation(
    storeVerificationTransaction
  )

  const handleResponse = async (e) => {
    if (e.experienceTransactionId) {
      try {
        const response = await mutateVerification({
          variables: {
            transactionId: e.experienceTransactionId,
          },
        })
        const status = response?.data?.storeVerificationTransaction?.status
        setStatus(status)
        callback(status)
      } catch (err) {
        setStatus(ID_VERIFICATION_STATUS.NOMATCH)
        callback(ID_VERIFICATION_STATUS.NOMATCH)
      }
    }
  }

  useEffect(() => {
    if (window.TruliooClient) {
      const instance = new window.TruliooClient({
        publicKey: process.env.REACT_APP_TRULIOO_CLIENT_KEY,
        accessTokenURL: process.env.REACT_APP_TRULIOO_ACCESS_TOKEN_URL,
        handleResponse,
      })

      setTrulioo(instance)
    }
  }, [])

  return { status, trulioo, loading, error }
}

export default useTrulioo
