import React, { useContext } from 'react'

import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import TabsCard, { TabsCardsProps } from '../../../../components/cards/TabsCard'
import Text from '../../../../components/typography/Text'
import Heading from '../../../../components/typography/Heading'
import Tab from '../../../../components/Tab'
import SubscriptionsList from '../../../../components/lists/SubscriptionsList'
import useSubscriptions from '../../../../hooks/useSubscriptions'
import { SORTED_SUBSCRIPTIONS } from '../../../../utils/subscription'
import isMobile from '../../../../mobile/utils/isMobile'
import FlowContext from '../../../../context/FlowContext'
import SubscriptionMobile from './subscriptions-mobile'

type SubscriptionSelectProps = {
  fromMobile?: boolean
  flowName: string
} & TabsCardsProps

const SubscriptionSelect: React.FC<SubscriptionSelectProps> = ({
  fromMobile,
  flowName,
}) => {
  const { data, loading } = useSubscriptions()
  const { next, setFlowData } = useContext(FlowContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSelectPlan = async (code) => {
    setFlowData
      ? await setFlowData({ plan: code, fromMobile })
      : (await dispatch.flow.SET_FLOW_DATA(flowName, {
          plan: code,
          fromMobile,
        })) &&
        (await dispatch.flow.SET_FLOW_META(flowName, { step: 2, fromMobile }))
    next ? next() : await navigate('/settings/subscriptions/new')
  }

  let indexFix = 0

  const activeSubscriptionCode = data?.subscription?.subscriptionPlan?.code
  const activeSubscriptionStatus = data?.subscription?.state

  if (activeSubscriptionStatus && activeSubscriptionStatus === 'Canceled')
    indexFix = -1

  const activeSubscriptionIndex = activeSubscriptionCode
    ? SORTED_SUBSCRIPTIONS.findIndex(
        (plan) => plan === activeSubscriptionCode
      ) + indexFix
    : -1

  if (isMobile()) {
    return <SubscriptionMobile />
  }

  const showAnnualy = false

  return (
    <>
      <Heading token="AVAILABLE_PLANS" as="h2" css={styles.heading} />
      <TabsCard
        empty={isEmpty(data)}
        css={styles.transparentCard}
        defaultTab={'monthly'}
        loading={loading}
        centerX={false}
      >
        <Tab
          primaryActiveColor
          id="monthly"
          element={
            <SubscriptionsList
              filter="monthly"
              select={handleSelectPlan}
              activeSubscriptionIndex={activeSubscriptionIndex}
              subscriptionPlans={data?.subscriptionPlans}
            />
          }
          label={<Text token="PAID_MONTHLY" />}
        />
        {showAnnualy && (
          <Tab
            primaryActiveColor
            id="annually"
            element={
              <SubscriptionsList
                filter="annually"
                select={handleSelectPlan}
                activeSubscriptionIndex={activeSubscriptionIndex}
                subscriptionPlans={data?.subscriptionPlans}
              />
            }
            label={<Text token="PAID_ANNUALLY" />}
          />
        )}
      </TabsCard>
    </>
  )
}

const styles = {
  transparentCard: {
    background: 'none',
    border: 0,
    padding: 0,
  },
  heading: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: 0,
    paddingLeft: 5,
  },
}

export default SubscriptionSelect
