import React from 'react'
import AnimatedSpinner from '../../components/AnimatedSpinner'
import { signOut } from '../../services/auth'
import { useDispatch } from 'react-redux'
import Element from '../../components/Element'
import { useNavigate, useSearchParams } from 'react-router-dom'

const LoginTokenScene = () => {
  const dispatch = useDispatch()
  const [search] = useSearchParams()
  const navigate = useNavigate()

  const token = search.get('token')
  const username = search.get('username')

  React.useEffect(() => {
    ;(async () => {
      if (token && username) {
        await signOut()
        try {
          await dispatch.auth.customSignIn({ username, token })
          navigate('/settings/subscriptions/mobile')
        } catch (err) {
          navigate('/auth/login')
        }
      } else {
        navigate('/auth/login')
      }
    })()
  }, [])

  return (
    <Element css={styles.root}>
      <AnimatedSpinner />
    </Element>
  )
}
const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
export default LoginTokenScene
