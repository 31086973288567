import React from 'react'
import { useTheme } from '@emotion/react'

import Switch from '../Switch'
import Text from '../typography/Text'
import IconCircle from '../IconCircle'
import Grid from '../Grid'
import Flex from '../Flex'
import ComponentCard from './ComponentCard'

import useIsSidekick from '../../hooks/useIsSidekick'
import useATM, { useATMToggle, getUpdatedCacheData } from '../../hooks/useAtm'
import { teamMembers } from '../../queries'

type AtmToggleCardProps = {
  id: string
}

const AtmToggleCard: React.FC<AtmToggleCardProps> = ({ id }) => {
  const { isCashWithdrawalDisabled, allowATM, bankAccountId, teamMembersData } =
    useATM(id)
    
  const [AtmEnable, setAtmEnable] = React.useState(!isCashWithdrawalDisabled)

  const [mutateATM] = useATMToggle(id, bankAccountId, AtmEnable)
  const isSk = useIsSidekick()

  const { theme } = useTheme()
  const styles = React.useMemo(
    () =>
      computeStyles({
        theme,
      }),
    [theme]
  )

  const handleChange = () => {
    mutateATM({
      variables: {
        accountOwnerId: id,
        bankAccountId,
      },
      update: (store, { data }) => {
        const cacheData = getUpdatedCacheData(teamMembersData, id, data)
        store.writeQuery({
          query: teamMembers,
          data: { ...cacheData },
        })
      },
    })

    setAtmEnable(!AtmEnable)
  }

  React.useEffect(() => {
    setAtmEnable(!isCashWithdrawalDisabled)
  }, [isCashWithdrawalDisabled, id])

  return (
    <ComponentCard>
      <Flex centerY gap="1rem">
        <IconCircle
          size={32}
          scale={1}
          icon="lock"
          iconColor="BLACK_900"
          color="TURQUOISE_300"
        ></IconCircle>
        <Grid gap={5} stretch>
          <Text css={styles.text} token="ATM_ACCESS" />
        </Grid>
        <Switch
          disabled={!!!bankAccountId || !allowATM || isSk}
          onChange={handleChange}
          checked={AtmEnable}
        />
      </Flex>
    </ComponentCard>
  )
}

const computeStyles = () => ({
  text: {
    fontSize: '14px',
  },
})

export default AtmToggleCard
