import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import SessionSplash from './SessionSplash'

const Session = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const tokenCondition = pathname === '/auth/tokenLogin'

  useEffect(() => {
    const getSession = async () => {
      await dispatch.auth.getCurrentSession()
      setLoading(false)
    }

    if (!tokenCondition) {
      getSession()
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <>
      {(!loading || tokenCondition) && <Outlet />}
      {loading && <SessionSplash />}
    </>
  )
}

export default Session
