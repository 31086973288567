import React from 'react'

import useCurrentUser from '../../../../hooks/useCurrentUser'
import {
  RecurlyProvider,
  Elements as RecurlyElements,
} from '@recurly/react-recurly'

import PaymentForm from '../../../../components/form/forms/PaymentForm'
import Card from '../../../../components/cards/Card'
import FlowContext from '../../../../context/FlowContext'
import { toast } from 'react-hot-toast'
import Heading from '../../../../components/typography/Heading'

import validateToken from '../../../../services/subscriptions'

type SubscriptionPaymentProps = {}

const SubscriptionPayment: React.FC<SubscriptionPaymentProps> = ({
  ...rest
}) => {
  const { data, loading, refetch } = useCurrentUser()
  const { next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (tokenId: string, isLoading: Function) => {
    isLoading(true)
    await validateToken(tokenId)
      .then(() => {
        setFlowData({ tokenId })
        next()
      })
      .catch((error) => {
        toast.error(error.message)
      })
      .finally(async () => {
        await refetch()
        isLoading(false)
      })
  }
  return (
    <Card css={styles.root} loading={loading}>
      <Heading token="SELECT_PLAN" as="h2" css={styles.heading} />
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
        <RecurlyElements>
          <PaymentForm
            submit={handleSubmit}
            user={data?.userProfile}
            {...rest}
          />
        </RecurlyElements>
      </RecurlyProvider>
    </Card>
  )
}

const styles = {
  root: {
    padding: 0,
  },
  heading: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: 0,
    paddingLeft: 5,
  },
}

export default SubscriptionPayment
