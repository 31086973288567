import React from 'react'
import { useTheme } from '@emotion/react'

import Card from './Card'

const FieldCard = ({ ...rest }) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  return <Card css={styles.root} {...rest} />
}

const computeStyles = ({ BLACK_200 }) => ({
  root: {
    background: 'white',
    border: `1px solid ${BLACK_200}`,
    borderRadius: 20,
  },
})

export default FieldCard
