import React, { PropsWithChildren } from 'react'
import { Theme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import FormContext from '../../../context/FormContext'
import { ColorType } from '../../../utils/themes'
import AvatarInitials from '../../AvatarInitials'
import Flex from '../../Flex'
import Grid from '../../Grid'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'
import Icon from '../../Icon'

type AccountMemberOptionFieldProps = {
  value?: string | number
  user?: any
  name?: string
  className?: string
  disabled?: boolean
  color?: ColorType
} & PropsWithChildren

const AccountMemberOptionField: React.FC<AccountMemberOptionFieldProps> = ({
  user,
  className,
  disabled,
  color = 'TURQUOISE_300',
  value = 'new',
  name,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const form = React.useContext(FormContext)
  const classNames = clsx({
    [className]: className,
  })

  return (
    <Flex
      centerY
      gap="1rem"
      className={classNames}
      css={styles.root}
      onClick={async () => {
        await form?.setFieldValue(name, value)
        form?.submitForm()
      }}
      {...rest}
    >
      {user && <AvatarInitials user={user} color={color} size={56} />}

      {!user && (
        <IconCircle
          icon="newMember"
          color={color}
          size={56}
          iconColor={theme.TEXT_PRIMARY}
          scale={0.5}
        />
      )}

      <Grid gap={5} vertical stretch>
        <Text css={styles.name} token={!user && 'SOMEONE_NEW'}>
          {user && `${user.givenName} ${user.familyName}`}
        </Text>
      </Grid>
      <Icon icon="chevron" color="BLACK_700" />
    </Flex>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    borderRadius: '16px',
    cursor: 'pointer',
    padding: ' 16px 24px 16px 16px',
    width: '100%',
  },
  name: {
    color: theme.TEXT_PRIMARY,
  },
})

export default AccountMemberOptionField
