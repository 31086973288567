import React from 'react'
import { TBreakpoins, TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Button from './Button'
import useIsSidekick from '../hooks/useIsSidekick'
import clsx from 'clsx'
import { checkSafari } from '../utils/functions'

const MainNav: React.FC = () => {
  const { theme, breakpoints } = useTheme()
  const isSK = useIsSidekick()
  const styles = React.useMemo(() => computeStyles(theme, breakpoints), [theme])

  const isSafari = checkSafari()
  const classNames = clsx({
    'is-safari': isSafari,
  })

  const showProducts = false

  return (
    <nav css={styles.root} className={classNames}>
      <Button nav to="/" token="DASHBOARD" css={styles.button} end />
      <Button
        nav
        to="/accounts"
        token="SIDEKICK_ACCOUNTS"
        css={styles.button}
      />

      <Button nav to="/cards" token="CARDS" css={styles.button} />
      {!isSK && showProducts && (
        <Button nav to="/products" token="PRODUCTS" css={styles.button} />
      )}
    </nav>
  )
}

const computeStyles: TComputeStyles = (
  {
    TEXT_PRIMARY_LIGHT,
    BACKGROUND,
    TEXT_PRIMARY_DARK,
    NAV_BORDER,
    NAV_SELECTED_BORDER,
  }: TTheme,
  { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE }: TBreakpoins
) => ({
  root: {
    overflowX: 'auto',

    userSelect: 'none',

    display: 'flex',
    '::-webkit-scrollbar': {
      width: '8px',
    },
    gap: '16px',

    gridArea: 'nav',
    flexDirection: 'column',

    '&.is-safari > *:not(:last-child)': {
      marginBottom: '16px',
    },
    [BREAKPOINT_MOBILE]: {
      flexDirection: 'row',
      '&.is-safari > *:not(:last-child)': {
        marginBottom: 0,
        marginRight: '16px',
      },
    },
  },

  button: {
    color: TEXT_PRIMARY_LIGHT,
    fontSize: '.95rem',
    backgroundColor: 'transparent',

    border: `1px solid ${NAV_BORDER}`,
    '&.active': {
      border: `1px solid ${NAV_SELECTED_BORDER}`,
      color: TEXT_PRIMARY_DARK,
      backgroundColor: BACKGROUND,
    },

    [BREAKPOINT_DESKTOP]: {
      justifyContent: 'flex-start',
    },
  },
})

export default MainNav
