import React from 'react'

import Button from '../Button'
import withDev from '../../hocs/withDev'

const DevButton = ({ ...rest }) => {
  return <Button {...rest} />
}

export default withDev(DevButton)
