import { init } from '@rematch/core'

import AuthModel from './auth'
import FlowModel from './flow'
import NotificationsModel from './notifications'

const store = init({
  models: {
    auth: AuthModel,
    flow: FlowModel,
    notifications: NotificationsModel,
  },

  redux: {
    devtoolOptions: {
      trace: true,
    },
  },
})

export default store
