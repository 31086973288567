import React from 'react'
import { useTheme } from '@emotion/react'

import AvatarInitials from '../AvatarInitials'
import Fullname from '../Fullname'

const ListDetailHeader = ({ user, subtitle }) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  return (
    <div css={styles.root}>
      <AvatarInitials user={user} color="YELLOW" size={50} />

      <div css={styles.content}>
        <Fullname user={user} />
        <div css={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  )
}

const computeStyles = ({ BLACK_300 }) => ({
  root: {
    display: 'flex',
    borderBottom: `1px solid ${BLACK_300}`,
    padding: '1rem',

    alignItems: 'center',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },

  subtitle: {},
})

export default ListDetailHeader
