import React from 'react'
import TOSDetails from '../../termsOfServices/tos/tosDetails'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Flex from '../../../components/Flex'

const TOSScene = ({ ...rest }) => {
  return (
    <CardLayoutLink {...rest}>
      <Flex vertical centerY>
        <Flex vertical css={styles.content}>
          <TOSDetails />
        </Flex>
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
}

export default TOSScene
