import React from 'react'
import { useNavigate } from 'react-router-dom'
import TOSDetails from './tos/tosDetails'
import TermsOfServicesForm from '../../components/form/forms/TermsOfServicesForm'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import Flex from '../../components/Flex'
import { useMutation } from '@apollo/client'
import { acceptTermsOfService as acceptTermsOfServiceMutation } from '../../queries'

const AcceptTOSScene = ({ ...rest }) => {
  const navigate = useNavigate()

  const [acceptTermsOfService, { loading: acceptTOSLoading }] = useMutation(
    acceptTermsOfServiceMutation
  )

  const handleFormSubmit = async () => {
    const resp = await acceptTermsOfService({
      variables: {
        tosVersion: '2022-01-01',
      },
    })

    if (resp.data.acceptTermsOfService.hasAcceptedTOS) navigate('/')
  }

  return (
    <CardLayoutLink {...rest}>
      <Flex vertical centerY>
        <Flex vertical css={styles.content}>
          <TOSDetails />

          <TermsOfServicesForm
            loading={acceptTOSLoading}
            submit={handleFormSubmit}
          />
        </Flex>
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
}

export default AcceptTOSScene
