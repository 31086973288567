import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const GeneralIcon = ({ size = 1, color = '#464E58', width, height }) => {
    const defaultWidth = 14
    const defaultHeight = 12
    let factor = getIconSizeFactor({
        size,
        width,
        height,
        defaultWidth,
        defaultHeight,
    })

    return (
        <svg
            width={defaultWidth * factor}
            height={defaultHeight * factor}
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6666 2.66667H10.9999V2C10.9999 1.46957 10.7892 0.960859 10.4141 0.585787C10.0391 0.210714 9.53035 0 8.99992 0H2.33325C1.80282 0 1.29411 0.210714 0.919038 0.585787C0.543966 0.960859 0.333252 1.46957 0.333252 2V10C0.333252 10.5304 0.543966 11.0391 0.919038 11.4142C1.29411 11.7893 1.80282 12 2.33325 12H11.6666C12.197 12 12.7057 11.7893 13.0808 11.4142C13.4559 11.0391 13.6666 10.5304 13.6666 10V4.66667C13.6666 4.13623 13.4559 3.62753 13.0808 3.25245C12.7057 2.87738 12.197 2.66667 11.6666 2.66667ZM2.33325 1.33333H8.99992C9.17673 1.33333 9.3463 1.40357 9.47132 1.5286C9.59635 1.65362 9.66659 1.82319 9.66659 2V2.66667H2.33325C2.15644 2.66667 1.98687 2.59643 1.86185 2.4714C1.73682 2.34638 1.66659 2.17681 1.66659 2C1.66659 1.82319 1.73682 1.65362 1.86185 1.5286C1.98687 1.40357 2.15644 1.33333 2.33325 1.33333ZM12.3333 8H11.6666C11.4898 8 11.3202 7.92976 11.1952 7.80474C11.0702 7.67971 10.9999 7.51014 10.9999 7.33333C10.9999 7.15652 11.0702 6.98695 11.1952 6.86193C11.3202 6.73691 11.4898 6.66667 11.6666 6.66667H12.3333V8ZM12.3333 5.33333H11.6666C11.1362 5.33333 10.6274 5.54405 10.2524 5.91912C9.8773 6.29419 9.66659 6.8029 9.66659 7.33333C9.66659 7.86377 9.8773 8.37247 10.2524 8.74755C10.6274 9.12262 11.1362 9.33333 11.6666 9.33333H12.3333V10C12.3333 10.1768 12.263 10.3464 12.138 10.4714C12.013 10.5964 11.8434 10.6667 11.6666 10.6667H2.33325C2.15644 10.6667 1.98687 10.5964 1.86185 10.4714C1.73682 10.3464 1.66659 10.1768 1.66659 10V3.88667C1.88076 3.96201 2.10621 4.00034 2.33325 4H11.6666C11.8434 4 12.013 4.07024 12.138 4.19526C12.263 4.32029 12.3333 4.48986 12.3333 4.66667V5.33333Z"
                fill={color}
            />
        </svg>)
}

export default GeneralIcon
