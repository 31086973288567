import React from 'react'

import FormButton from '../Button'
import FormFooter from '../FormFooter'
import Text from '../../typography/Text'
import SubscriptionPlanCard from '../../cards/SubscriptionPlanCard'
import InfoHeadingCard from '../../cards/InfoHeadingCard'
import PriceForm from '../../form/forms/PriceForm'
import FlowContext from '../../../context/FlowContext'
import {
  RecurlyProvider,
  Elements as RecurlyElements,
} from '@recurly/react-recurly'
export type PaymentSummaryFormProps = {
  disabled?: boolean
  loading?: boolean
  error?: any
  submit: Function
  subscription: any
  billingInfo: any
  user: any
  editCard: Function
  resetFlow: Function
  resetStep?: number
  editing?: any
  validSubscription?: any
}

const PaymentSummaryForm: React.FC<PaymentSummaryFormProps> = ({
  disabled,
  loading,
  error,
  submit = () => {},
  subscription,
  billingInfo,
  user,
  editCard,
  resetFlow,
  resetStep = 0,
  editing,
  validSubscription,
  ...rest
}) => {
  const { currentData } = React.useContext(FlowContext)
  const { last4Digits, nameOnCard } = billingInfo
  const email = user?.email

  const handleSubmit = async () => {
    const coupon = innerFormik?.current?.getFieldMeta('coupon')
    let code = !coupon.error && coupon.value ? coupon.value : undefined
    submit(code)
  }

  const back = (reset = false) => {
    reset ? resetFlow(resetStep) : editCard()
  }

  const innerFormik = React.useRef(null)

  const showEditPlan = false

  return (
    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
      <SubscriptionPlanCard
        editCallback={showEditPlan ? () => back(true) : null}
        subscription={subscription}
        hideBtn
        {...rest}
      />
      <InfoHeadingCard back={() => back()} token="PERSONAL_INFORMATION">
        <Text css={styles.text} token={nameOnCard} />
        <Text css={styles.text} token={email} />
      </InfoHeadingCard>

      <InfoHeadingCard back={() => back()} token="CARD_DETAILS">
        <Text css={styles.text} token={nameOnCard} />
        <Text css={styles.text}>{`**** **** **** ${last4Digits}`}</Text>
      </InfoHeadingCard>

      <RecurlyElements>
        <PriceForm
          validSubscription={validSubscription}
          editing={editing}
          subscriptionCode={currentData?.data?.plan}
          formRef={innerFormik}
          subscription={subscription}
        />
      </RecurlyElements>

      <FormFooter>
        <FormButton
          loading={loading}
          onClick={handleSubmit}
          token="CONFIRM_PAYMENT"
        />
      </FormFooter>
    </RecurlyProvider>
  )
}

const styles = {
  text: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '16px',
  },
}

export default PaymentSummaryForm
