import React from 'react'

import List from './List'
import ListItem from './ListItem'
import AccountMemberOptionField from '../form/fields/AccountMemberOptionField'
import { Theme, useTheme } from '@emotion/react'

type AccountMembersOptionListProps = {
  teamMembers: any[]
  name: string
  allowNewMember: boolean
}

const AccountMembersOptionList: React.FC<AccountMembersOptionListProps> = ({
  teamMembers,
  name,
  allowNewMember,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const users = React.useMemo(() => {
    return teamMembers?.map((sk) => (
      <ListItem as="li" key={`teamMember_${sk.userProfile?.id}`}>
        <AccountMemberOptionField
          user={sk.userProfile}
          name={name}
          css={styles.field}
          value={sk.userProfile?.id}
        />
      </ListItem>
    ))
  }, [teamMembers])

  return (
    <List as="ul" vertical gap="1rem" css={styles.root}>
      {users}
      {allowNewMember && (
        <ListItem as="li">
          <AccountMemberOptionField name={name} css={styles.field} />
        </ListItem>
      )}
    </List>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
  },
})

export default AccountMembersOptionList
