import React from 'react'
import clsx from 'clsx'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { TPlainStyle } from '@emotion/react'
import Element, { ElementProps } from './Element'

export type LinkProps = {
  disableLink?: boolean
  external?: boolean
  className?: string
} & RouterLinkProps &
  ElementProps

const Link: React.FC<LinkProps> = ({
  disableLink,
  external,
  className,
  ...rest
}) => {
  const classNames = clsx({
    [className]: className,
  })
  const { to, children } = rest
  return disableLink ? (
    <Element css={styles.root} {...rest} />
  ) : (
    <>
      {!external && (
        <RouterLink css={styles.root} className={classNames} {...rest} />
      )}
      {external && typeof to === 'string' && (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          css={styles.root}
          className={classNames}
        >
          {children && children}
        </a>
      )}
    </>
  )
}

const styles: TPlainStyle = {
  root: {
    textTransform: 'none',
    textDecoration: 'none',
    color: 'inherit',
  },
}

export default Link
