import React from 'react'
import { AnimatePresence } from 'framer-motion'

import Grid from './Grid'

import { createContext } from 'react'

type ExpandableProps = {
  expanded?: boolean
  setExpanded?: Function
}

export const ExpandableContext = createContext<ExpandableProps>({})

export const ExpandableAction = ({ children, allowExpand }) => {
  const { expanded, setExpanded } = React.useContext(ExpandableContext)

  const handleClick = () => {
    if (allowExpand) setExpanded(!expanded)
  }

  return (
    <div onClick={handleClick} css={styles.action}>
      {children}
    </div>
  )
}

export const ExpandableOutlet = ({ children, ...rest }) => {
  const { expanded } = React.useContext(ExpandableContext)

  return <AnimatePresence>{expanded && children}</AnimatePresence>
}

const Expandable = ({ children, initExpanded = false, ...rest }) => {
  const [expanded, setExpanded] = React.useState(initExpanded)

  return (
    <ExpandableContext.Provider value={{ expanded, setExpanded }}>
      <Grid css={styles.root} {...rest}>
        {children}
      </Grid>
    </ExpandableContext.Provider>
  )
}

const styles = {
  root: {
    maxWidth: 450,
  },

  action: {
    cursor: 'pointer',
  },
}

export default Expandable
