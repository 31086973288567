import React from 'react'

import Drawer, { Props as DrawerProps } from './Drawer'
import ComponentCard from '../cards/ComponentCard'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Flex from '../Flex'

type DrawerCardProps = {
  label?: string | JSX.Element
}

export type Props = DrawerCardProps & DrawerProps

const DrawerCard: React.FC<Props> = ({ children, label, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Drawer {...rest}>
      <Flex vertical css={styles.root} nowrap>
        <Flex>
          <span css={styles.handle} />
        </Flex>
        <ComponentCard label={label} css={styles.content}>
          {children}
        </ComponentCard>
      </Flex>
    </Drawer>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  DRAWER_HANDLE,
}: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,

    maxHeight: '30rem',
    overflowY: 'scroll',
  },
  handle: {
    backgroundColor: DRAWER_HANDLE,
    borderRadius: '8px',
    height: 4,
    margin: '20px auto 4px',
    width: 40,
  },
  content: {
    border: 'none',
  },
})

export default DrawerCard
