import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const LockIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 12
  const defaultHeight = 14
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00008 7.66671C5.82327 7.66671 5.6537 7.73695 5.52868 7.86197C5.40365 7.98699 5.33341 8.15656 5.33341 8.33337V10.3334C5.33341 10.5102 5.40365 10.6798 5.52868 10.8048C5.6537 10.9298 5.82327 11 6.00008 11C6.17689 11 6.34646 10.9298 6.47149 10.8048C6.59651 10.6798 6.66675 10.5102 6.66675 10.3334V8.33337C6.66675 8.15656 6.59651 7.98699 6.47149 7.86197C6.34646 7.73695 6.17689 7.66671 6.00008 7.66671ZM9.33341 5.00004V3.66671C9.33341 2.78265 8.98222 1.93481 8.3571 1.30968C7.73198 0.684563 6.88414 0.333374 6.00008 0.333374C5.11603 0.333374 4.26818 0.684563 3.64306 1.30968C3.01794 1.93481 2.66675 2.78265 2.66675 3.66671V5.00004C2.13631 5.00004 1.62761 5.21075 1.25253 5.58583C0.877462 5.9609 0.666748 6.46961 0.666748 7.00004V11.6667C0.666748 12.1971 0.877462 12.7058 1.25253 13.0809C1.62761 13.456 2.13631 13.6667 2.66675 13.6667H9.33341C9.86385 13.6667 10.3726 13.456 10.7476 13.0809C11.1227 12.7058 11.3334 12.1971 11.3334 11.6667V7.00004C11.3334 6.46961 11.1227 5.9609 10.7476 5.58583C10.3726 5.21075 9.86385 5.00004 9.33341 5.00004ZM4.00008 3.66671C4.00008 3.13627 4.21079 2.62757 4.58587 2.25249C4.96094 1.87742 5.46965 1.66671 6.00008 1.66671C6.53051 1.66671 7.03922 1.87742 7.41429 2.25249C7.78937 2.62757 8.00008 3.13627 8.00008 3.66671V5.00004H4.00008V3.66671ZM10.0001 11.6667C10.0001 11.8435 9.92984 12.0131 9.80482 12.1381C9.67979 12.2631 9.51023 12.3334 9.33341 12.3334H2.66675C2.48994 12.3334 2.32037 12.2631 2.19534 12.1381C2.07032 12.0131 2.00008 11.8435 2.00008 11.6667V7.00004C2.00008 6.82323 2.07032 6.65366 2.19534 6.52864C2.32037 6.40361 2.48994 6.33337 2.66675 6.33337H9.33341C9.51023 6.33337 9.67979 6.40361 9.80482 6.52864C9.92984 6.65366 10.0001 6.82323 10.0001 7.00004V11.6667Z"
        fill={color}
      />
    </svg>
  )
}

export default LockIcon
