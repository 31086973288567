import React from 'react'
import { useTheme } from '@emotion/react'

import Flex, { FlexProps } from './Flex'

import ICONS, { IconType } from './icons'
import { ColorType } from '../utils/themes'

export type IconProps = {
  icon?: IconType
  size?: number
  color?: ColorType | string
  width?: number
  height?: number
  logoColor?: string
  onClick?: Function
}

type Props = IconProps & FlexProps

const Icon: React.FC<Props> = ({
  icon = 'error',
  color,
  className,
  onClick,
  logoColor,
  ...rest
}) => {
  const { colors, theme } = useTheme()
  const WrappedIcon: React.FC<IconProps> = ICONS[icon] || ICONS.error
  return (
    <Flex centerX centerY className={className} onClick={onClick}>
      <WrappedIcon
        logoColor={logoColor}
        color={colors[color] || theme[color] || color}
        {...rest}
      />
    </Flex>
  )
}

export default Icon
