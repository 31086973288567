import React from 'react'
import * as Yup from 'yup'

import FormButton from '../Button'
import ButtonGroup from '../../ButtonGroup'
import Form from '../Form'
import FormFooter from '../FormFooter'

import FieldCard from '../../cards/FieldCard'
import Text from '../../typography/Text'
import TeamMemberTypeOptionField, {
  TeamMemberVariants,
} from '../fields/TeamMemberTypeOptionField'
import Paragraph from '../../typography/Paragraph'

type NewMemberSelectTypeFormProps = {
  submit?: Function
  loading?: boolean
  disabled?: boolean
  data?: { [key: string]: string }
}

const schema = Yup.object().shape({ type: Yup.string().required() })

const NewMemberSelectTypeForm: React.FC<NewMemberSelectTypeFormProps> = ({
  submit,
  loading,
  disabled,
  data,
  ...rest
}) => {
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{
        type: data.type || TeamMemberVariants.SIDEKICK,
      }}
      {...rest}
    >
      {({ values }) => (
        <>
          <FieldCard>
            <TeamMemberTypeOptionField
              label={<Text token="SIDEKICK" />}
              name="type"
              value={TeamMemberVariants.SIDEKICK}
            />
            <Paragraph token="ADD_SIDEKICK_INFO" />
            <TeamMemberTypeOptionField
              label={<Text token="HERO" />}
              name="type"
              value={TeamMemberVariants.HERO}
              disabled
            />
            <Paragraph token="ADD_HERO_INFO" />
          </FieldCard>

          <FormFooter>
            <ButtonGroup>
              <FormButton
                label={
                  values.type ? (
                    <Text token="NEXT" />
                  ) : (
                    <Text token="SELECT_A_TYPE" />
                  )
                }
                disabled={!values.type}
              />
            </ButtonGroup>
          </FormFooter>
        </>
      )}
    </Form>
  )
}

export default NewMemberSelectTypeForm
