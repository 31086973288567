import React, { ReactElement } from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import { get } from 'lodash'

import FormContext from '../../../context/FormContext'

import Grid from '../../Grid'
import FieldStatus from '../FieldStatus'
import Element from '../../Element'

type CreditCardFieldProps = {
  name?: string
  label?: string | ReactElement
  className?: string
  disabled?: boolean
  loading?: boolean
  onChange?: Function
  onValueChange?: Function
  id?: string
  required?: boolean
  description?: string | JSX.Element
}

const CreditCardField: React.FC<CreditCardFieldProps> = ({
  name,
  label,
  className,
  id,
  onChange,
  onValueChange,
  loading,
  required,
  description,
  disabled,
  ...rest
}) => {
  const form = React.useContext<{ [key: string]: any }>(FormContext)

  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })
  const fieldClassNames = clsx({
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })

  const handleChange = async (value) => {
    const newValue = `${value}`.replaceAll(' ', '').replaceAll(/[^0-9 ]/g, '')
    await form?.setFieldValue?.(name, newValue)
    await form?.handleFieldUpdate?.(name, newValue)
    onChange?.(newValue)
    onValueChange?.(newValue)
  }

  const { submitCount } = form || {}
  const error = get(form.errors, name)
  const defaultValue = get(form.values, name, '').match?.(/.{1,4}/g) || []

  return (
    <Element>
      <Grid
        as="label"
        gap={sizes.FIELD_GAP}
        css={styles.root}
        className={classNames}
        {...rest}
      >
        {label && (
          <span>
            {label}
            {required ? '*' : ''}
          </span>
        )}

        {description && description}

        <input
          className={fieldClassNames}
          css={styles.field}
          name={name}
          pattern="[0-9]"
          maxLength={19}
          placeholder="---- ---- ---- ----"
          value={defaultValue.join(' ')}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
      <FieldStatus show={!!error && submitCount > 0} status={error} />
    </Element>
  )
}

const computeStyles: TComputeStyles = (
  {
    TEXT_PRIMARY_LIGHT,
    PRIMARY_ALT,
    FIELD_BORDER,
    DISABLED,
    FIELD_DISABLED_BACKGROUND,
  }: TTheme,
  {
    FIELD_PADDING,
    FIELD_FONT_SIZE,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
    FIELD_STATUS_MIN_HEIGHT,
  }: TSizes
) => ({
  root: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: FIELD_BORDER_RADIUS,
    color: TEXT_PRIMARY_LIGHT,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    padding: FIELD_PADDING,
    position: 'relative',

    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&:focus-within': {
      border: `2px solid ${PRIMARY_ALT}`,
    },
  },
  field: {
    background: 'none',
    border: 0,
    flexGrow: 1,
    fontSize: FIELD_FONT_SIZE,
    outline: 0,
    width: '100%',
    '&.is-disabled': {
      color: DISABLED,
    },

    '&.is-loading': {
      color: DISABLED,
    },
  },
  statusContainer: {
    margin: 0,
    minHeight: FIELD_STATUS_MIN_HEIGHT,
    padding: 0,
  },
})

export default CreditCardField
