import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'

import Field from '../Field'
import Header from '../../typography/Header'
import { Theme, useTheme } from '@emotion/react'
import Text from '../../typography/Text'

type PasswordResetFormProps = {
  email?: string
  loading?: boolean
  submit: Function
}

const schema = Yup.object().shape({
  email: Yup.string().email().required('THIS_IS_A_REQUIRED_FIELD'),
})

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  email,
  loading,
  submit,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      initialValues={{
        email: email || '',
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
    >
      {() => (
        <>
          <Header css={styles.headline} token="PLEASE_ENTER_YOUR_EMAIL" />

          <Field
            css={styles.field}
            label={<Text token="EMAIL_ADDRESS" />}
            name="email"
            required
          />

          <FormButton
            label={<Text token="RESET_PASSWORD" />}
            css={styles.submit}
            loading={loading}
          />
        </>
      )}
    </Form>
  )
}

const computeStyles = ({ BACKGROUND_ALT }: Theme['theme']) => ({
  root: { width: '100%' },
  submit: { marginTop: 30 },
  headline: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default PasswordResetForm
