import React, { PropsWithChildren } from 'react'

import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'

import Portal from './Portal'
import Text, { TranslationToken } from './typography/Text'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

type Props = {
  placement?: Placement
  offset?: [number | null | undefined, number | null | undefined]
  className?: string
  tooltip?: string | JSX.Element
  message?: TranslationToken
  portal?: string
} & PropsWithChildren

const DefaultTooltip: React.FC<Props> = ({ message }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return <Text css={styles.root} token={message} />
}

const Tooltip: React.FC<Props> = ({
  children,
  message,
  tooltip,
  portal = '#portal-tooltip',
  placement = 'bottom',
  offset = [5, 5],
  className,
}) => {
  const { theme } = useTheme()
  const customStyles = React.useMemo(() => computeStyles(theme), [theme])
  const [open, setOpen] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  })

  return (
    <div>
      <div
        ref={setReferenceElement}
        className={className}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {children}
      </div>

      {open && (tooltip || message) && (
        <Portal type="tooltip">
          <div
            ref={setPopperElement}
            css={{ ...styles.popper, ...customStyles.container }}
            {...attributes.popper}
          >
            {tooltip && tooltip}
            {!tooltip && <DefaultTooltip message={message} />}
          </div>
        </Portal>
      )}
    </div>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
    border: `1px solid black`,
    borderRadius: 4,
    boxShadow: `
      0px 1px 1px gray,
      0px 2px 2px silver
    `,
    display: 'flex',
    fontSize: 14,
    letterSpacing: '-0.4px',
    lineHeight: '140%',
    maxWidth: 300,
    padding: '0.4rem 0.5rem 0.35rem 0.5rem',
    textTransform: 'uppercase',
    width: 'max-content',
  },

  container: {
    zIndex: 10000,
  },
})

export default Tooltip
