import React, { ReactElement } from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import { get } from 'lodash'

import FormContext from '../../../context/FormContext'

import Flex from '../../Flex'
import StyledSwitch from '../../Switch'

type SwitchFieldProps = {
  name?: string
  small?: boolean
  label?: string | ReactElement
  handleFieldUpdate?: Function
  className?: string
  disabled?: boolean
  labelIsSufix?: boolean
}

const SwitchField: React.FC<SwitchFieldProps> = ({
  name,
  small,
  label,
  handleFieldUpdate,
  className,
  disabled,
  labelIsSufix,
  ...rest
}) => {
  const formState = React.useContext<{ [key: string]: any }>(FormContext)

  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])
  const currentValue = get(formState?.values, name, false)

  const classNames = clsx({
    [className]: className,
    'is-disabled': formState.disabled || disabled,
    'is-loading': formState.loading,
  })

  const handleClick = async () => {
    await formState?.setFieldValue(name, !currentValue)
    await formState?.handleFieldUpdate(name, !currentValue)
  }

  return (
    <Flex
      css={styles.root}
      spread={!labelIsSufix}
      className={classNames}
      centerY
    >
      {!labelIsSufix && label}
      <StyledSwitch
        checked={currentValue}
        scale={small ? 0.8 : 1}
        onChange={handleClick}
      />
      {labelIsSufix && label}
    </Flex>
  )
}

const computeStyles: TComputeStyles = (
  { DISABLED_BACKGROUND, FIELD_BORDER, TEXT_PRIMARY }: TTheme,
  {
    FIELD_PADDING,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes
) => ({
  root: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: FIELD_BORDER_RADIUS,
    color: TEXT_PRIMARY,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    padding: FIELD_PADDING,

    '&.is-disabled': {
      backgroundColor: DISABLED_BACKGROUND,
      pointerEvents: 'none',
    },
  },
})

export default SwitchField
