import { createContext } from 'react'

export type LayoutContextProps = {
  setTitle?: Function
  setBack?: Function
  setSubtitle?: Function
  setLoading?: Function
  setError?: Function
  setStep?: Function
  setSteps?: Function
  setCancel?: Function
  setUseFooter?: Function
  setLarge?: Function
  setHeaderStyle?: Function
  setHeaderExtra?: Function
  setGrayed?: Function
  setProcessing?: Function
  setProcessingLabel?: Function
}

const LayoutContext = createContext<LayoutContextProps>({})

export default LayoutContext
