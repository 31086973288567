import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Flex from '../../../components/Flex'
import Paragraph from '../../../components/typography/Paragraph'
import Button from '../../../components/Button'
import { Theme, useTheme } from '@emotion/react'
import { useSearchParams } from 'react-router-dom'

export const CONTACT_EMAIL = 'hello@herofinancials.com'
export const CONTACT_MAILTO = `mailto:${CONTACT_EMAIL}`
export const CONTACT_NUMBER = '+1 (800) 508-8813'
export const CONTACT_NUMBER_URL = 'https://wa.me/18005088813'

const ContactUscene = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [params] = useSearchParams()
  const to = params.get('from')
  return (
    <CardLayoutLink {...rest} back={to || '/'}>
      <Flex vertical centerY>
        <Flex vertical css={styles.content}>
          <Paragraph>
            <Paragraph css={styles.legend} token="SEND_US_A_MESSAGE" />
            <Button
              button
              css={styles.link}
              onClick={(e) => {
                window.location.href = CONTACT_MAILTO
                e.preventDefault()
              }}
            >
              {CONTACT_EMAIL}
            </Button>
            <Paragraph css={styles.legend} token="OR_VIA_WHATSAPP" />
            <a
              css={styles.link}
              href={CONTACT_NUMBER_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {CONTACT_NUMBER}
            </a>
          </Paragraph>
        </Flex>
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles = ({ TEXT_PRIMARY, LINK }: Theme['theme']) => ({
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  legend: {
    display: 'inline',
    fontSize: '14px',
    color: TEXT_PRIMARY,
    marginRight: '4px',
  },
  link: {
    marginRight: '4px',
    display: 'inline',
    background: 'none',
    fontSize: '14px',
    color: LINK,
    padding: 0,
  },
})

export default ContactUscene
