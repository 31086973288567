import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const MoreInfoIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#8A929C',
  width,
  height,
}) => {
  const defaultWidth = 16
  const defaultHeight = 16
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346619 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346625 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C15.9974 10.1209 15.1537 12.1542 13.6539 13.6539C12.1542 15.1537 10.1209 15.9974 8 16ZM8 7.5C7.73479 7.5 7.48043 7.60536 7.2929 7.7929C7.10536 7.98043 7 8.23479 7 8.5V11.5C7 11.7652 7.10536 12.0196 7.2929 12.2071C7.48043 12.3946 7.73479 12.5 8 12.5C8.26522 12.5 8.51957 12.3946 8.70711 12.2071C8.89465 12.0196 9 11.7652 9 11.5V8.5C9 8.23479 8.89465 7.98043 8.70711 7.7929C8.51957 7.60536 8.26522 7.5 8 7.5ZM8 3.5C7.75278 3.5 7.5111 3.57331 7.30554 3.71067C7.09998 3.84802 6.93976 4.04324 6.84515 4.27165C6.75054 4.50006 6.72579 4.75139 6.77402 4.99387C6.82225 5.23634 6.9413 5.45907 7.11612 5.63389C7.29094 5.8087 7.51366 5.92775 7.75614 5.97599C7.99862 6.02422 8.24995 5.99946 8.47836 5.90485C8.70677 5.81024 8.90199 5.65003 9.03934 5.44447C9.17669 5.2389 9.25 4.99723 9.25 4.75C9.25 4.41848 9.11831 4.10054 8.88389 3.86612C8.64947 3.6317 8.33152 3.5 8 3.5Z"
        fill={color}
      />
    </svg>
  )
}

export default MoreInfoIcon
