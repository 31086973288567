import React, {
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'

import Button, { ButtonProps } from '../Button'

import FormContext from '../../context/FormContext'
import { Theme, useTheme } from '@emotion/react'

const FormButton: React.FC<ButtonProps> = ({ loading, ...rest }, ref) => {
  const { submitForm, ...formContext } = useContext(FormContext)
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [isLoading, setIsLoading] = React.useState(loading)

  useImperativeHandle(ref, () => ({
    setLoading(load) {
      setIsLoading(load)
    },
  }))

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return (
    <Button
      button
      type="button"
      onClick={submitForm}
      css={styles.root}
      {...formContext}
      {...rest}
      loading={isLoading}
    />
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    color: theme.TEXT_SECONDARY,
    fontSize: 14,
  },
})

export default forwardRef(FormButton)
