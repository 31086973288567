import React from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import Element from '../Element'
import Text from '../typography/Text'

export enum StatusTypes {
  error = 'error',
}

type FieldStatusProps = {
  status: string | JSX.Element
  className?: string
  type?: StatusTypes
  show?: boolean
  collapsable?: boolean
}

const FieldStatus: React.FC<FieldStatusProps> = ({
  status,
  type = 'error',
  className,
  show,
  collapsable,
}) => {
  const { theme, sizes } = useTheme()

  const classNames = clsx({
    [className]: className,
    'is-error': type === StatusTypes.error,
  })

  const styles = React.useMemo(
    () => computeStyles(theme, sizes, collapsable),
    [theme]
  )

  let statusProps = {}
  const statusIsString = typeof status === 'string'

  if (status && statusIsString) {
    try {
      statusProps = JSON.parse(status)
    } catch {
      statusProps = {
        token: status,
      }
    }
  }

  return (
    <Element css={styles.root}>
      <AnimatePresence>
        {show && (
          <motion.div
            className={classNames}
            css={styles.status}
            initial={{ scaleY: 0.8 }}
            animate={{ scaleY: 1 }}
            transition={{ type: 'tween', duration: 0.15 }}
          >
            {!statusIsString && status}
            {statusIsString && <Text {...statusProps} />}
          </motion.div>
        )}
      </AnimatePresence>
    </Element>
  )
}

const computeStyles: TComputeStyles = (
  { ERROR }: TTheme,
  { FIELD_STATUS_MIN_HEIGHT }: TSizes,
  collapsable
) => ({
  root: {
    margin: 0,
    minHeight: !collapsable && FIELD_STATUS_MIN_HEIGHT,
    padding: 0,
  },
  status: {
    fontSize: '11px',
    padding: '4px 16px',
    textAlign: 'end',
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    '&.is-error': {
      color: ERROR,
    },
  },
})

export default FieldStatus
