import React from 'react'

import Actions from '../Actions'
import { CardProps } from './Card'
import Tabs, { TabsOutlet, TabsProps } from '../Tabs'

import ComponentCard from './ComponentCard'
import { TPlainStyle } from '@emotion/react'

export type TabsCardsProps = { id?: string; centerX?: boolean } & CardProps &
  TabsProps

const TabsCard: React.FC<TabsCardsProps> = ({
  centerX,
  children,
  defaultTab,
  routed,
  ...rest
}) => {
  return (
    <Tabs defaultTab={defaultTab} routed={routed}>
      <ComponentCard {...rest}>
        <Actions nowrap centerX={centerX} css={styles.root}>
          {children}
        </Actions>

        <TabsOutlet />
      </ComponentCard>
    </Tabs>
  )
}

const styles: TPlainStyle = {
  root: {
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      width: 0,
    },
  },
}

export default TabsCard
