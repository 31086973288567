import React from 'react'
import { useMutation } from '@apollo/client'
import floor from 'lodash/floor'

import Button from '../../components/Button'
import ComponentCard from '../../components/cards/ComponentCard'
import CreditCardItem from '../../components/lists/items/CreditCardItem'
import Flex from '../../components/Flex'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import FormFooter from '../..//components/form/FormFooter'
import Text from '../../components/typography/Text'
import SmallText from '../../components/typography/SmallText'
import FormHeader from '../../components/typography/FormHeader'

import FlowContext from '../../context/FlowContext'

import { createBerkeleyFundsTransfer } from '../../queries'
import useWaitForNotification from '../../hooks/useWaitForNotification'

const FundAccountConfirmPaymentStep = ({ ...rest }) => {
  const [waiting, setWaiting] = React.useState(false)
  const { currentData, next, prev } = React.useContext(FlowContext)

  const { data: notification } = useWaitForNotification([
    'FundsTransferCompleted',
  ])

  const [makePayment, { loading: makePaymentLoading }] = useMutation(
    createBerkeleyFundsTransfer
  )

  React.useEffect(() => {
    if (!notification) return

    switch (notification?.code) {
      case 'FundsTransferCompleted':
        setWaiting(false)
        next()
        break
      case 'FundsTransferFailed':
        setWaiting(false)
        break
      default:
        setWaiting(false)
        break
    }
  }, [notification])

  const handleSubmit = async () => {
    setWaiting(true)
    await makePayment({
      variables: {
        amountInCents: floor(currentData.data?.amount, 2) * 100,
        fundingSourceId: currentData.data?.fundingSourceModel?.id,
        currency: 'CAD',
      },
    })
  }

  const selectedFundingMethod = currentData?.data?.fundingSourceModel
  const loading = makePaymentLoading || waiting

  return (
    <FlowStepLayout loading={loading}>
      <FormHeader token="COMPLETE_YOUR_PAYMENT" />

      <ComponentCard token="PAYER_DETAILS" outsideLabel>
        <Flex vertical>
          <SmallText>{selectedFundingMethod?.name}</SmallText>
          <SmallText>{selectedFundingMethod?.address_line1}</SmallText>
          {selectedFundingMethod?.address_line2 && (
            <SmallText>{selectedFundingMethod?.address_line2}</SmallText>
          )}
          <SmallText>
            {selectedFundingMethod?.address_city}{' '}
            {selectedFundingMethod?.address_postal_code}
          </SmallText>
          <SmallText>{selectedFundingMethod?.address_country}</SmallText>
        </Flex>
      </ComponentCard>

      <CreditCardItem
        token="PAYMENT_DETAILS"
        funding={selectedFundingMethod}
        outsideLabel
      />

      <Flex horizontalAlignment="flex-end">
        <Flex vertical verticalAlignment="flex-end" gap=".2rem">
          <SmallText uppercase token="AMOUNT_TO_PAY" />
          <Text
            currency={currentData?.data?.amount}
            currencyProps={{ currency: 'CAD' }}
            size="1.5rem"
          />
        </Flex>
      </Flex>

      <FormFooter>
        <Button
          button
          token="PREVIOUS_STEP"
          onClick={prev}
          disabled={waiting}
        />
        <Button
          button
          onClick={handleSubmit}
          token="PAY_NOW"
          disabled={loading}
        />
      </FormFooter>
    </FlowStepLayout>
  )
}

export default FundAccountConfirmPaymentStep
