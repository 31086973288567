import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'

import Header from '../../typography/Header'
import PasswordField from '../fields/PasswordField'
import PasswordStrength from '../../PasswordStrength'
import { validators } from '../../../utils/constants'
import PasswordRulesDescription from '../../PasswordRulesDescription'
import { Theme, useTheme } from '@emotion/react'
import Text from '../../typography/Text'

type NewPasswordFormProps = {
  loading?: boolean
  submit: Function
}

const schema = Yup.object().shape({
  password: Yup.string()
    .test((value) => validators.PASSWORD.test(value))
    .required(),
  confirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'PASSWORD_MUST_MATCH')
    .required(),
})

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  loading,
  submit,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      initialValues={{
        password: '',
        confirm: '',
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
    >
      {(form) => (
        <>
          <Header css={styles.headline} token="ENTER_NEW_PASSWORD" />

          <PasswordField
            css={styles.field}
            label={<Text token="NEW_PASSWORD" />}
            name="password"
            required
          />

          <PasswordStrength password={form?.values?.password || ''} />

          <PasswordField
            css={styles.field}
            label={<Text token="CONFIRM_NEW_PASSWORD" />}
            name="confirm"
            required
          />

          <PasswordRulesDescription />

          <FormButton
            label={<Text token="DONE" />}
            css={styles.submit}
            loading={loading}
          />
        </>
      )}
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: { width: '100%' },
  submit: { marginTop: 30 },
  headline: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
  },
})

export default NewPasswordForm
