import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Flex from './Flex'
import Text from './typography/Text'
import Detail from './typography/Detail'
import IconCircle from './IconCircle'
import { CategoryDictionary } from '../utils/categories'
import Paragraph from './typography/Paragraph'

type CategoryDescriptionProps = {
  category?: CategoryDictionary
}

const CategoryDescription: React.FC<CategoryDescriptionProps> = ({
  category,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Flex css={styles.root} nowrap>
      <Flex gap="1rem">
        <IconCircle
          icon={category.icon}
          iconColor={category.iconColor}
          color={category.bgColor || 'RED_400'}
          size={48}
          scale={1.5}
        />
      </Flex>
      <Detail css={styles.details}>
        <Flex vertical>
          <Text token={category.displayName} css={styles.name} />
          <Paragraph token={category.description} css={styles.description} />
        </Flex>
      </Detail>
    </Flex>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: { margin: '1rem 0' },
  details: {
    display: 'flex',
    paddingLeft: '1rem',
  },
  name: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
  },
  description: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '20px',
    margin: 0,
  },
})

export default CategoryDescription
