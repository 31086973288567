import React from 'react'

import FlowContext from '../../context/FlowContext'

const FlowOutlet = () => {
  const { currentOutlet } = React.useContext(FlowContext)

  return currentOutlet
}

export default FlowOutlet
