import React from 'react'
import Actions from '../../../components/Actions'
import Button from '../../../components/Button'

type SubscriptionsActionsProps = {
  visible?: boolean
  fromMobile?: boolean
}

const SubscriptionsActions: React.FC<SubscriptionsActionsProps> = ({
  visible,
  fromMobile,
}) => {
  if (!visible) return

  const showSwitchPlan = false

  return (
    <Actions>
      {showSwitchPlan && (
        <Button
          to={
            fromMobile
              ? '/settings/subscriptions/edit-mobile'
              : '/settings/subscriptions/edit'
          }
          iconColor="white"
          icon="switch"
          size={0.9}
          token="SWITCH_PLAN"
        />
      )}
      <Button
        to="/settings/subscriptions/invoices"
        iconColor="white"
        icon="invoice"
        size={0.9}
        token="INVOICES"
      />
    </Actions>
  )
}

export default SubscriptionsActions
