import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'

import { getCards } from '../../../queries'
import { Theme, useTheme } from '@emotion/react'
import PhysicalCard from '../../../components/PhysicalCard'
import Paragraph from '../../../components/typography/Paragraph'

const CardDetailsScene = ({ ...rest }) => {
  const { id } = useParams()
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { data: cardsData, loading } = useQuery(getCards)
  const cards = cardsData?.cards || []
  const card = cards.find((card) => card.id === id)

  const { country, addressLine1, addressLine2, city, postalCode, region } =
    card?.cardholder?.principalAddress || {}

  return (
    <CardLayoutLink
      title={<Text token="CARD_DETAILS" />}
      loading={loading}
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        <PhysicalCard card={card} css={styles.card} hasMenu={false} />
        <Paragraph css={styles.information}>
          <Text
            as="div"
            css={styles.informationLegend}
            token="BILLING_ADDRESS"
          />
          <Text as="div" css={styles.informationDetail}>
            {addressLine1}
          </Text>
          {addressLine2 && (
            <Text as="div" css={styles.informationDetail}>
              {addressLine2}
            </Text>
          )}
          <Text
            as="div"
            css={styles.informationDetail}
          >{`${city} ${region}`}</Text>
          <Text
            as="div"
            css={styles.informationDetail}
          >{`${country} ${postalCode}`}</Text>
        </Paragraph>
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles = ({ BACKGROUND_ALT }: Theme['theme']) => ({
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  card: {
    aspectRatio: '10/9',
    '@media (max-width: 500px)': {
      aspectRatio: '16/9',
    },
  },
  informationLegend: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: 16,
  },
  informationDetail: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  information: {
    backgroundColor: BACKGROUND_ALT,
    borderRadius: 16,
    fontSize: '14px',
    marginTop: 16,
    padding: '20px 16px',
  },
})

export default CardDetailsScene
