import React from 'react'
import { useNavigate } from 'react-router-dom'
import Heading from '../../../../components/typography/Heading'
import SubscriptionPlanCard from '../../../../components/cards/SubscriptionPlanCard'
import DrawerCard from '../../../../components/drawers/DrawerCard'
import IconCircle from '../../../../components/IconCircle'
import Text from '../../../../components/typography/Text'
import Flex from '../../../../components/Flex'

type SubscriptionsCurrentProps = {
  subscription: any
}

const SubscriptionsCurrent: React.FC<SubscriptionsCurrentProps> = ({
  subscription,
  ...rest
}) => {
  const [drawer, setDrawer] = React.useState<boolean>()
  const navigate = useNavigate()

  const openDrawer = () => {
    setDrawer(true)
  }

  const handleCancel = () => {
    navigate('/settings/subscriptions/cancel')
  }

  return (
    <>
      <Heading token="CURRENT_PLAN" as="h2" css={styles.heading} />
      <SubscriptionPlanCard
        active
        hideBtn
        subscription={subscription}
        editCallback={openDrawer}
        icon="moreVertical"
        {...rest}
      />
      <DrawerCard handleClickOutside={() => setDrawer(false)} isOpened={drawer}>
        <Flex
          onClick={handleCancel}
          verticalAlignment="center"
          gap={5}
          css={styles.btn}
        >
          <IconCircle
            icon="error"
            color="DISABLED_BACKGROUND"
            size={40}
            scale={0.8333}
          />
          <Text token={'CANCEL_PLAN'} />
        </Flex>
      </DrawerCard>
    </>
  )
}

const styles = {
  heading: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: 0,
    paddingLeft: 5,
  },
  btn: {
    cursor: 'pointer',
  },
}

export default SubscriptionsCurrent
