import React from 'react'
import sortBy from 'lodash/sortBy'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import ReallocateForm from '../../components/form/forms/ReallocateForm'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import useTeamMembers from '../../hooks/useTeamMembers'
import useReallocateFunds, {
  generatePayload,
} from '../../hooks/useReallocateFunds'

type ReallocateSceneProps = {
  title?: JSX.Element
}

const ReallocateScene: React.FC<ReallocateSceneProps> = ({ ...rest }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [additionalLoading, setAdditionalLoading] = React.useState(false)

  const { teamMembers, teamMember, loading, refetch } = useTeamMembers(
    true,
    true,
    undefined,
    id
  )
  const [mutateReallocate, { loading: mutationLoading }] = useReallocateFunds(
    id,
    teamMembers
  )

  const categories = teamMember?.bankAccounts?.[0]?.categories
  const sortedCategories = sortBy(categories, 'key')

  const handleSubmit = async (formData) => {
    const variables = generatePayload({
      ...formData,
      id: teamMember?.bankAccounts?.[0]?.bankAccountId,
    })
    setAdditionalLoading(true)
    await mutateReallocate({
      variables,
    })
    setTimeout(async () => {
      setAdditionalLoading(false)
      await refetch()
      navigate(-1)
    }, 5000)
  }

  return (
    <CardLayoutLink
      loading={loading || additionalLoading}
      cancel="/accounts"
      {...rest}
    >
      {!loading && !additionalLoading && (
        <ReallocateForm
          loading={mutationLoading || additionalLoading}
          onSubmit={handleSubmit}
          categories={sortedCategories}
        />
      )}
    </CardLayoutLink>
  )
}

export default ReallocateScene
