export type FlowStateType = { flow: { [key: string]: any } }

const FlowModel = {
  state: {},
  reducers: {
    SET_FLOW: (state, name, payload) => ({
      ...state,
      [name]: {
        meta: {
          completed: false,
          step: state[name].meta.step || 1,
          ...payload?.meta,
        },
        data: state[name].data
          ? { ...state[name].data, ...payload?.data }
          : payload?.data,
      },
    }),

    CLEAR_FLOW: (state, name) => {
      if (state[name].meta.keepDirty) return state

      return {
        ...state,
        [name]: null,
      }
    },

    SET_FLOW_META: (state, name, payload) => {
      const currentFlowData = state[name]

      return {
        ...state,
        [name]: {
          ...currentFlowData,
          meta: {
            ...currentFlowData?.meta,
            ...payload,
          },
        },
      }
    },

    SET_FLOW_DATA: (state, name, payload) => {
      const currentFlowData = state[name]

      return {
        ...state,
        [name]: {
          ...currentFlowData,
          data: {
            ...currentFlowData?.data,
            ...payload,
          },
        },
      }
    },

    SET_FLOW_DATA_ATTRIBUTE: (state, name, { attribute, data }) => {
      const currentFlowData = state[name]

      const isDataObject = typeof data === 'object'

      return {
        ...state,
        [name]: {
          ...currentFlowData,
          data: {
            ...currentFlowData?.data,
            [attribute]: isDataObject
              ? {
                  ...currentFlowData?.data?.[attribute],
                  ...data,
                }
              : data,
          },
        },
      }
    },
  },

  // effects: (dispatch) => ({

  // }),
}

export default FlowModel
