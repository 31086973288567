import React from 'react'
import clsx from 'clsx'

import Element, { ElementProps } from './Element'

export type GridProps = {
  gap?: any
  horizontal?: boolean
  vertical?: boolean
  columns?: number
  centerX?: boolean
  centerY?: boolean
  fixChildrenX?: boolean
  fixChildrenY?: boolean
  stretchChildrenX?: boolean
  stretchChildrenY?: boolean
} & ElementProps

const Grid: React.FC<GridProps> = ({
  gap,
  horizontal,
  columns,
  centerX,
  centerY,
  fixChildrenX,
  fixChildrenY,
  stretchChildrenX,
  stretchChildrenY,
  children,
  className,
  vertical,
  ...rest
}) => {
  const styles = React.useMemo(
    () => computeStyles({ gap, columns }),
    [gap, columns]
  )

  const classNames = clsx({
    [className]: className,
    gap: gap,
    columns: columns,

    'center-x': centerX,
    'center-y': centerY,
    'fix-children-x': fixChildrenX,
    'fix-children-y': fixChildrenY,
    'stretch-children-x': stretchChildrenX,
    'stretch-children-y': stretchChildrenY,

    'is-vertical': vertical && !horizontal,
    'is-horizontal': horizontal,
  })

  return (
    <Element css={styles.root} className={classNames} {...rest}>
      {children}
    </Element>
  )
}

const computeStyles = ({ gap, columns }) => ({
  root: {
    display: 'grid',

    '&.gap': {
      gridGap: gap,
    },

    '&.center-x': {
      justifyContent: 'center',
      justifyItems: 'center',
    },

    '&.center-y': {
      alignContent: 'center',
    },

    '&.fix-children-y': {
      gridAutoRows: 'min-content',
    },

    '&.fix-children-x': {
      justifyContent: 'flex-start',
    },

    '&.stretch-children-x': {
      justifyItems: 'stretch',
    },

    '&.stretch-children-y': {
      gridAutoRows: '1fr',
    },

    '&.is-horizontal': {
      gridAutoFlow: 'column',
    },

    '&.columns': {
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    },
  },
})

export default Grid
