import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import SelectField from './SelectField'
import Text, { TranslationToken } from '../../typography/Text'

const DayOfMonthSelectField = ({
  label = <Text token="REPEATS_ON_WHICH_DAY_OF_THE_MONTH" />,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const renderOptions = () => {
    const days = Array(30)
      .fill(undefined)
      .map((_, i) => (
        <option value={i + 1}>
          <Text token={`ORDINAL_${i + 1}` as TranslationToken} />
        </option>
      ))
    days.push(
      <option value={31}>
        <Text token="31_OR_LAST_DAY" />
      </option>
    )

    return days
  }
  return (
    <SelectField css={styles.root} label={label} hideDefaultOption {...rest}>
      {renderOptions()}
    </SelectField>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default DayOfMonthSelectField
