import React from 'react'

import Grid from '../../Grid'
import Flex from '../../Flex'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'


const CheckItem = ({
  bgColor = 'TURQUOISE_300',
  token,
}) => {

  return (
    <Flex centerY gap="12px" css={styles.root}>
      <IconCircle
        icon={'check'}
        color={bgColor}
        size={16}
        scale={1}
      />
      <Grid gap={5} stretch>
        <Text
          css={styles.categoryName}
          token={token}
          capitalize
        />
      </Grid>
    </Flex>
  )
}

const styles = {
  root: {},
  categoryName: {
    fontSize: '14px',
  },
}

export default CheckItem
