import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import Flex from '../../components/Flex'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import Paragraph from '../../components/typography/Paragraph'
import Categories, {
  CategoriesKeys,
  PseudoCategories,
  PseudoCategoriesKeys,
} from '../../utils/categories'
import CategoryDescription from '../../components/CategoryDescription'
import { useSearchParams } from 'react-router-dom'

type AboutCategoriesSceneProps = {
  title: JSX.Element
  back: string
}

const AboutCategoriesScene: React.FC<AboutCategoriesSceneProps> = ({
  back,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const [search] = useSearchParams()

  const from = search.get('from')

  return (
    <CardLayoutLink back={from || back} {...rest}>
      <Flex vertical gap="1rem" padding="1rem" css={styles.content}>
        <Paragraph css={styles.description} token="WHAT_ARE_CATEGORIES" />
        <Paragraph css={styles.description} token="CATEGORIES_SPENT" />
        {CategoriesKeys.map((cat) => {
          return <CategoryDescription category={Categories[cat]} />
        })}
        {PseudoCategoriesKeys.map((cat) => {
          return <CategoryDescription category={PseudoCategories[cat]} />
        })}
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY }: TTheme) => ({
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  description: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
    margin: 0,
  },
})

export default AboutCategoriesScene
