import React from 'react'

import { CardProps } from './Card'
import ComponentCard from './ComponentCard'
import List, { ListProps } from '../lists/List'

export type CardListProps = {} & CardProps & ListProps

const CardList: React.FC<CardListProps> = ({
  gap,
  padding,
  children,
  ...rest
}) => {
  return (
    <ComponentCard css={styles.root} {...rest}>
      <List gap={gap} padding={padding}>
        {children}
      </List>
    </ComponentCard>
  )
}

const styles = {
  root: {
    overflow: 'hidden',
    padding: 0,
  },
}

export default CardList
