import React from 'react'
import { ThemeProvider } from '@emotion/react'

import themes from '../utils/themes'

const Theme = ({ children }) => (
  <ThemeProvider theme={themes.default}>{children}</ThemeProvider>
)

export default Theme
