import React from 'react'
import isMobile from '../mobile/utils/isMobile'

const withMobile =
  (MobileWrapped: any, WebWrapped: any) =>
  (props): JSX.Element => {
    const mobile = isMobile()
    return mobile ? <MobileWrapped {...props} /> : <WebWrapped {...props} />
  }

export default withMobile
