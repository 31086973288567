import React from 'react'
import { useInvoices } from '../../../../hooks/useSubscriptions'
import isEmpty from 'lodash/isEmpty'
import Heading from '../../../../components/typography/Heading'
import CardLayoutLink from '../../../../components/layout/CardLayoutLink'
import ActionCard from '../../../../components/cards/ActionCard'
import Card from '../../../../components/cards/Card'
import { INVOICE_DATE_FORMAT } from '../../../../utils/constants'

const SubscriptionInvoices = ({ ...rest }) => {
    const { data: invoices, loading, error, refetch } = useInvoices(false)

    return (
        <CardLayoutLink
            loading={loading}
            error={error}
            {...rest}
        >
            <Card
                css={styles.root}
                empty={isEmpty(invoices)}
                retry={() => refetch()}
            >
                <Heading token="INVOICES_BY_DATE" />
                {invoices && invoices.map((invoice) => (
                    <ActionCard
                        key={invoice.id}
                        to={`/settings/subscriptions/invoices/${invoice.id}`}
                        css={styles.invoice}
                        dateFormat={INVOICE_DATE_FORMAT}
                        date={invoice.dateCreation}
                    />
                ))}
            </Card>

        </CardLayoutLink>
    )
}

const styles = {
    root: {
        padding: 0
    },
    invoice: {
        padding: 24
    }
}

export default SubscriptionInvoices
