import React from 'react'

import Button from '../../components/Button'
import ComponentCard from '../../components/cards/ComponentCard'
import CreditCardItem from '../../components/lists/items/CreditCardItem'
import Flex from '../../components/Flex'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import FormFooter from '../..//components/form/FormFooter'
import Text from '../../components/typography/Text'
import SmallText from '../../components/typography/SmallText'
import FormHeader from '../../components/typography/FormHeader'

import FlowContext from '../../context/FlowContext'

const FundAccountStatusStep = ({ ...rest }) => {
  const { currentData } = React.useContext(FlowContext)

  const selectedFundingMethod = currentData?.data?.fundingSourceModel

  return (
    <FlowStepLayout>
      <FormHeader token="notification_AdminFundsTransferCompleted" />

      <ComponentCard token="PAYER_DETAILS" outsideLabel>
        <Flex vertical>
          <SmallText>{selectedFundingMethod?.name}</SmallText>
          <SmallText>{selectedFundingMethod?.address_line1}</SmallText>
          {selectedFundingMethod?.address_line2 && (
            <SmallText>{selectedFundingMethod?.address_line2}</SmallText>
          )}
          <SmallText>
            {selectedFundingMethod?.address_city}{' '}
            {selectedFundingMethod?.address_postal_code}
          </SmallText>
          <SmallText>{selectedFundingMethod?.address_country}</SmallText>
        </Flex>
      </ComponentCard>

      <CreditCardItem
        token="PAYMENT_DETAILS"
        funding={selectedFundingMethod}
        outsideLabel
      />

      <Flex horizontalAlignment="flex-end">
        <Flex vertical verticalAlignment="flex-end" gap=".2rem">
          <SmallText uppercase token="YOU_PAID" />
          <Text
            currency={currentData?.data?.amount}
            currencyProps={{ currency: 'CAD' }}
            size="1.5rem"
          />
        </Flex>
      </Flex>

      <FormFooter>
        <Button to="/?refresh" token="OK" />
      </FormFooter>
    </FlowStepLayout>
  )
}

export default FundAccountStatusStep
