const RECURLY_DATA = {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    POSTAL_CODE: 'postal_code',
    ADDRESS_LINE_1: 'address1',
    ADDRESS_LINE_2: 'address2',
    CITY: 'city',
    REGION: 'state',
    COUNTRY: 'country'
}

export default RECURLY_DATA