import React from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client'
import { apiClientWithoutErrorHandler } from '../../config/api'
import { toast } from 'react-hot-toast'
import isMobile from '../../mobile/utils/isMobile'

import NotificationCard from '../cards/NotificationCard'

import {
  subscribeToUserNotifications,
  notification,
  openCardOrders,
} from '../../queries'
import useCurrentUser from '../../hooks/useCurrentUser'

const NotificationsRoute = () => {
  const dispatch = useDispatch()
  const { data: currentMember } = useCurrentUser()

  const hideMobileError = isMobile()
    ? { client: apiClientWithoutErrorHandler }
    : {}

  const { data } = useSubscription(subscribeToUserNotifications, {
    variables: { userId: currentMember?.userProfile?.id },
    shouldResubscribe: true,
    skip: !currentMember,
    ...hideMobileError,
  })

  const { refetch: refetchOpenCardOrders } = useQuery(openCardOrders, hideMobileError)

  const [getNotification, { data: notificationModel }] = useLazyQuery(
    notification,
    {
      fetchPolicy: 'no-cache',
      ...hideMobileError,
    }
  )

  const notificationActions = {
    ProfileUpdated: {
      action: () => {},
    },
    PaymentProfileDocumentVerified: {
      action: () => {},
    },
    DocumentDeclined: {
      action: () => {},
    },
    FundsTransferInitiated: {
      action: () => {},
    },
    DocumentVerified: {
      action: () => {},
    },
    PaymentProfileDocumentDeclined: {
      action: () => {},
    },
    AdminFundsTransferCompleted: {
      action: () => {},
    },
    FundsTransferCompleted: {
      action: () => {},
    },
    FundingSourceCreated: {
      action: () => {},
    },
    CardRegistered: {
      action: () => {},
    },
    CardReplaced: {
      action: () => {},
    },
    CardReplacing: {
      action: () => {},
    },
    CardUnlocked: {
      action: () => {},
    },
    CardLocked: {
      action: () => {},
    },
    CardOrderCanceled: {
      action: () => {
        refetchOpenCardOrders()
      },
    },
    FundsTransferFailed: {
      action: () => {},
    },
    InvalidActivationCode: {
      action: () => {},
    },
    SubscriptionUpdated: {
      action: () => {},
    },
  }

  React.useEffect(() => {
    if (!data) return

    getNotification({
      variables: { id: data.subscribeToUserNotifications?.id },
    })
  }, [data])

  React.useEffect(() => {
    if (!notificationModel) return

    dispatch.notifications.ADD_NOTIFICATION(notificationModel)

    toast.custom(
      <NotificationCard
        {...notificationModel?.notification}
        css={styles.root}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      />,
      {
        position: 'bottom-center',
      }
    )

    notificationActions[notificationModel?.notification?.code]?.action?.()
  }, [notificationModel])

  return <Outlet />
}

const styles = {
  root: {
    width: '100vw',
    maxWidth: '70%',
    boxShadow:
      'rgb(12 38 106 / 8%) 0px 6px 8px 4px, rgb(12 38 106 / 6%) 0px 16px 24px 6px, rgb(12 38 106 / 4%) 0px 24px 32px 8px',
  },
}

export default NotificationsRoute
