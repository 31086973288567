import { Theme, useTheme } from '@emotion/react'
import React from 'react'
import Button from './Button'

import Flex from './Flex'
import Grid from './Grid'
import Paragraph from './typography/Paragraph'
import Text from './typography/Text'

const TeachKids = () => {
  const { theme } = useTheme()
  const styles = computeStyles(theme)
  return (
    <Grid gap="1rem" css={styles.root}>
      <img alt="Hero" src="/images/myHeroLow.png" css={styles.image} />
      <Paragraph token="TEACH_KIDS_MONEY_SKILLS" css={styles.description} />
      <Flex css={styles.footer} centerY spread>
        <Text token="ADD_PRODUCT_FOR_YOU_SIDEKICK" css={styles.legend} />
        <Button token="START" css={styles.button} to="/products/new" />
      </Flex>
    </Grid>
  )
}
const computeStyles = ({
  TEACH_KIDS: TEACH_KIDS_BACKGROUND,
  TEACH_KIDS_BUTTON,
  TEXT_SECONDARY,
}: Theme['theme']) => ({
  root: {
    borderRadius: '16px',
    gridTemplateColumns: '3fr 1fr 1fr',
    gridTemplateRows: '1fr 64px',
    height: 'max-content',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    gridRowStart: 1,
    gridRowEnd: -1,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  description: {
    color: TEXT_SECONDARY,
    fontSize: '24px',
    fontWeight: 500,
    gridRowStart: 1,
    gridRowEnd: 2,
    gridColumnStart: 1,
    gridColumnEnd: 2,
    lineHeight: '32px',
    margin: 0,
    padding: '12px',
  },
  footer: {
    backgroundColor: TEACH_KIDS_BACKGROUND,
    gridRowStart: 2,
    gridRowEnd: -1,
    gridColumnStart: 1,
    gridColumnEnd: -1,
    padding: '12px',
  },
  legend: {
    color: TEXT_SECONDARY,
    fontSize: '14px',
    letterSpacing: '-.25px',
    lineHeight: '20px',
  },
  button: {
    backgroundColor: TEACH_KIDS_BUTTON,
    color: TEACH_KIDS_BACKGROUND,
    fontSize: '14px',
  },
})

export default TeachKids
