import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import CategoryCard from '../../cards/CategoryCard'
import Detail from '../../typography/Detail'
import DrawerCard from '../../drawers/DrawerCard'
import Field from '../Field'
import FormContext from '../../../context/FormContext'
import Heading from '../../typography/Heading'

const compareCategory = (firstCategory, secondCategory) =>
  firstCategory === secondCategory

enum FieldType {
  To = 'toCategory',
  From = 'fromCategory',
}

type FromToCategoryType = {
  categories: Array<any>
  disabled?: boolean
  from?: any
  onChange?: Function
  to?: any
} & React.PropsWithChildren

const FromToFieldCategory: React.FC<FromToCategoryType> = ({
  categories,
  disabled,
  from,
  onChange = () => {},
  to,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const form = React.useContext(FormContext)

  const [drawer, setDrawer] = useState({
    visible: false,
    fieldType: FieldType.From,
  })

  const fieldCondition = drawer.fieldType === FieldType.From

  const selectCategory = async (category) => {
    const oppositeField = fieldCondition ? to : from
    const field = fieldCondition ? from : to

    if (compareCategory(category, oppositeField)) {
      const oppositeName = fieldCondition ? FieldType.To : FieldType.From
      form.setFieldValue(oppositeName, field)
      onChange(oppositeName, field)
    }

    form.setFieldValue(drawer.fieldType, category)
    onChange(drawer.fieldType, category)

    setDrawer({
      visible: false,
      fieldType: drawer.fieldType,
    })
  }

  const handleDrawer = async (visible, fieldType) => {
    if (disabled) return
    await setDrawer({
      visible,
      fieldType,
    })
  }

  return (
    <>
      <Detail css={styles.heading} token="FROM_CATEGORY" />
      <CategoryCard
        category={from}
        disabled={disabled}
        onClick={() => handleDrawer(true, FieldType.From)}
      />
      <Detail css={styles.heading} token="TO_CATEGORY" />
      <CategoryCard
        category={to}
        disabled={disabled}
        onClick={() => handleDrawer(true, FieldType.To)}
      />
      <DrawerCard
        handleClickOutside={() => handleDrawer(false, drawer.fieldType)}
        isOpened={drawer.visible}
      >
        <Heading
          alignSelf="center"
          token={fieldCondition ? 'FROM_CATEGORY' : 'TO_CATEGORY'}
          css={styles.drawerHeading}
        />
        {categories?.map((category) => {
          const fieldMember = fieldCondition ? from : to
          const isSelected = compareCategory(category, fieldMember)
          return (
            <CategoryCard
              category={category}
              disabled={isSelected}
              css={isSelected && styles.selectedMember}
              key={`Category-${category.key}`}
              onClick={() => !isSelected && selectCategory(category)}
              bottomElement={
                <Field
                  type="radio"
                  css={styles.option}
                  name={`category-${category.key}-${drawer.fieldType}`}
                  value={false}
                  checked={isSelected}
                />
              }
            />
          )
        })}
      </DrawerCard>
    </>
  )
}

const computeStyles = (theme) => ({
  heading: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  drawerHeading: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '24px',
  },
  option: {
    padding: 0,
    margin: 0,
    gap: 0,
    width: 25,
  },
  selectedMember: {
    borderRadius: 10,
    border: `1px solid ${theme.PRIMARY_ALT}`,
  },
})

export default FromToFieldCategory
