import React from 'react'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import CardFreezeForm from '../../../components/form/forms/CardFreezeForm'

import {
  getCards,
  lockCard as lockCardMutation,
  unlockCard as unlockCardMutation,
} from '../../../queries'
import { TPlainStyle } from '@emotion/react'

const FreezeCardScene = ({ ...rest }) => {
  const { id } = useParams()
  const innerFormik = React.useRef(null)

  const { data: cardsData, loading: cardsLoading } = useQuery(getCards)

  const [lockCard, { loading: lockLoading }] = useMutation(lockCardMutation)

  const [unlockCard, { loading: unlockLoading }] =
    useMutation(unlockCardMutation)

  const cards = cardsData?.cards || []
  const card = cards.find((card) => card.id === id)
  const status = card?.status?.statusCode === 'Locked'
  const processing = lockLoading || unlockLoading

  const handleFieldUpdate = async (_, value) => {
    const variables = { id }
    try {
      if (value) {
        await lockCard({ variables })
      } else {
        await unlockCard({ variables })
      }
    } catch (err) {
      innerFormik?.current?.resetForm?.()
    }
  }

  const loading = cardsLoading
  return (
    <CardLayoutLink
      title={<Text token="FREEZE_UNFREEZE_CARD" />}
      loading={loading}
      processing={processing}
      processingLabel={
        <Text token={lockLoading ? 'FREEZING_CARD' : 'UNFREEZING_CARD'} />
      }
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        <CardFreezeForm
          handleFieldUpdate={handleFieldUpdate}
          loading={loading}
          status={status}
          innerRef={innerFormik}
        />
      </Flex>
    </CardLayoutLink>
  )
}

const styles: TPlainStyle = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default FreezeCardScene
