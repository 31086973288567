import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const SwitchIcon = ({ size = 1, color = '#FFF', width, height }) => {
    const defaultWidth = 24
    const defaultHeight = 24

    let factor = getIconSizeFactor({
        size,
        width,
        height,
        defaultWidth,
        defaultHeight,
    })

    return (
        <svg width={defaultWidth * factor} height={defaultHeight * factor} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 6C1 4.34315 2.34315 3 4 3H7C7.55228 3 8 3.44772 8 4V20C8 20.5523 7.55228 21 7 21H4C2.34315 21 1 19.6569 1 18V6ZM4 5C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H6V5H4Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 4C16 3.44772 16.4477 3 17 3H20C21.6569 3 23 4.34315 23 6V18C23 19.6569 21.6569 21 20 21H17C16.4477 21 16 20.5523 16 20V4ZM18 5V19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5H18Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 4C6 2.34315 7.34315 1 9 1H15C16.6569 1 18 2.34315 18 4V20C18 21.6569 16.6569 23 15 23H9C7.34315 23 6 21.6569 6 20V4ZM9 3C8.44772 3 8 3.44772 8 4V20C8 20.5523 8.44772 21 9 21H15C15.5523 21 16 20.5523 16 20V4C16 3.44772 15.5523 3 15 3H9Z" fill={color} />
        </svg>
    )

}

export default SwitchIcon
