import { SIDEKICK } from '../utils/features'
import useCurrentRole from './useCurrentRole'

const useIsSidekick = () => {
  const role = useCurrentRole()

  return role === SIDEKICK
}

export default useIsSidekick
