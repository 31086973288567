import React from 'react'

import SelectField from './SelectField'

import countries from '../../../utils/countries'

const CountrySelectField = ({ ...rest }) => {
  return (
    <SelectField {...rest}>
      {countries.map((country) => (
        <option
          key={`country_${country.code}_${country.abbreviation}`}
          value={country.country}
        >
          {country.country}
        </option>
      ))}
    </SelectField>
  )
}

export default CountrySelectField
