import React from 'react'
import * as Yup from 'yup'
import { DateTime } from 'luxon'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import GenderSelectField from '../fields/GenderSelectField'
import Field from '../Field'
import CountrySelectField from '../fields/CountrySelectField'
import Text from '../../typography/Text'
import PhoneField, { PHONE_VALIDATOR_REQUIRED } from '../fields/PhoneField'

export type AdditionalInfoFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
}

const DEFAULT_COUNTRY = 'Canada'

const schema = Yup.object().shape({
  principalAddress: Yup.object().shape({
    country: Yup.string().required(),
  }),
  gender: Yup.string().required(),
  dateOfBirth: Yup.string()
    .test('DOB', 'HERO_MUST_BE_18_YEARS_OLD', (value) => {
      return DateTime.fromISO(value).diffNow('years').years < -18
    })
    .required(),
  phoneNumber: PHONE_VALIDATOR_REQUIRED,
})

const AdditionalInfoForm: React.FC<AdditionalInfoFormProps> = ({
  data = {},
  submit,
  loading,
  disabled,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { country, gender, dateOfBirth, phoneNumber } = data

  const defaultInitialValues = {
    principalAddress: {
      country: country || DEFAULT_COUNTRY,
    },
    gender,
    dateOfBirth,
    phoneNumber,
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <CountrySelectField
        label={<Text token="COUNTRY" />}
        name="principalAddress.country"
        css={styles.field}
        required
        disabled
      />

      <GenderSelectField
        placeholder={<Text token="WHAT_DO_YOU_IDENTIFY_AS" />}
        label={<Text token="GENDER" />}
        name="gender"
        as="select"
        css={styles.field}
        required
      />
      <Field
        type="date"
        label={<Text token="DATE_OF_BIRTH" />}
        name="dateOfBirth"
        css={styles.field}
        required
      />
      <PhoneField
        label={<Text token="PHONE_NUMBER" />}
        name="phoneNumber"
        css={styles.phoneField}
        required
      />

      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  phoneField: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default AdditionalInfoForm
