import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const MaskIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#77AEEC',
  width,
  height,
}) => {
  const defaultWidth = 40
  const defaultHeight = 18
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 40 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4295 0C28.6093 0 24.0625 2.5073 19.5158 2.5073C14.969 2.5073 10.4222 0 3.60208 0C-3.21807 0 1.3287 14.3418 3.60208 16.8491C5.87547 19.3564 14.969 14.3418 19.5158 14.3418C24.0625 14.3418 33.1561 19.3564 35.4295 16.8491C37.7028 14.3418 42.2496 0 35.4295 0Z"
        fill={color}
      />
      <path
        d="M11.2734 12.5366C10.023 12.5366 9 11.4083 9 10.0293V7.52195C9 6.14293 10.023 5.01465 11.2734 5.01465C12.5237 5.01465 13.5468 6.14293 13.5468 7.52195V10.0293C13.5468 11.4083 12.5237 12.5366 11.2734 12.5366ZM29.4605 12.5366C28.2101 12.5366 27.1871 11.4083 27.1871 10.0293V7.52195C27.1871 6.14293 28.2101 5.01465 29.4605 5.01465C30.7108 5.01465 31.7338 6.14293 31.7338 7.52195V10.0293C31.7338 11.4083 30.7108 12.5366 29.4605 12.5366Z"
        fill="white"
      />
    </svg>
  )
}

export default MaskIcon
