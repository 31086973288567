import { useMutation, useQuery } from '@apollo/client'
import { addBankAccount as addBankAccountMutation } from '../queries/mutations'
import { teamMembers as teamMembersQuery } from '../queries'

const useAddBankAccount = (id) => {
  const { data: dataTeamMembers } = useQuery(teamMembersQuery, {
    fetchPolicy: 'cache-first',
  })

  const queryOptions = {
    notifyOnNetworkStatusChange: true,
    update: (store, { data }) => {
      const teamMembers = Object.assign([], dataTeamMembers.teamMembers)
      let updateMember = teamMembers.find(
        (member) => member.userProfile.id === id
      )
      if (updateMember) {
        const bankAccounts = [...updateMember.bankAccounts, data.addBankAccount]
        teamMembers[teamMembers.length - 1] = Object.assign(
          {},
          teamMembers[teamMembers.length - 1],
          { bankAccounts }
        )
        const res = { ...dataTeamMembers, teamMembers }
        store.writeQuery({
          query: teamMembersQuery,
          data: res,
        })
      }
    },
  }
  return useMutation(addBankAccountMutation, queryOptions)
}

export default useAddBankAccount
