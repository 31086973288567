import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const RepeatIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 20
  const defaultHeight = 22
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893L19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L17.5858 5L14.2929 1.70711C13.9024 1.31658 13.9024 0.683417 14.2929 0.292893Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9V10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10V9C0 7.67392 0.526784 6.40215 1.46447 5.46447C2.40215 4.52678 3.67392 4 5 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70711 12.2929C6.09763 12.6834 6.09763 13.3166 5.70711 13.7071L2.41421 17L5.70711 20.2929C6.09763 20.6834 6.09763 21.3166 5.70711 21.7071C5.31658 22.0976 4.68342 22.0976 4.29289 21.7071L0.292893 17.7071C-0.0976311 17.3166 -0.0976311 16.6834 0.292893 16.2929L4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 11C19.5523 11 20 11.4477 20 12V13C20 14.3261 19.4732 15.5979 18.5355 16.5355C17.5979 17.4732 16.3261 18 15 18H1C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16H15C15.7956 16 16.5587 15.6839 17.1213 15.1213C17.6839 14.5587 18 13.7956 18 13V12C18 11.4477 18.4477 11 19 11Z"
        fill={color}
      />
    </svg>
  )
}

export default RepeatIcon
