import { useIsValidSubscription } from './useSubscriptions'
import { useMutation } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

import useCurrentUser from './useCurrentUser'
import {
  enableCashWithrawals,
  disableCashWithrawals,
} from '../queries/mutations'

const ATM_ALLOW_PLANS = {
  'hero-premium-monthly': true,
  'hero-ultimate-monthly': true,
  'hero-premium-yearly': true,
  'hero-ultimate-yearly': true,
}

export type atmType = Function | Array<any> | any | boolean

const useATM = (accountOwnerId: string, accountIndex = 0, cache = true) => {
  const { subscription } = useIsValidSubscription()
  const { data, refetch, teamMembersData } = useCurrentUser(
    cache,
    accountOwnerId
  )
  const { isCashWithdrawalDisabled, bankAccountId } =
    data?.bankAccounts?.[accountIndex] || {}

  const allowATM = ATM_ALLOW_PLANS[subscription?.subscriptionPlan?.code] 
    
  return {
    allowATM: !!allowATM,
    isCashWithdrawalDisabled,
    bankAccountId,
    refetch,
    memberData: data,
    teamMembersData,
  }
}

export const useATMToggle = (
  accountOwnerId,
  bankAccountId,
  value?: boolean
) => {
  return useMutation(value ? disableCashWithrawals : enableCashWithrawals, {
    notifyOnNetworkStatusChange: true,
    variables: {
      accountOwnerId,
      bankAccountId,
    },
  })
}

export const getIsCashWithdrawalDisabled = (data): boolean => {
  if (isUndefined(data?.enableCashWithrawals)) {
    return data?.disableCashWithrawals?.isCashWithdrawalDisabled
  }
  return data?.enableCashWithrawals?.isCashWithdrawalDisabled
}

export const getUpdatedCacheData = (teamMembersData, id, mutationData) => {
  const itemIndex = teamMembersData.teamMembers.findIndex(
    (member) => member.userProfile.id === id
  )
  const cacheData = cloneDeep(teamMembersData)
  set(
    cacheData,
    `teamMembers[${itemIndex}].bankAccounts[0].isCashWithdrawalDisabled`,
    getIsCashWithdrawalDisabled(mutationData)
  )
  return cacheData
}

export default useATM
