import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import BasicInfoAndPasswordForm from '../../../components/form/forms/BasicInfoAndPasswordForm'
import FlowContext from '../../../context/FlowContext'

const GetStarted: React.FC<any> = ({
  data = {},
  submit,
  disabled,
  loading,
  error,
  ...rest
}) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) => {
    return await setFlowData({ [name]: value })
  }

  return (
    <FlowStepLayout>
      <BasicInfoAndPasswordForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default GetStarted
