import React from 'react'
import { DateTime } from 'luxon'

import List from './List'
import Text from '../typography/Text'
import TransferActivityItem from './items/TransferActivityItem'

import {
  groupTransactionsByMonths,
  getMonthName,
  getAmount,
} from '../../utils/functions'

const MONEY_IN = ['BerkeleyDirectSendDeposit', 'TransferIn', 'ReallocationIn']
const MONEY_OUT = [
  'SideKickAccountAllocation',
  'TransferOut',
  'ReallocationOut',
]
const PENDING_STATUS = 'Pending'

const isPending = (transaction) => transaction?.node?.status === PENDING_STATUS

const filters = {
  all: () => true,
  moneyIn: (transaction) =>
    isPending(transaction)
      ? getAmount(transaction?.node?.billingAmount) > 0
      : MONEY_IN.indexOf(
          transaction?.node?.type || transaction?.node?.transactionType
        ) > -1,
  moneyOut: (transaction) =>
    isPending(transaction)
      ? getAmount(transaction?.node?.billingAmount) < 0
      : MONEY_OUT.indexOf(
          transaction?.node?.type || transaction?.node?.transactionType
        ) > -1,
}

const getPendingTransactions = (transactions) => transactions?.filter(isPending)
const getCompletedTransactions = (transactions) =>
  transactions?.filter((transaction) => !isPending(transaction))

type TransactionsListProps = {
  transactions?: any[]
  showPending: boolean
  filter?: keyof typeof filters
  orderByDate?: boolean
  orderByStatus?: boolean
}

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions,
  showPending = false,
  filter = 'all',
  orderByDate,
  orderByStatus,
}) => {
  const sortedTransactions = transactions.sort(
    (a, b) =>
      DateTime.fromISO(
        b?.node?.transactionDateTime || b?.node?.updatedAt
      ).toMillis() -
      DateTime.fromISO(
        a?.node?.transactionDateTime || a?.node?.updatedAt
      ).toMillis()
  )

  const foundFilter = filters[filter]
  const pendingTransactions =
    getPendingTransactions(sortedTransactions)?.filter(foundFilter) || []

  const filteredTransactions = sortedTransactions.filter(foundFilter)
  const completedTransactions = getCompletedTransactions(filteredTransactions)
  const groupedDateTransactions = groupTransactionsByMonths(
    completedTransactions
  )

  return (
    <List vertical gap="1rem">
      {showPending && orderByStatus && !!pendingTransactions.length && (
        <>
          <Text as="h4" css={styles.transactionHeading} token="PENDING" />
          {pendingTransactions?.map((transaction) => {
            return (
              <TransferActivityItem
                key={transaction.id}
                transaction={transaction}
              />
            )
          })}
        </>
      )}
      {!!orderByDate &&
        Object.keys(groupedDateTransactions).map((key, index) => (
          <React.Fragment key={key + index}>
            <Text as="h4" css={styles.transactionHeading}>
              {getMonthName(key)}
            </Text>
            {groupedDateTransactions[key].map((transaction) => (
              <TransferActivityItem
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </React.Fragment>
        ))}
      {!orderByDate &&
        completedTransactions.map((transaction) => (
          <TransferActivityItem
            key={transaction.id}
            transaction={transaction}
          />
        ))}
    </List>
  )
}

const styles = {
  transactionHeading: {
    margin: 0,
    lineHeight: '1',
    fontWeight: 500,
    fontSize: '14px',
  },
}

export default TransactionsList
