import React from 'react'

import Text, { TranslationToken } from '../typography/Text'

export type CardTitleProps = {
  title?: string | JSX.Element
  className?: string
  token?: TranslationToken
}

const CardTitle: React.FC<CardTitleProps> = ({ title, token, className }) => {
  return (
    <div css={styles.root} className={className}>
      <Text token={token}>{title}</Text>
    </div>
  )
}

const styles = {
  root: {
    fontSize: '1.2rem',
  },
}

export default CardTitle
