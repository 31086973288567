import React from 'react'
import clsx from 'clsx'

import Element, { ElementProps } from './Element'
import { checkSafari } from '../utils/functions'

export type FlexProps = {
  gap?: any
  vertical?: boolean
  verticalAlignment?: 'flex-end' | 'flex-start' | 'center'
  horizontalAlignment?: 'flex-end' | 'flex-start' | 'center'
  center?: boolean
  centerX?: boolean
  centerY?: boolean
  fixChildrenX?: boolean
  fixChildrenY?: boolean
  fixSelf?: boolean
  stretchChildrenX?: boolean
  stretchChildrenY?: boolean
  stretchSelf?: boolean
  stretchSelfMax?: boolean
  nowrap?: boolean
  spread?: boolean
  onClick?: Function
} & ElementProps

const Flex: React.FC<FlexProps> = ({
  gap,
  vertical,

  verticalAlignment,
  horizontalAlignment,

  center,
  centerX,
  centerY,
  fixChildrenX,
  fixChildrenY,
  fixSelf,
  stretchChildrenX,
  stretchChildrenY,
  stretchSelf,
  stretchSelfMax,
  nowrap,
  spread,

  children,
  className,
  ...rest
}) => {
  const isSafari = checkSafari()
  const classNames = clsx({
    [className]: className,
    center: center,
    'center-y': centerY,
    'center-x': centerX,
    'fix-children-x': fixChildrenX,
    'fix-children-y': fixChildrenY,
    'fix-self': fixSelf,
    'stretch-children-x': stretchChildrenX,
    'stretch-children-y': stretchChildrenY,
    'stretch-self': stretchSelf,
    'stretch-self-max': stretchSelfMax,
    'is-vertical': vertical,
    'is-horizontal': !vertical,
    'is-safari': isSafari,
    nowrap: nowrap,
    spread: spread,
  })

  const styles = React.useMemo(
    () => computeStyles({ gap, verticalAlignment, horizontalAlignment }),
    [gap, verticalAlignment, horizontalAlignment]
  )

  return (
    <Element css={styles.root} className={classNames} {...rest}>
      {children}
    </Element>
  )
}

const computeStyles = ({ gap, verticalAlignment, horizontalAlignment }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

    ...(gap && { gap }),

    ...(verticalAlignment && { alignItems: verticalAlignment }),
    ...(horizontalAlignment && { justifyContent: horizontalAlignment }),

    '&.spread': {
      justifyContent: 'space-between',
    },

    '&.nowrap': {
      flexWrap: 'nowrap',
    },

    '&.is-horizontal': {
      flexDirection: 'row',
    },

    '&.is-vertical': {
      flexDirection: 'column',
    },

    '&.center': {
      justifyContent: 'center',
      alignItems: 'center',
    },

    '&.center-y': {
      alignItems: 'center',
    },

    '&.center-x': {
      justifyContent: 'center',
    },

    '&.fix-children-y': {
      alignItems: 'flex-start',
    },

    '&.fix-children-x': {
      justifyItems: 'flex-start',
    },

    '&.fix-self': {
      flex: '0 0 auto',
    },

    '&.stretch-children-x': {
      '& > *': {
        flex: '1 1 auto',
      },
    },

    '&.stretch-children-y': {
      alignItems: 'stretch',
    },

    '&.stretch-self': {
      flex: '1 1 auto',
    },

    '&.stretch-self-max': {
      flex: '999 1 auto',
    },
    '&.is-safari': {
      '&.is-horizontal > *:not(:last-child)': {
        marginRight: gap,
      },
      '&.is-vertical > *:not(:last-child)': {
        marginBottom: gap,
      },
    },
  },
})

export default Flex
