import { Capacitor } from '@capacitor/core'

export const isWebBrowserMobile: Function = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

const isMobile = () => Capacitor.isNativePlatform()

export default isMobile
