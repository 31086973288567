import { useQuery } from '@apollo/client'
import { teamMembers as teamMembersQuery, teamMembersById } from '../queries'

export enum RoleType {
  HERO = 'HERO',
  SIDEKICK = 'SIDEKICK',
}

export const isHero = (role) => role === RoleType.HERO

export const filterTeamMembersByRole = (teamMembers, roleFilter: RoleType) =>
  teamMembers.filter((member) => member.role === roleFilter)

const useTeamMembers = (
  cache = true,
  hasBankAccounts?,
  roleFilter?: RoleType,
  id?: string
) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  const { data, loading, refetch, error } = useQuery(teamMembersQuery, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  })

  let teamMembers = data?.teamMembers
  const exist = teamMembers && teamMembers.length

  if (exist && hasBankAccounts) {
    teamMembers = teamMembers.filter(
      (member) => member?.bankAccounts && member?.bankAccounts?.length
    )
  }

  if (exist && roleFilter) {
    teamMembers = filterTeamMembersByRole(teamMembers, roleFilter)
  }

  let teamMember

  if (exist && id) {
    teamMember = teamMembers.find((member) => member.userProfile.id === id)
  }

  return { teamMembers, teamMember, loading, refetch, error }
}

export default useTeamMembers

export const useTeamMemberById = (cache = true, id) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  return useQuery(teamMembersById, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  })
}
