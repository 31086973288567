import React from 'react'
import { generatePayload } from '../../utils/allocate'

import CardLayoutLink from '../../components/layout/CardLayoutLink'
import AllocateForm from '../../components/form/forms/AllocateForm'

import useAllocate from '../../hooks/useAllocate'
import useTeamMembers from '../../hooks/useTeamMembers'
import useSubscriptionsAllowance from '../../hooks/useSubscriptionsAllowance'
import useTeamHoldingAccount from '../../hooks/useTeamHoldingsAccount'
import { useNavigate } from 'react-router-dom'

type AllocationsSceneProps = {
  title?: JSX.Element
}

const AllocationsScene: React.FC<AllocationsSceneProps> = ({ ...rest }) => {
  const {
    teamMembers,
    loading: membersLoading,
    refetch: refetchMembers,
  } = useTeamMembers(true, true)
  const { allow, loading: allowanceLoading } = useSubscriptionsAllowance()
  const {
    data,
    loading: holdingLoading,
    refetch: refetchTeamHolding,
  } = useTeamHoldingAccount(true)

  const accounts = [data?.teamHoldingAccount, ...teamMembers]

  const categoryAllocate =
    typeof allow === 'object' ? allow?.categoryAllocate : false

  const [move, setMove] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [mutateAllocate, { loading: submitLoading }] = useAllocate(move)

  const navigate = useNavigate()

  const handleSubmit = async (data) => {
    const payload = generatePayload(
      data,
      categoryAllocate && !data?.fromAllocate?.userProfile?.id
    )

    await setSubmitting(true)
    await setMove(!!payload.sourceAccount)

    try {
      await mutateAllocate({
        variables: payload,
      })
      // TODO: replace this with a subscription logic on the transaction
      await new Promise((resolve) => setTimeout(resolve, 2500))
      if (!payload.sourceAccount) await refetchTeamHolding()
      await refetchMembers()
      navigate('/')
    } catch (err) {}

    await setSubmitting(false)
  }

  const CardLoading = membersLoading || allowanceLoading || holdingLoading

  return (
    <CardLayoutLink
      loading={!submitting ? CardLoading : false}
      cancel={'/'}
      {...rest}
    >
      {data && (
        <AllocateForm
          loading={submitLoading || submitting}
          onSubmit={handleSubmit}
          categoryAllocate={categoryAllocate}
          teamMembers={accounts}
        />
      )}
    </CardLayoutLink>
  )
}

export default AllocationsScene
