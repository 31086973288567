import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { Theme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import Field from '../Field'
import CountrySelectField from '../fields/CountrySelectField'
import FieldCard from '../../cards/FieldCard'
import Text from '../../typography/Text'
import Header from '../../typography/Header'
import RadioField from '../fields/RadioField'
import Paragraph from '../../typography/Paragraph'
import Flex from '../../Flex'

export type ShippingAddressFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  hideShippingMethod?: boolean
}

const DEFAULT_COUNTRY = 'Canada'
const FREE_SHIPPING_AMOUNT = 0
const WITH_TRACKING_SHIPPING_AMOUNT = 25

export const SHIPPING_METHOD = {
  FREE: 'Regular',
  RUSH_TRACK: 'Rush',
}

const schema = Yup.object().shape({
  shippingAddress: Yup.object().shape({
    addressLine1: Yup.string().trim().required(),
    addressLine2: Yup.string().optional(),
    city: Yup.string().required(),
    postalCode: Yup.string().length(6).required(),
    region: Yup.string().required(),
    country: Yup.string().required(),
  }),
  shippingMethod: Yup.string().required(),
  simcard: Yup.boolean(),
})

const ShippingAddressForm: React.FC<ShippingAddressFormProps> = ({
  data = {},
  submit,
  loading,
  disabled,
  hideShippingMethod,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { shippingAddress = {}, simcard, shippingMethod } = data
  const { country, addressLine1, addressLine2, city, postalCode, region } =
    shippingAddress

  const defaultInitialValues = {
    shippingAddress: {
      addressLine1,
      addressLine2: addressLine2 || '',
      city,
      postalCode,
      region,
      country: country || DEFAULT_COUNTRY,
    },
    shippingMethod,
    simcard: simcard || false,
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <FieldCard label={<Text token="ADDRESS" />}>
        <Field
          label={<Text token="ADDRESS_LINE_1" />}
          name="shippingAddress.addressLine1"
        />
        <Field
          label={<Text token="ADDRESS_LINE_2" />}
          name="shippingAddress.addressLine2"
        />
        <Field label={<Text token="CITY" />} name="shippingAddress.city" />

        <Field label={<Text token="REGION" />} name="shippingAddress.region" />

        <Field
          label={<Text token="POSTAL_CODE" />}
          name="shippingAddress.postalCode"
        />

        <CountrySelectField
          label={<Text token="COUNTRY" />}
          name="shippingAddress.country"
          disabled
        />
      </FieldCard>
      {!hideShippingMethod && (
        <>
          <Header css={styles.headline} token="SHIPPING" />
          <RadioField
            css={styles.field}
            label={
              <Flex vertical>
                <Text
                  css={styles.label}
                  token="FREE_DELIVERY_SHIPPING"
                  interpolation={{ amount: FREE_SHIPPING_AMOUNT }}
                />
                <Paragraph token="BUSINESS_DAYS_7_10" />
              </Flex>
            }
            name="shippingMethod"
            value={SHIPPING_METHOD.FREE}
          />
          <RadioField
            css={styles.field}
            label={
              <Flex vertical>
                <Text
                  css={styles.label}
                  token="RUSH_DELIVERY_WITH_TRACKING_SHIPPING"
                  interpolation={{
                    amount: WITH_TRACKING_SHIPPING_AMOUNT,
                  }}
                />
                <Paragraph token="BUSINESS_DAYS_2_3" />
              </Flex>
            }
            name="shippingMethod"
            value={SHIPPING_METHOD.RUSH_TRACK}
          />
          <Paragraph token="ADDITIONAL_FEES_WILL_BE_IMMEDIATELY_CHARGED_DISCLAIMER" />
        </>
      )}
      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
  },
  headline: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  label: {
    color: theme.TEXT_PRIMARY_DARK,
    fontSize: '14px',
    letterSpacing: '-.25px',
    lineHeight: '20px',
  },
  footer: {
    backgroundColor: theme.BACKGROUND,
  },
})

export default ShippingAddressForm
