import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'

import { cardPin } from '../../../queries'
import Header from '../../../components/typography/Header'
import Button from '../../../components/Button'
import Paragraph from '../../../components/typography/Paragraph'
import { getMinutesFromSeconds } from '../../../utils/functions'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

const RetrievePinScene = ({ ...rest }) => {
  const { id } = useParams()
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [formatedTime, setFormatedTime] = React.useState('')
  const [remaining, setRemaining] = React.useState(0)

  const { data, loading } = useQuery(cardPin, {
    variables: {
      id,
    },
  })
  const { pin } = data?.cardPin || {}

  React.useEffect(() => {
    setFormatedTime(getMinutesFromSeconds(remaining))
    if (remaining > 0) {
      setTimeout(() => {
        setRemaining(remaining - 1)
      }, 1000)
    }
  }, [remaining])

  return (
    <CardLayoutLink
      title={<Text token="RETRIEVE_PIN" />}
      loading={loading}
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        <Header css={styles.headline} token="YOUR_CARD_PIN" />
        {!!remaining && (
          <>
            <Text
              css={styles.remaining}
              token="YOUR_PIN_WILL_BE_HIDDEN_IN_TIME"
              interpolation={{ remainingTime: formatedTime }}
              capitalize={false}
            />
            <Paragraph css={styles.pin}>{pin}</Paragraph>
          </>
        )}
        {!remaining && (
          <Button
            button
            token="RETRIEVE_PIN"
            onClick={() => setRemaining(10)}
          />
        )}
      </Flex>
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY }: TTheme) => ({
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  headline: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '28px',
  },
  remaining: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    margin: '16px 0 22px',
  },
  pin: {
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.5px',
    lineHeight: '48px',
    margin: 0,
  },
})

export default RetrievePinScene
