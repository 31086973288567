import React from 'react'
import { useParams } from 'react-router'
import { useTheme } from '@emotion/react'
import useTeamMembers from '../../hooks/useTeamMembers'

import Actions from '../../components/Actions'
import Button from '../../components/Button'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import HeaderSKCarousel from '../../components/layout/HeaderSKCarousel'

import CategoryBalancesCard from '../../components/cards/CategoryBalancesCard'
import TransactionsMembersCard from '../../components/cards/TransactionsMembersCard'

import AtmToggleCard from '../../components/cards/AtmToggleCard'
import useSubscriptionsAllowance from '../../hooks/useSubscriptionsAllowance'

const AccountScene = ({ ...rest }) => {
  const { id } = useParams()
  const { theme } = useTheme()
  const { teamMembers } = useTeamMembers()
  const { allow, loading } = useSubscriptionsAllowance()

  const styles = React.useMemo(
    () => computeStyles(teamMembers?.length <= 1),
    []
  )

  const disableAllow =
    typeof allow === 'object' ? !allow?.generalAllowance : !allow
  const disableCategoryAllocation =
    typeof allow === 'object' ? !allow?.categoryAllocate : !allow

  return (
    <CardLayoutLink
      {...rest}
      loading={loading}
      headerStyle={styles.header}
      headerExtra={<HeaderSKCarousel />}
    >
      <Actions feature="funds.manage">
        <Button
          disabled={disableAllow}
          token="ALLOCATE"
          to={`/allocate?toAllocate=${id}`}
          icon="transaction"
          iconColor={theme.BACKGROUND_ALT}
          size={1}
        />
        <Button
          disabled={disableCategoryAllocation}
          token="REALLOCATE"
          to={`/re-allocate/${id}`}
          icon="transaction"
          iconColor={theme.BACKGROUND_ALT}
          size={1}
        />
      </Actions>
      <CategoryBalancesCard id={id} />
      <AtmToggleCard id={id} />
      <TransactionsMembersCard id={id} />
    </CardLayoutLink>
  )
}

const computeStyles = (smallHeader) => ({
  header: {
    minHeight: smallHeader ? 200 : 230,
  },
})

export default AccountScene
