import React from 'react'

import Flex from '../Flex'
import ComponentCard from './ComponentCard'
import SmallUserLink from '../user/SmallUserLink'

import withFeature from '../../hocs/withFeature'
import clsx from 'clsx'
import { checkSafari } from '../../utils/functions'

const QuickAllocationCard = ({ teamMembers }) => {
  const profiles = teamMembers?.map((member) => member.userProfile)

  const isSafari = checkSafari()

  const classNames = clsx({
    'is-safari': isSafari,
  })

  return (
    <ComponentCard token="QUICK_ALLOCATION">
      <Flex gap="1rem" css={styles.root} className={classNames}>
        {profiles.map((userProfile) => (
          <SmallUserLink
            key={userProfile?.id}
            user={userProfile}
            to={`allocate?toAllocate=${userProfile?.id}`}
          />
        ))}
      </Flex>
    </ComponentCard>
  )
}

const styles = {
  root: {
    '&.is-safari > *': {
      marginBottom: '1rem',
    },
  },
}

export default withFeature(QuickAllocationCard, 'funds.allocate')
