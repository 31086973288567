import React from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'

import clsx from 'clsx'
import Flex from '../../Flex'
import Text from '../../typography/Text'
import Detail from '../../typography/Detail'
import IconCircle from '../../IconCircle'
import { getCategoryDictByKey } from '../../../utils/categories'
import { getAmount } from '../../../utils/functions'
import CurrencyField from './CurrencyField'
import Element from '../../Element'

type CategoryAmountFieldProps = {
  category?: any
  className?: string
  hideIcon?: boolean
  hideAvailable?: boolean
  fundsAvailable?: any
  name?: string
  disabled?: boolean
}

const CategoryAmountField: React.FC<CategoryAmountFieldProps> = ({
  category,
  className,
  hideIcon,
  fundsAvailable,
  name,
  hideAvailable,
  ...rest
}) => {
  const { theme, sizes } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const categoryDict = getCategoryDictByKey(category?.key)
  const isValidCategory = !!categoryDict
  const fundsByCategory = getAmount(category?.fundsAvailable)

  const classNames = clsx({
    [className]: className,
    'has-category': isValidCategory,
  })

  return (
    <Flex vertical css={styles.root}>
      <Flex>
        {isValidCategory && (
          <Element css={styles.circleWrapper}>
            <IconCircle
              icon={categoryDict.icon}
              iconColor={categoryDict.iconColor}
              color={categoryDict.bgColor || 'RED_400'}
              size={48}
              scale={1.5}
            />
          </Element>
        )}
        <CurrencyField
          prefix="$ "
          name={isValidCategory ? category.key : name}
          css={styles.field}
          label={
            <Text
              token={isValidCategory ? categoryDict.displayName : 'AMOUNT'}
            />
          }
          collapseStatus
          {...rest}
        />
      </Flex>
      {!hideAvailable && (
        <Detail css={styles.available} className={classNames}>
          <Flex>
            <Text
              currency={
                isValidCategory ? fundsByCategory : getAmount(fundsAvailable)
              }
              css={styles.funds}
            />{' '}
            <Text token="AVAILABLE" />
          </Flex>
        </Detail>
      )}
    </Flex>
  )
}

const computeStyles: TComputeStyles = (
  { BACKGROUND_ALT }: TTheme,
  { FIELD_PADDING }: TSizes
) => ({
  root: { flex: 1 },
  available: {
    paddingTop: 10,
    '&.has-category': {
      paddingLeft: 80,
    },
  },
  field: {
    background: BACKGROUND_ALT,
    flex: 1,
  },
  funds: {
    paddingRight: 5,
  },
  circleWrapper: {
    padding: FIELD_PADDING,
  },
})

export default CategoryAmountField
