import React from 'react'

import { TPlainStyle, useTheme } from '@emotion/react'
import Actions from '../../components/Actions'
import Button from '../../components/Button'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import Text from '../../components/typography/Text'
import TransactionsHoldingCard from '../../components/cards/TransactionsHoldingCard'

import useTeamHoldingAccount from '../../hooks/useTeamHoldingsAccount'
import { getAmount } from '../../utils/functions'
import useSubscriptionsAllowance from '../../hooks/useSubscriptionsAllowance'
import { useIsValidSubscription } from '../../hooks/useSubscriptions'
import DrawerCard from '../../components/drawers/DrawerCard'
import List from '../../components/lists/List'
import BigMenuItem from '../../components/lists/items/BigMenuItem'

const MyAccountScene = ({ ...rest }) => {
  const [showMoreOption, setShowMoreOptions] = React.useState(false)
  const { data, loading } = useTeamHoldingAccount(true)
  const { allow, loading: allowLoading } = useSubscriptionsAllowance()
  const teamHoldingAccount = data?.teamHoldingAccount

  const fundsAvailable = teamHoldingAccount?.fundsAvailable
  const amount = getAmount(fundsAvailable)
  const { theme } = useTheme()

  const { data: subscriptionValidData, loading: subscriptionValidLoading } =
    useIsValidSubscription()

  const hasSubscriptionPlan = subscriptionValidData?.hasActiveSubscription

  const disableAllow =
    typeof allow === 'object' ? !allow?.generalAllowance : true
  const disableRecurring =
    typeof allow === 'object' ? !allow?.recurringAllowance : true

  return (
    <CardLayoutLink
      large
      loading={loading}
      title={<Text currency={amount} />}
      subtitle={<Text token="HERO_ACCOUNT" />}
      {...rest}
    >
      <Actions nowrap css={styles.root}>
        <Button
          disabled={!hasSubscriptionPlan}
          loading={subscriptionValidLoading}
          token="ADD_FUNDS"
          icon="plus"
          size={1}
          to={`/add-funds`}
          iconColor={theme.BACKGROUND_ALT}
        />
        <Button
          disabled={disableAllow}
          loading={allowLoading}
          icon="transaction"
          iconColor={theme.BACKGROUND_ALT}
          size={1}
          token="ALLOCATE"
          to={`/allocate`}
        />
        {!disableRecurring && (
          <Button
            button
            icon="moreVertical"
            iconColor={theme.BACKGROUND_ALT}
            size={1}
            onClick={() => setShowMoreOptions(true)}
          />
        )}
      </Actions>

      <TransactionsHoldingCard />
      <DrawerCard
        isOpened={showMoreOption}
        handleClickOutside={() => setShowMoreOptions(false)}
      >
        <List padding="10px" gap="1.5rem">
          <BigMenuItem
            label={<Text token="RECURRING_ALLOWANCE" />}
            to="/settings/recurring-allowance"
            icon="repeat"
            color={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_BACKGROUND}
            iconColor={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR}
          />
        </List>
      </DrawerCard>
    </CardLayoutLink>
  )
}

const styles: TPlainStyle = {
  root: {
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      width: 0,
    },
  },
}

export default MyAccountScene
