import React from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import ConfirmModal from '../../components/modals/ConfirmModal'
import FlowCardLayout, {
  FlowStep,
} from '../../components/layout/FlowCardLayout'

import SelectSidekickStep from './select-sidekick'
import DeliveryCardStep from './delivery-card'

import usePrompt from '../../hooks/usePrompt'
import { openCardOrders, teamMembers } from '../../queries'
import { AnimatePresence } from 'framer-motion'
import Text from '../../components/typography/Text'
import ShippingAddressStep from './shipping-address'
import SummaryStep from './summary'
import ThanksStep from './thanks'
import { FlowStateType } from '../../store/flow'

const OrderCard = ({ ...rest }) => {
  const [params] = useSearchParams()
  const { id } = useParams()
  const dispatch = useDispatch()
  const flowState = useSelector((state: FlowStateType) => state.flow.orderCard)
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta?.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)
  const { data: teamMembersData, loading } = useQuery(teamMembers)
  const { data: openCardOrdersData } = useQuery(openCardOrders)
  const orderedCards = openCardOrdersData?.openCardOrders || []
  const unavailableIds = orderedCards.map((order) => order.userProfile?.id)

  React.useEffect(() => {
    if (flowState?.meta?.keepDirty) return next()
    if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  const to = params.get('to')

  const prePopulateFlowData = async () => {
    await dispatch.flow.SET_FLOW_DATA('orderCard', {
      forUser: id,
    })
  }

  if (id && !flowState?.data?.forUser) prePopulateFlowData()

  const filteredTeamMembers = teamMembersData.teamMembers?.filter?.(
    (sk) =>
      sk.bankAccounts?.length > 0 &&
      unavailableIds.indexOf(sk.userProfile.id) === -1
  )

  return (
    <>
      <FlowCardLayout
        name="orderCard"
        loading={loading}
        cancel="/cards"
        to={to}
        title={<Text token="ORDER_SIDEKICK_CARD" />}
        start={id ? 1 : 0}
        {...rest}
      >
        <FlowStep
          title={<Text token="WHICH_ACCOUNT_IS_THIS_CARD_FOR" />}
          element={() => (
            <SelectSidekickStep teamMembers={filteredTeamMembers} />
          )}
        />

        <FlowStep
          title={<Text token="DELIVERY_METHOD" />}
          element={DeliveryCardStep}
        />

        <FlowStep
          title={<Text token="SHIPPING_ADDRESS" />}
          element={ShippingAddressStep}
        />

        <FlowStep title={<Text token="SUMMARY" />} element={SummaryStep} />

        <FlowStep
          title={<Text token="THANKS_FOR_YOUR_CARD_ORDER" />}
          element={ThanksStep}
        />
      </FlowCardLayout>

      <AnimatePresence>
        {showConfirm && (
          <ConfirmModal
            title={<Text token="CONFIRM" />}
            content="DATA_LOSS_CONFIRM_MESSAGE"
            confirm={next}
            cancel={closeModal}
            backdrop
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default OrderCard
