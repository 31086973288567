import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = () => {
  const user = useSelector((state) => state.auth.user)
  const { pathname } = useLocation()

  return (
    <>
      {user && <Outlet />}
      {!user && <Navigate to={`/auth/login?from=${pathname}`} />}
    </>
  )
}

export default PrivateRoute
