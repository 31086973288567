import React from 'react'
import { useInvoices } from '../../../../hooks/useSubscriptions'
import { useParams } from 'react-router'
import { useTheme } from '@emotion/react'

import CardLayoutLink from '../../../../components/layout/CardLayoutLink'
import ComponentCard from '../../../../components/cards/ComponentCard'
import Card from '../../../../components/cards/Card'
import Heading from '../../../../components/typography/Heading'
import Text from '../../../../components/typography/Text'
import Detail from '../../../../components/typography/Detail'
import Icon from '../../../../components/Icon'
import IconCircle from '../../../../components/IconCircle'
import Flex from '../../../../components/Flex'

import { INVOICE_DATE_FORMAT } from '../../../../utils/constants'
import { normalizedCardName } from '../../../../utils/functions'

const COMPLETED_STATUS: string = 'Paid'

const InvoiceDetail = ({ ...rest }) => {
    const { theme } = useTheme()
    const { id } = useParams()
    const { data: invoice, loading, refetch, error } = useInvoices(true, id)

    return (
        <CardLayoutLink
            loading={loading}
            error={error}
            {...rest}
        >
            <Card retry={() => refetch()} css={styles.root}>
                <Heading>
                    <Text token="INVOICE" />{' '}
                    <Text date={invoice?.dateCreation} dateFormat={INVOICE_DATE_FORMAT} />
                </Heading>
                <ComponentCard>
                    <Flex vertical>
                        <Detail css={styles.text} token="PAYMENT_ID" />
                        <Detail css={styles.text}> {invoice?.id}</Detail>
                    </Flex>
                    <Flex vertical>
                        <Detail css={styles.text} token="AMOUNT" />
                        <Detail css={styles.text}>${invoice?.paidInCents * 0.01}</Detail>
                    </Flex>
                    <Flex vertical>
                        <Detail css={styles.text} token="FUNDING_METHOD" />
                        <Flex verticalAlignment="center">
                            <Icon css={styles.card} color="transparent" logoColor={theme.TEXT_PRIMARY_DARK} icon={normalizedCardName(invoice?.cardType)}></Icon>
                            <Detail css={styles.text}> **** **** *** {invoice?.last4Digits}</Detail>
                        </Flex>
                    </Flex>
                    <Flex vertical>
                        <Detail css={styles.text} token="STATUS" />
                        <Flex>
                            <Detail css={styles.text}>{invoice?.state} </Detail>

                            {invoice?.state === COMPLETED_STATUS && <IconCircle icon="check"
                                color="TURQUOISE_200"
                                size={15}
                                iconColor={theme.BACKGROUND_ALT}
                                scale={1}
                                css={styles.stateIcon} />
                            }
                        </Flex>
                    </Flex>

                </ComponentCard>
            </Card>

        </CardLayoutLink>
    )
}

const styles = {
    root: {
        padding: 0,
    },
    text: {
        fontSize: '16px !important'
    },
    card: {
        marginRight: '5px',
    },
    stateIcon: {
        margin: '2px 0 0 5px'
    }
}

export default InvoiceDetail
