import React from 'react'

import FlowContext from '../../context/FlowContext'
import LayoutContext from '../../context/LayoutContext'

export type CardLayoutLinkProps = {
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  back?: string
  loading?: boolean
  error?: any
  children?: any
  cancel?: string
  useFooter?: boolean
  large?: boolean
  headerStyle?: any
  headerExtra?: string | JSX.Element
  processing?: boolean
  processingLabel?: string | JSX.Element
  grayed?: boolean
}

const CardLayoutLink: React.FC<CardLayoutLinkProps> = ({
  title,
  subtitle,
  back,
  loading,
  error,
  children,
  cancel,
  useFooter,
  large,
  headerStyle,
  headerExtra,
  processing,
  processingLabel,
  grayed,
}) => {
  const {
    setTitle,
    setBack,
    setSubtitle,
    setLoading,
    setError,
    setStep,
    setSteps,
    setCancel,
    setUseFooter,
    setLarge,
    setHeaderStyle,
    setHeaderExtra,
    setGrayed,
    setProcessing,
    setProcessingLabel,
  } = React.useContext(LayoutContext)

  const { currentIndex, currentStep, steps } = React.useContext(FlowContext)

  React.useEffect(() => {
    setProcessingLabel(processingLabel)
  }, [processingLabel])

  React.useEffect(() => {
    setProcessing(processing)
  }, [processing])

  React.useEffect(() => {
    setStep(currentIndex)
    setSteps(steps)
  }, [currentIndex, steps])

  React.useEffect(() => {
    setLarge(large)
  }, [large])

  React.useEffect(() => {
    setGrayed(grayed)
  }, [grayed])

  React.useEffect(() => setUseFooter(useFooter), [useFooter])

  React.useEffect(() => {
    setTitle(title || currentStep?.title)
  }, [title, currentStep])

  React.useEffect(() => {
    setSubtitle(subtitle)
    setBack(back)
    setLoading(loading)
    setError(error)
    setCancel(cancel)
    setHeaderStyle(headerStyle)
    setHeaderExtra(headerExtra)
  }, [loading, back, subtitle, error, cancel, headerStyle, headerExtra])

  return children
}

export default CardLayoutLink
