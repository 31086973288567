import React from 'react'
import { App } from '@capacitor/app'

const useNativeVersion = () => {
  const [loading, setLoading] = React.useState(true)
  const [info, setInfo] = React.useState<any>()

  React.useEffect(() => {
    ;(async () => {
      try {
        const AppInfo = await App.getInfo()
        setInfo(AppInfo)
      } catch (err) {
        console.error(err)
      }
      setLoading(false)
    })()
  }, [])

  return { data: info, loading }
}

export default useNativeVersion
