import React from 'react'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import CardTransactionLimitsForm from '../../../components/form/forms/CardTransactionLimitsForm'

const CardTransactionLimitsScene = ({ ...rest }) => {
  return (
    <CardLayoutLink title={<Text token="CARD_TRANSACTION_LIMIT" />} {...rest}>
      <Flex vertical padding="1rem" css={styles.content}>
        <CardTransactionLimitsForm />
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
}

export default CardTransactionLimitsScene
