import React from 'react'

import useCurrentEnvironment from '../hooks/useCurrentEnvironment'

const withDev = (Wrapped: any) => (props) => {
  const environment = useCurrentEnvironment()

  if (environment !== 'development') return null

  return <Wrapped {...props} />
}

export default withDev
