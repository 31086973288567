import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const BrandIcon = ({ size = 1, color = '#466990', width, height }) => {
  const defaultWidth = 70
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 70 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M31.4286 8H20V12.5714H31.4286V8Z" fill={color} />
      <path d="M31.4286 8H20V12.5714H31.4286V8Z" fill={color} />
      <path d="M31.4286 16H20V20.5714H31.4286V16Z" fill={color} />
      <path d="M31.4286 16H20V20.5714H31.4286V16Z" fill={color} />
      <path
        d="M13.3012 8.19447H4.4131V0H0V8.19447V12.4536V20.5714H4.4131V12.4536H13.3012V20.5714H17.7143V0H13.3012V8.19447Z"
        fill={color}
      />
      <path
        d="M13.3012 8.19447H4.4131V0H0V8.19447V12.4536V20.5714H4.4131V12.4536H13.3012V20.5714H17.7143V0H13.3012V8.19447Z"
        fill={color}
      />
      <path
        d="M53.1363 8.88282C53.1363 3.98491 49.1452 0 44.2392 0H20V4.31437H44.6101C45.6881 4.31437 46.722 4.7419 47.4842 5.50291C48.2465 6.26393 48.6747 7.29608 48.6747 8.37232C48.6747 9.44855 48.2465 10.4807 47.4842 11.2417C46.722 12.0027 45.6881 12.4303 44.6101 12.4303C44.5537 12.4303 44.498 12.4283 44.4424 12.426C44.3867 12.4237 44.3299 12.4303 44.2727 12.4303H38.511V8.17418H34.2381V20.5629H38.5114V16.2462H44.2147L49.6484 24H54.8377L54.8571 23.986L49.3515 16.146C51.6385 14.5368 53.1363 11.8817 53.1363 8.88282Z"
        fill={color}
      />
      <path
        d="M53.1363 8.88282C53.1363 3.98491 49.1452 0 44.2392 0H20V4.31437H44.6101C45.6881 4.31437 46.722 4.7419 47.4842 5.50291C48.2465 6.26393 48.6747 7.29608 48.6747 8.37232C48.6747 9.44855 48.2465 10.4807 47.4842 11.2417C46.722 12.0027 45.6881 12.4303 44.6101 12.4303C44.5537 12.4303 44.498 12.4283 44.4424 12.426C44.3867 12.4237 44.3299 12.4303 44.2727 12.4303H38.511V8.17418H34.2381V20.5629H38.5114V16.2462H44.2147L49.6484 24H54.8377L54.8571 23.986L49.3515 16.146C51.6385 14.5368 53.1363 11.8817 53.1363 8.88282Z"
        fill={color}
      />
      <path
        d="M59.395 3.62922e-06C57.2979 -0.00175758 55.2506 0.637541 53.529 1.83174C54.408 2.94826 55.0693 4.21922 55.4785 5.57874C56.5772 4.66541 57.9626 4.16564 59.3931 4.16659C62.7765 4.16659 65.5292 6.91127 65.5292 10.2851C65.5292 13.659 62.7765 16.4037 59.3931 16.4037C58.4819 16.4032 57.5823 16.2 56.7598 15.8091C55.9373 15.4182 55.2126 14.8492 54.6384 14.1438C53.9719 15.3978 53.0772 16.5172 52 17.445C52.9596 18.4346 54.1093 19.2214 55.3804 19.7585C56.6515 20.2956 58.018 20.5721 59.3986 20.5714C65.0869 20.5714 69.7143 15.9575 69.7143 10.2859C69.7143 4.61428 65.0833 3.62922e-06 59.395 3.62922e-06Z"
        fill={color}
      />
      <path
        d="M59.395 3.62922e-06C57.2979 -0.00175758 55.2506 0.637541 53.529 1.83174C54.408 2.94826 55.0693 4.21922 55.4785 5.57874C56.5772 4.66541 57.9626 4.16564 59.3931 4.16659C62.7765 4.16659 65.5292 6.91127 65.5292 10.2851C65.5292 13.659 62.7765 16.4037 59.3931 16.4037C58.4819 16.4032 57.5823 16.2 56.7598 15.8091C55.9373 15.4182 55.2126 14.8492 54.6384 14.1438C53.9719 15.3978 53.0772 16.5172 52 17.445C52.9596 18.4346 54.1093 19.2214 55.3804 19.7585C56.6515 20.2956 58.018 20.5721 59.3986 20.5714C65.0869 20.5714 69.7143 15.9575 69.7143 10.2859C69.7143 4.61428 65.0833 3.62922e-06 59.395 3.62922e-06Z"
        fill={color}
      />
    </svg>
  )
}

export default BrandIcon
