import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Element from '../../../components/Element'
import Flex from '../../../components/Flex'
import LoginDetailsForm from '../../../components/form/forms/LoginDetailsForm'
import { useParams } from 'react-router'
import useCurrentUser from '../../../hooks/useCurrentUser'

const LoginDetailsScene = ({ ...rest }) => {
  const { id } = useParams()
  const { data, loading } = useCurrentUser(true, id)
  const { userProfile } = data || {}

  const handleSubmit = async (_) => {
    // ToDo: Implement service
  }

  const title = `${userProfile?.givenName} ${userProfile?.familyName}`

  return (
    <CardLayoutLink loading={loading} title={title} {...rest}>
      <Flex centerX>
        <Element css={styles.content}>
          <LoginDetailsForm
            loading={loading}
            submit={handleSubmit}
            defaultValues={{ email: userProfile?.email }}
            id={id}
          />
        </Element>
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default LoginDetailsScene
