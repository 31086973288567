import React from 'react'

import Flex from './Flex'
import Text from './typography/Text'
import Spinner, { SpinnerProps } from './Spinner'

const ProcessingStatus: React.FC<SpinnerProps> = ({ label }) => {
  return (
    <Flex
      initial={{ scale: 0.5 }}
      exit={{ scale: 0 }}
      animate={{ scale: 1 }}
      vertical
      center
      gap="2rem"
    >
      {label && <Text>{label}</Text>}
      <Spinner />
    </Flex>
  )
}

export default ProcessingStatus
