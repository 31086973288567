import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import React from 'react'
import { IconType } from '../../icons'

import Field, { FieldProps } from '../Field'

export type RadioFieldProps = {
  value: string | number
  className?: string
  showBorder?: boolean
  icon?: IconType
} & FieldProps

const RadioField: React.FC<RadioFieldProps> = ({
  className,
  showBorder,
  ...rest
}) => {
  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])
  const classNames = clsx({
    [className]: className,
    'is-bordered': showBorder,
  })

  return (
    <Field type="radio" css={styles.root} className={classNames} {...rest} />
  )
}

const computeStyles: TComputeStyles = (
  { FIELD_BORDER }: TTheme,
  { FIELD_PADDING, FIELD_FONT_SIZE }: TSizes
) => ({
  root: {
    padding: FIELD_PADDING,
    fontSize: FIELD_FONT_SIZE,
    '&.is-bordered': {
      border: `2px solid ${FIELD_BORDER}`,
    },
  },
})

export default RadioField
