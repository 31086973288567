import { useTheme } from '@emotion/react'
import React from 'react'

import Banner, { Props as BannerProps } from './Banner'

const InfoSuccess: React.FC<BannerProps> = ({ ...rest }) => {
  const { theme } = useTheme()
  return <Banner icon="checkCircle" color={theme.SUCCESS} {...rest} />
}

export default InfoSuccess
