import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
  signIn,
  signUp,
  signOut,
  fetchCognitoSession,
  fetchCognitoUser,
  resendInvitation,
  forgotPassword,
  forgotPasswordSubmit,
  customSignIn,
} from '../services/auth'

import apiClient from '../config/api'
import { generateUUID } from '../utils/functions'
import { acceptToS } from '../services/user'

const AuthModel = {
  state: {
    user: null,
  },
  reducers: {
    SET_USER: (state, payload) => ({
      ...state,
      user: payload,
    }),
    SET_USERNAME: (state, payload) => ({
      ...state,
      username: payload,
    }),

    CLEAR_USER: (state) => ({
      ...state,
      user: null,
    }),
    SET_LOGIN_ERROR: (state, payload) => ({
      ...state,
      error: payload,
    }),
    SET_SIGNUP_ERROR: (state, payload) => ({
      ...state,
      error: payload,
    }),
    SET_PASSWORD_RESET_ERROR: (state, payload) => ({
      ...state,
      error: payload,
    }),
    SET_ACCEPT_TOS_FLAG: (state, payload) => ({
      ...state,
      user: {
        ...state?.user,
        attributes: {
          ...state?.user?.attributes,
          'custom:has_accepted_tos': true,
        },
      },
    }),
    SET_ACCEPT_TOS_ERROR: (state, payload) => ({
      ...state,
      error: payload,
    }),
    CLEAR_ERROR: (state) => ({
      ...state,
      error: null,
    }),
  },

  effects: (dispatch) => ({
    async signIn({ email, password }) {
      try {
        await signIn(email, password)

        dispatch.auth.getCurrentSession()
        return true
      } catch (e) {
        dispatch.auth.SET_LOGIN_ERROR(e)
        return false
      }
    },
    async signUp(payload) {
      try {
        const username = generateUUID()
        payload.username = username
        dispatch.auth.SET_USERNAME(username)
        const user = await signUp(payload)
        if (user) {
          return true
        }
      } catch (error) {
        throw error
      }
    },
    async forgotPasswordSubmit(payload) {
      try {
        const { username, code, password, event } = payload
        dispatch.auth.SET_USERNAME(username)
        await forgotPasswordSubmit(username, code, password, event)
        return true
      } catch (error) {
        dispatch.auth.SET_SIGNUP_ERROR(error)
      }
    },
    async resendInvitation(payload) {
      try {
        const { username } = payload
        await resendInvitation(username)
      } catch (error) {
        dispatch.auth.SET_SIGNUP_ERROR(error)
      }
    },
    async acceptTermsOfService() {
      try {
        const response = await acceptToS()
        if (response?.data?.acceptTermsOfService?.hasAcceptedTOS) {
          dispatch.auth.SET_ACCEPT_TOS_FLAG()
        }
      } catch (e) {
        dispatch.auth.SET_ACCEPT_TOS_ERROR(e.errors?.[0])
      }
    },

    async signOut() {
      await signOut()

      apiClient.clearStore()
      localStorage.removeItem('token')

      dispatch.auth.CLEAR_USER()
    },

    async getCurrentSession() {
      try {
        let session: CognitoUserSession = await fetchCognitoSession()
        session.getRefreshToken().getToken()

        localStorage.setItem('token', session.getAccessToken().getJwtToken())

        return await dispatch.auth.getCurrentUser()
      } catch (e) {
        await signOut()
      }
    },

    async getCurrentUser() {
      let user = await fetchCognitoUser()

      dispatch.auth.SET_USER(user)
    },
    requestForgotPassword: async (payload) => {
      try {
        const { email, redirectURL } = payload
        await forgotPassword(email, redirectURL)
        return true
      } catch (error) {
        await dispatch.auth.SET_PASSWORD_RESET_ERROR(error)
        return false
      }
    },
    requestForgotPasswordSubmit: async (payload, rootState) => {
      try {
        const { username, code, password, event } = payload
        await forgotPasswordSubmit(username, code, password, event)
        return true
      } catch (error) {
        await dispatch.auth.SET_PASSWORD_RESET_ERROR(error)
        return false
      }
    },
    customSignIn: async (payload) => {
      try {
        const { username, token } = payload
        const user = await customSignIn(username, token)
        if (user) {
          await dispatch.auth.SET_USER(user)
          await dispatch.auth.getCurrentSession()
        }
        return user
      } catch (error) {
        await dispatch.auth.CLEAR_USER()
        await dispatch.auth.SET_LOGIN_ERROR(error)
        return false
      }
    },
  }),
}

export default AuthModel
