import React from 'react'

import List from './List'
import CheckItem from './items/CheckItem'
import { useTheme } from '@emotion/react'

type CheckListProps = {
  list: Array<string | JSX.Element>
}

const CheckList: React.FC<CheckListProps> = ({ list = [], ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles({ ...theme }), [theme])
  return (
    <List vertical gap="1rem" css={styles.root} {...rest}>
      {list.map((content) => (
        <CheckItem key={`key-${content}`} token={content} />
      ))}
    </List>
  )
}

const computeStyles = () => ({
  root: {
    marginBottom: 16,
  },
})

export default CheckList
