import camelCase from 'lodash/camelCase'

export const SORTED_SUBSCRIPTIONS = [
    'hero-essentials-monthly',
    'hero-premium-monthly',
    'hero-ultimate-monthly',
    'hero-essentials-yearly',
    'hero-premium-yearly',
    'hero-ultimate-yearly'
]

const MONTHLY = 'PerOneMonth'

const normalizedPrice = (subscription) => ((subscription?.price?.[0]?.billingPeriodCostInCents) * 0.01)
const normalizedPeriodical = (subscription) => subscription.billingPeriod === MONTHLY ? 'Monthly' : 'Annually'
const normalizedCode = (code) => {
    const camel = camelCase(code)
    return camel.charAt(0).toUpperCase() + camel.slice(1)
}
const normalizedDescription = (subscription) => {
    const camel = normalizedCode(subscription?.code)
    return `subscription_plan_description_${camel}`
}
const normalizedTitle = (subscription) => {
    const camel = normalizedCode(subscription?.code)
    return `subscription_plan_title_${camel}`
}
const normalizedSubscriptionPlanData = (subscription) => {
    const title = normalizedTitle(subscription)
    const price = normalizedPrice(subscription)
    const periodical = normalizedPeriodical(subscription)
    const description = normalizedDescription(subscription)
    const descriptionList = (subscription?.features || []).map((content) => `subscription_plan_features_${content}`)
    return { title, price, periodical, description, descriptionList }
}

export default normalizedSubscriptionPlanData