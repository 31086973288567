import { NativeBiometric } from 'capacitor-native-biometric'
import isMobile from '../utils/isMobile'

const FIRST_LOGIN = 'HERO_FIRST_LOGIN'
const LOGOUT_KEY = 'HERO_LOGOUT'
const SERVER = 'herofinancials'

export const isFirstLogin = () => !Boolean(localStorage.getItem(FIRST_LOGIN))
export const setFirstLogin = () => localStorage.setItem(FIRST_LOGIN, 'true')
export const resetFirstLogin = () => localStorage.removeItem(FIRST_LOGIN)

export const doesManuallyLogout = () =>
  Boolean(localStorage.getItem(LOGOUT_KEY))
export const setDoesManuallyLogout = () => {
  if (isMobile()) localStorage.setItem(LOGOUT_KEY, 'true')
}

export const resetDoesManuallyLogout = () => {
  if (isMobile()) localStorage.removeItem(LOGOUT_KEY)
}


export const isBiometricAvailable = async () => {
  if (!isMobile()) return false
  try {
    const result = await NativeBiometric.isAvailable({ useFallback: true })
    if (!result.isAvailable) return false
    return true
  } catch (err) {
    return false
  }
}

export const verifyIdentity = async () => {
  const isAvailable = await isBiometricAvailable()
  if (isAvailable) {
    try {
      return await NativeBiometric.verifyIdentity({
        reason: 'For easy log in',
        title: 'Log in',
        description: 'For easy log in',
        useFallback: true,
      })
        .then((val) => {
          return true
        })
        .catch((err) => {
          return false
        })
    } catch (err) {
      return false
    }
  }
}

export const getCredentials = async () => {
  if (isFirstLogin() || doesManuallyLogout()) return false
  const isVerified = await verifyIdentity()
  if (isVerified) {
    try {
      const credentials = await NativeBiometric.getCredentials({
        server: SERVER,
      })
      return credentials
    } catch (err) {
      return false
    }
  }
  return false
}

export const setCredentials = async (email, password) => {
  const isAvailable = await isBiometricAvailable()
  if (isAvailable) {
    try {
      const credentials = await NativeBiometric.setCredentials({
        username: email,
        password,
        server: SERVER,
      })
      return credentials
    } catch (err) {
      return false
    }
  }
  return false
}

export const deleteCredentials = async () => {
  resetFirstLogin()
  resetDoesManuallyLogout()
  const isAvailable = await isBiometricAvailable()
  if (isAvailable) {
    try {
      await NativeBiometric.deleteCredentials({
        server: SERVER,
      })
      return true
    } catch (err) {
      return false
    }
  }
  return false
}
