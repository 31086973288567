import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const MoreVerticalIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#FFFFFF',
  width,
  height,
}) => {
  const defaultWidth = 4
  const defaultHeight = 18
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MoreVerticalIcon
