import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import AcceptInviteForm from 'src/components/form/forms/AcceptInviteForm'
import Text from '../../../components/typography/Text'

const AcceptInviteScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const error = useSelector((state) => state.auth.error)
  const [loading, setLoading] = React.useState(false)
  const [params] = useSearchParams()

  const code = params.get('code')
  const username = params.get('username')
  const event = params.get('event')

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
      dispatch.auth.CLEAR_ERROR()
    }
  }, [error])

  const handleFormSubmit = async (formData) => {
    setLoading(true)
    if (formData.password && username && code && event) {
      const success = await dispatch.auth.forgotPasswordSubmit({
        username,
        code,
        password: formData.password,
        event,
      })
      if (success) navigate('/auth/login')
    }
    setLoading(false)
  }

  return (
    <CardLayoutLink
      title={<Text token="COMPLETE_REGISTRATION" />}
      loading={loading}
      error={error}
      {...rest}
      cancel="/logout"
    >
      <AcceptInviteForm
        css={styles.content}
        submit={handleFormSubmit}
        {...rest}
      />
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    width: '100%',
    maxWidth: 630,
    justifySelf: 'center',
  },
}

export default AcceptInviteScene
