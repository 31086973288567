import React from 'react'

const Initials = ({ user }) => {
  if (!user) return

  const givenName = user.givenName || user.given_name
  const familyName = user.familyName || user.family_name

  return (
    <>
      {givenName.slice(0, 1)} {familyName.slice(0, 1)}
    </>
  )
}

export default Initials
