import React, { PropsWithChildren } from 'react'
import {
  motion,
  AnimationProps,
  MotionProps,
  MotionAdvancedProps,
} from 'framer-motion'
import clsx from 'clsx'

export type ElementProps = {
  stretch?: boolean
  as?: string
  className?: string
  width?: any
  height?: any
  padding?: any
  margin?: any
  innerRef?: any
  css?: any
  id?: string
  dangerouslySetInnerHTML?: any
} & AnimationProps &
  MotionProps &
  MotionAdvancedProps &
  PropsWithChildren

const Element: React.FC<ElementProps> = ({
  stretch,
  as = 'div',
  width,
  height,
  padding,
  margin,
  className,
  innerRef,
  ...rest
}) => {
  const classNames = clsx({
    [className]: className,
    stretch,
  })

  const styles = React.useMemo(
    () => computeStyles({ width, height, padding, margin }),
    [width, height, padding]
  )

  const DOMElement = motion[as]

  return (
    <DOMElement
      css={styles.root}
      className={classNames}
      {...rest}
      ref={innerRef}
    />
  )
}

const computeStyles = ({ width, height, padding, margin }) => ({
  root: {
    ...(width && { width }),
    ...(height && { height }),
    ...(padding && { padding }),
    ...(margin && { margin }),

    '&.stretch': {
      flex: 1,
    },
  },
})

export default Element
