import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const BrandGradient = ({ size = 1, width, height }) => {
  const defaultWidth = 70
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 70 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9526 8.18066H19.5325V12.4503H30.9526V8.18066Z"
        fill="url(#paint0_linear_3692_30041)"
      />
      <path
        d="M30.9526 16.2559H19.5325V20.5823H30.9526V16.2559Z"
        fill="url(#paint1_linear_3692_30041)"
      />
      <path
        d="M12.8795 8.18055H4.27422V0.000976562H0V8.18055V12.4502V20.554H4.27422V12.4502H12.8795V20.554H17.1443V0.000976562H12.8795V8.18055Z"
        fill="url(#paint2_linear_3692_30041)"
      />
      <path
        d="M52.7216 8.88111C52.7216 3.97716 48.7412 0.000976562 43.832 0.000976562H19.5704V4.32744H44.1827C46.4193 4.32744 48.2484 6.15459 48.2484 8.38882C48.2484 10.6231 46.4098 12.4502 44.1732 12.4502H44.0311H43.8605H38.1078V8.18055H33.8336V20.573H38.1078V16.2465H43.8036L49.234 24.0001H54.437H54.4655L48.9402 16.1613C51.319 14.514 52.7216 11.7875 52.7216 8.88111Z"
        fill="url(#paint3_linear_3692_30041)"
      />
      <path
        d="M59.0524 0.000917366C56.958 0.000917366 54.9299 0.635213 53.2145 1.82807C54.0864 2.93572 54.7593 4.20431 55.1573 5.54864C57.7446 3.39961 61.6113 3.74989 63.7626 6.3344C65.9139 8.91892 65.5633 12.7815 62.976 14.9305C60.3887 17.0796 56.522 16.7293 54.3707 14.1448C54.3707 14.1164 54.3423 14.1164 54.3423 14.088C53.6694 15.3376 52.7975 16.4358 51.7266 17.373C55.6786 21.4344 62.161 21.5291 66.2267 17.6097C70.2924 13.6903 70.3777 7.18644 66.4636 3.12506C64.4923 1.0991 61.8482 -0.036951 59.0524 0.000917366Z"
        fill="url(#paint4_linear_3692_30041)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3692_30041"
          x1="-1.96321"
          y1="10.3172"
          x2="70.2394"
          y2="10.3172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DCB1" />
          <stop offset="0.19" stopColor="#49D8B0" />
          <stop offset="0.37" stopColor="#48CCAC" />
          <stop offset="0.56" stopColor="#48B8A7" />
          <stop offset="0.75" stopColor="#479C9F" />
          <stop offset="0.93" stopColor="#467994" />
          <stop offset="1" stopColor="#456990" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3692_30041"
          x1="-1.96321"
          y1="18.4164"
          x2="70.2394"
          y2="18.4164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DCB1" />
          <stop offset="0.19" stopColor="#49D8B0" />
          <stop offset="0.37" stopColor="#48CCAC" />
          <stop offset="0.56" stopColor="#48B8A7" />
          <stop offset="0.75" stopColor="#479C9F" />
          <stop offset="0.93" stopColor="#467994" />
          <stop offset="1" stopColor="#456990" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3692_30041"
          x1="-1.96322"
          y1="10.2735"
          x2="70.2394"
          y2="10.2735"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DCB1" />
          <stop offset="0.19" stopColor="#49D8B0" />
          <stop offset="0.37" stopColor="#48CCAC" />
          <stop offset="0.56" stopColor="#48B8A7" />
          <stop offset="0.75" stopColor="#479C9F" />
          <stop offset="0.93" stopColor="#467994" />
          <stop offset="1" stopColor="#456990" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3692_30041"
          x1="-1.96323"
          y1="12.0008"
          x2="70.2394"
          y2="12.0008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DCB1" />
          <stop offset="0.19" stopColor="#49D8B0" />
          <stop offset="0.37" stopColor="#48CCAC" />
          <stop offset="0.56" stopColor="#48B8A7" />
          <stop offset="0.75" stopColor="#479C9F" />
          <stop offset="0.93" stopColor="#467994" />
          <stop offset="1" stopColor="#456990" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3692_30041"
          x1="-1.96319"
          y1="10.2321"
          x2="70.2394"
          y2="10.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DCB1" />
          <stop offset="0.19" stopColor="#49D8B0" />
          <stop offset="0.37" stopColor="#48CCAC" />
          <stop offset="0.56" stopColor="#48B8A7" />
          <stop offset="0.75" stopColor="#479C9F" />
          <stop offset="0.93" stopColor="#467994" />
          <stop offset="1" stopColor="#456990" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BrandGradient
