import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import Card from './Card'

const LinkCard: React.FC<LinkProps> = ({ to, ...rest }) => (
  <Link to={to} css={styles.root}>
    <Card css={styles.card} {...rest} />
  </Link>
)

const styles = {
  root: {
    textDecoration: 'none',
    color: 'inherit',

    display: 'flex',
  },

  card: {
    flex: 1,
  },
}

export default LinkCard
