import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const HomeIcon = ({ size = 1, color = '#464E58', width, height }) => {
    const defaultWidth = 12
    const defaultHeight = 14
    let factor = getIconSizeFactor({
        size,
        width,
        height,
        defaultWidth,
        defaultHeight,
    })

    return (
        <svg
            width={defaultWidth * factor}
            height={defaultHeight * factor}
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3334 4.33334L7.3334 0.826671C6.96671 0.498701 6.49202 0.317383 6.00006 0.317383C5.50811 0.317383 5.03341 0.498701 4.66673 0.826671L0.66673 4.33334C0.454975 4.52272 0.285997 4.75504 0.171044 5.01483C0.0560924 5.27463 -0.00219504 5.55592 6.31961e-05 5.84V11.6667C6.31961e-05 12.1971 0.210777 12.7058 0.58585 13.0809C0.960922 13.456 1.46963 13.6667 2.00006 13.6667H10.0001C10.5305 13.6667 11.0392 13.456 11.4143 13.0809C11.7894 12.7058 12.0001 12.1971 12.0001 11.6667V5.83334C12.0014 5.55038 11.9426 5.27036 11.8277 5.01179C11.7128 4.75322 11.5443 4.52197 11.3334 4.33334ZM7.3334 12.3333H4.66673V9C4.66673 8.82319 4.73697 8.65362 4.86199 8.5286C4.98702 8.40357 5.15659 8.33334 5.3334 8.33334H6.66673C6.84354 8.33334 7.01311 8.40357 7.13813 8.5286C7.26316 8.65362 7.3334 8.82319 7.3334 9V12.3333ZM10.6667 11.6667C10.6667 11.8435 10.5965 12.0131 10.4715 12.1381C10.3464 12.2631 10.1769 12.3333 10.0001 12.3333H8.66673V9C8.66673 8.46957 8.45602 7.96086 8.08094 7.58579C7.70587 7.21072 7.19716 7 6.66673 7H5.3334C4.80296 7 4.29426 7.21072 3.91918 7.58579C3.54411 7.96086 3.3334 8.46957 3.3334 9V12.3333H2.00006C1.82325 12.3333 1.65368 12.2631 1.52866 12.1381C1.40363 12.0131 1.3334 11.8435 1.3334 11.6667V5.83334C1.33352 5.73868 1.35379 5.64513 1.39287 5.55892C1.43196 5.47271 1.48895 5.39581 1.56006 5.33334L5.56006 1.83334C5.68172 1.72646 5.83812 1.66751 6.00006 1.66751C6.162 1.66751 6.3184 1.72646 6.44006 1.83334L10.4401 5.33334C10.5112 5.39581 10.5682 5.47271 10.6073 5.55892C10.6463 5.64513 10.6666 5.73868 10.6667 5.83334V11.6667Z"
                fill={color} />
        </svg>

    )
}

export default HomeIcon
