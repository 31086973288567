import React from 'react'
import { useTheme } from '@emotion/react'
import { transparentize } from 'polished'

import Flex, { FlexProps } from './Flex'

export type FullscreenProps = {
  backdrop?: boolean
  onClick?: Function
} & FlexProps

const Fullscreen: React.FC<FullscreenProps> = ({
  backdrop,
  children,
  onClick,
  ...rest
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  return (
    <Flex
      css={styles.root}
      initial={{
        ...(backdrop && {
          backgroundColor: transparentize(1, colors.BLUE_500),
        }),
      }}
      animate={{
        ...(backdrop && {
          backgroundColor: transparentize(0.2, colors.BLUE_500),
        }),
      }}
      exit={{
        ...(backdrop && {
          backgroundColor: transparentize(1, colors.BLUE_500),
        }),
      }}
      transition={{ ease: 'easeInOut', duration: 0.1 }}
      {...rest}
    >
      {backdrop && (
        <Flex css={[styles.root, styles.backdrop]} onClick={onClick} />
      )}
      {children}
    </Flex>
  )
}

const computeStyles = ({ BLUE_500 }) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',

    paddingBottom: 'env(safe-area-inset-bottom, 0)',
    backdrop: {
      zIndex: -1,
      backgroundColor: transparentize(0.2, BLUE_500),
    },
  },
})

export default Fullscreen
