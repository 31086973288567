import React from 'react'
import { useTheme } from '@emotion/react'

import Text, { TextProps } from './Text'

const Detail: React.FC<TextProps> = ({ ...rest }) => {
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  return <Text css={styles.root} {...rest} />
}

const computeStyles = ({ BLACK_700 }) => ({
  root: {
    color: BLACK_700,
    fontSize: 14,
  },
})

export default Detail
