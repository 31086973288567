import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const AlertIcon = ({ size = 1, color = '#398D8D', width, height }) => {
  const defaultWidth = 24
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5282 2.28306C10.9775 2.0301 11.4844 1.89722 12 1.89722C12.5156 1.89722 13.0225 2.0301 13.4718 2.28306C13.9211 2.53601 14.2976 2.90049 14.565 3.34133L14.5679 3.34608L23.0379 17.4861L23.046 17.4999C23.308 17.9535 23.4466 18.4678 23.448 18.9917C23.4495 19.5155 23.3138 20.0306 23.0544 20.4857C22.795 20.9408 22.4209 21.32 21.9694 21.5857C21.518 21.8513 21.0048 21.9941 20.481 21.9999L20.47 22L3.51903 22C2.99522 21.9942 2.48206 21.8513 2.03059 21.5857C1.57912 21.32 1.20507 20.9408 0.94567 20.4857C0.686267 20.0306 0.550547 19.5155 0.552014 18.9917C0.55348 18.4678 0.692083 17.9535 0.95403 17.4999L0.962153 17.4861L9.43215 3.34609L10.29 3.85995L9.43502 3.34133C9.70243 2.90049 10.0789 2.53601 10.5282 2.28306ZM11.1462 4.3766L2.68271 18.5058C2.59753 18.6556 2.55249 18.8249 2.55201 18.9973C2.55152 19.1719 2.59676 19.3436 2.68322 19.4953C2.76969 19.647 2.89438 19.7734 3.04487 19.8619C3.19404 19.9497 3.36342 19.9973 3.53642 20H20.4636C20.6366 19.9973 20.806 19.9497 20.9552 19.8619C21.1057 19.7734 21.2303 19.647 21.3168 19.4953C21.4033 19.3436 21.4485 19.1719 21.448 18.9973C21.4476 18.8249 21.4025 18.6556 21.3174 18.5058L12.855 4.37859C12.8546 4.37792 12.8542 4.37726 12.8538 4.3766C12.7648 4.23054 12.6397 4.10977 12.4906 4.02583C12.3409 3.94151 12.1719 3.89722 12 3.89722C11.8282 3.89722 11.6592 3.94151 11.5094 4.02583C11.3603 4.10977 11.2353 4.23054 11.1462 4.3766Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
        fill={color}
      />
    </svg>
  )
}

export default AlertIcon
