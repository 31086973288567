import React from 'react'
import { useTheme } from '@emotion/react'
import clsx from 'clsx'

import Card, { CardProps } from './Card'

export type ComponentCardProps = CardProps & {
  transparent?: boolean
  className?: string
}

const ComponentCard: React.FC<ComponentCardProps> = ({
  transparent,
  className,
  ...rest
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  const classNames = clsx({
    [className]: className,
    'is-transparent': transparent,
  })

  return <Card css={styles.root} {...rest} className={classNames} />
}

const computeStyles = ({ WHITE, BLACK_200 }) => ({
  root: {
    border: `1px solid ${BLACK_200}`,
    backgroundColor: WHITE,
    borderRadius: 10,
    height: 'max-content',

    '&.is-transparent': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
})

export default ComponentCard
