import React from 'react'
import { useTheme } from '@emotion/react'

import Header from './Header'
import { TextProps } from './Text'

const Detail: React.FC<TextProps> = ({ ...rest }) => {
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  return <Header css={styles.root} {...rest} />
}

const computeStyles = () => ({
  root: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
    fontFamily: 'Poppins',
  },
})

export default Detail
