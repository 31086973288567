import React from 'react'
import * as Yup from 'yup'
import { DateTime } from 'luxon'

import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import Form, { FormProps } from '../Form'
import Field from '../Field'
import CountrySelectField from '../fields/CountrySelectField'
import GenderSelectField from '../fields/GenderSelectField'
import DateField from '../fields/DateField'

import RadioField from '../fields/RadioField'
import FormButton from '../Button'
import FieldCard from '../../cards/FieldCard'
import FormFooter from '../FormFooter'

import FlowContext from '../../../context/FlowContext'

import { cleanNullProps } from '../../../utils/functions'
import Text from '../../typography/Text'
import PhoneField, {
  PHONE_VALIDATOR,
  PHONE_VALIDATOR_REQUIRED,
} from '../fields/PhoneField'

export type NewMemberInputDataFormProps = {
  user?: any
  submit?: any
  disabled?: boolean
  loading?: boolean
  error?: any
} & Partial<FormProps>

const validationSchema = Yup.object().shape({
  givenName: Yup.string().required(),
  middleNames: Yup.string(),
  familyName: Yup.string().required(),
  gender: Yup.string().required(),
  dateOfBirth: Yup.string()
    .test('DOB', 'SIDEKICK_MUST_BE_5_YEARS_OLD', (value) => {
      return DateTime.fromISO(value).diffNow('years').years < -5
    })
    .required(),
  email: Yup.string().email().when('receivesEmail', {
    is: 'yes',
    then: Yup.string().email().required(),
  }),
  mobilePhoneNumber: PHONE_VALIDATOR.when('receivesEmail', {
    is: 'yes',
    then: PHONE_VALIDATOR_REQUIRED,
  }),
  receivesEmail: Yup.string().required(),
  principalAddress: Yup.object().shape({
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().required(),
    region: Yup.string().required(),
  }),
})

const createPayload = (formData) => {
  const payload = { ...formData }

  payload.receivesEmail = payload.receivesEmail === 'yes'
  payload.canOrderCards = payload.receivesEmail
    ? payload.canOrderCards === 'yes'
    : false

  if (!payload.receivesEmail) {
    delete payload.email
    delete payload.mobilePhoneNumber
  }

  return cleanNullProps(payload)
}

const NewMemberInputDataForm: React.FC<NewMemberInputDataFormProps> = ({
  user,
  submit,
  disabled,
  loading,
  error,
  initialValues,
  ...rest
}) => {
  const { prev } = React.useContext(FlowContext)

  const handleSubmit = (formData) => submit(createPayload(formData))

  const initial = {
    receivesEmail: 'yes',
    canOrderCards: 'no',
    ...initialValues,
  }

  return (
    <Form
      onSubmit={handleSubmit}
      disabled={disabled || loading}
      loading={loading}
      validationSchema={validationSchema}
      initialValues={initial}
      {...rest}
    >
      {(form) => (
        <>
          <FieldCard label={<Text token="PERSONAL_INFORMATION" />}>
            <Field label={<Text token="FIRST_NAME" />} name="givenName" />
            <Field label={<Text token="MIDDLE_NAME" />} name="middleNames" />
            <Field label={<Text token="FAMILY_NAME" />} name="familyName" />

            <GenderSelectField
              label={<Text token="WHAT_DO_YOU_IDENTIFY_AS" />}
              name="gender"
            />

            <DateField
              label={<Text token="DATE_OF_BIRTH" />}
              name="dateOfBirth"
            />
          </FieldCard>

          <FieldCard label={<Text token="ADDRESS" />}>
            <Field
              label={<Text token="ADDRESS_LINE_1" />}
              name="principalAddress.addressLine1"
            />
            <Field
              label={<Text token="ADDRESS_LINE_2" />}
              name="principalAddress.addressLine2"
            />
            <Field label={<Text token="CITY" />} name="principalAddress.city" />

            <CountrySelectField
              label={<Text token="COUNTRY" />}
              name="principalAddress.country"
            />

            <Field
              label={<Text token="POSTAL_CODE" />}
              name="principalAddress.postalCode"
            />
            <Field
              label={<Text token="REGION" />}
              name="principalAddress.region"
            />
          </FieldCard>

          <FieldCard
            label={<Text token="LOGIN_CREDENTIALS" />}
            description={<Text token="LOGIN_CREDENTIALS_INFO" />}
          >
            <RadioField
              label={<Text token="CREATE_LOGIN_FOR_THIS_SIDEKICK" />}
              name="receivesEmail"
              value="yes"
            />
            <RadioField
              label={<Text token="SIDEKICK_NOT_NEED_ACCESS_HERO_APP" />}
              name="receivesEmail"
              value="no"
            />
          </FieldCard>

          {form?.values?.receivesEmail === 'yes' && (
            <>
              <FieldCard label={<Text token="CONTACT_INFO" />}>
                <Field label={<Text token="EMAIL" />} name="email" />

                <PhoneField
                  label={<Text token="PHONE_NUMBER" />}
                  name="mobilePhoneNumber"
                />
              </FieldCard>

              <FieldCard
                label={<Text token="SIDEKICK_CARDS" />}
                description={<Text token="SIDEKICK_CARDS_INFO" />}
              >
                <RadioField
                  label={<Text token="YES" />}
                  name="canOrderCards"
                  value="yes"
                />
                <RadioField
                  label={<Text token="NO" />}
                  name="canOrderCards"
                  value="no"
                />
              </FieldCard>
            </>
          )}

          <FormFooter>
            <ButtonGroup>
              <Button
                button
                onClick={prev}
                label={<Text token="PREVIOUS_STEP" />}
              />
              <FormButton
                label={<Text token="NEXT" />}
                loading={loading}
                css={{ flex: 2 }}
              />
            </ButtonGroup>
          </FormFooter>
        </>
      )}
    </Form>
  )
}

export default NewMemberInputDataForm
