import React from 'react'
import { useTheme } from '@emotion/react'

import LinkCard from './LinkCard'
import Icon from '../Icon'
import Fullname from '../Fullname'
import Initials from '../Initials'
import { IconType } from '../icons'
import { ColorType } from '../../utils/themes'

type UserCardType = {
  user?: any
  icon?: IconType
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  color?: ColorType
  to?: string
  className?: string
}

const UserCard: React.FC<UserCardType> = ({
  user,
  subtitle,
  color,
  to,
  className,
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }, color),
    [theme]
  )

  return (
    <LinkCard to={to} css={styles.root} className={className}>
      <div css={styles.circle}>
        <Initials user={user} />
      </div>
      <div css={styles.content}>
        <div css={styles.content.title}>
          <Fullname user={user} />
        </div>
        <div css={styles.content.subtitle}>{subtitle}</div>
      </div>
      <Icon icon="chevron" />
    </LinkCard>
  )
}

const computeStyles = (theme, color) => {
  const { WHITE, BLACK_300 } = theme

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '1.3rem',

      backgroundColor: WHITE,
      border: `1px solid ${BLACK_300}`,
    },

    circle: {
      width: 56,
      height: 56,
      borderRadius: '50%',
      backgroundColor: theme[color],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.5rem',
      flex: '1 auto',

      title: {
        fontWeight: 500,
      },

      subtitle: {
        fontSize: '.9rem',
      },
    },
  }
}

export default UserCard
