import { acceptTermsOfService, getUserProfile } from '../queries'
import { makeRequest } from '../utils/functions'

export const fetchUserInfo = async () => {
  const response = await makeRequest({
    query: getUserProfile,
  })
  return response
}

export const acceptToS = async () => {
  const response = await makeRequest({ query: acceptTermsOfService })
  return response
}
