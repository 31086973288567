import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const VisaMonoIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 28
  const defaultHeight = 11
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1332 10.7619H9.86511L11.2839 0.345089H13.5519L12.1332 10.7619ZM20.3558 0.599775C19.908 0.388609 19.199 0.15625 18.321 0.15625C16.081 0.15625 14.5038 1.57462 14.494 3.60227C14.4755 5.0986 15.6234 5.92965 16.4819 6.42842C17.3595 6.93817 17.6578 7.27082 17.6578 7.72532C17.6489 8.42315 16.9488 8.74482 16.2958 8.74482C15.3901 8.74482 14.9047 8.57907 14.1673 8.19079L13.8687 8.02428L13.5513 10.363C14.0835 10.6506 15.0634 10.9061 16.081 10.917C18.4609 10.917 20.0103 9.52061 20.0288 7.35975C20.0377 6.17374 19.4316 5.26511 18.125 4.52263C17.3315 4.0458 16.8458 3.72451 16.8458 3.23708C16.8551 2.79356 17.2569 2.33944 18.1524 2.33944C18.8895 2.31749 19.4313 2.5279 19.8414 2.73831L20.047 2.84919L20.3555 0.599775H20.3558ZM23.3702 7.07176C23.5569 6.47308 24.2759 4.15706 24.2759 4.15706C24.2663 4.17939 24.4623 3.54778 24.5742 3.15951L24.7329 4.05715C24.7329 4.05715 25.1625 6.55104 25.2555 7.07138H23.3702V7.07176ZM26.1701 0.34471H24.4158C23.8747 0.34471 23.4636 0.532792 23.2303 1.20905L19.8609 10.7615H22.2411C22.2411 10.7615 22.6331 9.47558 22.7169 9.19856H25.6293C25.6943 9.56451 25.8999 10.7615 25.8999 10.7615H28L26.1701 0.345089V0.34471ZM2.38374 1.69951C1.68583 1.21511 0.890078 0.8257 0 0.55512L0.0372858 0.34471H3.68651C4.18142 0.36666 4.58264 0.554741 4.7133 1.22041L5.50618 6.00647L5.5065 6.00722L5.74933 7.44792L7.97054 0.34471H10.3689L6.80385 10.7509H4.40514L2.38374 1.69951Z"
        fill={color}
      />
    </svg>
  )
}

export default VisaMonoIcon
