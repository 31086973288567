import React from 'react'
import { Theme, useTheme } from '@emotion/react'
import { passwordStrength } from 'check-password-strength'
import Text from './typography/Text'

const PasswordStrength = ({ password }) => {
  const { theme } = useTheme()

  let strength = passwordStrength(password)
  const progress = (strength.id + +!!password.length) / 4
  const dict = [
    'PASSWORD_TOO_WEAK',
    'PASSWORD_WEAK',
    'PASSWORD_MODERATE',
    'PASSWORD_SECURE',
  ]
  const styles = React.useMemo(
    () => computeStyles({ theme, progress, strength: strength.id }),
    [theme, progress]
  )
  return (
    <div css={styles.passwordStrengthBar}>
      <span css={styles.progressBarBackground}>
        <div css={styles.progressBar}></div>
      </span>
      <span css={styles.strengthLabel}>
        {<Text token={dict[strength.id]} />}
      </span>
    </div>
  )
}

type ComputeStylesType = {
  theme: Theme['theme']
  progress: number
  strength: number
}

const computeStyles = ({ theme, progress, strength }: ComputeStylesType) => ({
  passwordStrengthBar: {
    display: 'flex',
    alignItems: 'center',
  },
  strengthLabel: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.TEXT_PRIMARY,
    margin: '8px, 0px, 0px',
    padding: 8,
  },
  progressBar: {
    backgroundColor: [
      theme.PASSWORD_STRENGTH_TOO_WEAK,
      theme.PASSWORD_STRENGTH_WEAK,
      theme.PASSWORD_STRENGTH_MODERATE,
      theme.PASSWORD_STRENGTH_SECURE,
    ][strength],
    transition: '1s',
    height: 4,
    width: `${progress * 100}%`,
  },
  progressBarBackground: {
    backgroundColor: theme.PASSWORD_STRENGTH_BACKGROUND,
    display: 'flex',
    flexGrow: 2,
  },
})

export default PasswordStrength
