import React from 'react'
import find from 'lodash/find'

import Link from '../Link'
import List from '../lists/List'
import CategoryBalanceItem from '../lists/items/CategoryBalanceItem'
import ComponentCard from './ComponentCard'
import Text from '../typography/Text'

import useCurrentUser from '../../hooks/useCurrentUser'
import { getCategoryModelKeyByName } from '../../utils/categories'
import { getAmount } from '../../utils/functions'
import MoreInfo from '../MoreInfo'
import Flex from '../Flex'
import { TPlainStyle } from '@emotion/react'

const amountByCategory = (categories, categoryName) => {
  const key = getCategoryModelKeyByName(categoryName)
  const category = find(categories, (cat) => cat.key === key)
  return getAmount(category.fundsAvailable)
}

type CategoryBalancesCardProps = {
  id?: string
} & React.PropsWithChildren

const CategoryBalancesCard: React.FC<CategoryBalancesCardProps> = ({ id }) => {
  const { data: userData } = useCurrentUser(true, id)

  const { categories } = userData?.bankAccounts[0] || {}

  if (!categories || !categories.length) return

  return (
    <Link to="/categories" disableLink>
      <ComponentCard
        label={
          <Flex>
            <Text token="CATEGORY_BALANCES" uppercase />
            <MoreInfo
              css={styles.moreInfo}
              tooltip="ABOUT_CATEGORIES"
              to={`/about-categories?from=/accounts/${id}?tab=all`}
            />
          </Flex>
        }
      >
        <List vertical gap="1rem">
          <CategoryBalanceItem
            category="general"
            amount={amountByCategory(categories, 'general')}
          />
          <CategoryBalanceItem
            category="home"
            amount={amountByCategory(categories, 'home')}
          />
          <CategoryBalanceItem
            category="food"
            amount={amountByCategory(categories, 'food')}
          />
          <CategoryBalanceItem
            category="travel"
            amount={amountByCategory(categories, 'travel')}
          />
          <CategoryBalanceItem
            category="entertainment"
            amount={amountByCategory(categories, 'entertainment')}
          />
          <CategoryBalanceItem
            category="shopping"
            amount={amountByCategory(categories, 'shopping')}
          />
        </List>
      </ComponentCard>
    </Link>
  )
}

const styles: TPlainStyle = {
  moreInfo: {
    marginLeft: 5,
  },
}

export default CategoryBalancesCard
