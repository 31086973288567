import React from 'react'
import * as Yup from 'yup'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'
import CountrySelectField from '../fields/CountrySelectField'
import Text from '../../typography/Text'
import FormHeader from '../../typography/FormHeader'

import { cleanNullProps } from '../../../utils/functions'
import CurrencyField from '../fields/CurrencyField'

export type FundAmountFormProps = {
  data?: any
  submit?: Function
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
}

const schema = Yup.object().shape({
  amount: Yup.number().moreThan(0).required(),
  country: Yup.string().required(),
})

const FundAmountForm: React.FC<FundAmountFormProps> = ({
  submit,
  loading,
  data,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const defaultInitialValues = {
    ...data,
    country: 'Canada',
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <FormHeader token="HOW_MUCH_WOULD_YOU_LIKE_TO_ADD" />
      <CurrencyField
        label={<Text token="AMOUNT" uppercase />}
        prefix="$ "
        name="amount"
        css={styles.field}
      />
      <CountrySelectField
        label={<Text token="COUNTRY_YOU_ARE_PAYING_FROM" />}
        name="country"
        css={styles.field}
        disabled
      />

      <FormFooter css={styles.footer}>
        <FormButton token="NEXT" />
      </FormFooter>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND,
  BACKGROUND_ALT,
}: TTheme) => ({
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default FundAmountForm
