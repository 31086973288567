import React from 'react'
import Flex from '../../Flex'
import Form from '../Form'
import FormButton from '../Button'
import Field from '../Field'
import Text from '../../typography/Text'
import Detail from '../../typography/Detail'
import { DEFAULT_CURRENCY } from '../../../hooks/useSubscriptions'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { Subscription, useCheckoutPricing } from '@recurly/react-recurly'

const COUPON_NAME: string = 'coupon'

type PriceFormProps = {
  subscription: any
  submit?: Function
  subscriptionCode: string
  formRef: any
  editing?: any
  validSubscription?: any
}

const PriceForm: React.FC<PriceFormProps> = ({
  subscription,
  subscriptionCode,
  submit = () => {},
  formRef,
  editing,
  validSubscription,
  ...rest
}) => {
  const { theme } = useTheme()

  const subscriptions: Subscription[] = [
    {
      plan: subscriptionCode,
      quantity: 1,
    },
  ]

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const [error, setError] = React.useState(null)
  const [{ price, loading }, setInput] = useCheckoutPricing(
    { subscriptions, currency: DEFAULT_CURRENCY },
    setError
  )

  const { price: subPrice } = subscription
  const defaultInitialValues = { [COUPON_NAME]: '' }

  const _setupFee = (subPrice?.[0]?.setupFeeInCents || 0) * 0.01
  const setupFeePrice = !editing || !validSubscription.isValid ? _setupFee : 0

  const discount = Number(price?.now?.discount) > 0
  const total = Number(price?.now?.discount)

  const totalPrice =
    !editing || discount || !validSubscription.isValid
      ? price?.now?.total
      : (total || 0) - _setupFee

  const handleSubmit = async () => {
    formRef?.current?.setFieldError(COUPON_NAME, undefined)
    await setError(undefined)
    await setInput({
      subscriptions,
      currency: DEFAULT_CURRENCY,
      coupon: formRef?.current?.values?.[COUPON_NAME],
    })
  }

  React.useEffect(() => {
    if (error) {
      formRef?.current?.setFieldError(COUPON_NAME, error.message)
    }
  }, [error])

  return (
    <Form
      innerRef={formRef}
      onSubmit={handleSubmit}
      loading={loading}
      disabled={loading}
      initialValues={defaultInitialValues}
      {...rest}
    >
      <Flex>
        <Flex verticalAlignment="flex-start" css={styles.promoCode}>
          <Flex verticalAlignment="center" gap="10px">
            <Field
              onBlur={handleSubmit}
              name={COUPON_NAME}
              label={<Text token="DISCOUNT_CODE" />}
              css={styles.field}
              collapseStatus
            />
            <FormButton token="APPLY" />
          </Flex>
        </Flex>
        <Flex vertical css={styles.columns}>
          <Detail token="SETUP_FEE" />
          <Detail css={styles.priceSmall} currency={setupFeePrice} />
          <Detail token="DISCOUNT" />
          <Detail
            css={styles.priceSmall}
            currency={price?.now?.discount || 0}
          />
          <Detail token="TOTAL" />
          <Detail css={styles.totalPrice} currency={totalPrice || 0} />
        </Flex>
      </Flex>
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  FULL_CONTRAST,
  BACKGROUND_ALT,
}: TTheme) => ({
  priceSmall: {
    color: FULL_CONTRAST,
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: 10,
  },
  totalPrice: {
    color: FULL_CONTRAST,
    fontSize: '32px',
    lineHeight: '40px',
  },
  field: {
    background: BACKGROUND_ALT,
    marginRight: 10,
  },
  promoCode: {
    flex: 2,
  },
  columns: {
    flex: 1,
    alignItems: 'end',
  },
})

export default PriceForm
