import React from 'react'
import { motion } from 'framer-motion'
import { useTheme } from '@emotion/react'

import DelayedRedirect from './DelayedRedirect'
import Icon from './Icon'

const Status404 = () => {
  const { theme, colors } = useTheme()
  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  return (
    <motion.div
      css={styles.root}
      initial={{ scale: 1.1 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.1 }}
    >
      <div css={styles.grid}>
        <Icon icon="brand" size={1.25} color={colors.WHITE} />
        <div css={styles.header}>404</div>
        <div css={styles.description}>
          We are still working on this feature, come back later.
        </div>
        <div css={styles.description}>
          We'll redirect you back in <DelayedRedirect back /> seconds
        </div>
      </div>
    </motion.div>
  )
}

const computeStyles = ({ CATEGORY_TURQUOISE, WHITE }) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url("/images/bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    flexDirection: 'column',
    textAlign: 'center',
  },

  logo: {
    width: 264,
    height: 140,

    background: 'url("/images/hero-logo.gif")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },

  grid: {
    display: 'grid',
    gridGap: '1.5rem',
    maxWidth: '70%',
  },

  header: {
    fontSize: '3rem',
    fontWeight: 600,
    color: CATEGORY_TURQUOISE,
  },

  description: {
    fontSize: '1.2rem',
    fontWeight: 500,
    color: WHITE,
  },
})

export default Status404
