import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

import TRANSLATIONS_DE from './lib/de.json'
import TRANSLATIONS_EN from './lib/en.json'
import TRANSLATIONS_ES from './lib/es.json'
import TRANSLATIONS_FR from './lib/fr.json'
import TRANSLATIONS_JA from './lib/ja.json'
import TRANSLATIONS_PT from './lib/pt.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      de: {
        translation: TRANSLATIONS_DE,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      ja: {
        translation: TRANSLATIONS_JA,
      },
      pt: {
        translation: TRANSLATIONS_PT,
      },
    },
  })

export default i18n
