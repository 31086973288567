import React from 'react'

import Flex, { FlexProps } from '../Flex'
import Link, { LinkProps } from '../Link'

export type ListItemProps = { to?: string } & FlexProps & Omit<LinkProps, 'to'>

const ListItem: React.FC<ListItemProps> = ({ to, ...rest }) => {
  if (to)
    return (
      <Link to={to}>
        <Flex {...rest} />
      </Link>
    )

  return <Flex {...rest} />
}

export default ListItem
