import React from 'react'

import SelectField from './SelectField'

import states from '../../../utils/states'

const StateSelectField = ({ ...rest }) => {
  return (
    <SelectField {...rest}>
      {states.map((state) => (
        <option key={`state_${state.key}`} value={state.abbreviation}>
          {state.pretty}
        </option>
      ))}
    </SelectField>
  )
}

export default StateSelectField
