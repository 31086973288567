import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const LeafIcon = ({ size = 1, color = '#464E58', width, height }) => {
  const defaultWidth = 35
  const defaultHeight = 38
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 18.25L0 18.85L8.175 26.0375C8.8 27.8875 7.9625 28.425 7.425 29.4L16.3 28.275L16.075 37.2125L17.925 37.15L17.5125 28.2875L26.4125 29.3375C25.8625 28.175 25.375 27.5625 25.875 25.7125L34.05 18.9L32.625 18.3875C31.45 17.475 33.125 14.0375 33.375 11.8625C33.375 11.8625 28.6125 13.5 28.3 12.6375L27.0625 10.325L22.7375 15.075C22.2625 15.2 22.0625 15 21.95 14.6L23.95 4.6375L20.775 6.425C20.5125 6.5375 20.25 6.425 20.075 6.125L17.0125 0L13.8625 6.3625C13.625 6.5875 13.3875 6.6125 13.1875 6.4625L10.1625 4.7625L11.975 14.6625C11.8375 15.0375 11.4875 15.1625 11.0875 14.95L6.925 10.225C6.3875 11.1 6.0125 12.525 5.3 12.85C4.5875 13.1375 2.175 12.2375 0.5625 11.8875C1.1125 13.875 2.8375 17.175 1.75 18.2625V18.25Z"
        fill={color}
      />
    </svg>
  )
}

export default LeafIcon
