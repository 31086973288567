import React from 'react'

import Flex, { FlexProps } from '../Flex'

const SafeArea: React.FC<FlexProps> = ({ children, className }) => {
  return (
    <Flex css={styles.root} className={className}>
      {children}
    </Flex>
  )
}

const styles = {
  root: {
    //paddingBottom: 'env(safe-area-inset-bottom, 0)',
  },
}

export default SafeArea
