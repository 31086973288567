import React from 'react'
import * as Yup from 'yup'
import Form from '../Form'

import Header from '../../typography/Header'
import Detail from '../../typography/Detail'
import { Theme, useTheme } from '@emotion/react'
import SwitchField from '../fields/SwitchField'

type CardBlocklistProps = {
  loading?: boolean
  submit?: Function
  status?: boolean
  error?: boolean
  handleFieldUpdate?: Function
  innerRef?: any
  disabled?: boolean
}

const schema = Yup.object().shape({
  status: Yup.boolean(),
})

const CardBlocklistForm: React.FC<CardBlocklistProps> = ({
  loading,
  submit,
  status,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form
      initialValues={{
        status: status,
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
      {...rest}
    >
      {({ values }) => (
        <>
          <Header css={styles.headline} token="BLOCKLIST" />

          <Detail token="BLOCKLIST_INFO" capitalize={false} />

          <SwitchField
            label={
              <Header
                token={
                  values?.status ? 'BLOCKLIST_ENABLED' : 'BLOCKLIST_DISABLED'
                }
                css={styles.sectionToggleLabel}
              />
            }
            name="status"
            css={styles.sectionToggle}
            labelIsSufix
          />
        </>
      )}
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: { width: '100%' },
  headline: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  sectionToggle: {
    border: 'none',
  },
  sectionToggleLabel: {
    color: theme.TEXT_PRIMARY,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1.71,
    margin: '0 9px',
  },
})

export default CardBlocklistForm
