import React from 'react'
import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'

import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'

import {
  enableBlockList as enableBlockListMutation,
  disableBlockList as disableBlockListMutation,
} from '../../../queries'
import CardBlocklistForm from '../../../components/form/forms/CardBlocklistForm'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useIsSidekick from '../../../hooks/useIsSidekick'
import { TPlainStyle } from '@emotion/react'

const BlocklistScene = ({ ...rest }) => {
  const { id } = useParams()
  const innerFormik = React.useRef(null)

  const { data, loading, refetch } = useCurrentUser(true, id)

  const bankAccount = data?.bankAccounts?.[0] || {}
  const { isBlockListEnabled } = bankAccount
  const [enableBlockList, { loading: enableLoading }] = useMutation(
    enableBlockListMutation
  )

  const [disableBlockList, { loading: disableLoading }] = useMutation(
    disableBlockListMutation
  )
  const isSidekick = useIsSidekick()

  const processing = enableLoading || disableLoading

  const handleFieldUpdate = async (_, value) => {
    const variables = {
      accountOwnerId: id,
      bankAccountId: bankAccount.bankAccountId,
    }

    try {
      if (value) {
        await enableBlockList({ variables })
        refetch()
      } else {
        await disableBlockList({ variables })
        refetch()
      }
    } catch (err) {
      innerFormik?.current?.resetForm?.()
    }
  }

  return (
    <CardLayoutLink
      title={<Text token="BLOCKLIST" />}
      loading={loading}
      processing={processing}
      processingLabel={
        <Text
          token={enableLoading ? 'ENABLING_BLOCKLIST' : 'DISABLING_BLOCKLIST'}
        />
      }
      {...rest}
    >
      <Flex vertical padding="1rem" css={styles.content}>
        <CardBlocklistForm
          handleFieldUpdate={handleFieldUpdate}
          loading={loading}
          status={isBlockListEnabled}
          innerRef={innerFormik}
          disabled={isSidekick}
        />
      </Flex>
    </CardLayoutLink>
  )
}

const styles: TPlainStyle = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default BlocklistScene
