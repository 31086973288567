const NotificationsModel = {
  state: [],
  reducers: {
    CLEAR: () => {
      return []
    },

    ADD_NOTIFICATION: (state, payload) => [...state, payload],
  },
}

export default NotificationsModel
