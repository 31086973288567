import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import ConfirmModal from '../../../../components/modals/ConfirmModal'
import FlowCardLayout, {
  FlowStep,
} from '../../../../components/layout/FlowCardLayout'

import DeliveryCardStep from '../../delivery-card'

import usePrompt from '../../../../hooks/usePrompt'
import Text from '../../../../components/typography/Text'
import ShippingAddressStep from './shipping-address'
import SummaryStep from './summary'
import ThanksStep from '../../thanks'
import { FlowStateType } from '../../../../store/flow'

const OrderCardReplace = ({ ...rest }) => {
  const [params] = useSearchParams()
  const { id } = useParams()
  const dispatch = useDispatch()
  const flowState = useSelector(
    (state: FlowStateType) => state.flow.replaceCard
  )
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta?.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)

  React.useEffect(() => {
    if (flowState?.meta?.keepDirty) return next()
    if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  const to = params.get('to')

  const prePopulateFlowData = async () => {
    await dispatch.flow.SET_FLOW_DATA('replaceCard', {
      forUser: id,
    })
  }

  if (!flowState?.data?.forUser) prePopulateFlowData()

  return (
    <>
      <FlowCardLayout
        name="replaceCard"
        cancel="/cards"
        to={to}
        title={<Text token="ORDER_SIDEKICK_CARD" />}
        {...rest}
      >
        <FlowStep
          title={<Text token="DELIVERY_METHOD" />}
          element={DeliveryCardStep}
        />

        <FlowStep
          title={<Text token="SHIPPING_ADDRESS" />}
          element={ShippingAddressStep}
        />

        <FlowStep title={<Text token="SUMMARY" />} element={SummaryStep} />

        <FlowStep
          title={<Text token="THANKS_FOR_YOUR_CARD_ORDER" />}
          element={ThanksStep}
        />
      </FlowCardLayout>

      <AnimatePresence>
        {showConfirm && (
          <ConfirmModal
            title={<Text token="CONFIRM" />}
            content="DATA_LOSS_CONFIRM_MESSAGE"
            confirm={next}
            cancel={closeModal}
            backdrop
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default OrderCardReplace
