import React from 'react'
import { useTheme } from '@emotion/react'

import Element from './Element'
import { ColorType } from '../utils/themes'

export type BulletProps = {
  color: ColorType
  size: number
}

const Bullet: React.FC<BulletProps> = ({
  color = 'RED_400',
  size = 20,
  ...rest
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ colors, color, size }),
    [theme, colors]
  )

  return <Element css={styles.root} {...rest} />
}

const computeStyles = ({ color, colors, size }) => ({
  root: {
    backgroundColor: colors[color],
    width: size,
    height: size,
    borderRadius: '100%',
  },
})

export default Bullet
