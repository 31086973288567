import { teamHoldingAccountFunds } from '../queries'
import { useQuery } from '@apollo/client'

const useTeamHoldingAccountFunds = (cache?) => {
  const queryOptions = {
    fetchPolicy: cache ? 'cache-first' : 'network-only',
    notifyOnNetworkStatusChange: true,
  }
  return useQuery(teamHoldingAccountFunds, queryOptions)
}

export default useTeamHoldingAccountFunds
