import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'

import SelectField from './SelectField'
import Text, { TranslationToken } from '../../typography/Text'

export const daysDict: TranslationToken[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

const DayOfWeekSelectField = ({
  label = <Text token="REPEATS_ON_WHICH_DAY_OF_THE_WEEK" />,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <SelectField css={styles.root} label={label} hideDefaultOption {...rest}>
      {daysDict.map((day, index) => (
        <option value={index + 1} key={`DayofWeek_${day}_${index + 1}`}>
          <Text token={day} />
        </option>
      ))}
    </SelectField>
  )
}

const computeStyles: TComputeStyles = ({ BACKGROUND_ALT }: TTheme) => ({
  root: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default DayOfWeekSelectField
