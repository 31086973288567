import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const GlobeIcon = ({ size = 1, color = '#464E58', width, height }) => {
  const defaultWidth = 24
  const defaultHeight = 24
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.41 8.64V8.59C20.7053 6.66623 19.4269 5.00529 17.7474 3.83187C16.068 2.65845 14.0687 2.02917 12.02 2.02917C9.9712 2.02917 7.97189 2.65845 6.29246 3.83187C4.61304 5.00529 3.33455 6.66623 2.62995 8.59V8.64C1.84308 10.8109 1.84308 13.1891 2.62995 15.36V15.41C3.33455 17.3338 4.61304 18.9947 6.29246 20.1681C7.97189 21.3416 9.9712 21.9708 12.02 21.9708C14.0687 21.9708 16.068 21.3416 17.7474 20.1681C19.4269 18.9947 20.7053 17.3338 21.41 15.41V15.36C22.1968 13.1891 22.1968 10.8109 21.41 8.64V8.64ZM4.25995 14C3.91318 12.6892 3.91318 11.3108 4.25995 10H6.11995C5.95998 11.3285 5.95998 12.6715 6.11995 14H4.25995ZM5.07995 16H6.47995C6.71467 16.8918 7.05016 17.7541 7.47995 18.57C6.49925 17.9019 5.67945 17.0241 5.07995 16ZM6.47995 8H5.07995C5.67082 6.97909 6.48014 6.10147 7.44995 5.43C7.03051 6.24725 6.70509 7.10942 6.47995 8V8ZM11 19.7C9.77172 18.7987 8.9091 17.4852 8.56995 16H11V19.7ZM11 14H8.13995C7.95334 12.6732 7.95334 11.3268 8.13995 10H11V14ZM11 8H8.56995C8.9091 6.51477 9.77172 5.20132 11 4.3V8ZM18.92 8H17.52C17.2852 7.10816 16.9497 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0908 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0908 17.4852 14.2282 18.7987 13 19.7V19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9797 17.7541 17.3152 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0867 11.3108 20.0867 12.6892 19.74 14Z"
        fill={color}
      />
    </svg>
  )
}

export default GlobeIcon
