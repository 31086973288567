import React from 'react'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'

import Flex from './Flex'
import Bullet from './Bullet'
import IconButton from './IconButton'

const Activity = () => {
  const { colors } = useTheme()
  const newNotifications = useSelector((state) => state.notifications)

  const thereAreNewNotifications = newNotifications.length > 0

  return (
    <Flex css={styles.root}>
      {thereAreNewNotifications && <Bullet size={7} css={styles.bullet} />}
      <IconButton
        icon="activity"
        iconColor={colors.BLACK_600}
        to="/notifications"
      />
    </Flex>
  )
}

const styles = {
  root: {
    position: 'relative',
  },
  bullet: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}

export default Activity
