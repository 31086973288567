import React from 'react'
import { Theme, useTheme } from '@emotion/react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import Grid from './Grid'
import Flex, { FlexProps } from './Flex'
import Icon from './Icon'
import Text from './typography/Text'
import AnimatedSpinner from './AnimatedSpinner'
import Pill from './Pill'

import { ExpandableContext } from './Expandable'

export type PhysicalCardProps = FlexProps & {
  card: any
  loading?: boolean
  hasMenu?: boolean
}

const PhysicalCard: React.FC<PhysicalCardProps> = ({
  card,
  loading,
  hasMenu = true,
  className,
  ...rest
}) => {
  const { expanded } = React.useContext(ExpandableContext)
  const { colors, theme, breakpoints } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, breakpoints), [theme])

  const classNames = clsx({
    [className]: className,
    'is-loading': loading,
    'is-open': expanded,
  })

  const cardholder = card?.cardholder
  const status = card?.status?.statusCode

  return (
    <Flex css={styles.root} vertical spread className={classNames} {...rest}>
      {!loading && (
        <>
          <Flex spread>
            <Flex gap="1rem">
              <Text>
                {cardholder?.givenName} {cardholder?.familyName}
              </Text>
              {status && <Pill variant="primary-alt">{status}</Pill>}
            </Flex>
            {hasMenu && (
              <Icon
                icon="chevron"
                color="WHITE"
                css={styles.icon}
                className={classNames}
              />
            )}
          </Flex>

          <Grid gap="1rem">
            <Flex gap="1rem" css={styles.digits}>
              <Text
                token="HIDDEN_CARD_NUMBER"
                interpolation={{ lastDigits: card?.last4Digits }}
              />
            </Flex>

            <Flex spread>
              <Icon icon="brand" size={0.9} color="WHITE" />
              <Icon icon="mastercardMono" size={1.827} color="WHITE" />
            </Flex>
          </Grid>
        </>
      )}

      {loading && (
        <Flex center stretch>
          <AnimatedSpinner
            color={transparentize(0.6, colors.WHITE)}
            size={40}
          />
        </Flex>
      )}
    </Flex>
  )
}

const computeStyles = (
  { CARD_BACKGROUND, CARD_TEXT }: Theme['theme'],
  { BREAKPOINT_SMALL }: Theme['breakpoints']
) => ({
  root: {
    backgroundColor: CARD_BACKGROUND,
    padding: '1.5rem',
    borderRadius: 16,

    aspectRatio: '16/9',

    color: CARD_TEXT,
    fontSize: 19,
    fontWeight: '600',

    overflow: 'hidden',

    [BREAKPOINT_SMALL]: {
      fontSize: 16,
    },

    '&.is-loading': {
      aspectRatio: 'auto',
    },
  },

  icon: {
    '&.is-open': {
      transform: 'rotate(90deg)',
    },
  },

  digits: {
    fontFamily: 'Courier New',
    fontSize: 21,
    letterSpacing: 3,

    [BREAKPOINT_SMALL]: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: 0,
    },
  },
})

export default PhysicalCard
