import React from 'react'
import Switch, { ReactSwitchProps } from 'react-switch'
import { useTheme } from '@emotion/react'

type StyledSwitchProps = {
  scale?: number
} & ReactSwitchProps

const StyledSwitch: React.FC<StyledSwitchProps> = ({ scale = 1, ...rest }) => {
  const { colors } = useTheme()
  return (
    <Switch
      onColor={colors.TURQUOISE_400}
      onHandleColor={colors.TURQUOISE_500}
      handleDiameter={20 * scale}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0"
      height={14 * scale}
      width={34 * scale}
      {...rest}
    />
  )
}

export default StyledSwitch
