import React from 'react'

import Portal from '../Portal'
import ApplicationContainer from '../layout/ApplicationContainer'
import Grid, { GridProps } from '../Grid'

import LayoutContext from '../../context/LayoutContext'
import { useTheme } from '@emotion/react'
import { getSafeArea } from '../../mobile/safearea/safearea-config'

const FormFooter: React.FC<GridProps> = ({
  children,
  noBackground,
  ...rest
}) => {
  const { setUseFooter } = React.useContext(LayoutContext)
  const [safeArea, setSafeArea] = React.useState({})

  const { theme } = useTheme()

  const styles = React.useMemo(
    () =>
      computeStyles({
        SAFEBOTTOM: safeArea?.bottom || 0,
        BACKGROUND: !noBackground ? theme.BACKGROUND : 'transparent',
      }),
    [safeArea, theme, noBackground]
  )

  setUseFooter(true)

  React.useEffect(() => {
    ;(async () => {
      const { insets } = await getSafeArea()
      setSafeArea(insets)
    })()
    return () => {
      setUseFooter(false)
    }
  }, [])

  return (
    <Portal type="default">
      <div css={styles.root}>
        <ApplicationContainer>
          <Grid gap={12} padding={12} horizontal {...rest}>
            {children}
          </Grid>
        </ApplicationContainer>
      </div>
    </Portal>
  )
}

const computeStyles = ({ SAFEBOTTOM, BACKGROUND }) => ({
  root: {
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    background: BACKGROUND,
    paddingBottom: SAFEBOTTOM,
  },
})

export default FormFooter
