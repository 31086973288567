import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const KeyholeCircleIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#507FB4',
  width,
  height,
}) => {
  const defaultWidth = 20
  const defaultHeight = 20
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 9.72V9.43132L9.25 9.28698C9.02329 9.1561 8.83478 8.96817 8.70321 8.74186C8.5719 8.516 8.50185 8.25978 8.50001 7.99855C8.50039 7.60125 8.65838 7.2203 8.93935 6.93934C9.22065 6.65804 9.60218 6.5 10 6.5C10.3978 6.5 10.7794 6.65804 11.0607 6.93934C11.3416 7.2203 11.4996 7.60124 11.5 7.99854C11.4982 8.25977 11.4281 8.516 11.2968 8.74186C11.1652 8.96817 10.9767 9.1561 10.75 9.28698L10.5 9.43132V9.72V13C10.5 13.1326 10.4473 13.2598 10.3536 13.3536C10.2598 13.4473 10.1326 13.5 10 13.5C9.8674 13.5 9.74022 13.4473 9.64645 13.3536C9.55268 13.2598 9.5 13.1326 9.5 13V9.72ZM4.72209 2.10104C6.28435 1.05717 8.12108 0.5 10 0.5C11.2476 0.5 12.4829 0.745725 13.6355 1.22314C14.7881 1.70056 15.8354 2.40033 16.7175 3.28249C17.5997 4.16464 18.2994 5.21191 18.7769 6.36451C19.2543 7.5171 19.5 8.75244 19.5 10C19.5 11.8789 18.9428 13.7157 17.899 15.2779C16.8551 16.8402 15.3714 18.0578 13.6355 18.7769C11.8996 19.4959 9.98947 19.684 8.14665 19.3175C6.30383 18.9509 4.61109 18.0461 3.28249 16.7175C1.95389 15.3889 1.0491 13.6962 0.682544 11.8534C0.315985 10.0105 0.504116 8.1004 1.22315 6.36451C1.94218 4.62861 3.15982 3.14491 4.72209 2.10104ZM5.27766 17.0675C6.67548 18.0015 8.31886 18.5 10 18.5C12.2543 18.5 14.4164 17.6045 16.0104 16.0104C17.6045 14.4163 18.5 12.2543 18.5 10C18.5 8.31886 18.0015 6.67547 17.0675 5.27765C16.1335 3.87984 14.806 2.79037 13.2528 2.14702C11.6996 1.50368 9.99058 1.33535 8.34174 1.66333C6.6929 1.9913 5.17834 2.80085 3.9896 3.98959C2.80085 5.17834 1.9913 6.69289 1.66333 8.34173C1.33536 9.99057 1.50368 11.6996 2.14703 13.2528C2.79037 14.806 3.87984 16.1335 5.27766 17.0675Z"
        fill={color}
        stroke={color}
      />
    </svg>
  )
}

export default KeyholeCircleIcon
