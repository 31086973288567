import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import React from 'react'
import Flex from '../../Flex'
import IconCircle from '../../IconCircle'

import RadioField, { RadioFieldProps } from './RadioField'

export const TeamMemberVariants = {
  HERO: 'HERO',
  SIDEKICK: 'SIDEKICK',
}

type TeamMemberTypeOptionFieldProps = {
  value?: 'HERO' | 'SIDEKICK' | string
  label?: string | JSX.Element
  subtitle?: string
  className?: string
} & RadioFieldProps

const TeamMemberTypeOptionField: React.FC<TeamMemberTypeOptionFieldProps> = ({
  label,
  subtitle,
  value,
  className,
  ...rest
}) => {
  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
  })

  const isHero = value === TeamMemberVariants.HERO

  return (
    <>
      <RadioField
        css={styles.root}
        className={classNames}
        value={value}
        {...rest}
      >
        <IconCircle
          css={styles.image}
          icon={isHero ? 'brand' : 'mask'}
          size={56}
          scale={isHero ? 0.557 : 0.975}
          color={isHero ? 'TURQUOISE_400' : 'BLUE_200'}
          iconColor={isHero ? 'WHITE' : 'BLUE_300'}
        />
        <Flex css={styles.content} vertical centerX>
          <div css={styles.label}>{label}</div>
          <div css={styles.subtitle}>{subtitle}</div>
        </Flex>
      </RadioField>
    </>
  )
}

const computeStyles: TComputeStyles = (
  { FIELD_BORDER, TEXT_PRIMARY }: TTheme,
  {
    FIELD_PADDING,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes
) => ({
  root: {
    color: TEXT_PRIMARY,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    gridTemplateColumns: '60px 1fr 13px',
    padding: FIELD_PADDING,

    '&.is-radio': {
      border: `2px solid ${FIELD_BORDER}`,
      borderRadius: FIELD_BORDER_RADIUS,
      display: 'grid',
      gridTemplateColumns: '60px 1fr 20px',
    },
    '& > input': {
      gridColumnStart: -2,
    },
  },
  image: {
    alignSelf: 'center',
    justifySelf: 'center',
    gridColumnStart: 1,
    gridRowStart: 1,
  },
  content: {
    fontStyle: 'normal',
    fontWeight: FIELD_FONT_WEIGHT,
    gridColumnStart: 2,
    gridRowStart: 1,
  },
  label: {
    fontSize: '16px',
    letterSpacing: '-0.1px',
    lineHeight: '24px',
  },
  subtitle: {
    fontSize: '14px',
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
})

export default TeamMemberTypeOptionField
