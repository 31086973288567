import set from 'lodash/set'
import get from 'lodash/get'
import { useMutation } from '@apollo/client'
import { reallocateFunds } from '../queries/mutations'
import { teamMembers as teamMembersQuery } from '../queries'
import { getAmountPayload, getDecimal } from '../utils/allocate'
import cloneDeep from 'lodash/cloneDeep'

type amountOperationType = 'add' | 'sub'

const amountsOperation = (amount1, amount2, operation: amountOperationType) => {
  const basicUnit = String(
    Number(amount1.basicUnit) +
      Number(amount2.basicUnit) * (operation === 'sub' ? -1 : 1)
  )

  const fraction = getDecimal(
    Number(amount1.fraction) * 0.01 +
      Number(amount2.fraction) * (operation === 'sub' ? -1 : 1) * 0.01
  )

  return {
    ...amount1,
    basicUnit,
    fraction,
  }
}

const findCategoryIndex = (item, key) =>
  item.bankAccounts[0].categories.findIndex((category) => category.key === key)

export const getUpdatedCacheData = (teamMembers, id, mutationData) => {
  const { sourceCategoryKey, targetCategoryKey, amount } =
    mutationData.reallocateFunds

  const itemIndex = teamMembers.findIndex(
    (member) => member.userProfile.id === id
  )
  const item = cloneDeep(
    teamMembers.find((member) => member.userProfile.id === id)
  )

  const sourceCategoryKeyIndex = findCategoryIndex(item, sourceCategoryKey)
  const targetCategoryKeyIndex = findCategoryIndex(item, targetCategoryKey)
  const sourceGetter = `bankAccounts[0].categories[${sourceCategoryKeyIndex}].fundsAvailable`
  const targetGetter = `bankAccounts[0].categories[${targetCategoryKeyIndex}].fundsAvailable`

  const cacheData = cloneDeep(teamMembers)

  set(
    item,
    sourceGetter,
    amountsOperation(get(item, sourceGetter), amount, 'sub')
  )

  set(
    item,
    targetGetter,
    amountsOperation(get(item, targetGetter), amount, 'add')
  )
  set(cacheData, `[${itemIndex}]`, item)

  return cacheData
}

export const generatePayload = (formData) => {
  const { id, fromCategory, toCategory, amount } = formData
  return {
    targetAccount: id,
    sourceCategoryKey: fromCategory.key,
    targetCategoryKey: toCategory.key,
    amount: getAmountPayload(amount),
  }
}

const useReallocateFunds = (id, teamMembers) => {
  return useMutation(reallocateFunds, {
    update: (store, { data }) => {
      const cacheData = getUpdatedCacheData(teamMembers, id, data)
      store.writeQuery({
        query: teamMembersQuery,
        data: { teamMembers: cacheData },
      })
    },
  })
}

export default useReallocateFunds
