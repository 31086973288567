import React from 'react'
import clsx from 'clsx'

import AnimatedSpinner from '../AnimatedSpinner'
import Status from '../statuses/Status'
import Button from '../Button'
import Grid from '../Grid'
import Flex from '../Flex'
import Text, { TranslationToken } from '../typography/Text'
import { TPlainStyle } from '@emotion/react'

export type CardProps = {
  label?: string | JSX.Element
  token?: TranslationToken
  description?: string
  loading?: boolean
  error?: any
  empty?: boolean
  emptyMessage?: string
  action?: any
  retry?: Function
  outsideLabel?: boolean
  className?: string
  feature?: string
  ref?: any
} & React.PropsWithRef<React.PropsWithChildren>

const Card: React.FC<CardProps> = ({
  label,
  token,
  description,
  loading,
  error,
  empty,
  emptyMessage = 'There is nothing here',
  action,
  className,
  retry,
  children,
  ref,
  outsideLabel = false,
  ...rest
}) => {
  const cardRef = React.useRef(ref)
  const classNames = clsx({
    [className]: className,
    'is-loading': loading,
    'is-error': error,
    'is-empty': empty,
  })

  return (
    <>
      {(label || token) && outsideLabel && (
        <Text as="header" token={token} uppercase css={styles.header}>
          {label}
        </Text>
      )}

      <Grid
        gap="10px"
        css={styles.root}
        className={classNames}
        innerRef={cardRef}
        {...rest}
      >
        {(label || token) && !outsideLabel && (
          <Text as="header" token={token} uppercase css={styles.header}>
            {label}
          </Text>
        )}
        {description && <p css={styles.description}>{description}</p>}

        {loading && (
          <Flex center>
            <AnimatedSpinner size={40} />
          </Flex>
        )}

        {empty && !error && !loading && (
          <Grid centerX centerY vertical height={200} gap="1rem">
            {emptyMessage && <Text>{emptyMessage}</Text>}
            {retry && <Button button onClick={retry} label="Refresh" />}
          </Grid>
        )}

        {error && (
          <Status
            error={error}
            redirect={false}
            label="Retry"
            action={action}
          />
        )}

        {!loading && !error && !empty && children}
      </Grid>
    </>
  )
}

const styles: TPlainStyle = {
  root: {
    padding: '1rem',
    borderRadius: 10,
  },

  header: {
    fontSize: 12,
    letterSpacing: 1,
    textAlign: 'left',
    justifySelf: 'flex-start',
  },

  description: {
    fontSize: 14,
  },
}

export default Card
