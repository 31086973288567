import React from 'react'
import * as Yup from 'yup'

import { useSearchParams } from 'react-router-dom'
import { isTeamHoldingAccount } from '../../../utils/allocate'
import { sumCategoriesAllocate } from '../../../utils/allocate'
import { getAmount } from '../../../utils/functions'

import Form from '../Form'
import FromToField from '../fields/FromToField'
import AmountFields from '../fields/AmountsField'
import Detail from '../../typography/Detail'
import Flex from '../../Flex'
import Text from '../../typography/Text'
import FormFooter from '../FormFooter'
import Button from '../Button'
import LinkButton from '../../Button'
import Info from '../../Info'
import {
  ALLOCATE_VALIDATOR_POSITIVE_REQUIRED,
  ALLOCATE_VALIDATOR_REQUIRED,
} from '../fields/CurrencyField'
import { TPlainStyle } from '@emotion/react'

// Helpers
export const getMemberId = (member) => member?.userProfile?.id
export const getMemberById = (members, id) =>
  members.find((member) => getMemberId(member) === id)

// Validators
const AmountFieldValidator = ALLOCATE_VALIDATOR_REQUIRED
const PositiveAmountFieldValidator = ALLOCATE_VALIDATOR_POSITIVE_REQUIRED

const CommonSchema = {
  fromAllocate: Yup.object().required(),
  toAllocate: Yup.object().required(),
}

// Types
type AllocateFormProps = {
  teamMembers: Array<any>
  categoryAllocate: boolean
  onSubmit: Function
  loading?: boolean
  onlyFromHero?: boolean
}

const AllocateForm: React.FC<AllocateFormProps> = ({
  teamMembers,
  categoryAllocate,
  onSubmit = () => {},
  loading,
  onlyFromHero,
  ...rest
}) => {
  // Url Params
  const [search] = useSearchParams()
  const fromAllocateId = search.get('fromAllocate')
  const toAllocateId = search.get('toAllocate')
  const formRef = React.useRef(null)

  const fromAllocate =
    getMemberById(teamMembers, fromAllocateId) ||
    teamMembers.filter((member) => isTeamHoldingAccount(member))[0]

  const toAllocate =
    getMemberById(
      teamMembers.filter((member) => !isTeamHoldingAccount(member)),
      toAllocateId
    ) ||
    teamMembers.filter(
      (member) =>
        !isTeamHoldingAccount(member) &&
        getMemberId(fromAllocate) !== getMemberId(member)
    )[0]

  const [allocationCondition, setAllocationCondition] = React.useState(
    categoryAllocate && isTeamHoldingAccount(fromAllocate)
  )

  const [maxToAllocate, setMaxToAllocate] = React.useState(
    getAmount(fromAllocate.fundsAvailable)
  )

  const initialCategoriesValidators = () => {
    const member = getMemberById(teamMembers, toAllocate.userProfile.id)
    if (member?.bankAccounts && member?.bankAccounts.length) {
      const categories =
        member?.bankAccounts?.[0]?.categories
          ?.map?.((cat) => cat.key)
          ?.sort?.((a, b) => a.localeCompare(b)) || []

      const rest = categories.slice(1)

      return categories.reduce((a, v, i) => {
        const simpleValidator = AmountFieldValidator(maxToAllocate)
        const complexValidator = Yup.number().when(rest, {
          is: (...args) => {
            return (
              args.reduce((acc, arg) => {
                return acc + (arg || 0)
              }, 0) > 0
            )
          },
          then: Yup.number(),
          otherwise: AmountFieldValidator(maxToAllocate).moreThan(
            0,
            'CATEGORIES_GREATER_THAN_0'
          ),
        })
        const validator = i === 0 ? complexValidator : simpleValidator

        return {
          ...a,
          [v]: validator,
        }
      }, {})
    }
  }

  const initialCategoriesValues = () => {
    const member = getMemberById(teamMembers, toAllocate.userProfile.id)
    if (member?.bankAccounts && member?.bankAccounts.length) {
      const categories =
        member?.bankAccounts?.[0]?.categories
          ?.map?.((cat) => cat.key)
          ?.sort?.((a, b) => a.localeCompare(b)) || []

      return categories.reduce((a, v) => {
        return {
          ...a,
          [v]: 0,
        }
      }, {})
    }
  }

  const initialValues = allocationCondition
    ? { fromAllocate, toAllocate, ...(initialCategoriesValues() || {}) }
    : { fromAllocate, toAllocate, amount: 0 }

  const WithCategotySchema = Yup.object().shape({
    ...CommonSchema,
    ...initialCategoriesValidators(),
  })

  const SimpleSchema = Yup.object().shape({
    ...CommonSchema,
    amount: PositiveAmountFieldValidator(maxToAllocate),
  })

  const checkAllocationCondition = (key, value) => {
    if (key === 'fromAllocate') {
      setAllocationCondition(categoryAllocate && isTeamHoldingAccount(value))
      setMaxToAllocate(
        getAmount(value.fundsAvailable || value.bankAccounts[0].fundsAvailable)
      )
    }
  }

  const handleSubmit = (formData) => {
    const values = formData || {}
    allocationCondition && delete formData.amount
    onSubmit(values, formRef?.current)
  }

  const effectAllocationCallback = () => {
    const form = formRef?.current
    const toAllocate = form?.values?.fromAllocate
    const val = categoryAllocate && isTeamHoldingAccount(toAllocate)
    setAllocationCondition(val)
    setMaxToAllocate(
      getAmount(
        toAllocate.fundsAvailable || toAllocate.bankAccounts[0].fundsAvailable
      )
    )
  }

  const calculateRelocation = () => {
    const form = formRef?.current
    if (form?.values) {
      const { toAllocate, fromAllocate, amount, ...categories } = form?.values

      let allocation = getAmount(sumCategoriesAllocate(categories))
      if (allocation < 0) {
        allocation = 0
      }
      return allocationCondition ? Number(allocation) : Number(amount)
    }
    return 0
  }

  const calculateTotalRelocation = () =>
    Number(calculateRelocation()) +
    Number(
      getAmount(
        formRef?.current?.values?.toAllocate?.bankAccounts?.[0]?.fundsAvailable
      )
    )

  React.useEffect(effectAllocationCallback, [])
  React.useEffect(effectAllocationCallback, [categoryAllocate])

  return (
    <Form
      loading={loading}
      innerRef={formRef}
      validationSchema={allocationCondition ? WithCategotySchema : SimpleSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      {...rest}
    >
      {(form) => {
        return (
          <>
            <FromToField
              disabled={loading}
              onChange={checkAllocationCondition}
              to={form?.values?.toAllocate}
              from={form?.values?.fromAllocate}
              teamMembers={teamMembers}
              onlyFromHero={onlyFromHero}
            />
            <Detail
              css={styles.text}
              token={
                allocationCondition ? 'ADD_TO_CATEGORY' : 'AMOUNT_TO_ALLOCATE'
              }
            />

            {allocationCondition && (
              <Detail capitalize={false} token="AMOUNT_TO_ALLOCATE_CATEGORY" />
            )}
            <AmountFields
              disabled={!maxToAllocate}
              fundsAvailable={
                form?.values?.fromAllocate?.bankAccounts?.[0]?.fundsAvailable
              }
              categories={
                allocationCondition &&
                form?.values?.toAllocate?.bankAccounts?.[0]?.categories
              }
            />

            {!maxToAllocate && (
              <Info
                token="NOT_ENOUGH_FUNDS_OR_ADD_NEW_FUNDS"
                actions={[
                  <LinkButton
                    to="/add-funds"
                    token="ADD_FUNDS"
                    css={styles.addFunds}
                  />,
                ]}
              />
            )}

            <Flex vertical verticalAlignment="flex-end">
              <Detail token="TOTAL_ALLOCATION" />
              <Text currency={calculateRelocation() || 0} css={styles.price} />
              <Detail token="AVAILABLE_AFTER_ALLOCATION" />
              <Text
                currency={calculateTotalRelocation() || 0}
                css={styles.priceSmall}
              />
            </Flex>
            <FormFooter>
              <Button
                loading={loading}
                disabled={!maxToAllocate}
                token="CONFIRM"
              />
            </FormFooter>
          </>
        )
      }}
    </Form>
  )
}

const styles: TPlainStyle = {
  addFunds: {
    padding: 8,
  },
  text: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  price: {
    fontSize: '32px',
    marginBottom: 10,
  },
  priceSmall: {
    fontSize: '18px',
  },
}

export default AllocateForm
