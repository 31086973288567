import React from 'react'
import * as Yup from 'yup'
import Form from '../Form'

import AccountMembersOptionList from '../../lists/AccountMembersOptionList'

type SelectSidekickFormProps = {
  teamMembers?: object[]
  data?: object
  submit?: Function
  loading?: boolean
  disabled?: boolean
  allowNewMember?: boolean
  fieldName?: string
  handleFieldUpdate?: Function
}

const SelectSidekickForm: React.FC<SelectSidekickFormProps> = ({
  teamMembers,
  submit,
  loading,
  disabled,
  allowNewMember,
  fieldName,
  ...rest
}) => {
  const schema = Yup.object().shape({ [fieldName]: Yup.string().required() })
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ [fieldName]: '' }}
      {...rest}
    >
      <AccountMembersOptionList
        teamMembers={teamMembers}
        name={fieldName}
        allowNewMember={allowNewMember}
      />
    </Form>
  )
}

export default SelectSidekickForm
