import React from 'react'
import { useTheme } from '@emotion/react'

import Grid, { GridProps } from '../Grid'

export type ListProps = {} & GridProps

const List: React.FC<GridProps> = ({ children, className, ...rest }) => {
  const { theme, colors } = useTheme()
  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  return (
    <Grid css={styles.root} className={className} {...rest}>
      {children}
    </Grid>
  )
}

const computeStyles = ({ WHITE, BLACK_300 }) => ({
  root: {
    overflow: 'hidden',
  },
})

export default List
