import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

import SessionSplash from '../SessionSplash'

import useIsTOSAccepted from '../../hooks/useIsTOSAccepted'

const AcceptedTOSRoute = () => {
  const { data: isTosAccepted, loading } = useIsTOSAccepted()
  const { pathname } = useLocation()

  return (
    <>
      {isTosAccepted && <Outlet />}
      {loading && <SessionSplash />}
      {!isTosAccepted && !loading && (
        <Navigate to={`/onboarding/accept-tos?from=${pathname}`} />
      )}
    </>
  )
}

export default AcceptedTOSRoute
