import React from 'react'
import { Theme, useTheme } from '@emotion/react'

import List from './lists/List'
import Text from './typography/Text'
import BigMenuItem from './lists/items/BigMenuItem'
import useCurrentUser from '../hooks/useCurrentUser'

const PhysicalCardMenu = ({ card, ...rest }) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const { data: user } = useCurrentUser(true, card.cardholder.id)

  const { isBlockListEnabled } = user?.bankAccounts?.[0] || {}
  return (
    <List
      padding="1rem"
      gap="1.5rem"
      variants={animation.root}
      initial="hidden"
      animate="show"
      exit="exit"
      css={styles.root}
      {...rest}
    >
      <BigMenuItem
        label={<Text token="FREEZE_UNFREEZE_CARD" />}
        to={`/cards/freeze/${card.id}`}
        icon="lock"
        color={theme.CARD_MENU_ITEM_ICON_BACKGROUND}
        iconColor={theme.CARD_MENU_ITEM_ICON_COLOR}
        scale={1.429}
      />
      <BigMenuItem
        label={<Text token="CARD_TRANSACTION_LIMIT" />}
        to={`/cards/transaction-limits/${card.id}`}
        icon="exchange"
        color={theme.CARD_MENU_ITEM_ICON_BACKGROUND}
        iconColor={theme.CARD_MENU_ITEM_ICON_COLOR}
        scale={1}
      />
      <BigMenuItem
        label={<Text token="RETRIEVE_PIN" />}
        to={`/cards/retrieve-pin/${card.id}`}
        icon="calculator"
        color={theme.CARD_MENU_ITEM_ICON_BACKGROUND}
        iconColor={theme.CARD_MENU_ITEM_ICON_COLOR}
        scale={0.909}
      />
      <BigMenuItem
        label={<Text token="VIEW_CARD_DETAILS" />}
        to={`/cards/details/${card.id}`}
        icon="info"
        color={theme.CARD_MENU_ITEM_ICON_BACKGROUND}
        iconColor={theme.CARD_MENU_ITEM_ICON_COLOR}
        scale={1}
      />
      <BigMenuItem
        label={<Text token="BLOCKLIST" />}
        to={`/cards/blocklist/${card?.cardholder?.id}`}
        icon="keyholeCircle"
        color={
          isBlockListEnabled
            ? theme.CARD_MENU_ITEM_ICON_BACKGROUND_ALT
            : theme.CARD_MENU_ITEM_ICON_BACKGROUND
        }
        iconColor={
          isBlockListEnabled
            ? theme.CARD_MENU_ITEM_ICON_COLOR_ALT
            : theme.CARD_MENU_ITEM_ICON_COLOR
        }
        scale={1}
      />
      <BigMenuItem
        label={<Text token="REPLACE_CARD" />}
        to={`/cards/replace/${card?.cardholder?.id}/`}
        icon="ban"
        color={theme.CARD_MENU_ITEM_ICON_BACKGROUND}
        iconColor={theme.CARD_MENU_ITEM_ICON_COLOR}
        scale={1}
      />
    </List>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    border: `1px solid ${theme.CARD_BORDER}`,
    backgroundColor: theme.BACKGROUND_ALT,
    borderRadius: 10,
  },
})

const animation = {
  root: {
    hidden: {
      opacity: 0,

      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,

      transition: {
        duration: 0.2,
        staggerChildren: 0.025,
      },
    },
    exit: {
      y: -30,
      opacity: 0,

      transition: {
        duration: 0.1,
        staggerChildren: 0.025,
        staggerDirection: -1,
      },
    },
  },
}

export default PhysicalCardMenu
