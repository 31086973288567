import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'

const SwitchIcon = ({ size = 1, color = '#464E58', width, height }) => {
  const defaultWidth = 20
  const defaultHeight = 20

  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19C9 18.4477 9.44772 18 10 18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H10C9.44772 20 9 19.5523 9 19Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.87866C15.7026 2.87866 15.4174 2.9968 15.2071 3.20709L2.90299 15.5112L2.37439 17.6256L4.48877 17.097L16.7929 4.79288C16.897 4.68875 16.9796 4.56514 17.036 4.42909C17.0923 4.29305 17.1213 4.14724 17.1213 3.99998C17.1213 3.85273 17.0923 3.70692 17.036 3.57087C16.9796 3.43483 16.897 3.31121 16.7929 3.20709C16.6888 3.10296 16.5652 3.02037 16.4291 2.96402C16.2931 2.90767 16.1473 2.87866 16 2.87866ZM13.7929 1.79288C14.3783 1.20751 15.1722 0.878662 16 0.878662C16.4099 0.878662 16.8158 0.959397 17.1945 1.11626C17.5732 1.27312 17.9173 1.50303 18.2071 1.79288C18.497 2.08272 18.7269 2.42681 18.8837 2.8055C19.0406 3.1842 19.1213 3.59008 19.1213 3.99998C19.1213 4.40988 19.0406 4.81576 18.8837 5.19446C18.7269 5.57316 18.497 5.91725 18.2071 6.20709L5.70713 18.7071C5.57897 18.8352 5.41839 18.9262 5.24256 18.9701L1.24256 19.9701C0.901782 20.0553 0.541295 19.9555 0.292914 19.7071C0.0445341 19.4587 -0.055315 19.0982 0.0298787 18.7574L1.02988 14.7574C1.07384 14.5816 1.16476 14.421 1.29291 14.2929L13.7929 1.79288Z"
        fill={color}
      />
    </svg>
  )
}

export default SwitchIcon
