import React from 'react'
import useSubscriptions, {
  useIsValidSubscription,
} from '../../../hooks/useSubscriptions'
import SubscriptionSelect from './flows/subscriptions-select'
import SubscriptionsCurrent from './flows/subscriptions-current'

import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import SubscriptionsActions from './subscription-actions'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import isMobile from '../../../mobile/utils/isMobile'

export const flowName: string = 'subscriptions'

const SubscriptionsScene = ({ fromMobile = false, ...rest }) => {
  const { data, loading } = useSubscriptions()
  const {
    data: subscriptionValidData,
    loading: subscriptionValidLoading,
    refetch,
  } = useIsValidSubscription()

  const isValid = subscriptionValidData?.isValid
  const currentSubscription = data?.subscription

  React.useEffect((): any => {
    let listener
    if (isMobile()) {
      listener = App.addListener(
        'appUrlOpen',
        async (event: URLOpenListenerEvent) => {
          refetch()
        }
      )
    }
    return () => {
      if(listener && listener.remove) {
        listener.remove()
      }
    }
  }, [])

  return (
    <CardLayoutLink loading={loading || subscriptionValidLoading} {...rest}>
      <SubscriptionsActions
        fromMobile={fromMobile}
        visible={currentSubscription && isValid}
      />
      {isValid && (
        <SubscriptionsCurrent
          subscription={currentSubscription.subscriptionPlan}
        />
      )}
      {!!(data?.subscriptionPlans && !isValid) && (
        <SubscriptionSelect fromMobile={fromMobile} flowName={flowName} />
      )}
    </CardLayoutLink>
  )
}

export default SubscriptionsScene
