import React from 'react'

const usePortal = (type) => {
  const ref = React.useRef(null)

  const getElement = () => {
    if (!ref.current) {
      ref.current = document.getElementById(`portal-${type}`)
    }

    return ref.current
  }

  return getElement()
}

export default usePortal
