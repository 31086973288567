import React from 'react'

import Text, { TextProps } from './Text'

const Heading: React.FC<TextProps> = ({ ...rest }) => (
  <Text css={styles.root} {...rest} />
)

const styles = {
  root: {
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 300,
    letterSpacing: '0.5px',
    lineHeight: '32px',
  },
}

export default Heading
