import React from 'react'

import Form from '../Form'
import Field from '../Field'
import GenderSelectField from '../fields/GenderSelectField'
import FormButton from '../Button'
import FieldCard from '../../cards/FieldCard'
import DateField from '../fields/DateField'
import FormFooter from '../FormFooter'
import Text from '../../typography/Text'

import { cleanNullProps } from '../../../utils/functions'
import PhoneField from '../fields/PhoneField'

export type PersonalInformationFormProps = {
  user: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  hideContactInfo?: boolean
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  user,
  submit,
  disabled,
  loading,
  error,
  hideContactInfo,
  ...rest
}) => {
  const {
    id,
    givenName,
    middleNames,
    familyName,
    email,
    dateOfBirth,
    gender,
    locale,
    principalAddress,
    mobilePhoneNumber,
  } = user

  const { addressLine1, addressLine2, city, country, postalCode, region } =
    principalAddress || {}

  const defaultInitialValues = {
    id,
    givenName,
    middleNames,
    familyName,
    email,
    dateOfBirth,
    gender,
    locale,
    mobilePhoneNumber,
    principalAddress: {
      addressLine1,
      addressLine2,
      city,
      country,
      postalCode,
      region,
    },
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      disabled={disabled}
      loading={loading}
      initialValues={defaultInitialValues}
      {...rest}
    >
      <FieldCard label={<Text token="PERSONAL_INFORMATION" />}>
        <Field label={<Text token="FIRST_NAME" />} name="givenName" />
        <Field label={<Text token="MIDDLE_NAMES" />} name="middleNames" />
        <Field label={<Text token="LAST_NAME" />} name="familyName" />

        <GenderSelectField
          label={<Text token="WHAT_DO_YOU_IDENTIFY_AS" />}
          name="gender"
          as="select"
        />

        <DateField label={<Text token="DATE_OF_BIRTH" />} name="dateOfBirth" />
      </FieldCard>

      {!hideContactInfo && (
        <FieldCard label={<Text token="CONTACT_INFO" />}>
          <Field label={<Text token="EMAIL" />} name="email" disabled />
          <PhoneField
            label={<Text token="PHONE_NUMBER" />}
            name="mobilePhoneNumber"
          />
        </FieldCard>
      )}

      <FieldCard label={<Text token="ADDRESS" />}>
        <Field
          label={<Text token="ADDRESS_LINE_1" />}
          name="principalAddress.addressLine1"
        />
        <Field
          label={<Text token="ADDRESS_LINE_2" />}
          name="principalAddress.addressLine2"
        />
        <Field label={<Text token="CITY" />} name="principalAddress.city" />
        <Field
          label={<Text token="COUNTRY" />}
          name="principalAddress.country"
        />
        <Field
          label={<Text token="POSTAL_CODE" />}
          name="principalAddress.postalCode"
        />
        <Field label={<Text token="REGION" />} name="principalAddress.region" />
      </FieldCard>

      <FormFooter>
        <FormButton token="SAVE" loading={loading} />
      </FormFooter>
    </Form>
  )
}

export default PersonalInformationForm
