import React from 'react'
import find from 'lodash/find'
import get from 'lodash/get'

import FEATURES, { FeatureType } from '../utils/features'

import useCurrentUser from '../hooks/useCurrentUser'
import useTeamMembers from '../hooks/useTeamMembers'

export type WithFeatureProps = {
  feature: FeatureType
} & any

const withFeature =
  (Wrapped: any, hocFeature?: FeatureType) =>
  ({ feature, ...props }: WithFeatureProps) => {
    const { data: currentUser } = useCurrentUser()
    const { teamMembers } = useTeamMembers()

    const featureId = feature || hocFeature
    const hasTeamMembers = teamMembers?.length > 0

    if (featureId && currentUser && hasTeamMembers) {
      const foundFeature = get(FEATURES, featureId)

      if (foundFeature) {
        const foundRole = Boolean(
          find(foundFeature, (currentRole) => currentRole === currentUser.role)
        )

        if (!foundRole) return null
      }
    }

    return <Wrapped {...props} />
  }

export default withFeature
