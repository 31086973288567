import { useMutation, useQuery } from '@apollo/client'
import { addSideKick as addSideKickMutation } from '../queries/mutations'
import { teamMembers as teamMembersQuery } from '../queries'

const useAddSideKick = () => {
  const { data: dataTeamMembers } = useQuery(teamMembersQuery, {
    fetchPolicy: 'cache-first',
  })

  const queryOptions = {
    notifyOnNetworkStatusChange: true,
    update: (store, { data }) => {
      const teamMembers = Object.assign([], dataTeamMembers.teamMembers)
      teamMembers.push(data.addSideKick)
      const res = { ...dataTeamMembers, teamMembers }
      store.writeQuery({
        query: teamMembersQuery,
        data: res,
      })
    },
  }
  return useMutation(addSideKickMutation, queryOptions)
}

export default useAddSideKick
