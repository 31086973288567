import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import CardLayoutLink from '../../components/layout/CardLayoutLink'
import PasswordResetForm from '../../components/form/forms/PasswordResetForm'
import Info from '../../components/Info'
import { useSearchParams } from 'react-router-dom'

const PasswordResetScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [search] = useSearchParams()
  const from = search.get('from')
  const email = search.get('email')
  const error = useSelector(
    (state: { auth: { error: { message: string } } }) => state.auth.error
  )
  const [loading, setLoading] = React.useState(false)
  const [showInfo, setShowInfo] = React.useState(false)

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
      dispatch.auth.CLEAR_ERROR()
    }
  }, [error])

  const redirectURL = `${window.location.origin}/auth/new-password`
  const handleFormSubmit = async (values) => {
    setLoading(true)
    setShowInfo(true)
    await dispatch.auth.requestForgotPassword({
      email: values.email,
      redirectURL,
    })
    setLoading(false)
  }

  return (
    <CardLayoutLink
      loading={loading}
      error={error}
      {...rest}
      cancel={from || '/'}
    >
      <PasswordResetForm
        loading={loading}
        submit={handleFormSubmit}
        email={email}
      />

      {showInfo && <Info css={styles.info} token="SENT_RESET_EMAIL" />}
    </CardLayoutLink>
  )
}

const styles = {
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default PasswordResetScene
