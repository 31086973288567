import React from 'react'

import Grid from '../../Grid'
import Flex from '../../Flex'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'
import Detail from '../../typography/Detail'

import CategoriesDict from '../../../utils/categories'

const CategoryBalanceItem = ({
  amount = 0,
  category = 'general',
  showDate = false,
}) => {
  const CurrentCategory = React.useMemo(
    () => CategoriesDict[category],
    [category]
  )

  return (
    <Flex centerY gap="1rem" css={styles.root}>
      <IconCircle
        icon={CurrentCategory.icon}
        iconColor={CurrentCategory.iconColor}
        color={CurrentCategory.bgColor || 'RED_400'}
        size={32}
        scale={1}
      />
      <Grid gap={5} stretch>
        <Text
          css={styles.categoryName}
          token={CurrentCategory.displayName}
          capitalize
        />
        {showDate && <Detail date={Date.now()} />}
      </Grid>
      <Text currency={amount} />
    </Flex>
  )
}

const styles = {
  root: {},
  categoryName: {
    fontSize: '14px',
  },
}

export default CategoryBalanceItem
