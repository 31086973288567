import find from 'lodash/find'
import { IconType } from '../components/icons'
import { TranslationToken } from '../components/typography/Text'

export type CategoryDictionary = {
  displayName: TranslationToken
  icon: IconType
  iconColor: string
  bgColor: string
  modelKey: string
  size: number
  scale: number
  description: TranslationToken
}

export type CategoryType = {
  general: CategoryDictionary
  food: CategoryDictionary
  entertainment: CategoryDictionary
  travel: CategoryDictionary
  shopping: CategoryDictionary
  home: CategoryDictionary
}

export type PseudoCategoryType = {
  atm: CategoryDictionary
  vault: CategoryDictionary
}

const DEFAULT_ICON_COLOR = 'BLACK_900'
const iconColor = DEFAULT_ICON_COLOR

const Categories: CategoryType = {
  general: {
    displayName: 'mcc_category_defaultDisplayName_cat_001',
    icon: 'general',
    bgColor: 'CATEGORY_TURQUOISE',
    iconColor,
    modelKey: 'cat_001',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_001',
  },
  home: {
    displayName: 'mcc_category_defaultDisplayName_cat_002',
    icon: 'home',
    bgColor: 'CATEGORY_PURPLE',
    iconColor,
    modelKey: 'cat_002',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_002',
  },
  food: {
    displayName: 'mcc_category_defaultDisplayName_cat_003',
    icon: 'food',
    bgColor: 'CATEGORY_CYAN',
    iconColor,
    modelKey: 'cat_003',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_003',
  },
  travel: {
    displayName: 'mcc_category_defaultDisplayName_cat_004',
    icon: 'travel',
    bgColor: 'CATEGORY_SKY',
    iconColor,
    modelKey: 'cat_004',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_004',
  },
  entertainment: {
    displayName: 'mcc_category_defaultDisplayName_cat_005',
    icon: 'entertainment',
    bgColor: 'CATEGORY_ACQUAMARINE',
    iconColor,
    modelKey: 'cat_005',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_005',
  },
  shopping: {
    displayName: 'mcc_category_defaultDisplayName_cat_006',
    icon: 'shopping',
    bgColor: 'CATEGORY_BLUE',
    iconColor,
    modelKey: 'cat_006',
    size: 48,
    scale: 1.125,
    description: 'mcc_category_defaultDescription_cat_006',
  },
}

export const PseudoCategories: PseudoCategoryType = {
  atm: {
    displayName: 'ATM',
    icon: 'moneyWithdrawal',
    bgColor: 'CATEGORY_YELLOW',
    iconColor,
    modelKey: 'atm',
    size: 48,
    scale: 1.125,
    description: 'ATM_CATEGORY_DESCRIPTION',
  },
  vault: {
    displayName: 'VAULT',
    icon: 'lock',
    bgColor: 'CATEGORY_LIGHT_TURQUOISE',
    iconColor,
    modelKey: 'vault',
    size: 48,
    scale: 1.125,
    description: 'VAULT_CATEGORY_DESCRIPTION',
  },
}

export default Categories

export const CategoriesKeys = Object.keys(Categories)
export const PseudoCategoriesKeys = Object.keys(PseudoCategories)

export const getCategoryDictByKey: CategoryDictionary | any = (key: string) => {
  return find(Categories, (cat) => cat.modelKey === key)
}
export const getCategoryModelKeyByName: CategoryDictionary | any = (
  name: string
) => {
  return find(Categories, (cat, key) => key === name)?.modelKey
}
