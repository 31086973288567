import Amplify, { Auth } from 'aws-amplify'
import { validatePhoneNumber } from '../utils/functions'
import { setOneSignalExternalId } from '../mobile/onesignal/pushNotifications'
import {
  setCredentials,
  deleteCredentials,
  isFirstLogin,
  setFirstLogin,
} from '../mobile/biometric/biometric'

export const signIn = async (email, password) => {
  try {
    if (isFirstLogin()) setFirstLogin()
    const user = await Auth.signIn(email, password)
    if (user) {
      await setOneSignalExternalId(user.username)
    }
    await setCredentials(email, password)
    return user
  } catch (error) {
    await deleteCredentials()
    throw new Error('Incorrect username or password.')
  }
}

export const signUp = async (payload) => {
  try {
    const {
      username,
      firstName,
      lastName,
      email,
      password,
      locale,
      gender,
      dateOfBirth,
      phoneNumber,
    } = payload

    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        locale: locale || navigator.language || 'en-CA',
        gender,
        birthdate: dateOfBirth,
        phone_number: validatePhoneNumber(phoneNumber),
      },
    })
    return user
  } catch (error) {
    throw new Error(error.message)
  }
}

export const confirmSignUp = async (username, code) => {
  try {
    await Auth.confirmSignUp(username, code)
    return true
  } catch (error) {
    throw new Error(error.message)
  }
}

export const resendInvitation = async (username) => {
  try {
    await Auth.resendSignUp(username)
  } catch (error) {
    throw new Error(error.message)
  }
}

export const forgotPassword = async (email, redirectURL) => {
  try {
    await Auth.forgotPassword(email, { redirectURL })
  } catch (error) {
    throw new Error(error.message)
  }
}

export const forgotPasswordSubmit = async (username, code, password, event) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, password, { event })
  } catch (error) {
    throw new Error(error.message)
  }
}

export const signOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCognitoSession = async () => {
  try {
    const session = await Auth.currentSession()
    return session
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchCognitoUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateUserPassword = async (oldPassword, password) => {
  try {
    const user = await fetchCognitoUser()
    await Auth.changePassword(user, oldPassword, password)
    return true
  } catch (error) {
    throw new Error(error.message)
  }
}

export const customSignIn = async (username, token) => {
  Amplify.configure({
    Auth: {
      authenticationFlowType: 'CUSTOM_AUTH',
    },
  })

  try {
    let user = await Auth.signIn(username)
    if (user.challengeName === 'CUSTOM_CHALLENGE') {
      user = await Auth.sendCustomChallengeAnswer(user, token)
    }
    return true
  } catch (error) {
    throw new Error(error.message)
  }
}
