import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import PersonalInformationForm from '../../../components/form/forms/PersonalInformationForm'

import { getUserProfile, updateUserProfile } from '../../../queries'

const SettingsProfilePersonalInformationScene = ({ ...rest }) => {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(getUserProfile)
  const [save, { loading: saving, error: saveError }] =
    useMutation(updateUserProfile)

  const handleSubmit = async (formData) => {
    await save({ variables: formData })
    navigate('/settings/profile')
  }

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      {data && (
        <PersonalInformationForm
          user={data.userProfile}
          submit={handleSubmit}
          loading={saving}
          error={saveError}
        />
      )}
    </CardLayoutLink>
  )
}

export default SettingsProfilePersonalInformationScene
