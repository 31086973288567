import useCurrentUser from './useCurrentUser'

const useIsTOSAccepted = () => {
  const { data: user, loading } = useCurrentUser(true, undefined, true)

  const isTosAccepted = user?.userProfile?.hasAcceptedTOS

  return {
    data: isTosAccepted,
    loading,
  }
}

export default useIsTOSAccepted
