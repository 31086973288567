import React from 'react'
import * as Yup from 'yup'

import FormButton from '../Button'
import ButtonGroup from '../../ButtonGroup'
import Form from '../Form'
import FormFooter from '../FormFooter'

import RadioField from '../fields/RadioField'
import { Theme, useTheme } from '@emotion/react'
import Text from '../../typography/Text'

type ChooseOrderCardFormProps = {
  handleFieldUpdate?: Function
  submit?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}

const schema = Yup.object().shape({ owner: Yup.string().required() })

const defaultInitialValues = { orderCard: 'now' }

const ChooseOrderCardForm: React.FC<ChooseOrderCardFormProps> = ({
  submit,
  loading,
  disabled,
  data,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues, ...data }}
      {...rest}
    >
      {({ values }) => (
        <>
          <RadioField
            css={styles.field}
            label={<Text token="ORDER_CARD_NOW" />}
            name="orderCard"
            value="now"
          />

          <RadioField
            css={styles.field}
            label={<Text token="ORDER_CARD_LATER" />}
            name="orderCard"
            value="later"
          />

          <FormFooter>
            <ButtonGroup>
              <FormButton
                label={<Text token={'NEXT'} />}
                disabled={!values.orderCard}
              />
            </ButtonGroup>
          </FormFooter>
        </>
      )}
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  sectionTitle: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.25,
    lineHeight: '24px',
    margin: '0',
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
    '&.is-disabled.is-radio': {
      backgroundColor: theme.DISABLED_BACKGROUND,
    },
  },
  footer: {
    backgroundColor: theme.BACKGROUND,
  },
})

export default ChooseOrderCardForm
