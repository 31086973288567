import React from 'react'

import { Theme, useTheme } from '@emotion/react'
import Flex from '../Flex'
import Pill, { PillProps } from '../Pill'
import { ReactI18NextChild } from 'react-i18next'
import clsx from 'clsx'

export type StatusCardProps = {
  label?: string | ReactI18NextChild
  status?: string | ReactI18NextChild
  className?: string
} & PillProps

const StatusCard: React.FC<StatusCardProps> = ({
  label,
  status,
  className,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const classNames = clsx({
    [className]: className,
  })
  return (
    <Flex css={styles.root} className={classNames} spread>
      {label && label}
      <Pill {...rest}>{status}</Pill>
    </Flex>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    color: theme.TEXT_PRIMARY_DARK,
    fontSize: 14,
    padding: '20px 26px',
    textDecoration: 'none',
    width: '100%',
  },
})

export default StatusCard
