import React from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'

import ComponentCard, { ComponentCardProps } from '../../cards/ComponentCard'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'
import Flex from '../../Flex'

type CreditCardItemProps = ComponentCardProps & {
  value?: string
  label?: string | JSX.Element
  funding?: any
  className?: string
  loading?: boolean
}

const brandIconMapping = {
  default: 'card',
  mastercard: 'mastercardMono',
  visa: 'visaMono',
}

const CreditCardItem: React.FC<CreditCardItemProps> = ({
  funding,
  value,
  className,
  loading,
  ...rest
}) => {
  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
  })

  const { brand, network_type, last_four_digits, name } = funding || {}

  return (
    <ComponentCard css={styles.root} className={classNames} {...rest}>
      <IconCircle
        css={styles.image}
        icon={brandIconMapping[brand || network_type || 'default']}
        size={56}
        iconWidth={32}
        color={theme.FUNDING_METHOD_OPTION_ICON_BACKGROUND}
        iconColor={theme.FUNDING_METHOD_OPTION_ICON_COLOR}
      />
      <Flex css={styles.content} vertical centerX>
        <Text
          loading={loading}
          css={styles.label}
          token={brand || network_type || 'CARD'}
        ></Text>
        <Text
          loading={loading}
          css={styles.label}
          token="HIDDEN_CARD_NUMBER"
          interpolation={{ lastDigits: last_four_digits }}
        />
        <Text
          loading={loading}
          css={styles.label}
          token="FUNDER_PERSONALIZED"
          interpolation={{ name: name }}
        />
      </Flex>
    </ComponentCard>
  )
}

const computeStyles: TComputeStyles = (
  { TEXT_PRIMARY }: TTheme,
  { FIELD_PADDING, FIELD_LABEL_FONT_SIZE, FIELD_FONT_WEIGHT }: TSizes
) => ({
  root: {
    color: TEXT_PRIMARY,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    gridTemplateColumns: '60px 1fr',
    padding: FIELD_PADDING,
  },
  image: {
    alignSelf: 'center',
    gridColumnStart: 1,
    gridRowStart: 1,
    justifySelf: 'center',
  },
  content: {
    fontStyle: 'normal',
    fontWeight: FIELD_FONT_WEIGHT,
    gridColumnStart: 2,
    gridRowStart: 1,
  },
  label: {
    fontSize: '16px',
    letterSpacing: '-0.1px',
    lineHeight: '24px',
  },
})

export default CreditCardItem
