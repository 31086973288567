import React from 'react'
import { useNavigate } from 'react-router-dom'
import { History } from 'swiper'
import { Swiper as SwiperProps } from 'swiper/types'
import { TPlainStyle } from '@emotion/react'

import Carousel from '../../components/Carousel'
import AccountCard from '../../components/cards/AccountCard'
import useTeamMembers from '../../hooks/useTeamMembers'

type HeaderSKCarouselProps = {}

const historyConfig = {
  keepQuery: true,
  replaceState: true,
  key: 'accounts',
}

const HeaderSKCarousel: React.FC<HeaderSKCarouselProps> = () => {
  const navigate = useNavigate()
  const { teamMembers } = useTeamMembers(true, true)

  const changeSlide = (e: SwiperProps) => {
    const id = teamMembers[e.activeIndex]?.userProfile?.id
    if (id) navigate(`/accounts/${id}`)
  }

  return (
    <Carousel
      style={styles.root}
      modules={[History]}
      onSlideChangeTransitionEnd={changeSlide}
      history={historyConfig}
    >
      {teamMembers &&
        teamMembers.map(({ userProfile, bankAccounts }) => (
          <AccountCard
            disableLink
            key={userProfile.id}
            user={userProfile}
            bankAccount={bankAccounts?.[0]}
            to={userProfile.id}
          />
        ))}
    </Carousel>
  )
}

const styles: TPlainStyle = {
  root: {
    left: 0,
    width: '100%',
    zIndex: 1000,
  },
}

export default HeaderSKCarousel
