import { gql } from '@apollo/client'

export const subscribeToUserNotifications = gql`
  subscription Subscription($userId: ID!) {
    subscribeToUserNotifications(userId: $userId) {
      userId
      id
    }
  }
`
