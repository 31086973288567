import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import find from 'lodash/find'

import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import PersonalInformationForm from '../../../components/form/forms/PersonalInformationForm'

import { teamMembers, updateUserProfile } from '../../../queries'
import useCurrentUser from '../../../hooks/useCurrentUser'

const SettingsMemberPersonalInformationScene = ({ ...rest }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(teamMembers)
  const [save, { loading: saving, error: saveError }] =
    useMutation(updateUserProfile)

  const { data: hero } = useCurrentUser(true)

  const user = find(
    data?.teamMembers,
    (member) => member?.userProfile?.id === id
  )

  const handleSubmit = async (formData) => {
    await save({ variables: formData })
    toast.success(<Text token="info_code_ProfileUpdated" />)
    navigate('/settings/team')
  }

  const hideContactInfo = user?.userProfile?.email?.includes?.(
    hero?.userProfile?.id
  )

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      {user && (
        <PersonalInformationForm
          user={user.userProfile}
          submit={handleSubmit}
          loading={saving}
          error={saveError}
          hideContactInfo={hideContactInfo}
        />
      )}
    </CardLayoutLink>
  )
}

export default SettingsMemberPersonalInformationScene
