import React from 'react'
import { AnimatePresence } from 'framer-motion'
import Icon from './Icon'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Flex from './Flex'
import clsx from 'clsx'
import Grid from './Grid'
import { IconType } from './icons'
import Text, { TranslationToken } from './typography/Text'

export type BannerProps = {
  dismissable?: boolean
  className?: string
  icon?: IconType
  actions?: React.ReactNode[]
  token?: TranslationToken
  color?: string
  interpolation?: any
  capitalize?: boolean
  iconSize?: number
  label?: TranslationToken | JSX.Element
}

export type Props = BannerProps & React.PropsWithChildren

const Banner: React.FC<Props> = ({
  actions,
  capitalize = false,
  children,
  className,
  color,
  dismissable,
  icon,
  iconSize = 20,
  interpolation,
  label,
  token,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, color), [theme])
  const [hide, setHide] = React.useState(false)

  const classNames = clsx({
    [className]: className,
  })

  const labelIsString = typeof label === 'string'
  const labelComponent = labelIsString ? (
    <Text css={styles.label} token={label} />
  ) : (
    label
  )
  const hasLabel = !!label

  return (
    <AnimatePresence>
      {!hide && (
        <Grid
          transition={{ duration: 0.2 }}
          initial={{ scaleX: 1 }}
          exit={{ scaleX: 0 }}
          css={styles.root}
          className={classNames}
        >
          {icon && (
            <Flex vertical css={styles.iconContainer}>
              <Icon icon={icon} color={color} width={iconSize} />
            </Flex>
          )}
          <Flex vertical nowrap fixChildrenY css={styles.content} spread>
            {hasLabel && labelComponent}
            {token && (
              <Text
                token={token}
                css={styles.notificationToken}
                interpolation={interpolation}
                capitalize={capitalize}
              />
            )}
            {children}
            {actions && (
              <Flex
                horizontalAlignment="flex-end"
                css={styles.actionsContainer}
              >
                {actions}
              </Flex>
            )}
          </Flex>
          {dismissable && (
            <button onClick={() => setHide(true)} css={styles.dismissButton}>
              <Icon icon="times" />
            </button>
          )}
        </Grid>
      )}
    </AnimatePresence>
  )
}

const computeStyles: TComputeStyles = (
  { BACKGROUND_ALT, PRIMARY, TEXT_PRIMARY }: TTheme,
  borderColor
) => ({
  root: {
    alignItems: 'flex-start',
    backgroundColor: BACKGROUND_ALT,
    borderLeft: `4px solid ${borderColor || PRIMARY}`,
    borderRadius: 4,
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-between',
    overflow: 'hidden',
    transformOrigin: '0 0',
  },
  iconContainer: {
    justifyContent: 'flex-start',
    paddingLeft: 10,
    paddingTop: 10,
  },
  content: {
    minHeight: 56,
    padding: 10,
    width: '100%',
  },
  label: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '-0.25px',
    lineHeight: '20px',
    marginBottom: 8,
  },
  notificationToken: {
    color: TEXT_PRIMARY,
    fontSize: 14,
    overflow: 'hidden',
  },
  actionsContainer: {
    marginTop: '1rem',
    width: '100%',
  },
  dismissButton: {
    background: 'none',
    border: 'none',
    padding: 14,
  },
})

export default Banner
