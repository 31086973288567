import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'

const SubscriptionDeeplink = ({ ...rest }) => {
  const deeplinkURL: URL = new URL(
    process.env.REACT_APP_WEB_DOMAIN || 'https://portal.herofinancials.app'
  )
  const url = `hero://${deeplinkURL.hostname}`
  const handleRedirection = () => {
    window.location.assign(url)
  }

  React.useEffect(() => {
    handleRedirection()
  }, [])
  return (
    <CardLayoutLink {...rest}>
      <Flex vertical verticalAlignment="center" horizontalAlignment="center">
        <Text css={styles.text} token="notification_SubscriptionUpdated" />
        <Button token="BACK_TO_THE_APP" onClick={handleRedirection} />
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  text: {
    marginBottom: 10
  }
}

export default SubscriptionDeeplink
