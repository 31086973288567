import { QueryHookOptions, QueryResult } from '@apollo/client'
import useCards from './useCards'

import find from 'lodash/find'

const useCardByUser = (userId, variables?: QueryHookOptions): QueryResult => {
  const { data: cards, loading, error, ...rest } = useCards(variables)

  const foundCard = find(
    cards?.cards,
    (card) => card?.cardholder?.id === userId
  )

  return { data: foundCard, loading, error, ...rest }
}

export default useCardByUser
