import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { Theme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import Header from '../../typography/Header'
import Field from '../Field'
import PasswordRulesDescription from '../../PasswordRulesDescription'
import PasswordStrength from '../../PasswordStrength'
import { validators } from '../../../utils/constants'
import PasswordField from '../fields/PasswordField'
import Text from '../../typography/Text'

export type BasicInfoAndPasswordFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
}

const schema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string()
    .test((value) => validators.PASSWORD.test(value))
    .required(),
})

const BasicInfoAndPasswordForm: React.FC<BasicInfoAndPasswordFormProps> = ({
  data = {},
  submit,
  loading,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { firstName, lastName, email, password } = data

  const defaultInitialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    password: password || '',
  }

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      {(form) => (
        <>
          <Field
            label={<Text token="FIRST_NAME" />}
            name="firstName"
            css={styles.field}
            required
          />
          <Field
            label={<Text token="LAST_NAME" />}
            name="lastName"
            css={styles.field}
            required
          />
          <Field
            label={<Text token="EMAIL_ADDRESS" />}
            name="email"
            css={styles.field}
            required
          />

          <Header token="CREATE_PASSWORD" css={styles.sectionTitle} />

          <PasswordField
            label={<Text token="PASSWORD" />}
            name="password"
            css={styles.field}
            required
          />

          <PasswordStrength password={form?.values?.password || ''} />

          <PasswordRulesDescription />
          <FormFooter css={styles.footer}>
            <FormButton label={<Text token="NEXT" />} />
          </FormFooter>
        </>
      )}
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  sectionTitle: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.25,
    lineHeight: '24px',
    margin: '0',
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: theme.BACKGROUND,
  },
})

export default BasicInfoAndPasswordForm
