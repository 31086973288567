import React from 'react'
import { motion } from 'framer-motion'

import Spinner, { SpinnerProps } from './Spinner'

const AnimatedSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <motion.div
      initial={{ scale: 0.5 }}
      exit={{ scale: 0 }}
      animate={{ scale: 1 }}
    >
      <Spinner {...props} />
    </motion.div>
  )
}

export default AnimatedSpinner
