import React from 'react'
import { useTheme } from '@emotion/react'

const ApplicationContainer: React.FC<React.PropsWithChildren> = ({
  children,
  className,
}) => {
  const { colors, theme } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  return (
    <div css={styles.root} className={className}>
      <div css={styles.container}>{children}</div>
    </div>
  )
}

const computeStyles = ({ BACKGROUND }) => ({
  root: {
    backgroundColor: BACKGROUND,
    display: 'flex',
    justifyContent: 'center',
  },

  container: {
    maxWidth: 1200,
    width: '100%',
  },
})

export default ApplicationContainer
