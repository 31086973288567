import React from 'react'
import { motion } from 'framer-motion'

import Activity from './Activity'
import Icon from './Icon'
import IconButton from './IconButton'
import Flex from './Flex'

const Header = () => {
  return (
    <header css={styles.root}>
      <motion.div
        initial={{ scale: 1.07 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.1 }}
      >
        <Icon icon="brand_gradient" size={1.15} css={{ marginLeft: '.5rem' }} />
      </motion.div>
      <Flex as="aside">
        {/* <LanguageSelector /> */}
        <Activity />
        <IconButton to="/settings" icon="cog" size={0.909} />
      </Flex>
    </header>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridArea: 'header',
  },
}

export default Header
