import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const ExchangeIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#507FB4',
  width,
  height,
}) => {
  const defaultWidth = 20
  const defaultHeight = 16
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.05724 4.64568L2.19918 5.5H3.41002H15C15.1326 5.5 15.2598 5.55268 15.3536 5.64645C15.4473 5.74021 15.5 5.86739 15.5 6C15.5 6.13261 15.4473 6.25979 15.3536 6.35355C15.2598 6.44732 15.1326 6.5 15 6.5H1.00148C0.90313 6.49931 0.807157 6.46962 0.725583 6.41465C0.643722 6.35948 0.580023 6.28132 0.542513 6.19001L0.542529 6.19L0.540924 6.18618C0.502635 6.09513 0.492173 5.99477 0.51086 5.89778C0.529462 5.80123 0.576115 5.71232 0.644972 5.64215L4.64357 1.64355L4.29002 1.29L4.64357 1.64355C4.73811 1.54902 4.86632 1.49591 5.00002 1.49591C5.13371 1.49591 5.26193 1.54902 5.35646 1.64355C5.451 1.73809 5.50411 1.86631 5.50411 2C5.50411 2.13355 5.45111 2.26164 5.35677 2.35615C5.35666 2.35625 5.35656 2.35635 5.35646 2.35645L3.05724 4.64568ZM19.4575 9.80999L19.4575 9.81L19.4591 9.81382C19.4974 9.90487 19.5079 10.0052 19.4892 10.1022C19.4706 10.1988 19.4239 10.2877 19.3551 10.3578C19.3548 10.3581 19.3544 10.3585 19.3541 10.3588L15.3565 14.3564L15.355 14.3579C15.3085 14.4048 15.2532 14.442 15.1923 14.4673C15.1314 14.4927 15.066 14.5058 15 14.5058C14.934 14.5058 14.8687 14.4927 14.8077 14.4673C14.7468 14.442 14.6915 14.4048 14.645 14.3579L14.6421 14.355C14.5953 14.3085 14.5581 14.2532 14.5327 14.1923C14.5073 14.1314 14.4942 14.066 14.4942 14C14.4942 13.934 14.5073 13.8686 14.5327 13.8077C14.5581 13.7468 14.5953 13.6915 14.6421 13.645L14.6428 13.6443L16.9428 11.3543L17.8009 10.5H16.59H5.00002C4.86741 10.5 4.74023 10.4473 4.64646 10.3536C4.5527 10.2598 4.50002 10.1326 4.50002 10C4.50002 9.86739 4.5527 9.74022 4.64646 9.64645C4.74023 9.55268 4.86741 9.5 5.00002 9.5H18.9986C19.0969 9.50071 19.1929 9.53039 19.2745 9.58535C19.3563 9.64052 19.42 9.71868 19.4575 9.80999Z"
        fill={color}
        stroke={color}
      />
    </svg>
  )
}

export default ExchangeIcon
