import { useMutation, useQuery } from '@apollo/client'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import React from 'react'
import Flex from '../../components/Flex'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import OrderCardSummaryForm from '../../components/form/forms/OrderCardSummaryForm'
import { SHIPPING_METHOD } from '../../components/form/forms/ShippingAddressForm'
import Header from '../../components/typography/Header'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import {
  getUserProfile,
  orderCard as orderCardMutation,
  teamMembers,
} from '../../queries'

const SIDEKICK_CARD_ACCOUNT = 'SideKickCardAccount'
const PHONE_BOX_SIMCARD = 'PhoneBoxSimCard'

const SummaryStep = ({ ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { currentData, next, setFlowData } = React.useContext(FlowContext)
  const { data: user } = useQuery(getUserProfile)
  const { loading: teamMembersLoading, data: teamMembersData } =
    useQuery(teamMembers)

  const [orderCard, { loading }] = useMutation(orderCardMutation)

  const sk =
    teamMembersData.teamMembers.find(
      (acc) => acc.userProfile?.id === currentData.data.forUser
    ) || user

  const handleSubmit = async (formData) => {
    const { simcard, ...rest } = currentData.data
    const variables = {
      ...rest,
      bankAccountType: SIDEKICK_CARD_ACCOUNT,
      addOns: simcard ? [PHONE_BOX_SIMCARD] : [],
    }

    const newCard = await orderCard({ variables })
    await setFlowData(formData)
    if (newCard) next()
  }

  const isRush = currentData?.data.shippingMethod === SHIPPING_METHOD.RUSH_TRACK

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout>
      <Text
        css={styles.legend}
        token="PLEASE_CHECK_BEFORE_CONFIRMING_YOUR_ORDER"
      />
      <OrderCardSummaryForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data, sk: sk?.userProfile }}
        loading={loading || teamMembersLoading}
        {...rest}
      />
      <Flex vertical gap="1rem">
        {isRush && (
          <>
            <Header as="h3" css={styles.rushHeader} token="RUSH_DELIVERY_FEE" />
            <Text
              css={styles.rushDescription}
              token="RUSH_DELIVERY_FEE_WILL_BE_DEDUCTED"
            />
          </>
        )}
        <Text
          css={styles.rushShippingLabel}
          token={isRush ? 'RUSH_SHIPPING' : 'FREE_SHIPPING'}
        />
        <Text
          css={styles.shippingAmount}
          token="CURRENCY_WITH_SIGN"
          interpolation={{ amount: isRush ? 25 : 0 }}
        />
        <Text css={styles.totalLabel} token="TOTAL" />
        <Text
          css={styles.totalAmount}
          token="CURRENCY_WITH_SIGN"
          interpolation={{ amount: isRush ? 25 : 0 }}
        />
      </Flex>
    </FlowStepLayout>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: {},
  legend: {
    fontSize: '14px',
    color: TEXT_PRIMARY,
  },
  rushHeader: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '.5px',
    lineHeight: '32px',
    margin: 0,
    marginBottom: '16px',
  },
  rushDescription: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    margin: 0,
    wordBreak: 'break-word',
  },
  rushShippingLabel: {
    alignSelf: 'end',
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  shippingAmount: {
    alignSelf: 'end',
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '20px',
    letterSpacing: '-.25px',
    wordBreak: 'break-word',
  },
  totalLabel: {
    alignSelf: 'end',
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  totalAmount: {
    alignSelf: 'end',
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '48px',
    fontWeight: 300,
    letterSpacing: '-.25px',
    wordBreak: 'break-word',
  },
})

export default SummaryStep
