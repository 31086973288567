import React from 'react'
import Card from '../../../../../components/cards/Card'
import FlowContext from '../../../../../context/FlowContext'
import Element from '../../../../../components/Element'
import useTrulioo from '../../../../../hooks/useTrulioo'
import HeroSpinner from '../../../../../components/Spinner'

const KYCFlowStepData: React.FC = () => {
  const { next, setFlowData } = React.useContext(FlowContext)
  const onTruliooComplete = async (status) => {
    await setFlowData({ status })
    next()
  }
  const { loading } = useTrulioo(onTruliooComplete)
  return (
    <Card css={styles.root} loading={loading}>
      <Element css={styles.loader}>
        <HeroSpinner />
      </Element>
      <Element css={styles.wrapper} id="trulioo-embedid"></Element>
    </Card>
  )
}

const styles = {
  root: {
    position: 'relative',
  },
  wrapper: {
    position: 'relative',
    zIndex: 10,
    '& > iframe': {
      height: 'calc(100vh - 98px) !important',
    },
  },
  loader: {
    position: 'absolute',
    right: 'calc(50vw - 50px)',
    bottom: '50vh',
    zIndex: 1,
  },
}

export default KYCFlowStepData
