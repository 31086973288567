import * as COLORS from '../colors'
import * as BREAKPOINTS from '../breakpoints'

import DarkTheme from './dark'
import DefaultTheme from './default'

export * as BREAKPOINTS from '../breakpoints'
export * as COLORS from '../colors'

const Themes = {
  default: DefaultTheme,
  dark: DarkTheme,
}

export type ColorType = keyof typeof COLORS
export type BreakpointType = keyof typeof BREAKPOINTS

export default Themes
