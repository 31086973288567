import React from 'react'

import Element from '../Element'

const SceneZoomInAnimation = ({ children, as = 'main', ...rest }) => (
  <Element
    as={as}
    initial={{ scale: 1.05 }}
    animate={{ scale: 1 }}
    transition={{ duration: 0.15, ease: 'easeOut' }}
    {...rest}
  >
    {children}
  </Element>
)

export default SceneZoomInAnimation
