import React from 'react'
import { motion } from 'framer-motion'

const SessionSplash = () => {
  return (
    <motion.div
      css={styles.root}
      initial={{ scale: 1.1 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 0.75 }}
        transition={{ duration: 0.2 }}
        css={styles.logo}
      ></motion.div>
    </motion.div>
  )
}

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url("/images/bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  logo: {
    width: 264,
    height: 140,

    background: 'url("/images/hero-logo.gif")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
}

export default SessionSplash
