import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import React from 'react'
import Element from '../../Element'
import Flex from '../../Flex'
import IconCircle from '../../IconCircle'
import { IconType } from '../../icons'
import Paragraph from '../../typography/Paragraph'
import Text from '../../typography/Text'

import RadioField, { RadioFieldProps } from './RadioField'

type ProductOptionFieldProps = {
  type?: 'local' | 'international'
  label?: string | JSX.Element
  subtitle?: string
  className?: string
} & RadioFieldProps

type TIconCircleProps = {
  [key: string]: {
    icon: IconType
    scale: number
    color: string
    iconColor: string
  }
}

const iconCircleProps: TIconCircleProps = {
  local: {
    icon: 'leaf',
    scale: 0.737,
    color: 'BLUE_200',
    iconColor: 'BLACK_900',
  },
  international: {
    icon: 'globe',
    scale: 1.17,
    color: 'TURQUOISE_300',
    iconColor: 'BLACK_900',
  },
}

const ProductOptionField: React.FC<ProductOptionFieldProps> = ({
  label,
  subtitle,
  type = 'local',
  className,
  disabled,
  ...rest
}) => {
  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
  })

  const isLocal = type === 'local'

  return (
    <Element>
      <RadioField css={styles.root} className={classNames} {...rest}>
        <IconCircle css={styles.image} size={56} {...iconCircleProps[type]} />
        <Flex css={styles.content} vertical centerX>
          <div css={styles.label}>
            {label || (
              <Text
                token={
                  isLocal
                    ? 'SIDEKICK_CARD_CANADA'
                    : 'SIDEKICK_CARD_INTERNATIONAL'
                }
              />
            )}
          </div>
          <div css={styles.subtitle}>{subtitle}</div>
        </Flex>
      </RadioField>
      <Paragraph
        css={styles.description}
        token={
          isLocal
            ? 'SIDEKICK_CARD_CANADA_DESCRIPTION'
            : 'SIDEKICK_CARD_INTERNATIONAL_DESCRIPTION'
        }
      />
    </Element>
  )
}

const computeStyles: TComputeStyles = (
  { FIELD_BORDER, TEXT_PRIMARY, TEXT_PRIMARY_DARK }: TTheme,
  { FIELD_PADDING, FIELD_FONT_WEIGHT, FIELD_BORDER_RADIUS }: TSizes
) => ({
  root: {
    color: TEXT_PRIMARY,
    fontWeight: FIELD_FONT_WEIGHT,
    gridTemplateColumns: '60px 1fr 13px',
    padding: FIELD_PADDING,

    '&.is-radio': {
      border: `2px solid ${FIELD_BORDER}`,
      borderRadius: FIELD_BORDER_RADIUS,
      display: 'grid',
      gridTemplateColumns: '60px 1fr 20px',
    },
    '& > input': {
      gridColumnStart: -2,
    },
  },
  image: {
    alignSelf: 'center',
    gridColumnStart: 1,
    gridRowStart: 1,
    justifySelf: 'center',
  },
  content: {
    fontStyle: 'normal',
    fontWeight: FIELD_FONT_WEIGHT,
    gridColumnStart: 2,
    gridRowStart: 1,
  },
  label: {
    color: TEXT_PRIMARY_DARK,
    fontSize: '16px',
    letterSpacing: '-0.1px',
    lineHeight: '24px',
  },
  subtitle: {
    fontSize: '14px',
    letterSpacing: '-0.25px',
    lineHeight: '20px',
  },
  description: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.25px',
    margin: '8px 0 0 0',
  },
})

export default ProductOptionField
