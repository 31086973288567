import React from 'react'

import Text, { TextProps } from './Text'

const SmallText: React.FC<TextProps> = ({ ...rest }) => {
  return <Text css={styles.root} {...rest} />
}

const styles = {
  root: {
    fontSize: 12,
  },
}

export default SmallText
