import React from 'react'

import LayoutContext from '../../context/LayoutContext'

const MainLayoutLink = ({ loading, error, children }) => {
  const { setLoading, setError } = React.useContext(LayoutContext)

  React.useEffect(() => {
    setLoading(loading)
    setError(error)
  }, [loading, error])

  return children
}

export default MainLayoutLink
