import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'

import Flex from '../../Flex'
import FormFooter from '../FormFooter'
import Text from '../../typography/Text'

type TermsOfServicesFormProps = {
  loading?: boolean
  submit: Function
}

const TermsOfServicesForm: React.FC<TermsOfServicesFormProps> = ({
  loading,
  submit,
}) => {
  const schema = Yup.object().shape({
    accept: Yup.bool().isTrue().required(),
  })

  return (
    <Form
      initialValues={{
        accept: false,
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
    >
      {(form) => (
        <FormFooter>
          <Flex centerY gap="1rem">
            <input
              type="checkbox"
              id="accept"
              name="accept"
              onChange={(e) => form.setFieldValue('accept', e.target.checked)}
            />
            <label htmlFor="accept">
              <Text token="AGREE_TERMS_OF_SERVICE" />
            </label>
          </Flex>
          <FormButton label="Agree" css={styles.submit} loading={loading} />
        </FormFooter>
      )}
    </Form>
  )
}

const styles = {
  root: { width: '100%' },
}

export default TermsOfServicesForm
