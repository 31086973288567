import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useIsValidSubscription } from '../../../hooks/useSubscriptions'
import useAddSideKick from '../../../hooks/useAddSidekick'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import isMobile from '../../../mobile/utils/isMobile'

import ConfirmModal from '../../../components/modals/ConfirmModal'
import FlowCardLayout, {
  FlowStep,
} from '../../../components/layout/FlowCardLayout'

import SelectSidekickStep from './select-sidekick'
import AddMemberStep from './add-member'
import OrderCardStep from './order-card'
import SelectProductStep from './select-product'

import usePrompt from '../../../hooks/usePrompt'
import { teamMembers as teamMembersQuery } from '../../../queries'

import { AnimatePresence } from 'framer-motion'
import Text from '../../../components/typography/Text'

import SubscriptionSelect from '../../settings/subscriptions/flows/subscriptions-select'
import SubscriptionPayment from '../../settings/subscriptions/flows/subscriptions-payment'
import SubscriptionSummary from '../../settings/subscriptions/flows/subscriptions-summary'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { FlowStateType } from '../../../store/flow'

const flowName: string = 'addProduct'

const AddProduct = ({ ...rest }) => {
  const [params] = useSearchParams()

  const flowState = useSelector((state: FlowStateType) => state.flow[flowName])

  const [reRender, setReRender] = useState<number>(0)

  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)

  const {
    subscription,
    loading: subscriptionLoading,
    refetch,
  } = useIsValidSubscription()

  const [showSubscription, setShowSubscription] = React.useState(
    flowState?.data?.showSubscription
  )

  const dispatch = useDispatch()
  const [addSidekick, { loading: addSideKickLoading }] = useAddSideKick()

  const { data: teamMembersData, loading } = useQuery(teamMembersQuery)

  const { data: user } = useCurrentUser()

  React.useEffect(() => {
    if (flowState?.meta.keepDirty) return next()
    if (flag) setShowConfirm(flag)
  }, [flag])

  const checkSubscription = async () => {
    const value = !subscription
    await setShowSubscription(value)
    await dispatch.flow.SET_FLOW_DATA(flowName, {
      showSubscription: value,
    })
  }

  React.useEffect(() => {
    if (!subscriptionLoading) {
      checkSubscription()
    }
  }, [subscription, subscriptionLoading])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  const to = params.get('to')

  const submitAfterSubscription = async () => {
    const notNewSK = flowState?.data?.owner !== 'new'

    if (notNewSK)
      return dispatch.flow.SET_FLOW_DATA(flowName, {
        owner: flowState?.data?.owner,
      })

    const newSk = await addSidekick({ variables: flowState?.data?.userData })
    const id = newSk?.data?.addSideKick?.userProfile?.id
    await dispatch.flow.SET_FLOW_DATA(flowName, {
      owner: id,
    })
  }
  const { teamMembers = [] } = teamMembersData || {}
  const hasTeamMembers = teamMembers.length > 0
  const filteredMembers = hasTeamMembers
    ? teamMembers?.filter?.((sk) => sk.bankAccounts?.length === 0)
    : [user]

  React.useEffect((): any => {
    let listener
    if (isMobile()) {
      listener = App.addListener(
        'appUrlOpen',
        async (event: URLOpenListenerEvent) => {
          await refetch()
          await setReRender(Math.random())
        }
      )
    }
    return () => {
      if (listener && listener.remove) {
        listener.remove()
      }
    }
  }, [])

  return (
    <>
      <FlowCardLayout
        name={flowName}
        loading={loading || subscriptionLoading || addSideKickLoading}
        cancel="/products"
        to={to}
        title={<Text token="ADD_A_PRODUCT" />}
        refresh={reRender}
        {...rest}
      >
        <FlowStep
          title={<Text token="WHO_IS_THIS_PRODUCT_FOR" />}
          element={() => (
            <SelectSidekickStep allowNewMember teamMembers={filteredMembers} />
          )}
        />
        <FlowStep
          title={<Text token="SIDEKICK_DETAILS" />}
          element={AddMemberStep}
          condition={(data, meta) => data?.owner === 'new' || data?.userData}
        />
        <FlowStep
          element={SubscriptionSelect}
          condition={() => showSubscription}
        />
        <FlowStep
          element={SubscriptionPayment}
          condition={() => showSubscription}
        />
        <FlowStep
          element={() => (
            <SubscriptionSummary
              editPlanStep={2}
              submitBackStep={1}
              submit={submitAfterSubscription}
            />
          )}
          condition={() => showSubscription}
        />
        <FlowStep
          title={<Text token="PRODUCTS_SELECTED" />}
          element={SelectProductStep}
        />
        <FlowStep
          title={<Text token="ORDER_SIDEKICK_CARD" />}
          element={OrderCardStep}
        />
      </FlowCardLayout>

      <AnimatePresence>
        {showConfirm && (
          <ConfirmModal
            title={<Text token="CONFIRM" />}
            content="DATA_LOSS_CONFIRM_MESSAGE"
            confirm={next}
            cancel={closeModal}
            backdrop
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default AddProduct
