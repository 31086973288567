import React from 'react'
import { useQuery } from '@apollo/client'

import Button from '../../../components/Button'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import TeamMemberCard from '../../../components/cards/TeamMemberCard'

import { teamMembers } from '../../../queries'
import Text from '../../../components/typography/Text'

const SettingsTeamScene = ({ ...rest }) => {
  const { loading, data, error } = useQuery(teamMembers)

  return (
    <CardLayoutLink loading={loading} error={error} {...rest}>
      <Button token="ADD_TEAM_MEMBER" to="/settings/team/add-team-member" />
      {data &&
        data.teamMembers
          .filter((member) => member.role === 'SIDEKICK')
          .map(({ userProfile }) => (
            <TeamMemberCard
              showVerificationIdStatus
              key={userProfile.id}
              user={userProfile}
              subtitle={<Text token="SIDEKICK" />}
              sidekick
            />
          ))}
    </CardLayoutLink>
  )
}

export default SettingsTeamScene
