import React from 'react'

const Fullname = ({ user }) => {
  if (!user) return

  const givenName = user.givenName || user.given_name
  const familyName = user.familyName || user.family_name

  return (
    <>
      {user && (
        <>
          {givenName} {familyName}
        </>
      )}
    </>
  )
}

export default Fullname
