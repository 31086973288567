import { Capacitor } from '@capacitor/core'
import OneSignal from 'onesignal-cordova-plugin'

const OneSignalInit = (): void => {
  if (Capacitor.isNativePlatform()) {
    if (process.env.ENV !== 'prod') {
      OneSignal.setLogLevel(6, 0)
    }

    OneSignal.setAppId(process.env.REACT_APP_ONE_SIGNAL_APP_ID)
    OneSignal.setNotificationOpenedHandler((jsonData) => {
      // eslint-disable-next-line no-console
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
    })

    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      // eslint-disable-next-line no-console
      console.log('User accepted notifications: ' + accepted)
    })
  }
}

export default OneSignalInit

export const setOneSignalExternalId = async (userId) => {
  if (Capacitor.isNativePlatform()) {
    await OneSignal.setExternalUserId(userId)
  }
}
