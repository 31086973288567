import React from 'react'
import FlowStepLayout from '../../../../../components/flows/FlowStepLayout'

import NewMemberSelectTypeForm from '../../../../../components/form/forms/NewMemberSelectTypeForm'

import FlowContext from '../../../../../context/FlowContext'

const SelectMemberTypeStep = () => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  return (
    <FlowStepLayout>
      <NewMemberSelectTypeForm
        submit={handleSubmit}
        data={{ ...currentData?.data }}
      />
    </FlowStepLayout>
  )
}

export default SelectMemberTypeStep
