import React from 'react'
import { useTheme } from '@emotion/react'

import { ColorType } from '../utils/themes'

import Initials from './Initials'

export type AvatarInitialsProps = {
  user?: any
  size?: number
  color?: ColorType
}

const AvatarInitials: React.FC<AvatarInitialsProps> = ({
  user,
  size,
  color,
  ...rest
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ theme, colors, size, color }),
    [size, color]
  )

  return (
    <div css={styles.root} {...rest}>
      <Initials user={user} />
    </div>
  )
}

const computeStyles = ({ theme, colors, size, color }) => ({
  root: {
    width: size,
    height: size,
    backgroundColor: colors[color],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default AvatarInitials
