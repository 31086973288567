import React from 'react'
import Paragraph from './typography/Paragraph'
import Text from './typography/Text'

const PasswordRulesDescription = () => {
  const styles = computeStyles()
  return (
    <Paragraph css={styles.paragraph}>
      <Text token="PASSWORD_MUST_AT_LEAST_8" />
      <ul css={styles.list}>
        <li>
          <Paragraph
            css={styles.paragraph}
            token="BOTH_LOWER_AND_UPPER_CASE_LETTERS"
          />
        </li>
        <li>
          <Paragraph css={styles.paragraph} token="AT_LEAST_ONE_NUMBER" />
        </li>
        <li>
          <Paragraph
            css={styles.paragraph}
            token="AT_LEAST_ONE_SPECIAL_CHARACTER"
          />
        </li>
      </ul>
    </Paragraph>
  )
}

const computeStyles = () => ({
  list: {
    margin: '8px 0 0',
    paddingLeft: 18,
  },
  paragraph: {
    fontSize: 14,
    letterSpacing: -0.25,
    lineHeight: '20px',
    margin: 0,
  },
})

export default PasswordRulesDescription
