import React from 'react'

import Grid from '../../Grid'
import ListItem from '../ListItem'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'
import Detail from '../../typography/Detail'

import { normalizeTransactionModel, getAmount } from '../../../utils/functions'
import { useTheme } from '@emotion/react'
import { getCategoryDictByKey } from '../../../utils/categories'

const TransferActivityItem = ({ transaction }) => {
  const { theme } = useTheme()
  const { node } = transaction || {}

  const {
    TRANSFER_ACTIVITY_ICON_COLOR_DEfAULT,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_DEfAULT,
    TRANSFER_ACTIVITY_ICON_COLOR_ADD_FUNDS,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ADD_FUNDS,
    TRANSFER_ACTIVITY_ICON_COLOR_ALLOCATION,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ALLOCATION,
  } = theme

  const TRANSACTIONS_TYPES = {
    default: {
      icon: 'transaction',
      bgColor: TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_DEfAULT,
      iconColor: TRANSFER_ACTIVITY_ICON_COLOR_DEfAULT,
      size: 48,
      scale: 0.917,
      label: 'TRANSFER',
    },
    BerkeleyDirectSendDeposit: {
      icon: 'plus',
      bgColor: TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ADD_FUNDS,
      iconColor: TRANSFER_ACTIVITY_ICON_COLOR_ADD_FUNDS,
      size: 48,
      scale: 1.125,
      label: 'FUNDS_ADDED',
    },
    TransferIn: {
      icon: 'plus',
      bgColor: TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ADD_FUNDS,
      iconColor: TRANSFER_ACTIVITY_ICON_COLOR_ADD_FUNDS,
      size: 48,
      scale: 1.125,
      label: 'FUNDS_ADDED',
    },
    SideKickAccountAllocation: {
      icon: 'transaction',
      bgColor: TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ALLOCATION,
      iconColor: TRANSFER_ACTIVITY_ICON_COLOR_ALLOCATION,
      size: 48,
      scale: 0.917,
      label: 'FUNDS_ALLOCATED',
    },
    TransferOut: {
      icon: 'transaction',
      bgColor: TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ALLOCATION,
      iconColor: TRANSFER_ACTIVITY_ICON_COLOR_ALLOCATION,
      size: 48,
      scale: 0.917,
      label: 'FUNDS_ALLOCATED',
    },
  }

  const normalizedTransaction = normalizeTransactionModel(transaction)

  const billingAmount = normalizedTransaction?.node?.billingAmount
  const amount = getAmount(billingAmount)

  const transactionType =
    TRANSACTIONS_TYPES[node?.transactionType || node?.type] ||
    getCategoryDictByKey(node.mccCategory) ||
    TRANSACTIONS_TYPES.default

  const label =
    node.description || transactionType.label || transactionType.displayName

  return (
    <ListItem centerY gap="1rem" css={styles.root}>
      <IconCircle
        icon={transactionType.icon}
        color={transactionType.bgColor}
        iconColor={transactionType.iconColor}
        size={transactionType.size}
        scale={transactionType.scale}
      />

      <Grid gap={5} stretch>
        <Text token={label} />
        <Detail
          relativeDate
          date={normalizedTransaction?.node?.transactionDateTime}
          dateFormat="ccc, d LLLL, h:mm a"
        />
      </Grid>

      <Text currency={amount} />
    </ListItem>
  )
}

const styles = {
  root: {},
}

export default TransferActivityItem
