import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Flex from '../../../components/Flex'
import Text from '../../../components/typography/Text'
import NotificationsSettingsForm from '../../../components/form/forms/NotificationsSettingsForm'

const NotificationsSettingsScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const error = useSelector((state) => state.auth.error)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
      dispatch.auth.CLEAR_ERROR()
    }
  }, [error])

  const handleSubmit = async (formData) => {
    setLoading(true)
    // ToDo: Implement service
    setLoading(false)
  }

  const title = <Text token="NOTIFICATIONS" />
  const subtitle = <Text token="SETTINGS" />

  return (
    <CardLayoutLink
      loading={loading}
      error={error}
      title={title}
      subtitle={subtitle}
      {...rest}
    >
      <Flex vertical centerY>
        <Flex vertical padding="1rem" css={styles.content}>
          <NotificationsSettingsForm submit={handleSubmit} loading={loading} />
        </Flex>
      </Flex>
    </CardLayoutLink>
  )
}

const styles = {
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  info: {
    marginBottom: '16px',
    marginTop: '32px',
  },
}

export default NotificationsSettingsScene
