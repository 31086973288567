import React from 'react'
import { keyframes, useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Currency } from 'react-intl-number-format'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import Element, { ElementProps } from '../Element'
import TRANSLATIONS_EN from '../../config/translations/lib/en.json'

export type TranslationToken = keyof typeof TRANSLATIONS_EN

export type TextProps = {
  as?: string
  token?: TranslationToken
  interpolation?: any
  date?: any
  dateFormat?: any
  currency?: any
  currencyProps?: any
  size?: any
  capitalize?: boolean
  uppercase?: boolean
  relativeDate?: boolean
  dateIsTimestamp?: boolean
  dateIsRelative?: boolean
  center?: boolean
  loading?: boolean
  value?: any
} & ElementProps &
  React.PropsWithChildren

const Text: React.FC<TextProps> = ({
  as = 'span',
  token,
  date,
  center,
  dateFormat = 'cccc, H:mm',
  dateIsTimestamp,
  dateIsRelative,
  relativeDate,
  currency,
  currencyProps,
  interpolation,
  children,
  size,
  capitalize,
  uppercase,
  className,
  loading,
  ...rest
}) => {
  const { t, i18n } = useTranslation()
  const { theme } = useTheme()

  const classNames = clsx({
    [className]: className,
    'is-capitalized': capitalize,
    'is-uppercase': uppercase,
    'is-centered': center,
    'is-loading': loading,
  })

  const currencyClassNames = clsx({
    [className]: className,
    'is-negative': currency < 0,
  })

  const styles = React.useMemo(
    () => computeStyles({ ...theme, size }),
    [size, theme]
  )

  const content = token ? t(token, { ...interpolation }) : children

  if (token)
    return (
      <Element
        as={as}
        dangerouslySetInnerHTML={!loading ? { __html: content } : null}
        className={classNames}
        css={styles.root}
        {...rest}
      />
    )

  if (date) {
    let normalizedDate: DateTime, displayDate: string

    if (!dateIsTimestamp) normalizedDate = DateTime.fromISO(date)
    if (dateIsTimestamp) normalizedDate = DateTime.fromMillis(date)

    if (dateIsRelative) {
      displayDate = normalizedDate.toRelative({ locale: i18n.language })
    } else {
      const formattedDate = normalizedDate
        .setLocale(i18n.language)
        .toFormat(dateFormat)

      const passFromYesterday =
        normalizedDate.setLocale(i18n.language).diffNow('days')?.days < -2

      const relativeString = normalizedDate.toRelativeCalendar({
        locale: i18n.language,
      })
      const relativeDateString = `${
        relativeString.charAt(0).toUpperCase() + relativeString.slice(1)
      }, ${normalizedDate.setLocale(i18n.language).toFormat('H:mm a')}`

      displayDate = relativeDate
        ? !passFromYesterday
          ? relativeDateString
          : formattedDate
        : formattedDate
    }

    return (
      <Element as={as} css={styles.root} className={classNames} {...rest}>
        {!loading && displayDate}
      </Element>
    )
  }

  if (!isUndefined(currency))
    return (
      <Element
        as={as}
        css={styles.currency}
        className={currencyClassNames}
        {...rest}
      >
        <Currency locale="en-US" currency="USD" {...currencyProps}>
          {currency}
        </Currency>
      </Element>
    )

  return (
    <Element as={as} css={styles.root} className={classNames} {...rest}>
      {!loading && content}
    </Element>
  )
}

const computeStyles = ({ TEXT_CURRENCY, size }) => ({
  root: {
    ...(size && { fontSize: size }),

    '&.is-capitalized': {
      textTransform: 'capitalize',
    },

    '&.is-uppercase': {
      textTransform: 'uppercase',
    },

    '&.is-centered': {
      textAlign: 'center',
    },
    '&.is-loading': {
      width: '100%',
      height: '15px',
      display: 'block',
      background: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), lightgray`,
      backgroundRepeat: 'repeat-y',
      backgroundSize: '50px 500px',
      backgroundPosition: '0 0',
      animation: `${shine} 1s infinite`,
      margin: '2px 0',
    },
  },

  currency: {
    ...(size && { fontSize: size }),

    '&.is-negative': {
      color: TEXT_CURRENCY,
    },
  },
})

const shine = keyframes`
  to {
    background-position: 100% 0;
  }
`

export default Text
