import React from 'react'
import { useSubscription, useLazyQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import find from 'lodash/find'

import { notification } from '../queries'
import { subscribeToUserNotifications } from '../queries/subscriptions'
import useCurrentUser from './useCurrentUser'
import { apiClientWithoutErrorHandler } from '../config/api'
import isMobile from '../mobile/utils/isMobile'

type NotificationType = {
  id?: string | number
  userId?: string | number
  notificationType?: 'info' | 'warning' | 'error'
  hasBeenRead?: boolean
  code?: string
  timestamp?: string | number
}

const useWaitForNotification = (codes) => {
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState<NotificationType>()
  //eslint-disable-next-line
  const notifications = useSelector(
    (state: { notifications: NotificationType }) => state.notifications
  )
  const { data: currentMember } = useCurrentUser()

  const hideMobileError = isMobile()
    ? { client: apiClientWithoutErrorHandler }
    : {}

  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error,
  } = useSubscription(subscribeToUserNotifications, {
    variables: { userId: currentMember?.userProfile?.id },
    skip: !currentMember,
    ...hideMobileError,
  })

  const [
    getNotification,
    { data: notificationModel, loading: notificationModelLoading },
  ] = useLazyQuery(notification, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    ...hideMobileError,
  })

  React.useEffect(() => {
    if (!subscriptionData?.subscribeToUserNotifications?.id) return

    getNotification({
      variables: { id: subscriptionData.subscribeToUserNotifications.id },
    })
  }, [subscriptionData])

  React.useEffect(() => {
    const foundNotification = notificationModel?.notification

    if (!foundNotification) return

    if (find(codes, (code) => foundNotification.code === code)) {
      setResult(foundNotification)
    }
  }, [notificationModel])

  React.useEffect(() => {
    setLoading(subscriptionLoading || notificationModelLoading)
  }, [subscriptionLoading, notificationModelLoading])

  return { data: result, loading, error }
}

export default useWaitForNotification
