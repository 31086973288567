import React from 'react'
import useAxios from 'axios-hooks'
import { useMutation, useLazyQuery } from '@apollo/client'
import { toast } from 'react-hot-toast'

import AddFundingMethodForm from '../../components/form/forms/AddFundingMethodForm'
import FlowStepLayout from '../../components/flows/FlowStepLayout'

import FlowContext from '../../context/FlowContext'
import useWaitForNotification from '../../hooks/useWaitForNotification'

import { createBerkeleyFundingSource, fundingSource } from '../../queries'

const FundAccountAddFundingMethod = ({ ...rest }) => {
  const [waiting, setWaiting] = React.useState(false)
  const { setFlowData, next } = React.useContext(FlowContext)

  const url = process.env.REACT_APP_BERKELEY_API_URL
  const apiKey = process.env.REACT_APP_BERKELEY_API_KEY

  const { data: notification } = useWaitForNotification([
    'FundingSourceCreated',
  ])

  const [createBerkeleySource, { data: newFundingSource }] = useMutation(
    createBerkeleyFundingSource
  )

  const [getFundingSource, { data: newFundingSourceModel }] =
    useLazyQuery(fundingSource)

  const [
    { data: berkeleyResponse, error: berkeleyError },
    createBerkeleyToken,
  ] = useAxios(
    {
      url,
      method: 'POST',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    {
      manual: true,
    }
  )

  React.useEffect(() => {
    if (!newFundingSourceModel) return

    setFlowData({ fundingSourceModel: newFundingSourceModel.fundingSource })
    setWaiting(false)
    next()
  }, [newFundingSourceModel])

  React.useEffect(() => {
    if (!notification) return

    switch (notification.code) {
      case 'FundingSourceCreated':
        getFundingSource({
          variables: { id: newFundingSource?.createBerkeleyFundingSource?.id },
        })
        break
      default:
        setWaiting(false)
        break
    }
  }, [notification])

  React.useEffect(() => {
    if (!berkeleyResponse?.data) return

    createBerkeleySource({
      variables: { token: berkeleyResponse?.data?.token },
    })
  }, [berkeleyResponse])

  React.useEffect(() => {
    if (!berkeleyError) return

    toast.error(berkeleyError?.response?.data?.error?.message)

    setWaiting(false)
  }, [berkeleyError])

  const handleSubmit = async (formData) => {
    const newFundingMethod = formData.newFundingMethod
    const personalInfo = formData.personalInfo

    setWaiting(true)

    createBerkeleyToken({
      data: {
        card: {
          name: newFundingMethod.name,
          // brand: newFundingMethod.brand,
          number: newFundingMethod.number,
          expiry_month: newFundingMethod.expiryMonth,
          expiry_year: newFundingMethod.expiryYear,
          cvv: newFundingMethod.cvv,
          address_line1: personalInfo.addressLine1,
          address_line2: personalInfo.addressLine2,
          address_country: 'CAN',
          address_city: personalInfo.addressCity,
          address_state: personalInfo.addressState,
          address_postal_code: personalInfo.addressPostalCode,
        },
      },
    })
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout loading={waiting}>
      <AddFundingMethodForm
        submit={handleSubmit}
        disabled={waiting}
        handleFieldUpdate={handleFieldUpdate}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default FundAccountAddFundingMethod
