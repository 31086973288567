import { Auth } from 'aws-amplify'
import { appsyncConfig } from '../../amplify'
import store from '../../../store'

const {
  aws_appsync_graphqlEndpoint,
  aws_appsync_region,
  aws_appsync_authenticationType,
} = appsyncConfig

const url = aws_appsync_graphqlEndpoint
const region = aws_appsync_region
const auth = {
  type: aws_appsync_authenticationType,
  jwtToken: async () => {
    try {
      const currentSession = await Auth.currentSession()
      return await Promise.resolve(currentSession?.accessToken?.jwtToken)
    } catch (err) {
      await store.dispatch.auth.signOut()
      return await Promise.reject(err)
    }
  },
}

const config = { url, region, auth }

export default config
