import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import ConfirmModal from '../../../components/modals/ConfirmModal'
import FlowCardLayout, {
  FlowStep,
} from '../../../components/layout/FlowCardLayout'

import usePrompt from '../../../hooks/usePrompt'
import GetStarted from './getStarted'
import AreYou from './areYou'
import CompleteProfile from './completeProfile'
import VerifyEmail from './verifyEmail'
import { toast } from 'react-hot-toast'
import Text from '../../../components/typography/Text'

const RegisterScene = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const flowState = useSelector((state) => state.flow.signUp)
  const [flag, setFlag, next] = usePrompt(
    flowState?.data && !flowState?.meta.completed
  )
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (flowState?.meta.keepDirty) return next()
    if (flag) setShowConfirm(flag)
  }, [flag])

  const closeModal = () => {
    setShowConfirm(false)
    setFlag(false)
  }

  const to = params.get('to')

  const handleFormSubmit = async (values) => {
    setLoading(true)
    for (let key in values) {
      values[key] = values[key]?.trim?.()
    }
    try {
      await dispatch.auth.signUp(values)
      setLoading(false)
    } catch (err) {
      toast.error(err.message)
      setLoading(false)
      throw err
    }
  }

  return (
    <>
      <FlowCardLayout
        name="signUp"
        loading={loading}
        cancel="/auth/login"
        to={to}
        title={<Text token="SIGN_UP" />}
        submit={handleFormSubmit}
        {...rest}
      >
        <FlowStep
          title={<Text token="LETS_GET_STARTED" />}
          element={GetStarted}
        />

        <FlowStep title={<Text token="ARE_YOU" />} element={AreYou} />

        <FlowStep
          title={<Text token="COMPLETE_YOUR_PROFILE" />}
          element={() => <CompleteProfile submit={handleFormSubmit} />}
        />

        <FlowStep
          title={<Text token="VERIFY_YOUR_EMAIL" />}
          element={VerifyEmail}
        />
      </FlowCardLayout>

      {showConfirm && (
        <ConfirmModal
          title={<Text token="CONFIRM" />}
          content={<Text token="DATA_LOSS_CONFIRM_MESSAGE" />}
          confirm={next}
          cancel={closeModal}
          backdrop
        />
      )}
    </>
  )
}

export default RegisterScene
