import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'

import normalizedSubscriptionPlanData from '../../utils/subscription'

import ComponentCard from './ComponentCard'
import Pill from '../Pill'
import Grid from '../Grid'
import Text, { TranslationToken } from '../typography/Text'
import Detail from '../typography/Detail'
import Heading from '../typography/Heading'
import CheckList from '../lists/CheckLists'
import Button from '../Button'
import { IconType } from '../icons'
import Icon from '../Icon'

type SubscriptionPlanCardProps = {
  subscription: any
  active?: boolean
  callback?: Function
  editCallback?: Function
  disabled?: boolean
  hideBtn?: boolean
  icon?: IconType
  className?: any
  promotion?: TranslationToken
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  subscription,
  active = false,
  callback = () => {},
  editCallback,
  disabled = false,
  hideBtn = false,
  icon = 'edit',
  className,
  promotion,
}) => {
  const { title, price, periodical, description, descriptionList } =
    normalizedSubscriptionPlanData(subscription)
  const rebate = subscription?.price?.[0]?.rebate

  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  return (
    <ComponentCard css={styles.root} className={classNames}>
      <Heading as={'h3'} css={styles.title} token={title as TranslationToken} />
      {!!editCallback && (
        <Icon
          css={styles.back}
          color="BLACK_900"
          onClick={() => editCallback()}
          icon={icon}
        />
      )}
      {rebate && !active && (
        <Pill token="subscription_plan_rebate" interpolation={{ rebate }} />
      )}
      {active && <Pill variant="primary-alt" token="ACTIVE" />}
      {promotion && <Pill variant="promo" token={promotion} />}
      <Grid css={styles.priceWrapper} horizontal fixChildrenX gap={2}>
        <Text css={styles.price} currency={price} />
        <Text css={styles.periodicals}>/</Text>
        <Text
          token={periodical.toUpperCase() as TranslationToken}
          css={styles.periodicals}
        />
      </Grid>
      <Detail
        css={styles.description}
        capitalize={false}
        token={description as TranslationToken}
      />
      <CheckList list={descriptionList} />
      {!disabled && !hideBtn && (
        <Button
          button
          css={styles.btn}
          onClick={callback}
          token={'CHOOSE_PLAN'}
        />
      )}
    </ComponentCard>
  )
}

const computeStyles: TComputeStyles = ({ TEXT_PRIMARY }: TTheme) => ({
  root: {
    display: 'block',
    position: 'relative',
    '&.is-disabled': {
      cursor: 'not-allowed',
      opacity: 0.55,
    },
  },
  price: {
    fontSize: 32,
    lineHeight: '40px',
  },
  priceWrapper: {
    alignItems: 'baseline',
    marginBottom: 16,
  },
  periodicals: {
    color: TEXT_PRIMARY,
  },
  title: {
    display: 'inline-block',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '-0.1px',
    lineHeight: '24px',
    margin: 0,
    marginRight: 10,
  },
  description: {
    color: TEXT_PRIMARY,
    display: 'block',
    fontSize: 14,
    marginBottom: 16,
  },
  btn: {
    width: '100%',
  },
  back: {
    cursor: 'pointer',
    position: 'absolute',
    right: 25,
    top: 25,
  },
})

export default SubscriptionPlanCard
