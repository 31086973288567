import { useQuery } from '@apollo/client'
import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'
import ShippingAddressForm, {
  SHIPPING_METHOD,
} from '../../components/form/forms/ShippingAddressForm'

import Text from '../../components/typography/Text'

import FlowContext from '../../context/FlowContext'
import useCards from '../../hooks/useCards'
import { teamMembers } from '../../queries'

const ShippingAddressStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)
  const { data: teamMembersData } = useQuery(teamMembers)
  const { data: cardsData, loading } = useCards()
  const cards = cardsData?.cards || []
  const sk = teamMembersData.teamMembers.find(
    (acc) => acc.userProfile?.id === currentData?.data?.forUser
  )
  const isReplace = cards.some(
    (card) => card.cardholder?.id === sk?.userProfile?.id
  )

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  return (
    <FlowStepLayout loading={loading}>
      <Text token="ENTER_THE_ADDRESS_TO_WHICH_YOU_WISH" />
      {!loading && (
        <ShippingAddressForm
          submit={handleSubmit}
          data={{
            shippingAddress: sk?.userProfile?.principalAddress || {},
            ...currentData?.data,
            shippingMethod: isReplace
              ? SHIPPING_METHOD.RUSH_TRACK
              : currentData?.data.shippingMethod || SHIPPING_METHOD.FREE,
          }}
          hideShippingMethod={isReplace}
          {...rest}
        />
      )}
    </FlowStepLayout>
  )
}

export default ShippingAddressStep
