import { Theme, useTheme } from '@emotion/react'
import React from 'react'
import Flex from '../../../components/Flex'
import Header from '../../../components/typography/Header'
import Paragraph from '../../../components/typography/Paragraph'

const TOSDetails: React.FC = () => {
  const theme = useTheme()
  const styles = React.useMemo(() => computeStyles(theme.theme), [theme])
  return (
    <Flex css={styles.root}>
      <Flex vertical>
        <Header css={styles.headline}>Terms of Service</Header>
        <Paragraph>
          These Terms of Service set forth the terms and conditions for the use
          of the products and services (collectively, the “<b>Services</b>“)
          offered by Hero Innovation Group. (“
          <b>Hero</b>”, “<b>we</b>“, “<b>us</b>” and “<b>our</b>” ). The
          Services are provided through our mobile and web-based applications as
          well as our websites at www.heroinnovationgroup.com and
          www.sidekickcard.com and www.herofinancials.com. (collectively, the “
          <b>Products</b>”).
        </Paragraph>
        <Paragraph>
          By opening, registering or using a Hero and SideKick Account, or by
          otherwise using the Services, Customers (“<b>you</b>” and “<b>your</b>
          “) agree to be bound by these Terms of Service and consent to receive
          communications related to the Services in electronic format. You
          should carefully read the terms and conditions set forth herein when
          registering to use the Services. Please note that if you do not accept
          these Terms of Service, you will not be able to use the Services.
        </Paragraph>
        <Paragraph>
          You agree, by using the Services and accepting these Terms of Service,
          to comply with and accept our{' '}
          <a href="https://www.cc94.mobi/site/bb1345ba/privacy">
            Privacy Policy
          </a>
          , which sets forth the terms on which we process any personal
          information we collect about you, or that you provide to us. By using
          the Services, you consent to such processing and you confirm that all
          information provided by you is accurate.
        </Paragraph>
        <Paragraph>
          You may not use the Services in connection with any activity, product,
          service or transaction that violates any applicable local, provincial,
          national or international law, rule or regulation, or promotes or
          facilitates the violation of any law, rule or regulation or causes us
          to breach any applicable law, rule or regulation. When using any
          portion of the Services described in the below sections{' '}
          <b>Uploading Money, Receiving Money from Third Parties</b> and{' '}
          <b>Withdrawing or Using Money</b>, you agree to use such portion of
          the Services for remitting and transmitting funds only in respect of
          payments for tuition, rent, student accommodations and living
          expenses.
        </Paragraph>
        <Paragraph>
          Please read carefully all of the terms herein and each of the other
          agreements that apply to you. Your use of certain of the Services may
          be subject to additional terms and conditions, as communicated by us
          to you, and such additional terms and conditions are incorporated into
          these Terms of Service. These Terms of Service contain several
          sections, and you should read all of them carefully. The headings are
          for reference only. Some capitalized terms have specific definitions
          as provided herein.
        </Paragraph>

        <b>1. Registration</b>
        <Paragraph>
          Hero Innovation Group is registered in Canada with the Financial
          Transactions Reports Analysis Centre of Canada (“
          <b>FINTRAC</b>“), registration number M19409559. Hero Innovation Group
          is registered as a money service business and is able to provide money
          transfer services to customers.
        </Paragraph>

        <b>2. Eligibility</b>
        <Paragraph>
          To be eligible to use the Services, you must be at least 18 years old,
          or the age of majority in your province or territory of residence, or
          if you are a minor by having a parent or legal guardian agree to the
          terms set forth herein on your behalf. You represent and warrant to us
          that if you are an individual and you open an account with us at the
          Hero or Hero App website (a “<b>Products</b>“) that you are acting
          exclusively for your own benefit and are not acting on behalf of any
          third party principal or any third party beneficiary. You must only
          use the Services to transact on your own account and not on behalf of
          any other person. If you intend to use the Services to transact on
          behalf of another person, you must first inform us of your desire to
          do so and provide us with any additional information about the person
          we may request in order that we may decide whether to permit the
          transaction.
        </Paragraph>

        <b>3. The Services</b>
        <b>A. Opening an Account</b>
        <Paragraph>
          In order to use some or all of the Services, you must first open an
          Account by providing certain information. For legal reasons, all
          information you provide during the sign-up process or any time
          thereafter must be complete, accurate and truthful. You are
          responsible for keeping your mailing address, email address, telephone
          number and other contact information up-to-date in your Hero or
          SideKick Card Account profile. To make changes to your profile, login
          to your profile page. We may refuse to provide or may discontinue
          providing the Services to any person at any time for any reason. We
          treat all activities under a Hero or SideKick Card Account to be those
          of the registered user.
        </Paragraph>
        <b>i. Account Security and Privacy</b>
        <Paragraph>
          Protecting your privacy is very important to us. Your passwords are
          stored on Hero servers in encrypted form. We do not disclose your
          personal information, including account details, postal or email
          addresses, to anyone except when legally required to do so or as
          specified in our{' '}
          <a href="https://www.cc94.mobi/site/bb1345ba/privacy">
            Privacy Policy
          </a>
          . Sensitive information between your browser and the
          www.heroinnovationgroup.com or www.sidekickcard.com websites or
          www.herofinancials.com (together, the “<b>Websites</b>“) is
          transferred in encrypted form using Secure Socket Layer (SSL). When
          transmitting sensitive information over the Websites, you should
          always make sure that your browser can validate the applicable
          Website’s certificate.
        </Paragraph>
        <Paragraph>
          You, not Hero, are responsible for maintaining adequate security and
          control of any and all IDs, passwords, or any other details that you
          use to access our Products and Services. You must never disclose your
          Account password. We will never ask you to provide your password to us
          or to a third party. Please inform us if anyone asks for your
          password, and contact Customer Support if you are not sure about this
          or any other security-related aspect of your Account. You must never
          let anyone access your Account or watch you access your Account.
        </Paragraph>
        <Paragraph>
          If you suspect your Account, login details, password or any other
          security features are stolen, lost, used without authorization or
          otherwise compromised, you are advised to change your password.
          Contact Customer Support immediately if you believe your credentials
          have been compromised or you are suspicious about the security of your
          password or any other security features. The compromise of your
          credentials could enable thieves to access your bank account and
          attempt transactions not authorized by you. Contacting us is the best
          way to minimize your risk of loss. In addition, contact us at once if
          your transaction history for your Account shows transactions that you
          did not initiate. You can contact our Customer Support department at{' '}
          <b>1-800-508-8813</b> or by email at <b>hello@herofinancials.com</b>.
        </Paragraph>
        <Paragraph>
          We rely on you to regularly check the transaction history of your
          Account and to contact Customer Support immediately in case you have
          any questions or concerns. We may (but are not obligated to) suspend
          your Account or otherwise restrict its functionality if we have
          concerns about the security of the Account or any of its security
          features, or potential unauthorized or fraudulent use of your Account.{' '}
          <b>See We May Close your Account</b>, below, for more details.
        </Paragraph>
        <Paragraph>
          You must ensure that your email account(s) are secure and only
          accessible by you, as your email address may be used to reset
          passwords or to communicate with you about the security of your
          Account. Let Customer Support know immediately if your email address
          becomes compromised. Never use any functionality that allows login
          details or passwords to be stored by the computer or browser you are
          using or to be cached or otherwise recorded. Additional Hero products
          or services you use may have additional security requirements, as
          notified to you by us, and you must familiarize yourself with those
          requirements.
        </Paragraph>
        <Paragraph>
          In the case of what you believe to be any incorrect or misdirected
          payment, see <b>Errors and Unauthorized Transactions</b>.
        </Paragraph>
        <b>ii. Duplicate Accounts</b>
        <Paragraph>
          Hero reserves the right to refuse the creation of duplicate accounts
          for the same user due to security and client identification
          requirements. In a case where duplicate accounts are detected, Hero
          reserves the right to close or merge these duplicate accounts without
          notification to you.
        </Paragraph>
        <b>iii. Verification</b>
        <Paragraph>
          If you open an Account and use certain of the Services, the law
          requires that we verify some of your information. You authorize us to
          make any inquiries we consider necessary to validate your identity,
          either directly or through third parties, including checking
          commercial databases or credit reports. For that we may ask for a
          credit header check together with bank account ownership verification.
          If a credit header check fails, we may request certified photo ID.
        </Paragraph>
        <Paragraph>
          Hero reserves the right to access various government and private
          databases so as to verify your information. If certain databases
          provide a match to the information you provide then we may not have to
          ask for photographic evidence of your identity. Hero reserves the
          right to close, suspend or limit access to your Account and/or the
          Services in the event we are unable to obtain or verify this
          information.
        </Paragraph>
        <b>B. Uploading Money</b>
        <Paragraph>
          You may upload money in Canadian dollars into your Account in order to
          (i) send it to another person or to your own account or (ii) hold a
          balance in your Account for use in the future.
        </Paragraph>
        <Paragraph>
          You can upload money through one or more methods, for example, a
          pre-authorized direct debit via electronic funds transfer (“
          <b>EFT</b>“) or a wire transfer from your bank account, or with a
          credit or debit card. The number of methods available to you will
          depend on a number of factors including your verification status with
          us. Upload methods are not part of our Services, they are services
          provided by third parties. Third party service providers responsible
          for processing funds f or your Account may request information from
          you and you may be required to satisfy certain requirements depending
          on the particular upload method. The upload methods may have limits
          and restrictions. In no event shall Hero have any liability to you
          under these Terms of Service for the actions or omissions of a third
          party service provider or any of its affiliates. We cannot guarantee
          the use of any particular upload method and may change or stop
          offering a particular upload method at any time without notice to you.
        </Paragraph>
        <Paragraph>
          We will credit your Account once we have received your money, and are
          not responsible for the money you have uploaded until we have received
          it. For some particular upload methods, we will credit the money to
          your Account as soon as possible subject to our right of reversal.
          This means if the actual amount you intended to upload does not reach
          us within a reasonable time, we may deduct such amount from your
          Account. See <b>Reversals and Chargebacks</b> for details. If you do
          not have enough money in your Account for this purpose, we may demand
          repayment from you using other methods. See{' '}
          <b>Negative Account Balances</b> for further details.
        </Paragraph>
        <Paragraph>
          For legal and security reasons, we may impose limits on how much you
          are able to upload into your Account.
        </Paragraph>
        <b>C. Receiving Money from Third Parties</b>
        <Paragraph>
          In certain circumstances, we may provide you with specified Account
          details that you can in turn provide to qualified third parties who
          may be able to send money to your Account using either the electronic
          funds transfer method or other acceptable methods.
        </Paragraph>
        <Paragraph>
          When someone sends money to your Account using a method that we
          support, the money will appear in your Account. You should check the
          incoming funds in your Account against your own records regularly and
          let us know if there are any irregularities. You will not have access
          to such funds until we receive the funds into our specified account.
        </Paragraph>
        <Paragraph>
          When someone sends money to your Account, the sender may place
          restrictions on how the funds may be used. The sender may also request
          and receive information about how the funds are used based on the
          Account settings. You and the sender are responsible for making all
          arrangements and coming to an agreement on the restrictions and
          monitoring the use of any transferred funds. We cannot be responsible,
          and do not guarantee, that the features of the Services that allow a
          sender to restrict and monitor the use of funds by the recipient will
          always be available or be uninterrupted.
        </Paragraph>
        <Paragraph>
          You acknowledge that the money received in your Account via this
          method may be subject to <b>Reversal or Chargeback</b> and you agree
          that we may deduct the received amount from your Account if it is
          reversed by the person who paid you the received amount or any
          relevant payment services provider. If for any reason the transaction
          is cancelled or refused, then we will promptly return the deposit to
          the bank account from which the transaction originated. See{' '}
          <b>Negative Account Balances</b> for further details. In the event
          that we are unable to return the deposit, then we will promptly
          contact you using the most current contact information provided to us
          when you registered for your Account.
        </Paragraph>
        <Paragraph>
          You are not permitted to receive money to your Account by payment
          methods, including cash, mailed check, or electronic check, other than
          those permitted by us.
        </Paragraph>
        <b>D. Holding a Balance in your Account</b>
        <b>i. Hero is Not a Bank and your Account is Not a Bank Account</b>
        <Paragraph>
          Hero is not a bank and therefore value held as a balance in your
          Account represents an unsecured claim against Hero and is not insured
          by the Canada Deposit Insurance Corporation (CDIC) or any other
          deposit protection scheme. Hero owns the interest or other earnings on
          these investments, if any. Hero does not use balances held by its
          customers for operating expenses or other corporate purposes. In
          addition, as discussed above, the specified Account details that we
          provide to you in order for you to receive funds from third parties
          are for accounts held by Hero and its affiliates (and we will credit
          your Account, which is held by us, upon receipt of such funds), and
          are not for a bank account held by you.
        </Paragraph>
        <b>ii. History of Transactions</b>
        <Paragraph>
          All of your transactions (including your balance, uploaded money, and
          money you have received, sent and/or withdrawn), including related
          fees, if applicable, are recorded in the transaction history section
          of your Account. You may access this information when you log into
          your Account.
        </Paragraph>
        <b>E. Withdrawing or Using Money</b>
        <Paragraph>
          If you have an Account balance, you may withdraw any remaining funds
          by:
        </Paragraph>
        <ul>
          <li>
            Transferring them to the source bank account through electronic
            funds transfer or other method; or
          </li>
          <li>
            Withdrawing them from an ATM following the limits and fees shown in
            the SideKick Cardholder Agreement.
          </li>
        </ul>
        <Paragraph>
          The number of payout methods available to you will depend on a number
          of factors, including your verification status with us. We cannot
          guarantee the use of any particular payout method and may change or
          stop offering a payout method at any time without notice to you, but
          we will ensure that you will have at least one payout method available
          to you unless prohibited by applicable law. To request a withdrawal,
          please contact our Customer Support department by telephone at{' '}
          <b>1-800-508-8813</b> or by email at <b>hello@herofinancials.com</b>
        </Paragraph>
        <Paragraph>
          When you use your SideKick Card to purchase goods and/or services from
          a retailer, the transaction will be subject to the terms and
          conditions of that retailer and/or any payment service provider or
          processor. Additional charges may apply in respect of such purchases.
          We do not provide any warranties, representations, conditions or
          guarantees with respect to retailers’ goods and/or services or such
          transactions. You should carefully review the terms and conditions of
          any such retailer, payment service provider or processor before making
          any purchase, making any transaction or entering into any contract.
          This should include any terms affecting your eligibility to do so in
          these Terms of Service or elsewhere.
        </Paragraph>
        <Paragraph>
          We may charge you a fee for each withdrawal or send money request. We
          will let you know the exact amount when you submit your request. You
          can also learn more about the fees we charge under <b>Fees</b>.
        </Paragraph>
        <b>i. Limits on Withdrawal and Sending Money</b>
        <Paragraph>
          You agree that your Account is subject to withdrawal and send money
          limits. If your withdrawal request exceeds the current limit, we may
          decline your request or impose additional checks, obligations or time
          limitations before allowing any funds to be withdrawn.
        </Paragraph>
        <b>ii. Delay in Withdrawal or Sending Money</b>
        <Paragraph>
          We do not have any control over the time it may take for your or a
          recipient’s bank or payment provider to credit and make funds
          available to you or your recipient once we make the funds available to
          you or the recipient’s bank or payment provider.<br></br>
          We may delay a withdrawal in certain situations, including if we need
          to confirm that the withdrawal has been authorized by you or if other
          payments to your Account have been reversed, for example, as a result
          of a chargeback or bank reversal. See <b>
            Reversals and Chargebacks
          </b>{' '}
          for more details.
        </Paragraph>
        <b>F. Fees and Taxes</b>
        <Paragraph>
          Hero reserves the right to charge fees for access to or use of any
          part of the Services. You may also view the fee structure on our{' '}
          <b>Pricing Page</b>. For clarity, the fees applicable to you on the{' '}
          <b>Pricing Page</b> form part of these Terms of Service and are
          subject to change. All fees, unless otherwise specified, are in
          Canadian dollars.
        </Paragraph>
        <Paragraph>
          You may be required to pay a subscription fee to access or use the
          Services. The fees for Services (plus any applicable taxes or other
          additional charges, such as administration, transaction or access
          fees), accepted methods of payment and accepted frequencies of billing
          (such as one-time, every four weeks, monthly or annually) will be
          specified at the time of subscription. For periodic subscriptions to
          the Services, you will be automatically billed (i.e., charged or
          debited) or invoiced on or around the periodic anniversary date of
          your subscription, and periodically thereafter in accordance with the
          billing cycle selected at the time of purchase.
        </Paragraph>
        <Paragraph>
          You may be charged fees for withdrawing and sending money, which fees
          will be disclosed to you when you place an order and prior to you
          confirming the transaction. If a fee structure applies to you, you
          agree to pay the relevant fees using your chosen payment method when
          you send or withdraw funds. Our fees do not include any fees that your
          bank or the recipient’s bank may charge. Those fees may be deducted
          from money you upload into your Account or balances in your Account.
        </Paragraph>
        <Paragraph>
          You are responsible for providing and maintaining current, complete
          and accurate billing information. You must contact us immediately to
          advise us of any changes to your billing information to avoid any
          interruptions to the Services.
        </Paragraph>
        <Paragraph>
          You are responsible for any taxes which may be applicable to payments
          you make or receive, and it is your responsibility to collect, report
          and pay the correct tax to the appropriate taxation authority.
        </Paragraph>
        <b>G. Closing Your Account</b>
        <b>i. You May Close Your Account</b>
        <Paragraph>
          You may close your Account at any time by contacting our Customer
          Support department by telephone at <b>1-800-508-8813</b> or by email
          at <b>hello@herofinancials.com.</b>
        </Paragraph>
        <Paragraph>
          At the time of closure, if you still have money in your Account, you
          must withdraw your money within a reasonable period of time by
          following the steps described in <b>Withdrawing or Sending Money</b>.
          You must not close your Account to avoid an investigation. If you
          attempt to close your Account during an investigation, we may hold
          your money until the investigation is fully completed. You agree that
          you will continue to be responsible for all obligations related to
          your Account even after it is closed.
        </Paragraph>
        <b>ii. We May Close your Account</b>
        <Paragraph>
          Hero, in its sole discretion, reserves the right to suspend or
          terminate these Terms of Service and access to or use of the Websites,
          our software, the Hero App, the systems (including any networks and
          servers used to provide any of the Services) operated by us or on our
          behalf or some or all of the Services for any reason and at any time
          upon notice to you and, upon the closure of your Account, the payment
          to you of any unrestricted funds held in your account.
        </Paragraph>
        <Paragraph>
          Reasons we may close your Account include, but are not limited to:
        </Paragraph>
        <ul>
          <li>
            a breach by you of any provision of these Terms of Service or
            documents referred to in these Terms of Service;
          </li>
          <li>
            we are requested or directed to do so by any competent court of law,
            government authority or agency, or law enforcement agency;
          </li>
          <li>
            we have reason to believe you are in violation or breach of any
            applicable law, rule or regulation; or
          </li>
          <li>
            we have reason to believe you are involved in any fraudulent
            activity, money laundering, terrorism financing or other criminal or
            illegal activity.
          </li>
        </ul>
        <Paragraph>
          We may also suspend your Account if it has been compromised or for
          other security reasons, or if it has been used or is being used
          without your authorization or fraudulently. See{' '}
          <b>Account Security and Privacy</b> for details.
        </Paragraph>
        <Paragraph>
          If we close your Account or terminate your use of the Services for any
          reason, we will provide you with notice of our actions and make any
          unrestricted funds held in your Account available for withdrawal. You
          are responsible for all reversals, chargebacks, fees, fines, penalties
          and other liabilities incurred by Hero, any other Hero customer or any
          third party, caused by or arising out of your breach of these Terms of
          Service and/or your use of the Services. You agree to reimburse Hero,
          any Hero customer and any third party for any and all such
          liabilities. See <b>Reversals and Chargebacks</b> and{' '}
          <b>Negative Account Balances</b>.
        </Paragraph>
        <Paragraph>
          On termination for any reason, all rights granted to you in connection
          with the Hero App shall cease and you must immediately delete or
          remove the Hero App from your devices. If you do not log in to your
          Account for two (2) or more years, we may close your Account and send
          the funds remaining in your Account to your last known primary
          address.
        </Paragraph>
        <b>H. Communications</b>
        <Paragraph>
          We are required to provide certain information to you in writing. By
          accepting these Terms of Service, you agree that we can communicate
          with you electronically either by email, by posting notices on the
          Websites, or through the Hero App.
        </Paragraph>
        <Paragraph>
          We may call or text message you at the telephone number(s) you have
          provided to us. We may place such calls or texts to (i) provide
          notices regarding your Account or account activity, (ii) investigate
          or prevent fraud, or (iii) collect a debt owed to us. We may share
          your phone number(s) with service providers with whom we contract to
          assist us in providing you with services, but will not share your
          phone number(s) with third parties for their own purposes without your
          consent or except in accordance with our{' '}
          <a href="https://www.cc94.mobi/site/bb1345ba/privacy">
            Privacy Policy
          </a>
          . Standard telephone minute and text charges may apply.
        </Paragraph>

        <b>4. Issues that May Occur</b>
        <b>A. Reversals and Chargebacks</b>
        <Paragraph>
          You are responsible for all reversals, chargebacks, claims, fees,
          fines, penalties and other liabilities incurred by Hero caused by or
          arising out of your breach of these Terms of Service and/or your use
          of the Services. You agree to reimburse Hero for any and all such
          liabilities.
        </Paragraph>
        <Paragraph>
          Payments to you may be invalidated and reversed by Hero if:
        </Paragraph>
        <ul>
          <li>
            Our investigation of a bank reversal finds that the transaction was
            fraudulent.
          </li>
          <li>Hero sent the payment to you in error.</li>
          <li>
            The payment was unauthorized or invalidated by the sending bank.
          </li>
          <li>
            You received the payment for activities that violated these Terms of
            Service or any other agreement between you and Hero.
          </li>
          <li>Hero has a claim against you for the funds.</li>
        </ul>
        <Paragraph>
          When you receive a payment, you are liable to Hero for the full amount
          of the payment sent to you plus any fees if the payment is later
          invalidated for any reason.
        </Paragraph>
        <b>B. Negative Account Balances</b>
        <Paragraph>
          If your Account balance becomes negative for any reason, including on
          account of a reversal or chargeback, that negative balance represents
          an amount you owe to Hero and you agree to repay the negative balance
          immediately without any notice from us. Hero may deduct amounts owed
          from money you upload or money you receive into your Account. We may
          send you reminders or take other reasonable actions to recover the
          negative balance from you, for example, by using a debt collection
          service or taking legal action.
        </Paragraph>
        <b>C. Errors and Unauthorized Transactions</b>
        <Paragraph>
          To protect yourself from errors and unauthorized activity, you should
          regularly log into your Account and review your Account transaction
          history. You should review transactions to ensure that each
          transaction was authorized and accurately completed.
        </Paragraph>
        <Paragraph>
          Hero will protect you from unauthorized activity and errors in your
          Account. When this protection applies, Hero will cover you for the
          full amount of the unauthorized activity as long as you cooperate with
          us and follow the procedures described in this section. Where this
          section requires you to notify us, please do so by contacting our
          Customer Support department by telephone at <b>1-800-508-8813</b> or
          by email at <b>hello@herofinancials.com</b>.
        </Paragraph>
        <Paragraph>
          We will rectify any errors that we discover. If the error results in:
        </Paragraph>
        <ul>
          <li>
            You receiving less than the correct amount to which you were
            entitled, then we will credit your Account for the difference
            between what you should have received and what you actually
            received.
          </li>
          <li>
            You receiving more than the correct amount to which you were
            entitled, then we will debit your Account for the difference between
            what you actually received and what you should have received.
          </li>
        </ul>
        <Paragraph>
          If you believe an error of the type described above has occurred, and
          you have contacted us in accordance with the information above, we
          will investigate and determine whether an error occurred within 90
          days (although we typically do this within 10 business days) after you
          contact us. We will inform you of our determination within three (3)
          business days after completing our investigation.
        </Paragraph>
        <b>i. Account Balance Errors and Unauthorized Transactions</b>
        <Paragraph>
          With regard to your Account balance, the following are considered
          errors:
        </Paragraph>
        <ul>
          <li>
            When money is either incorrectly taken from your Account or
            incorrectly uploaded into your Account, or when a transaction is
            incorrectly recorded in your Account.
          </li>
          <li>
            You send money to a third party or withdraw money, and the incorrect
            amount is debited from your Account.
          </li>
          <li>An incorrect amount is credited to your Account.</li>
          <li>
            A transfer to or from your Account is missing from or not properly
            identified in your Account statement.
          </li>
        </ul>
        <Paragraph>
          If you believe an error of the type described in this section has
          occurred, and you have contacted us in accordance with the information
          above, we will investigate and determine whether an error occurred
          within 10 business days after we hear from you and will correct any
          error promptly. If we need more time, however, we may take up to 45
          days to investigate your complaint or question and we may, under
          certain circumstances, credit your Account for the amount you think is
          in error, until we complete our investigation. For new Accounts, we
          may take up to 20 business days to credit your Account for the amount
          you think is in error. We will tell you the results within three (3)
          business days after completing our investigation. If we decide that
          there was no error, we will send you a written explanation. You may
          ask for copies of the documents that we used in our investigation, and
          we will forward you those copies if we are not prohibited from doing
          so due to privacy, confidentiality, or other reasons.
        </Paragraph>
        <Paragraph>
          <i>What is an Unauthorized Transaction?</i>
        </Paragraph>
        <Paragraph>
          An unauthorized transaction occurs when money is sent from your
          Account that you did not authorize and that did not benefit you. For
          example, if someone steals your password, uses the password to access
          your Account, and sends a payment from your Account, an unauthorized
          transaction has occurred.
        </Paragraph>
        <Paragraph>
          The following are not considered unauthorized transactions:
        </Paragraph>
        <ul>
          <li>
            If you give someone access to your Account (by providing them with
            your login information, for example) and they use your Account
            without your knowledge or permission. You are responsible for
            transactions made in this situation.
          </li>
          <li>
            The invalidation and reversal of a payment as a result of the
            actions described under <b>Reversals and Chargebacks</b>.
          </li>
        </ul>
        <Paragraph>
          If you believe your login information has been lost or stolen, please
          contact our Customer Support department by telephone at{' '}
          <b>1-800-508-8813</b> or by email at <b>hello@herofinancials.com</b>,
          or write to us at: Hero Innovation Group., 170 – 422 Richards Street,
          Vancouver, British Columbia, Canada V6B 2Z4, ATTN: Compliance. Tell us
          right away if you believe your login information has been lost or
          stolen, or if you believe that transactions have been made in your
          Account without your permission using your login information. You
          could lose all the money in your Account.
        </Paragraph>
        <Paragraph>
          Also, if your Account statement shows transfers that you did not make,
          including those made with your login information or by other means,
          tell us immediately. If you do not tell us within 60 days after we
          provided the applicable statement to you, you may not be entitled to
          receive any money you lost if we can prove that we could have
          prevented someone from taking the money if you had told us in time. If
          a good reason (such as a long trip or a hospital stay) kept you from
          telling us, we may extend the time periods in our sole discretion.
        </Paragraph>
        <b>D. Complaints</b>
        <Paragraph>
          If you have a question or complaint regarding the Services, please
          email us at <b>hello@herofinancials.com</b>. You may also contact us
          by writing to: Hero Innovation Group, 170 – 422 Richards Street,
          Vancouver, British Columbia, Canada V6B 2Z 4, ATTN: Compliance, or by
          calling us at <b>1-800-508-8813</b>. Please note that email
          communications will not necessarily be secure; accordingly, you should
          not include credit card information or other sensitive information in
          your email correspondence with us.
        </Paragraph>

        <b>5. Technology</b>
        <b>A. Hero App</b>
        <Paragraph>
          In consideration of you agreeing to abide by these Terms of Service,
          we hereby grant you a non-transferable, non-exclusive license to use
          the Hero App on your device subject to these Terms of Service. We
          reserve all other rights. From time to time updates to the Hero App
          may be issued through the App Store or Google Play. Depending on the
          update, you may not be able to use the Services via the Hero App until
          you have downloaded the latest version of the Hero App and accepted
          any new terms. In addition, you must comply with the terms of any
          third-party agreement applicable to you when using the App, such as
          your wireless data service agreement.
        </Paragraph>
        <b>B. Information Security</b>
        <Paragraph>
          Please see <b>Account Security and Privacy</b> for further details on
          how to keep your Account safe. You are responsible for configuring
          your information technology, computer programs and platform in order
          to access the Services. You should use your own virus protection
          software. You must not misuse the Services by introducing viruses,
          trojans, worms, logic bombs or other materials which are malicious or
          technologically harmful. You must not attempt to gain unauthorized
          access to the Services, the Websites or our servers, computers or
          databases. You must not attack the Services, including via the
          Websites, with any type of denial-of-service attack. By breaching this
          provision, you may be committing a criminal offence. We may report any
          such breach to relevant law enforcement authorities and we may
          co-operate with those authorities by disclosing your identity or other
          information to them. In the event of such a breach, your right to
          access and use the Websites and/or the Services will cease immediately
          without notice, and you agree to immediately cease all such access and
          use.
        </Paragraph>
        <b>C. Third Party Services</b>
        <Paragraph>
          You acknowledge and agree that we may engage third party partners and
          providers in order to deliver the Services to you. Certain Website or
          Hero App functionality may enable access to information, products,
          services and other materials made available by third parties (“
          <b>Third Party Materials</b>
          “), or allow for the routing or transmission of Third Party Materials,
          including via links. By using such functionality, you are directing us
          to access, route and transmit to you any applicable Third Party
          Materials
        </Paragraph>
        <Paragraph>
          We neither control nor endorse, nor are we responsible for, any Third
          Party Materials, including the accuracy, validity, timeliness,
          completeness, reliability, integrity, quality, legality, usefulness or
          safety of such Third Party Materials, or any intellectual property
          rights therein. Certain Third Party Materials may, among other things,
          be inaccurate, misleading or deceptive. Nothing in these Terms of
          Service shall be deemed to be a representation or warranty by us with
          respect to any Third Party Materials. We have no obligation to monitor
          Third Party Materials, and we may block or disable access to any Third
          Party Materials (in whole or part) through the Websites or Hero App at
          any time. In addition, the availability of any Third Party Materials
          through the Websites or Hero App does not imply our endorsement of, or
          our affiliation with, any provider of such Third Party Materials, nor
          does such availability create any legal relationship between you and
          any such provider.
        </Paragraph>
        <Paragraph>
          Your use of Third Party Materials is at your own risk and is subject
          to any additional terms, conditions and policies applicable to such
          Third Party Materials (such as terms of service or privacy policies of
          the providers of such Third Party Materials).
        </Paragraph>

        <b>6. Limitations on Liability and Other General Terms</b>
        <b>A. Limitation on Hero’s Liability</b>
        <Paragraph>
          SOME PROVINCES AND TERRITORIES DO NOT PROVIDE EXCLUSION OF LIMITATION
          OF LIABILITY FOR ALL TYPES OF DAMAGES. IN THOSE PROVINCES, HERO WILL
          ONLY BE LIABLE TO YOU FOR DAMAGES THAT WE ARE EXPRESSLY REQUIRED TO BE
          LIABLE TO YOU UNDER APPLICABLE LAW.
        </Paragraph>
        <Paragraph>
          IN ANY OTHER CASE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT
          SHALL WE, AND THE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS,
          EMPLOYEES AND SUPPLIERS OF HERO, BE LIABLE FOR LOST PROFITS OR ANY
          SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL, OR PUNITIVE DAMAGES
          (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF DATA OR LOSS OF
          BUSINESS) ARISING OUT OF OR IN CONNECTION WITH OUR WEBSITES, THE HERO
          APP, THE SERVICES, OR THESE TERMS OF SERVICE (HOWEVER ARISING,
          INCLUDING NEGLIGENCE). OUR LIABILITY, AND OUR (AND THEIR RESPECTIVE)
          OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES AND SUPPLIERS,
          TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE
          ACTUAL AMOUNT OF DIRECT DAMAGES. IN ADDITION, TO THE EXTENT PERMITTED
          BY APPLICABLE LAW, HERO AND OUR (AND THEIR RESPECTIVE) OFFICERS,
          DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, AND SUPPLIERS ARE NOT
          LIABLE, AND YOU AGREE NOT TO HOLD THESE PARTIES RESPONSIBLE, FOR ANY
          DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY,
          GOODWILL OR REPUTATION, PROFITS, OR OTHER INTANGIBLE LOSSES OR ANY
          SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES) RESULTING DIRECTLY OR
          INDIRECTLY FROM: (A) YOUR USE OF OR YOUR INABILITY TO USE HERO’S SITES
          AND SERVICES; (B) DELAYS OR DISRUPTIONS IN HERO’S SITES AND SERVICES;
          (C) VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING HERO’S
          SITES OR SERVICES OR ANY SITE OR SERVICE LINKED TO HERO’S SITES OR
          SERVICES; (D) GLITCHES, BUGS, ERRORS, OR INACCURACIES OF ANY KIND IN
          HERO’S SITES OR SERVICES OR IN THE INFORMATION AND GRAPHICS OBTAINED
          FROM THEM; (E) THE CONTENT, ACTIONS, OR INACTIONS OF THIRD PARTIES;
          (F) A SUSPENSION OR OTHER ACTION TAKEN WITH RESPECT TO YOUR ACCOUNT;
          (G) YOUR NEED TO MODIFY PRACTICES, CONTENT OR BEHAVIOR, OR YOUR LOSS
          OF OR INABILITY TO DO BUSINESS, AS A RESULT OF CHANGES TO THIS
          AGREEMENT OR HERO’S POLICIES. HERO RESERVES THE RIGHT TO MODIFY ITS
          POLICIES AND THIS TERMS OF SERVICE AT ANY TIME CONSISTENT WITH THE
          PROVISIONS OUTLINED HEREIN.
        </Paragraph>
        <b>B. Service Availability</b>
        <Paragraph>
          We will try to make sure the Services are available to you when you
          need them. However, we do not guarantee that the Services will always
          be available or be uninterrupted. We have the right to suspend,
          withdraw, discontinue or change all or any part of the Services
          without notice. We will not be liable to you if for any reason the
          Services are unavailable (in whole or in part) at any time or for any
          period. You are responsible for making all arrangements necessary for
          you to have access to the Services (including all hardware and
          telecommunications services).
        </Paragraph>
        <b>C. No Warranty</b>
        <Paragraph>
          SOME PROVINCES AND TERRITORIES DO NOT ALLOW FOR THE EXCLUSION OF
          WARRANTIES. IN THESE PROVINCES AND TERRITORIES, YOU HAVE ONLY THE
          WARRANTIES THAT ARE EXPRESSLY REQUIRED TO BE PROVIDED IN ACCORDANCE
          WITH APPLICABLE LAW.
        </Paragraph>
        <Paragraph>
          IN ALL OTHER PROVINCES AND TERRITORIES, EXCEPT AS EXPRESSLY PROVIDED
          HEREIN, HERO, OUR EMPLOYEES AND OUR SUPPLIERS PROVIDE THE SERVICES “AS
          IS” AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR
          STATUTORY. HERO, OUR EMPLOYEES AND OUR SUPPLIERS SPECIFICALLY DISCLAIM
          ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </Paragraph>
        <b>D. Events Outside of Our Control</b>
        <Paragraph>
          “Hero Innovation Group”, “Hero Financials”, “SideKick Card,” and
          “Hero,” are all logos related to the Services that are either
          trademarks or registered trademarks of Hero or Hero’s licensors. You
          may not copy, imitate, modify or use them without Hero’s prior written
          consent. In addition, all page headers, custom graphics, button icons,
          and scripts are service marks, trademarks and/or trade dress of Hero.
          You may not copy, imitate, modify or use them without our prior
          written consent. You may use HTML logos provided by Hero for the
          purpose of redirecting web traffic to the Services. You may not alter,
          modify or change these HTML logos in any way, use them in a manner
          that mischaracterizes Hero or the Services or display them in any
          manner that implies Hero’s sponsorship or endorsement. All right,
          title and interest in and to the Websites, any content thereon, the
          Services, the technology related to the Services, and any and all
          technology and any content created or derived from any of the
          foregoing is the exclusive property of Hero and its licensors.
        </Paragraph>
        <b>E. Independent Contractor Relationship</b>
        <Paragraph>
          Our relationship with you under these Terms of Service is as
          independent contractors for all intents and purposes. Hero is not your
          agent or trustee.
        </Paragraph>
        <b>F. Law and Jurisdiction</b>
        <Paragraph>
          The provision of the Services and any dispute or claim arising out of
          the provision of the Services is governed by the laws of the Province
          of British Columbia and the federal laws of Canada applicable therein.
          Any dispute or claim arising out of or in connection with the
          Services, these Terms of Service, or use of the Websites or Hero App
          will be subject to the exclusive jurisdiction of the courts of the
          Province of British Columbia.
        </Paragraph>
        <b>G. No Assignment</b>
        <Paragraph>
          You may not transfer or assign any rights or obligations you have
          under these Terms of Service without Hero’s prior written consent.
          Hero may transfer or assign these Terms of Service or any right or
          obligation under these Terms of Service at any time.
        </Paragraph>
        <b>H. No Waiver</b>
        <Paragraph>
          Our failure to act with respect to a breach of any of your obligations
          under these Terms of Service by you or others does not waive our right
          to act with respect to subsequent or similar breaches.
        </Paragraph>
        <b>I. Entire Agreement</b>
        <Paragraph>
          These Terms of Service, along with any applicable policies and
          agreements incorporated herein, on the Websites or in the Hero App,
          set forth the entire understanding between you and Hero with respect
          to the Services. Certain terms by their nature will survive the
          termination of this Agreement, including but not limited to 3.G.ii.,
          4.A., 4.B., 6.A., and 6.C. If any provision of this Agreement is held
          to be invalid or unenforceable, such provision shall be struck and the
          remaining provisions shall be enforced.
        </Paragraph>
        <b>SideKick Reloadable Prepaid Mastercard® Card Cardholder Agreement</b>
        <b>
          Please read this Agreement carefully and retain a copy for your
          records.
        </b>
        <Paragraph>
          <i>
            The following terms and conditions apply to your use of the Sidekick
            Reloadable Prepaid Mastercard Card.
          </i>
        </Paragraph>
        <Paragraph>
          <i>
            By signing and/or using the Card, you are agreeing to these terms
            and conditions and fees outlined below. Cards are issued in
            connection with award, promotion or loyalty programs, without
            consideration given or provided by you.
          </i>
        </Paragraph>
        <Paragraph>
          <b>INFORMATION DISCLOSURE SUMMARY </b>(detailed terms and conditions
          will follow):
        </Paragraph>
        <Paragraph>
          <b>Card issuer:</b> This card is issued by Peoples Trust Company under
          licence by Mastercard International Incorporated.
        </Paragraph>
        <Paragraph>
          <b>Card information and balance:</b> To obtain the expiry date of the
          Card, if you have questions regarding the Card Balance, or to log a
          complaint, you may call customer service at <b>1-800-508-8813</b> or
          log into the <b>Hero App</b> for free.
        </Paragraph>
        <Paragraph>
          <b>Card restrictions:</b>
        </Paragraph>
        <ul>
          <li>Cardholders are restricted to one Card per person.</li>
          <li>Use of the Card in certain countries may be restricted by law</li>
          <li>The Card is not returnable.</li>
          <li>The Card is not refundable.</li>
          <li>
            You may not use your Card to commit or facilitate illegal activity.
          </li>
          <li>Only the Program Sponsor may add funds to the Card.</li>
          <li>The Card cannot be used for pay-at-the-pump transactions.</li>
          <li>
            You do not have the right to stop the payment of any transaction you
            conduct with the Card.
          </li>
          <li>
            The Card may not be cancelled by you unless otherwise directed in
            this Agreement or by Applicable Law.
          </li>
          <li>
            Regular pre-authorized debit (PAD) transactions, where you authorize
            a company or organization to withdraw funds from the Card, are not
            permitted. All PAD transactions will be rejected and Peoples Trust
            Company will not be liable for any costs incurred by you as a
            result.
          </li>
          <li>
            The Card is subject to certain limits, as set out below. Peoples
            Trust Company may change these limits in accordance with Applicable
            Law and will post notice on payaccount.io/activate at least thirty
            (30) days in advance of the date such change is to come into effect.
            The change will take effect on the date indicated in the notice.
            Your continued use of the Card, after the change to the limits has
            come into effect, will be taken as your acceptance of that change.
          </li>
        </ul>
        <Paragraph>
          <b>Limits</b>
        </Paragraph>
        <Paragraph>
          Maximum Card Balance $10,000 <br />
          Maximum daily spend at point-of-sale $5,000 <br />
          Maximum number of point-of-sale- transactions / day 10 <br />
          Maximum daily ATM withdrawal $500 <br />
          Maximum number of daily ATM withdrawals 2 <br />
          Maximum daily combined spend and cash withdrawal $5,000
        </Paragraph>
        <Paragraph>
          Please also note: The Card may be deactivated at any time if fraud,
          related to the Card or use of the Card, is suspected.
        </Paragraph>
        <Paragraph>
          <b>Card expiry and access to funds:</b> The Card has an expiry date
          printed on the front. Funds will expire on the last calendar day of
          the printed month shown on the Card. After expiry, the Card will be
          void, and no replacement will be made.
        </Paragraph>
        <Paragraph>
          <b>Fees:</b> The table below sets out the fees that may be imposed
          upon the Card. You acknowledge being advised of the fees and agree to
          pay all fees charged under this Agreement.
        </Paragraph>
        <Paragraph>
          <b>Fees†</b>
        </Paragraph>
        <Paragraph>
          Customer service – live agent $2.00 <br />
          Foreign exchange charge 2.5% <br />
          ATM†† withdrawal (Canada) $1.95 <br />
          ATM†† withdrawal (international) $4.95 <br />
          ATM†† decline $0.50
        </Paragraph>
        <Paragraph>Card replacement (lost or stolen) $20.00</Paragraph>
        <Paragraph>Monthly fee</Paragraph>
        <ul>
          <li>
            Card Inactivity Fees [If there has been no card activity for 180
            days, this fee will be deducted from the card balance]: $2.50
          </li>
          <li>
            Card Dormancy Fees [If there has been no card activity for 210 days,
            this fee will be deducted from the card balance]: $2.50
          </li>
        </ul>
        <Paragraph>
          † Subject to change. See terms and conditions below for details.
        </Paragraph>
        <Paragraph>
          †† Additional fees may be charged for use of ATMs by ATM operators,
          over which we have no control.
        </Paragraph>
        <Paragraph>
          <b>
            Funds loaded onto the Card are not insured by the Canada Deposit
            Insurance Corporation (CDIC).
          </b>
        </Paragraph>
        <Paragraph>
          <b>
            Lost or stolen Card or PIN: You must take all reasonable steps to
            protect the Card and/or PIN against loss, theft, or unauthorized
            use.
          </b>{' '}
          You should not maintain a written record of, or disclose the PIN to a
          third party, including family members and friends.{' '}
          <b>
            If you lose the Card and/or PIN or you become aware that the PIN may
            have become known to someone else, you must contact customer service
            immediately at 1-800-508-8813.
          </b>{' '}
          Avoid PIN combinations that may be easily guessed by others. All
          transactions carried out on the Card before you notify us will be
          considered to have been made by you.
        </Paragraph>
        <Paragraph>
          If you forget the PIN, you can obtain a reminder by calling customer
          service at <b>1-800-508-8813</b>. The PIN may be disabled if an
          incorrect PIN is entered three (3) times. If the PIN is disabled,
          please call customer service for assistance.
        </Paragraph>
        <Paragraph>
          <b>Split tender transactions:</b> If the Balance on the Card is
          insufficient to cover the full Transaction Amount, you may request the
          merchant to conduct a split tender transaction, which is where you use
          the Card as partial payment of the Transaction Amount and then pay the
          remainder of the amount with another form of payment (e.g. cash,
          cheque, credit or debit). If you fail to inform the merchant that you
          would like to complete a split tender transaction prior to swiping the
          Card, the Card may be declined. Some merchants may require payment for
          the remaining Balance in cash. Merchants do not have to and may not
          agree to accept split tender transactions.
        </Paragraph>
        <Paragraph>
          <b>Personal Information:</b> By accepting the Card, you consent to the
          collection, use, disclosure and retention of your personal information
          by Peoples Trust Company and its service providers as described below.
          The collection of that information is necessary for the entering into
          and performance of this Agreement. Therefore, if you do not consent to
          the collection, use, disclosure and retention of your personal
          information, you may not accept or use a Card.
        </Paragraph>
        <Paragraph>
          <b>Key Cardholder Responsibilities under this Agreement:</b>
        </Paragraph>
        <ul>
          <li>
            You must take all reasonable steps to protect the Card (and PIN, if
            applicable) against loss, theft, or unauthorized use. If you lose
            the Card (or PIN), you must call customer service immediately.
          </li>
          <li>
            You must activate and/or sign the Card as instructed upon receipt of
            the Card.
          </li>
          <li>
            You must surrender the Card to us immediately upon request by us.
          </li>
          <li>
            You must ensure that there is a sufficient Balance on the Card to
            cover the full amount of transactions made with the Card.
          </li>
          <li>
            If your information, associated with the Card, changes, you must
            notify us of the change(s).
          </li>
          <li>
            If you become aware that your information, associated with the Card,
            is incorrect, you must notify us of the correct information.
          </li>
          <li>
            If you find an error in any transaction record, you must communicate
            the error to the merchant with whom you made the transaction.
          </li>
          <li>
            If you wish to dispute a transaction on your Card, you must notify
            us in writing of your dispute within sixty (60) days of the
            transaction date.
          </li>
          <li>
            You must only use our online resources as set out in ‘Website and
            Availability’, below.
          </li>
        </ul>
        <Paragraph>
          <b>DETAILED TERMS AND CONDITIONS:</b>
        </Paragraph>
        <Paragraph>
          <b>Definitions:</b>
        </Paragraph>
        <ul>
          <li>
            <b>‘Agreement’</b> means this SideKick Reloadable Prepaid Mastercard
            Card Cardholder Agreement between Peoples Trust Company and the
            Cardholder and all documents that are expressly referred to herein,
            which govern your use of the SideKick Reloadable Prepaid Mastercard
            Card.
          </li>
          <li>
            <b>‘Amendment’</b> refers to any change to a term or condition of
            this Agreement or to the addition of a new term or condition,
            including increasing or adding new fees.
          </li>
          <li>
            <b>‘Applicable Law’</b> means the Trust and Loan Companies Act
            (Canada), the Personal Information Protection and Electronic
            Documents Act (Canada), the Act Respecting the Protection of
            Personal Information in the Private Sector (Québec), the Consumer
            Protection Act (Québec), the Proceeds of Crime (Money Laundering)
            and Terrorist Financing Act (PCMLTFA), Canada’s Anti-Spam
            Legislation (CASL), PCI DSS or any other statute, regulation or
            operating rule of any Governmental Authority or any other regulatory
            authority that Peoples Trust Company and the Program Sponsor are
            subject to, or any bylaw, operating rule or regulation of
            Mastercard.
          </li>
          <li>
            <b>‘Balance’</b> means the amount of the funds that are loaded onto
            the Card.
          </li>
          <li>
            <b>‘Canada’s Anti-Spam Legislation (CASL)’ </b>means An Act to
            promote the efficiency and adaptability of the Canadian economy by
            regulating certain activities that discourage reliance on electronic
            means of carrying out commercial activities, and to amend the
            Canadian Radio-television and Telecommunications Commission Act, the
            Competition Act, the Personal Information Protection and Electronic
            Documents Act and the Telecommunications Act (Canada), and its
            regulations, as may be amended from time to time.
          </li>
          <li>
            <b>‘Card’</b> refers to the SideKick Reloadable Prepaid Mastercard
            Card activated, signed, or used by the Cardholder.
          </li>
          <li>
            <b>‘Cardholder’</b> means you or any other individual who has
            received, activated, signed or used the Card.
          </li>
          <li>
            <b>‘Foreign exchange charge’</b> means the foreign exchange service
            charge we assess when you complete transactions in a currency other
            than Canadian dollar.
          </li>
          <li>
            <b>‘Governmental Authority’</b> means any federal, provincial,
            territorial, regional, municipal or local governmental authority,
            quasi-governmental authority (including the Office of the
            Superintendent of Financial Institutions), government organization,
            court, commission, board, professional agency, tribunal,
            organization, or any regulatory, administrative or other agency, or
            any political or other subdivision, department, or branch of any of
            the foregoing, in each case to the extent it has jurisdiction over
            Peoples Trust Company and/or the Program Sponsor or any Person,
            property, transaction, activity, event or other matter related to
            this Agreement. The above definition is deemed to include any
            interim or permanent transferee or successor of a Government
            Authority’s underlying mandate, function or activity.
          </li>
          <li>
            <b>‘Mastercard’</b> means Mastercard Incorporated, and its
            successors and assigns.
          </li>
          <li>
            <b>‘Mastercard Conversion Rate’</b> means the rate that we pay to
            Mastercard to convert foreign currency to Canadian currency plus the
            Foreign exchange charge.
          </li>
          <li>
            <b>‘PCI DSS’</b> means a multifaceted security standard defined by
            Payment Card Industry Security Standards Council and includes
            requirements for security management, policies, procedures, network
            architecture, software design and other critical protective
            measures.
          </li>
          <li>
            <b>‘POS’</b> means point of sale, where you can use the Card to
            purchase goods or services from a merchant.
          </li>
          <li>
            <b>‘Program Manager’</b> means they act on behalf of the issuing
            bank as a third-party agent to issue prepaid cards.
          </li>
          <li>
            <b>‘Program Sponsor’</b> means the business or organization that has
            provided the Card to you as part of such Program Sponsor’s loyalty,
            award or promotion program.
          </li>
          <li>
            <b>‘Transaction Amount’</b> is the amount that is debited from the
            Balance in connection with the Cardholder’s use of the Card to
            purchase goods or services, which includes the amount of the Balance
            to be transferred, the Card service charges and the taxes imposed to
            complete the transaction.
          </li>
          <li>
            <b>‘we’</b>, <b>‘us’</b>, and <b>‘our’</b> mean Peoples Trust
            Company, and our successors, subsidiaries, affiliates or assignees.
          </li>
          <li>
            <b>‘Website’</b> means payaccount.io/activate.
          </li>
          <li>
            <b>‘you’</b>, <b>‘your’</b>, and <b>‘yours’</b> each mean the
            Cardholder.
          </li>
        </ul>
        <Paragraph>
          <b>Acceptance:</b> This Agreement constitutes a binding agreement
          between you and us with respect to the terms of use of the Card.
        </Paragraph>
        <Paragraph>
          <b>The SideKick Reloadable Prepaid Mastercard Card:</b> The Card is a
          prepaid Mastercard that can be used anywhere that Mastercard is
          accepted, including mail order, online, telephone and point of sale
          retail merchants, subject to the terms of this Agreement.
        </Paragraph>
        <Paragraph>
          <b>
            Activating the Card: The Card cannot be used for any purpose until
            it has been activated and signed by you on the back of the Card
            where indicated.
          </b>{' '}
          Whether or not the Card has an activation sticker adhered to the Card
          face, <b>you must sign the back of the Card</b>.
        </Paragraph>
        <Paragraph>
          <b>Ownership and Use of the Card:</b> The Card can be used to pay the
          full amount of the purchase and applicable taxes, so long as the
          Balance remaining on the Card is sufficient. The Card is, and will
          remain, our property. The Card is not a credit card, charge card, or
          debit card and its usage will not enhance nor improve your credit
          rating. No interest dividends or other earnings or returns will be
          paid on the Card. Neither the Card nor the Balance is a deposit
          account.<br></br>You have no right to write cheques on, or demand
          repayment of, the outstanding Balance on the Card, but are strictly
          limited to the right to use the Card, in accordance with this
          Agreement, as payment for goods and services from merchants who accept
          Mastercard.
        </Paragraph>
        <Paragraph>
          When you use the Card to make a purchase, the amount of the purchase,
          plus any applicable fees and taxes, will be deducted from the
          available Balance associated with the Card. If you use the Card for
          card-not-present transactions (such as online, mail or telephone
          purchases), the legal effect is the same as if you used the physical
          Card.
        </Paragraph>
        <Paragraph>
          You will be solely and completely responsible for the possession, use
          and control of the Card.{' '}
          <b>
            You must surrender the Card to us immediately upon request by us
          </b>
          . The Card is provided to you, the Cardholder, only. You may not sell
          or assign the Card to a third party. If you authorize another person
          to use the Card you agree, to the extent permitted by law, that you
          will be liable for all transactions arising from use of the Card by
          such person.
        </Paragraph>
        <Paragraph>
          You agree that we are not required to verify the signature on any
          sales draft prepared in connection with a transaction on the Card, and
          we may authorize and process a transaction even if the signature on
          the sales draft is different than the signature on the Card. You do
          not have the right to stop the payment of any transaction you conduct
          with the Card. We are not liable to you for declining authorization
          for any particular transaction, regardless of our reason. We may, in
          our sole discretion, cancel or suspend any features or services of the
          Card at any time, with or without cause, with thirty (30) days’ notice
          to you or as otherwise required by Applicable Law.
        </Paragraph>
        <Paragraph>
          Some merchants (including, but not limited to, fuel stations,
          restaurants, hotels, cruise lines, or car rental companies) may
          pre-authorize the Transaction Amount for the purchase amount plus up
          to 20% (or more) above the purchase amount to ensure there are
          sufficient funds available on the Card to cover any tips or incidental
          expenses. In such cases, your transaction will be declined if the Card
          Balance will not cover the Transaction Amount plus the additional
          amount.
          <br></br>A pre-authorization will place a ‘hold’ on an amount of your
          available Card funds until the merchant sends us the final payment
          amount of your purchase. Once the final payment amount is received,
          the pre-authorization amount on hold in excess of that final payment
          amount will be released. The time it takes for a pre-authorization
          hold to be removed may vary depending on the type of merchant. A hold
          is typically removed within fifteen (15) business days for most
          standard merchants, and within thirty (30) days for hotels, cruise
          lines and car rental agencies. During the hold period, you will not
          have access to the pre-authorized amount.
        </Paragraph>
        <Paragraph>
          <b>
            Information About Balance: It is your responsibility to ensure that
            there is a sufficient Balance on the Card to cover transactions plus
            any pre-authorized amounts.
          </b>{' '}
          To obtain the current Balance amount, or the transaction history, you
          may call customer service toll-free at <b>1-800-508-8813</b> or visit
          the Website. The Card Balance will reflect all transactions that have
          been posted to our system. You are not allowed to exceed the Balance
          available on your Card for any transaction.
        </Paragraph>
        <Paragraph>
          If you attempt to use the Card when there is insufficient Balance
          available to cover the full Transaction Amount, the transaction in
          most instances will be declined. However, if due to a systems
          malfunction or for any reason whatsoever, a transaction occurs despite
          insufficient Balance on the Card, creating a negative amount, you
          agree to reimburse us, upon request, for the amount of the Transaction
          Amount in excess of the Balance.
        </Paragraph>
        <Paragraph>
          <b>Transactions Made in Foreign Currencies:</b> We convert
          transactions, made in a foreign currency, to Canadian dollars using
          the Mastercard Conversion Rate in effect on the day the transaction is
          posted to the Card. The Mastercard Conversion Rate in effect on the
          posting date may differ from the rate in effect on the date of the
          transaction.
        </Paragraph>
        <Paragraph>
          However, if a foreign currency transaction is refunded to the Card,
          the Mastercard Conversion Rate used to convert your refund to Canadian
          dollars for the Card is the rate that we pay to Mastercard minus the
          Foreign exchange charge. Additionally, the rate that we pay to
          Mastercard may not be the same as the rate that existed on the date
          the transaction was refunded. For these reasons, the amount that is
          credited to the Card for a refund of a foreign currency transaction
          will, in most cases, be less than the amount that was originally
          charged to the Card for that transaction.
        </Paragraph>
        <Paragraph>
          <b>Protection Against Loss, theft, or Unauthorized Use:</b> If the
          Card is lost or stolen, you will be asked to provide us with the name
          on the Card and the Card number, and to answer an identifying question
          drawn from your personal information. If you lose the Card, someone
          might be able to use the Balance on the Card.
        </Paragraph>
        <Paragraph>
          The Card can be used without a PIN to make purchases online.
        </Paragraph>
        <Paragraph>
          We will refund any remaining Balance (less the card replacement fee)
          after we have processed all transactions completed before we had an
          opportunity to act on your information. We will have a customer
          service representative or automated voice response service available
          seven (7) days a week, twenty-four (24) hours a day at 1-855-342-6924
          that will allow immediate cancellation of the Card upon your request.
          We recommend that you write down the Card number and the customer
          service number in case the Card is lost or stolen.
        </Paragraph>
        <Paragraph>
          A replacement Card with any remaining Balance (less our fees as stated
          in the table marked ‘Fees’) will be issued within ten to fifteen
          (10-15) business days after you report the Card lost or stolen to
          ensure that all transactions have been processed properly.
        </Paragraph>
        <Paragraph>
          You agree, to the extent permitted by Applicable Law, to cooperate
          with us in our attempts to recover from unauthorized users and to
          assist in their prosecution.
        </Paragraph>
        <Paragraph>
          The Mastercard Zero Liability Policy applies to purchases made
          in-store, via telephone, online, or from a mobile device. As a
          cardholder, you will not be held responsible for unauthorized
          transactions if:
        </Paragraph>
        <Paragraph>
          (i) you have exercised reasonable care in protecting the Card and PIN
          (Personal Identification Number) from loss or theft, and
        </Paragraph>
        <Paragraph>
          (ii) you promptly reported the loss or theft of the Card or PIN to us.
        </Paragraph>
        <Paragraph>
          If these conditions are not met you will be liable for all
          unauthorized transactions completed before you reported the loss or
          theft of the Card or PIN to us. Verification of a Zero Liability claim
          can take up to one hundred and twenty (120) days once all the required
          forms and/or documents have been received and confirmed by us, and may
          require a police investigation.
        </Paragraph>
        <Paragraph>
          <b>Notification and Changes to Terms:</b> Subject to the limitations
          of Applicable Law, we may from time to time amend any term or
          condition of this Agreement or add a new term or condition, including
          increasing or adding new fees. As required by Applicable Law, notice
          of any Amendments will be sent to you at the most recent mailing or
          email address that we have on record for you. We must, at least thirty
          (30) days before the Amendment comes into force, send you a written
          notice drawn up clearly and legibly, setting out the new clause(s)
          only, or the amended clause(s) and the clause(s) as it (they) read
          formerly, the date of the coming into force of the Amendment and your
          rights set forth below.
        </Paragraph>
        <Paragraph>
          You may refuse the Amendment and rescind this Agreement without cost,
          penalty or cancellation indemnity by sending us a notice to that
          effect no later than thirty (30) days after the Amendment comes into
          force, if the Amendment entails an increase in your obligations or a
          reduction in our obligations. If you choose to rescind this Agreement,
          the Cancellation section of this Agreement will apply. Notification of
          any Amendment will also be posted on the Website at least sixty (60)
          days in advance of the effective date of the Amendment, unless
          otherwise required by Applicable Law. The change will take effect on
          the date indicated in the notice.{' '}
          <b>
            You are responsible for (i) informing us of any change in your
            mailing or email address, by contacting customer service at
            1-800-508-8813
          </b>
          , and (ii) for checking the Website for such notifications. Notice
          will be deemed to be received by you five (5) days after mailing, or
          the next business day after electronic mail.
        </Paragraph>
        <Paragraph>
          You may notify us by delivering notice to the Program Sponsor or
          sending notice to us at the Website (other than notification of a lost
          or stolen Card, which may only be done by telephone as set out above).
          Notice will be deemed to be received on the date of delivery of notice
          to us or the Program Sponsor, as applicable, and the next business day
          after electronic mail.
        </Paragraph>
        <Paragraph>
          <b>Personal Information Consent:</b> By applying for a Card, you
          consent to the collection, use, disclosure and retention of your
          personal information by us and our service providers for purposes
          relating to your application for a Card and your use of a Card (if
          issued to you) and as otherwise described below. The collection of
          that information is necessary for the entering into and performance of
          this Agreement. Therefore, if you do not consent to the collection,
          use, disclosure and retention of your personal information, you may
          not apply for or use a Card. As explained below, you may withdraw your
          consent at any time by cancelling your Card and all related services
          from us. The restrictions and requirements described herein do not
          apply to information that is aggregated or otherwise de-personalized
          and does not identify you.
        </Paragraph>
        <Paragraph>
          <b>Safeguarding Your Personal Information:</b> We protect personal
          information in our possession or control from loss, theft, alteration
          and misuse. The safeguards employed by us to protect your personal
          information depend on the sensitivity, amount, distribution, format
          and storage of the personal information. Although technologies can
          make it easier for fraud to occur, we employ around the clock
          monitoring systems and controls to detect and prevent fraudulent
          activity. We also build fraud prevention measures into our due
          diligence processes and regularly update our fraud
          detection/prevention methods. While we take precautions to protect
          your personal information from loss, theft, alteration, or misuse, no
          system or security measure is completely secure. Any transmission of
          your personal data is at your own risk and we expect that you will use
          appropriate measures to protect your personal information as well.
        </Paragraph>
        <Paragraph>
          <b>Collecting your Personal Information:</b> We and our service
          providers will collect information about you (e.g. your name, address,
          telephone number and date of birth) when you apply for a Card and, if
          a Card is issued to you, we and our service providers will collect
          information about you and your use of the Card and related services,
          including information about your Card transactions (e.g. the date,
          amount and place of each transaction) (all collectively “Cardholder
          Information”). We and our service providers will collect your
          Cardholder Information directly from you and from other sources,
          including the Program Sponsor and third party providers of identity
          verification, demographic and fraud prevention services.
        </Paragraph>
        <Paragraph>
          <b>Using Your Personal Information:</b> We and our service providers
          will use, disclose and retain your Cardholder Information to process
          your application for a Card (including to verify your identity) and,
          if a Card is issued to you, to provide you with services relating to
          your Card (including to administer your Card and to process your Card
          transactions), to protect against fraud and for legal compliance
          purposes, to perform and enforce this Agreement, to protect and
          enforce our legal rights and for other purposes required or permitted
          by Applicable Law. We will disclose your Cardholder Information to our
          service providers to assist us to provide services to you and to
          provide related services to us.
        </Paragraph>
        <Paragraph>
          We maintain physical, electronic and procedural security measures that
          comply with Canadian regulations to safeguard Cardholder Information.
        </Paragraph>
        <Paragraph>
          We and our service providers may use and store your Cardholder
          Information at facilities in various countries (including Canada and
          the United States of America). The personal information protection
          laws of those countries might be different from the laws of the
          jurisdiction in which you are located, and might permit courts,
          government, law enforcement and regulatory agencies and security
          authorities to access your Cardholder Information without notice. The
          laws on data protection in other jurisdictions, to which we may
          transfer your information, may differ from those in your jurisdiction
          and any personal information transferred to another jurisdiction will
          be subject to law enforcement and national security authorities in
          that jurisdiction. Subject to these laws, we will use reasonable
          measures to maintain protections of your personal information that are
          equivalent to those that apply in your jurisdiction. You hereby give
          your consent to such cross-border transfers (including to the United
          States) of such personal information to third parties for the purpose
          set out above.
        </Paragraph>
        <Paragraph>
          We will use and rely on your Cardholder Information to issue and
          administer your Card and provide related services. We and our service
          providers will rely on you to ensure that your Cardholder Information
          is accurate, complete and up to date.{' '}
          <b>
            You will promptly inform us (by contacting Customer Service at
            1-800-508-8813 of any changes to your Cardholder Information or if
            you discover any errors in your Cardholder Information
          </b>
          . You may communicate with us through our customer service number [or
          the Website] with regards to requests to access information related to
          you that we have obtained. If such information is obtained from
          providers of identity verification data and demographic information,
          we will inform you of your right of access and rectification in
          relation to the file held by the personal information agent and will
          indicate to you the manner in which and the place where you may have
          access to the reports or recommendations and cause them to be
          rectified, where necessary. We and our service providers may use your
          Cardholder Information (including your telephone and mobile phone
          numbers and your email addresses) to contact you, including by regular
          and electronic mail, telephone call (including by pre-recorded or
          artificial voice messages and automatic telephone dialling systems)
          and instant messaging, regarding your Card and related matters,
          regardless of whether you incur any long distance or usage charges as
          a result.
        </Paragraph>
        <Paragraph>
          We and our service providers may monitor and record their
          communications and correspondence with you (including emails, online
          chats and telephone calls) for quality assurance, staff training and
          legal compliance purposes. With your consent, Peoples Trust and its
          service providers may share this information for audit related
          purposes to ensure you are receiving the best possible customer
          service.
        </Paragraph>
        <Paragraph>
          <b>Other Uses of Your Personal Information:</b> In addition to the
          foregoing, if you consent to a Program Sponsor or other third party
          collecting and using your personal information (including Cardholder
          Information) for their own purposes (not as our service provider),
          including to send marketing and promotional messages to you, then we
          will not have any control over, and will not be responsible or liable
          for, the collection, use, disclosure and retention of your personal
          information by the Program Sponsor or third party, the marketing or
          promotional messages that they send to you, or any other wrongful act
          or omission by the Program Sponsor or third party.
        </Paragraph>
        <Paragraph>
          <b>Your Right to Access Your Personal Information:</b> You may obtain
          access to the Cardholder Information we hold about you at any time and
          review its content and accuracy, and have it amended as appropriate;
          however, access may be restricted as permitted or required by law. To
          request access to such information, to ask questions about our privacy
          policies or to withdraw your consent to the collection, use and
          disclosure of your Cardholder Information and to cancel your Card and
          all related services from us, contact Customer Service (at{' '}
          <b>1-800-508-8813</b>). If you withdraw your consent, we will continue
          to collect, use, disclose and retain your Cardholder Information for
          as long as may be reasonably required to perform services relating to
          the cancellation of your Card, to protect against fraud and for legal
          compliance purposes, to perform and enforce this Agreement, to protect
          and enforce our legal rights and for other purposes required or
          permitted by Applicable Law.
        </Paragraph>
        <Paragraph>
          <b>Peoples Trust Company’s Privacy Policies:</b> Our general personal
          information practices are described in our privacy policy, as amended
          from time to time, available online at{' '}
          <a href="http://www.peoplestrust.com/en/legal/privacy-security/privacy/">
            http://www.peoplestrust.com/en/legal/privacy-security/privacy/
          </a>{' '}
          .
        </Paragraph>
        <Paragraph>
          <b>
            Disputes: If you believe a transaction on your Card account is
            incorrect, you must notify us in writing of your dispute within
            sixty (60) days of the transaction date.
          </b>{' '}
          Following your notification, a form will need to be completed and
          faxed to the customer service team within ninety (90) days of the
          transaction date. You can obtain a dispute form by calling{' '}
          <b>1-855-342-6924</b> and following the prompts for lost or stolen
          cards. Please note that this form must be received within ninety (90)
          days of the date of the disputed transaction or you will have been
          deemed to have accepted such transaction{' '}
          <b>
            If you identify an error in any transaction record, you must address
            such error with the applicable merchant.
          </b>{' '}
          If there is any dispute in regard to purchases you make using the
          Card, you agree to settle such disputes with the merchant from whom
          the purchase was made. Please ask the merchant for any return policy
          that may apply to purchases made with the Card. We are not responsible
          for any problems you may have with any goods or services that you
          purchase with your Card, whether with regard to quality, safety,
          legality, or any other aspect of your purchase. If you are entitled to
          a refund for any reason for goods or services obtained with the Card,
          you agree to accept credits to the Balance on your Card in place of
          cash.
        </Paragraph>
        <Paragraph>
          <b>
            Arbitration: Subject to all other terms of this Agreement, and to
            the extent not prohibited by Applicable Law, you agree that any
            claim of any kind against us, the Program Manager, the Program
            Sponsor or Mastercard arising from or related to this Agreement or
            the use of the Card (i) shall be resolved by final and binding
            arbitration before a single arbitrator at Vancouver, British
            Columbia and (ii) shall not be brought through class or individual
            litigation proceedings. If such a claim is advanced by class
            proceeding by any other person on your behalf, you will opt out of,
            or not opt into, such proceedings as circumstances dictate.
          </b>
        </Paragraph>
        <Paragraph>
          <b>Complaints:</b> If you have a complaint or inquiry about any aspect
          of your Card, you may first attempt to resolve the complaint or
          inquiry by calling our toll-free customer service number at{' '}
          <b>1-800-508-8813</b>. If customer service is unable to resolve the
          complaint or inquiry to your satisfaction, you may call us at
          1-855-694-6214 or submit your complaint or inquiry through the form
          found on the Website (
          <a href="http://www.peoplestrust.com/en/about-us/contact/">
            http://www.peoplestrust.com/en/about-us/contact/
          </a>
          ). We will do our best to resolve your complaint or inquiry.
        </Paragraph>
        <Paragraph>
          If for some reason we are unable to resolve the issue to your
          satisfaction, you may refer your inquiry or complaint to the Ombudsman
          for Banking Services and Investments at <b>1-888-451-4519</b> for
          resolution. If the Cardholder has a concern regarding a potential
          violation of a consumer protection law, a public commitment, or an
          industry code of conduct, the concern may be communicated at any time
          to the Financial Consumer Agency of Canada, either in person, by
          letter, by telephone, or through its website at:
        </Paragraph>
        <Paragraph>
          Financial Consumer Agency of Canada <br></br>
          427 Laurier Avenue West, 6th Floor <br></br>
          Ottawa, ON, K1R 1B9
        </Paragraph>
        <Paragraph>
          Telephone: <b>1-866-461-3222</b> <br></br>
          <a href="www.fcac-acfc.gc.ca">www.fcac-acfc.gc.ca</a>
        </Paragraph>
        <Paragraph>
          Our complaints policy can be found online at:{' '}
          <b>
            http://www.peoplestrust.com/en/about-us/resolving-your-concerns/.
          </b>
        </Paragraph>
        <Paragraph>
          <b>Cancellation:</b> You may at any time terminate this Agreement by
          calling <b>1-855-342-6924</b>. We may terminate this Agreement at any
          time, with or without cause. Upon Agreement cancellation, your card
          will also be cancelled, and you should destroy it immediately. Once
          the card is cancelled, a cheque for any remaining Balance, less any
          outstanding fees and preauthorized amounts, (and, in the case you
          cancelled the Agreement will be mailed to you within forty-five (45)
          business days from the cancellation date. Despite any termination of
          this Agreement, you must fulfil your obligations under this Agreement.
        </Paragraph>
        <Paragraph>
          Alternatively, you may surrender the Card to the Program Sponsor and
          the Program Sponsor will reimburse you in cash any remaining Balance
          on your Card less any outstanding fees.{' '}
          <b>
            We may terminate this Agreement at any time, at which time you must
            immediately return the Card to us or as we direct
          </b>
          , provided that if you are not in default of your obligations under
          this Agreement, we will notify you in writing at least sixty (60) days
          before the date of termination. We or our Program Sponsor will
          reimburse you any remaining Balance on the Card less outstanding fees.{' '}
          <b>
            Despite any termination of this Agreement, you must fulfil all of
            your obligations under this Agreement.
          </b>
        </Paragraph>
        <Paragraph>
          <b>
            No Warranty of Availability or Uninterrupted Use: FROM TIME TO TIME
            CARD SERVICES MAY BE INOPERATIVE, AND WHEN THIS HAPPENS, YOU MAY BE
            UNABLE TO USE YOUR CARD OR OBTAIN INFORMATION ABOUT THE BALANCE ON
            YOUR CARD. PLEASE NOTIFY US IF YOU HAVE ANY PROBLEMS USING YOUR
            CARD. YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY INTERRUPTION OF
            SERVICE.
          </b>
        </Paragraph>
        <Paragraph>
          <b>Third Party Claims:</b> In the event we reimburse you for a refund
          claim you have made, or if we otherwise provide you with a credit or
          payment with respect to any problem arising out of any transaction
          made with the Card, you are automatically deemed to assign and
          transfer to us any rights and claims (excluding tort claims) that you
          have, had or may have against any third party for an amount equal to
          the amount we have paid to you or credited to your Card. You agree
          that you will not pursue any claim against or reimbursement from such
          third party for the amount that we paid or credited to your Card, and
          that you will cooperate with us if we decide to pursue the third party
          for the amount paid or credited to you. If we do not exercise our
          rights under this section, we do not give up our rights to exercise
          them in the future.
        </Paragraph>
        <Paragraph>
          <b>
            Disclaimer of Warranties: EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN
            THIS AGREEMENT AND EXCEPT FOR ANY APPLICABLE WARRANTIES SET OUT IN
            THE CONSUMER PROTECTION ACT (QUÉBEC), WE MAKE NO REPRESENTATIONS OR
            WARRANTIES OF ANY KIND TO YOU, WHETHER EXPRESS OR IMPLIED, REGARDING
            ANY SUBJECT MATTER OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION,
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
            PARTICULAR PURPOSE OR THOSE ARISING BY STATUTE OR OTHERWISE IN LAW
            OR FROM A COURSE OF DEALING OR USAGE OF TRADE.
          </b>
        </Paragraph>
        <Paragraph>
          <b>
            Limitation of Liability: EXCEPT IN QUÉBEC, OR AS EXPRESSLY REQUIRED
            BY THIS AGREEMENT OR APPLICABLE LAW, WE WILL NOT BE LIABLE TO YOU
            FOR PERFORMING OR FAILING TO PERFORM ANY OBLIGATION UNDER THIS
            AGREEMENT UNLESS WE HAVE ACTED IN BAD FAITH. WITHOUT LIMITING THE
            FOREGOING, WE WILL NOT BE LIABLE TO YOU FOR DELAYS OR MISTAKES
            RESULTING FROM ANY CIRCUMSTANCES BEYOND OUR CONTROL, INCLUDING,
            WITHOUT LIMITATION, ACTS OF GOVERNMENTAL AUTHORITIES, NATIONAL
            EMERGENCIES, INSURRECTION, WAR, RIOTS, FAILURE OF MERCHANTS TO
            PERFORM OR PROVIDE SERVICES, FAILURE OF COMMUNICATION SYSTEMS, OR
            FAILURES OF OR DIFFICULTIES WITH OUR EQUIPMENT OR SYSTEMS. ALSO
            WITHOUT LIMITING THE FOREGOING, WE WILL NOT BE LIABLE TO YOU FOR ANY
            DELAY, FAILURE OR MALFUNCTION ATTRIBUTABLE TO YOUR EQUIPMENT, ANY
            INTERNET SERVICE, ANY PAYMENT SYSTEM OR ANY CUSTOMER SERVICE
            FUNCTION. IN THE EVENT THAT WE ARE HELD LIABLE TO YOU, YOU WILL ONLY
            BE ENTITLED TO RECOVER YOUR ACTUAL AND DIRECT DAMAGES. IN NO EVENT
            WILL YOU BE ENTITLED TO RECOVER ANY INDIRECT, CONSEQUENTIAL,
            EXEMPLARY OR SPECIAL DAMAGES (WHETHER IN CONTRACT, TORT, OR
            OTHERWISE), EVEN IF YOU HAVE ADVISED US OF THE POSSIBILITY OF SUCH
            DAMAGES.
          </b>
        </Paragraph>
        <Paragraph>
          <b>Website and Availability:</b> Although considerable effort is made
          to ensure that our Website and other operational and communications
          channels available around the clock, we do not warrant these channels
          to be available and error free at all times. You agree that we will
          not be responsible for temporary interruptions in service due to
          maintenance, website changes, or failures, nor will we be liable for
          extended interruptions due to failures beyond our control, including
          but not limited to the failure of interconnecting and operating
          systems, computer viruses, forces of nature, labour disputes and armed
          conflicts. We will not bear any liability, whatsoever, for any damage
          or interruptions caused by any computer viruses that may affect your
          computer or other equipment. You agree to act responsibly with regard
          to the Website and its use.{' '}
          <b>
            You will not violate any laws, interfere or disrupt computer
            networks, impersonate another person or entity, violate the rights
            of any third party, stalk, threaten or harass anyone, gain any
            unauthorized entry, or interfere with the Website’s systems and
            integrity.
          </b>
        </Paragraph>
        <Paragraph>
          <b>Assignment:</b> At our sole discretion, we may assign our rights
          and responsibilities under this Agreement at any time and without
          notice to you. If we do make such an assignment, then this Agreement
          will remain binding on you and your respective executors,
          administrators, successors, representatives and permitted assigns.
        </Paragraph>
        <Paragraph>
          <b>Entire Agreement:</b> This Agreement sets forth the entire
          understanding and Agreement between you and us, whether written or
          oral, with respect to the subject matter hereof and supersedes any
          prior or contemporaneous understandings or Agreements with respect to
          such subject matter.
        </Paragraph>
        <Paragraph>
          <b>Governing Law:</b> The parties agree that any claim or action
          brought pursuant to this Agreement will be brought in the exclusive
          jurisdiction of the courts of British Columbia and this Agreement will
          be construed in accordance with and governed by the laws of the
          Province of British Columbia and the laws of Canada applicable
          therein.
        </Paragraph>
        <Paragraph>
          <b>FOR RESIDENTS OF QUÉBEC ONLY:</b> The parties attorn to the
          jurisdiction of Québec and this Agreement will be construed in
          accordance with and governed by the laws of the province of Québec and
          the laws of Canada applicable therein.
        </Paragraph>
        <Paragraph>
          <b>Section Headings:</b> Section headings in this Agreement are for
          convenience of reference only and will not govern the interpretation
          of any provision of this Agreement.
        </Paragraph>
        <Paragraph>
          <b>Severability:</b> If any of the terms of this Agreement are
          invalid, changed by Applicable Law or declared invalid by order of
          court or regulatory authority, the remaining terms of this Agreement
          will not be affected, and this Agreement will be interpreted as if the
          invalid terms had not been included in this Agreement.
        </Paragraph>
        <Paragraph>
          <b>Contact Information:</b> If you have questions regarding the Card,
          or need to report a lost or stolen Card, you may call customer service
          at <b>1-800-508-8813</b> or email to: <b>hello@herofinancials.com.</b>
        </Paragraph>
        <Paragraph>
          Mastercard is a registered trademark, and the circles design is a
          trademark of Mastercard International Incorporated.{' '}
        </Paragraph>
        <Paragraph>
          <i>Updated: June 8, 2022.</i> <br></br>
          <b>Privacy Policy</b>
        </Paragraph>
        <Paragraph>Last modified: June 8, 2022</Paragraph>
        <Paragraph>
          We know that you care how information about you is used and shared,
          and we appreciate your trust that we will do so carefully and
          sensibly. This Privacy Policy describes the privacy practices of Hero
          Innovation Group (“Hero”, “we”, “our” and “us”) and applies to your
          use of the products and services we offer (collectively, the
          “Services”). We provide the Services through our mobile applications,
          web based applications and our websites at
          www.HeroInnovationGroup.com, www.SideKickCard.com and
          www.herofinancials.com (collectively, the “Account”). By using the
          Services, you accept the practices described in this Privacy Policy.
        </Paragraph>
        <Paragraph>This Privacy Policy explains:</Paragraph>
        <ul>
          <li>What information we collect and why we collect it.</li>
          <li>How we use that information.</li>
          <li>
            The choices we offer, including how to access and update your
            information.
          </li>
        </ul>
        <Paragraph>Information We Collect</Paragraph>
        <Paragraph>
          We collect information to provide, personalize and continually improve
          the Services and provide the best experience. This includes helping us
          to present relevant information to you within the Hero App and
          recognizing when you should receive discounts, incentives, rewards and
          other offers for an action you have taken.
        </Paragraph>
        <Paragraph>
          While using the services gathered data further aims to prevent fraud
          and remain in compliance with data privacy and finance regulations
          such as, but not limited to, FINTRAC, AML, GDPR and LGPD.
        </Paragraph>
        <Paragraph>We collect information in three ways:</Paragraph>
        <Paragraph>
          1. Information you give us. Many of the Services require you to sign
          up for an Account. When you do, we will ask for personal information,
          such as your name, company name, address, email address, telephone
          number and/or loyalty, prepaid, debit or credit card information to
          store with your Account. To complete transactions using the Services,
          you will need to provide the account and card details for one or more
          loyalty, prepaid, debit or credit cards held by you. We will also
          retain feedback and social media information posted by you in
          connection with the Services.
        </Paragraph>
        <Paragraph>
          2. Information we obtain from your use of the Services. We may collect
          information regarding your use of the Services including:
        </Paragraph>
        <ul>
          <li>
            Device Information. We collect device-specific information such as
            your hardware model, operating system and version, unique device
            identifiers, and mobile network information including phone number.
          </li>
          <li>
            Transaction Information. When you make, or request to make, a
            transaction, including a return or refund, we will receive details
            of the transaction including the amount of the transaction; the time
            of the transaction; the goods and/or services purchased; the details
            of any loyalty, points, discounts, incentives, rewards and other
            offers or benefits offered by, provided to or redeemed by or with
            you; and the details of the third party, including name, location
            and type of business.
          </li>
          <li>
            Location Information. We may collect and process information about
            your actual location. We use various technologies to determine
            location, including IP address, GPS and other sensors that may, for
            example, provide us with information on nearby devices, Wi-Fi access
            points and cell towers. You may disable location services with the
            Hero App or generally on your mobile device.
          </li>
          <li>
            Local Storage and Cookies. We may collect and store information
            (including personal information) locally on your device using
            mechanisms such as browser web storage (including HTML 5) and
            application data caches. We may also send one or more cookies or
            anonymous identifiers to your device when you interact with the
            Services or the services we offer to our partners, such as Hero or
            SideKick Card features that may appear on other sites.
          </li>
          <li>
            Log Information. We automatically collect and store certain
            information in server logs including details regarding your use of
            the Services; internet protocol address; device information;
            telephony log information such as your phone number; event
            information such as crashes, system activity, hardware settings,
            browser type, browser language and the date and time of your use;
            your interaction and viewing history for content within the
            Services; and cookies that may uniquely identify your browser or
            your Account.
          </li>
        </ul>
        <Paragraph>
          3. Information we obtain from our partners. Your name and the name of
          any additional cardholder associated with any loyalty, prepaid, debit
          or credit card linked to your Account is received from the issuer of
          the card when it is linked to your Account. These details allow you to
          use other funding sources, access loyalty, points, discounts,
          incentives, rewards and other offers or benefits, and enable us to
          process transactions using the correct source of funds.
        </Paragraph>
        <Paragraph>
          Information we collect when you use the Services, in addition to
          information we obtain about you from our partners, may be associated
          with your Account. When information is associated with your Account,
          we treat it as personal information.
        </Paragraph>
        <Paragraph>Information Security</Paragraph>
        <Paragraph>
          We work hard to protect our users from loss, interference, misuse and
          unauthorized access to, modification of and disclosure of information
          we hold. In particular:
        </Paragraph>
        <ul>
          <li>We encrypt many of our services using SSL.</li>
          <li>We offer password protection for your Account.</li>
          <li>
            We regularly review our information collection, storage and
            processing practices and security measures, to guard against
            unauthorized access.
          </li>
          <li>
            We restrict access to personal information to Hero employees,
            contractors, merchants and agents who need to know that information
            in order to provide the Services or to process it for us, and who
            are subject to strict contractual confidentiality obligations.
          </li>
        </ul>
        <Paragraph>How We Use Information We Collect</Paragraph>
        <Paragraph>
          We use the information collected about you to provide, maintain,
          protect and improve the Services, and to develop new products and
          services. We also use the information to protect you, other users and
          Hero.
        </Paragraph>
        <Paragraph>
          Specific examples of the way we use your information include:
        </Paragraph>
        <ul>
          <li>
            To authorize and process transactions including returns and refunds.
          </li>
          <li>
            To apply discounts, incentives, rewards and other offers applicable
            to your transactions.
          </li>
          <li>
            To provide appropriate data and content to you including details of
            applicable discounts, incentives, rewards and other offers.
          </li>
          <li>To verify user identity and maintain and update our records.</li>
          <li>
            To comply with any applicable law, rule or regulation and to detect
            and prevent any unauthorized use of our services such as money
            laundering, terrorist financing or other criminal activities.
          </li>
          <li>
            To communicate with users, including dealing with user enquiries.
          </li>
          <li>To conduct product and marketing research.</li>
          <li>To improve our products and services.</li>
        </ul>
        <Paragraph>
          We may use the name for your Account across all of the Services. In
          addition, we may replace past names associated with your Account so
          that you are represented consistently across all the Services.
        </Paragraph>
        <Paragraph>
          When you contact us, we keep a record of your communication to help
          solve any issues you might be facing. We may use your telephone number
          to send you notifications and information relating to actions you have
          taken. We may also inform you about our services through email, SMS,
          push notifications or within the Hero App, such as letting you know
          about upcoming changes or improvements.
        </Paragraph>
        <Paragraph>
          We use information we collect and may combine information from any of
          the Services with information, including personal information, from
          other Hero services to improve your user experience and the overall
          quality of our services.
        </Paragraph>
        <Paragraph>
          We will ask for your consent before using information for a purpose
          other than those that are set out in this Privacy Policy.
        </Paragraph>
        <Paragraph>Information We Share</Paragraph>
        <Paragraph>
          We do not share personal information with companies, organizations and
          individuals outside of Hero unless one of the following circumstances
          applies:
        </Paragraph>
        <Paragraph>
          Transaction processing, including processing discounts, incentives,
          rewards and other offers
        </Paragraph>
        <ul>
          <li>
            We provide personal information to our affiliates or other trusted
            persons to process your transactions, based on our instructions and
            in compliance with our Privacy Policy and any other legally required
            confidentiality and security measures.
          </li>
        </ul>
        <Paragraph>Legal reasons</Paragraph>
        <ul>
          <li>
            We will share personal information with persons outside of Hero if
            we have a good faith belief that access, use, preservation or
            disclosure of the information is reasonably necessary to
          </li>
          <li>
            comply with any applicable law, rule, regulation, legal process or
            enforceable governmental request;
          </li>
          <li>
            enforce applicable Terms of Service, including the investigation of
            potential violations;
          </li>
          <li>
            detect, prevent or otherwise address fraud, security or technical
            issues; or
          </li>
          <li>
            protect against harm to the rights, property or safety of Hero, our
            users or the
          </li>
          <li>public as required or permitted by law.</li>
        </ul>
        <Paragraph>Aggregated/anonymised data</Paragraph>
        <ul>
          <li>
            We may share aggregated, non-personally identifiable information
            publicly and with our merchants, partners or connected sites. For
            example, we may share information publicly to show trends about the
            general use of our services.
          </li>
        </ul>
        <Paragraph>Program operators</Paragraph>
        <ul>
          <li>
            We may share personal information, including transaction data, with
            our program operators for billing and accounting purposes and
            resolving technical issues.
          </li>
        </ul>
        <Paragraph>Corporate transactions</Paragraph>
        <ul>
          <li>
            If we are involved in a major corporate transaction such as a
            merger, acquisition or sale of all or substantially all of our
            business or one or more divisions of our business, we may disclose
            certain personal information to potential buyers, underwriters and
            advisors. If we do this, we will take reasonable precautions to
            ensure that recipients of any personal information are obligated to
            keep it confidential. To the extent that we transfer our business or
            a division of our business to which personal information is
            relevant, such personal information will be also be transferred and
            the new owner or newly controlling party will be permitted to use
            such personal information in accordance with this Privacy Policy.
          </li>
        </ul>
        <Paragraph>With your consent</Paragraph>
        <ul>
          <li>
            We will also share personal information with persons outside of Hero
            when we have received your consent to do so.
          </li>
        </ul>
        <Paragraph>Our Communications with You</Paragraph>
        <Paragraph>
          Among other things, we will communicate with you on a regular basis
          via email, SMS push notifications or within the Hero App. We may also
          communicate by phone to resolve user complaints or investigate
          suspicious transactions. Communications will be made for various
          reasons, including sending records of transactions requested or made,
          sending information about important changes to the Services, and to
          send notices and other disclosures required by law. You agree to
          receive such communications electronically until you advise us
          otherwise. We may send you promotional messages about new services,
          discounts, incentives, rewards and other offers or opportunities that
          may interest you. If you do not want to receive promotional messages
          or other communications from us, please adjust the settings within the
          Hero App or under your Account. However, we reserve the right to send
          you notices for customer service reasons, including notices about your
          account or transactions using the Services. Also, to help us make
          communications more useful and interesting, we may receive a
          confirmation when you open or receive communications from us.
        </Paragraph>
        <Paragraph>Accessing and Updating Your Personal Information</Paragraph>
        <Paragraph>
          Whenever you use the Services, we aim to provide you with access to
          your personal information. If that information is incorrect, we
          provide ways to update it quickly or to delete it (unless we need to
          keep that information for legitimate business or legal purposes). When
          updating or correcting your personal information, we may ask you to
          verify your identity before we can act on your request.
        </Paragraph>
        <Paragraph>
          We may reject requests for access to or to correct your personal
          information if they are unreasonably repetitive, require
          disproportionate technical effort (for example, developing a new
          system or fundamentally changing an existing practice), risk the
          privacy of others, or we are legally obligated to do so.
        </Paragraph>
        <Paragraph>
          Where we can provide you with access to your personal information, we
          will do so for free, unless providing the information means we will
          incur an unreasonable cost. In such cases, we may charge you a fee
          commensurate with such cost but will advise you of any such charge in
          advance.
        </Paragraph>
        <Paragraph>
          We aim to maintain our services in a manner that protects your
          personal information from accidental or malicious destruction. Because
          of this, after you delete any such information from the Services, we
          may not immediately delete residual copies from our active servers and
          may not remove information from our backup systems to ensure
          compliance with regulations such as FINTRAC.
        </Paragraph>
        <Paragraph>When This Privacy Policy Applies</Paragraph>
        <Paragraph>
          This Privacy Policy applies to all of the services we offer, both
          directly and through our subsidiaries and affiliates, and any services
          offered by any of them on other sites and through other mobile apps.
          This Privacy Policy excludes services that have separate privacy
          policies which do not specifically reference or incorporate this
          Privacy Policy.
        </Paragraph>
        <Paragraph>
          This Privacy Policy does not apply to services offered by other
          persons, including sites that may include Hero services, or other
          sites linked from our services unless those services specifically
          reference or incorporate this Privacy Policy. This Privacy Policy does
          not cover the information practices of other persons who advertise our
          services.
        </Paragraph>
        <Paragraph>Opting Out, Corrections and Complaints</Paragraph>
        <Paragraph>
          Upon request, we will (a) correct or update your personal information;
          (b) stop sending communications to you via email, SMS or push
          notifications; (c) stop disclosing non-public personal information
          about you to non-affiliated third parties; or (d) disable your
          account. Some of these requests can be satisfied by changing settings
          within the Hero App. To the extent that you are unable to do so within
          the Hero App, you may submit such requests to
        </Paragraph>
        <Paragraph>
          Hero Innovation Group. Attn: Privacy Officer 170 – 422 Richards Street
          Vancouver, BC V6B 2Z4
        </Paragraph>
        <Paragraph>Email: privacy@herofinancials.com.</Paragraph>
        <Paragraph>
          Please do not email or mail your account number or other sensitive
          information.
        </Paragraph>
        <Paragraph>
          We may need to communicate with you about certain issues that we are
          legally permitted to regardless of your desire to opt out of receiving
          such communications; accordingly, you will be unable to opt out of
          these communications.
        </Paragraph>
        <Paragraph>
          You may also register any complaints pertaining to the use by us of
          your personal information not in accordance with this Privacy Policy
          in the above manner.
        </Paragraph>
        <Paragraph>
          Compliance and Cooperation with Regulatory Authorities and Complaints
        </Paragraph>
        <Paragraph>
          We regularly review our compliance with this Privacy Policy. We also
          adhere to several self- regulatory frameworks.
        </Paragraph>
        <Paragraph>
          We will work with appropriate regulatory authorities, including local
          privacy protection authorities, to resolve any complaints regarding
          the transfer of personal information that we cannot resolve with our
          users directly.
        </Paragraph>
        <Paragraph>Changes</Paragraph>
        <br></br>
        <br></br>
        <Paragraph>
          This Privacy Policy may change from time to time. We will not reduce
          your rights under this Privacy Policy without your explicit consent.
          We will post any privacy policy changes on this page and, if the
          changes are significant, we will provide a more prominent notice
          (including, for certain services, email notification of Privacy Policy
          changes). We will also keep prior versions of this Privacy Policy in
          an archive for your review.
        </Paragraph>
      </Flex>
    </Flex>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    background: 'none',
    padding: 0,
    color: theme.TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-.25px',
    lineHeight: '20px',
    marginBottom: '30px',
  },
  headline: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 300,
    letterSpacing: '.5px',
  },
})

export default TOSDetails
