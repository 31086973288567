import { teamHoldingAccount } from '../queries'
import { OperationVariables, QueryHookOptions, useQuery } from '@apollo/client'

const useTeamHoldingAccount = (cache?) => {
  const queryOptions: QueryHookOptions<any, OperationVariables> = {
    fetchPolicy: cache ? 'cache-first' : 'network-only',
    notifyOnNetworkStatusChange: true,
  }
  return useQuery(teamHoldingAccount, queryOptions)
}

export default useTeamHoldingAccount
