import React from 'react'
import isEmpty from 'lodash/isEmpty'

import Tab from '../Tab'
import TabsCard, { TabsCardsProps } from './TabsCard'
import Text from '../typography/Text'
import TransactionsList from '../lists/TransactionsList'

import useTransactions from '../../hooks/useTransactions'

const TransactionsMembersCard: React.FC<TabsCardsProps> = ({ id, ...rest }) => {
  const [transactions, loading, refetch] = useTransactions(id)

  React.useEffect(() => {
    !!refetch && refetch()
  }, [id])

  return (
    <TabsCard
      token="TRANSACTIONS"
      defaultTab="all"
      loading={loading}
      empty={isEmpty(transactions)}
      centerX
      {...rest}
    >
      <Tab
        id="all"
        label={<Text token="ALL" />}
        element={
          <TransactionsList
            showPending
            orderByStatus
            orderByDate
            transactions={transactions}
          />
        }
      />
      <Tab
        id="moneyin"
        label={<Text token="MONEY_IN" />}
        element={
          <TransactionsList
            showPending
            orderByStatus
            orderByDate
            transactions={transactions}
            filter="moneyIn"
          />
        }
      />
      <Tab
        id="moneyout"
        label={<Text token="MONEY_OUT" />}
        element={
          <TransactionsList
            showPending
            orderByStatus
            orderByDate
            transactions={transactions}
            filter="moneyOut"
          />
        }
      />
    </TabsCard>
  )
}

export default TransactionsMembersCard
