import React from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import type { History } from 'history'

const usePrompt = (when = false) => {
  const [flag, setFlag] = React.useState(false)
  const confirm = React.useRef(null)
  const context = React.useRef(null)
  const { navigator } = React.useContext(NavigationContext)

  const blockNavigator = navigator as History

  const next = React.useCallback(() => {
    confirm.current?.()
    context.current?.retry?.()
  }, [flag])

  React.useEffect(() => {
    if (!when) return

    const unblock = blockNavigator?.block?.((tx) => {
      setFlag(true)
      context.current = tx
    })

    confirm.current = unblock

    return unblock
  }, [blockNavigator, when])

  return [flag, setFlag, next] as const
}

export default usePrompt
