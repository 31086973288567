import React, { ReactElement } from 'react'
import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import * as Yup from 'yup'
import get from 'lodash/get'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'

import FormContext from '../../../context/FormContext'

import Grid, { GridProps } from '../../Grid'
import FieldStatus from '../FieldStatus'
import Element from '../../Element'

type CurrencyFieldProps = {
  name?: string
  label?: string | ReactElement
  className?: string
  disabled?: boolean
  loading?: boolean
  onChange?: Function
  id?: string
  required?: boolean
  description?: string | JSX.Element
  collapseStatus?: boolean
} & CurrencyInputProps &
  GridProps

export const ALLOCATE_VALIDATOR = (max) => Yup.number().max(max)
export const ALLOCATE_VALIDATOR_REQUIRED = (max) =>
  Yup.number().max(max).required('THIS_IS_A_REQUIRED_FIELD')
export const ALLOCATE_VALIDATOR_POSITIVE = (max) =>
  Yup.number().moreThan(0).max(max)
export const ALLOCATE_VALIDATOR_POSITIVE_REQUIRED = (max) =>
  Yup.number().moreThan(0).max(max).required('THIS_IS_A_REQUIRED_FIELD')

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  name,
  label,
  className,
  id,
  onChange,
  loading,
  required,
  description,

  allowDecimals,
  allowNegativeValue,
  value,
  onValueChange,
  placeholder,
  decimalsLimit,
  decimalScale,
  fixedDecimalLength,
  prefix,
  suffix,
  decimalSeparator = '.',
  groupSeparator = ',',
  intlConfig,
  disabled,
  disableAbbreviations,
  disableGroupSeparators,
  maxLength,
  step,
  transformRawValue,
  collapseStatus,
  ...rest
}) => {
  const form = React.useContext<{ [key: string]: any }>(FormContext)

  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })
  const fieldClassNames = clsx({
    'is-disabled': form.disabled || disabled,
    'is-loading': form.loading || loading,
  })

  const handleChange = async (value) => {
    await form?.setFieldValue?.(name, value)
    await form?.handleFieldUpdate?.(name, value)
    onChange?.(value)
    onValueChange?.(value)
  }

  const submitCount = form?.submitCount
  const error = get(form.errors, name)
  const defaultValue = form.values?.[name] || 0

  return (
    <Element css={styles.wrapper}>
      <Grid
        as="label"
        gap={sizes.FIELD_GAP}
        css={styles.root}
        className={classNames}
        {...rest}
      >
        {label && (
          <span>
            {label}
            {required ? '*' : ''}
          </span>
        )}

        {description && description}

        <CurrencyInput
          className={fieldClassNames}
          css={styles.field}
          id={id}
          name={name}
          allowDecimals={allowDecimals}
          allowNegativeValue={allowNegativeValue}
          defaultValue={defaultValue}
          value={value}
          onValueChange={handleChange}
          placeholder={placeholder}
          decimalsLimit={decimalsLimit}
          decimalScale={decimalScale}
          fixedDecimalLength={fixedDecimalLength}
          prefix={prefix}
          suffix={suffix}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          intlConfig={intlConfig}
          disabled={disabled}
          disableAbbreviations={disableAbbreviations}
          disableGroupSeparators={disableGroupSeparators}
          maxLength={maxLength}
          step={step}
          transformRawValue={transformRawValue}
        />
      </Grid>
      <FieldStatus
        show={!!error && submitCount > 0}
        status={error}
        collapsable={collapseStatus}
      />
    </Element>
  )
}

const computeStyles: TComputeStyles = (
  {
    TEXT_PRIMARY_LIGHT,
    PRIMARY_ALT,
    FIELD_BORDER,
    DISABLED,
    FIELD_DISABLED_BACKGROUND,
  }: TTheme,
  {
    FIELD_PADDING,
    FIELD_FONT_SIZE,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes
) => ({
  wrapper: { flex: 1 },
  root: {
    border: `2px solid ${FIELD_BORDER}`,
    borderRadius: FIELD_BORDER_RADIUS,
    color: TEXT_PRIMARY_LIGHT,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    padding: FIELD_PADDING,
    position: 'relative',

    '&.is-disabled': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&.is-loading': {
      backgroundColor: FIELD_DISABLED_BACKGROUND,
      color: DISABLED,
      pointerEvents: 'none',
    },

    '&:focus-within': {
      border: `2px solid ${PRIMARY_ALT}`,
    },
  },
  field: {
    background: 'none',
    border: 0,
    flexGrow: 1,
    fontSize: FIELD_FONT_SIZE,
    outline: 0,
    width: '100%',
    '&.is-disabled': {
      color: DISABLED,
    },

    '&.is-loading': {
      color: DISABLED,
    },
  },
})

export default CurrencyField
