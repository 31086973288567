import React from 'react'
import { ApolloError } from '@apollo/client'

import StatusServerError from './StatusServerError'

export type StatusProps = {
  error: ApolloError
  loading?: boolean
  redirect?: boolean
  action?: any
  refetch?: any
  refetching?: boolean
  label?: string
}

const statuses = {
  401: StatusServerError,
  default: StatusServerError,
}

const Status: React.FC<StatusProps> = ({ error, ...rest }) => {
  const statusCode = error.networkError?.statusCode

  const StatusComponent = statuses[statusCode] || statuses.default

  return <StatusComponent error={error} {...rest} />
}

export default Status
