import React from 'react'
import { NavigateProps, useNavigate } from 'react-router-dom'

export type DelayedRedirectProps = {
  back?: boolean
  delay?: number
} & NavigateProps

const DelayedRedirect: React.FC<DelayedRedirectProps> = ({
  delay = 5,
  to,
  back,
}) => {
  let timer, interval
  const navigate = useNavigate()
  const [counter, setCounter] = React.useState(0)

  const handleRedirection = () => {
    if (back) return navigate(-1)
    return navigate(to)
  }

  const handleInterval = () => {
    setCounter((prevCounter) => prevCounter + 1)
  }

  React.useEffect(() => {
    interval = setInterval(handleInterval, 1000)
    timer = setTimeout(handleRedirection, delay * 1000)

    return () => {
      clearTimeout(timer)
      clearInterval(interval)
    }
  }, [])

  return delay - counter
}

export default DelayedRedirect
