import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { Theme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import Header from '../../typography/Header'
import Detail from '../../typography/Detail'
import PasswordStrength from '../../PasswordStrength'
import { validators } from '../../../utils/constants'
import PasswordField from '../fields/PasswordField'
import PhysicalCard from '../../PhysicalCard'
import PasswordRulesDescription from '../../PasswordRulesDescription'
import clsx from 'clsx'
import Flex from '../../Flex'
import Text from '../../typography/Text'

export type AcceptInviteFormProps = {
  data?: any
  submit: (formData: any) => void
  disabled?: boolean
  loading?: boolean
  error?: any
  className?: string
}

const defaultInitialValues = {
  password: '',
  confirm: '',
}

const schema = Yup.object().shape({
  password: Yup.string()
    .test((value) => validators.PASSWORD.test(value))
    .required(),
  confirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'PASSWORD_MUST_MATCH')
    .required(),
})

const AcceptInviteForm: React.FC<AcceptInviteFormProps> = ({
  submit,
  loading,
  className,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const classNames = clsx({
    [className]: className,
  })

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      {(form) => (
        <Flex className={classNames} vertical nowrap gap="1rem">
          <Header css={styles.sectionTitle} token="WELCOME" />

          <Header
            css={styles.sectionSubTitle}
            token="HERO_SETUP_ACCOUNT_FOR_YOU"
          />

          <PhysicalCard
            card={{
              cardholder: { givenName: 'SideKick' },
              last4Digits: '****',
            }}
            hasMenu={false}
          />

          <Header
            capitalize={false}
            css={styles.sectionTitle}
            token="CREATE_PASSWORD"
          />

          <Detail token="CREATE_PASSWORD_FOR_SIDEKICK" />

          <PasswordField
            label={<Text token="PASSWORD" />}
            name="password"
            css={styles.field}
            required
          />

          <PasswordStrength password={form?.values?.password || ''} />

          <PasswordField
            css={styles.field}
            label={<Text token="CONFIRM_NEW_PASSWORD" />}
            name="confirm"
            required
          />

          <PasswordRulesDescription />

          <FormFooter css={styles.footer}>
            <FormButton token="NEXT" />
          </FormFooter>
        </Flex>
      )}
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  sectionTitle: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0.5,
    lineHeight: '28px',
    margin: '0',
  },
  sectionSubTitle: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.25,
    lineHeight: '28px',
    margin: 0,
  },
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
  },
  footer: {
    backgroundColor: theme.BACKGROUND,
  },
})

export default AcceptInviteForm
