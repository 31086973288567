import { useTheme } from '@emotion/react'
import React from 'react'

import Banner, { Props as BannerProps } from './Banner'

const InfoAlert: React.FC<BannerProps> = ({ ...rest }) => {
  const { theme } = useTheme()
  return <Banner icon="alertCircle" color={theme.WARNING} {...rest} />
}

export default InfoAlert
