import React from 'react'
import PaymentSummaryForm from '../../../../components/form/forms/PaymentSummaryForm'
import Card from '../../../../components/cards/Card'
import Heading from '../../../../components/typography/Heading'
import FlowContext from '../../../../context/FlowContext'
import useSubscriptions from '../../../../hooks/useSubscriptions'
import {
  useSubscribe,
  useIsValidSubscription,
  DEFAULT_CURRENCY,
} from '../../../../hooks/useSubscriptions'
import { useNavigate } from 'react-router-dom'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import { useBillingInfo } from '../../../../hooks/useBilling'

type SubscriptionSummaryProps = {
  submit?: Function
  submitBackStep?: number
  editPlanStep?: number
}

const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  submit,
  submitBackStep = 1,
  editPlanStep = 0,
  ...rest
}) => {
  const { currentData, setStep, prev, next } = React.useContext(FlowContext)
  const { data, loading, refetch } = useSubscriptions(
    true,
    currentData?.data?.plan
  )
  const { data: validSubscription, loading: validLoading } =
    useIsValidSubscription()
  const { data: userData, loading: userLoading } = useCurrentUser()
  const { data: billingData, loading: billingLoading } = useBillingInfo()
  const navigate = useNavigate()

  const edit = currentData?.data?.edit
  const code = currentData?.data?.plan
  const fromMobile = currentData?.data?.fromMobile

  const [mutateSubscribe, { loading: mutationLoading }] = useSubscribe(edit)

  const handleSubmit = async (coupon) => {
    const payload = {
      ...(edit && { currency: DEFAULT_CURRENCY }),
      code,
      coupon,
    }
    await mutateSubscribe({
      variables: payload,
    })
    if (submit) {
      try {
        await submit()
      } catch (err) {
        await refetch()
      }
      next()
    } else {
      if (fromMobile) {
        navigate('/settings/subscriptions/deeplink')
      } else {
        next()
      }
    }
  }

  return (
    <Card
      css={styles.root}
      loading={loading || billingLoading || userLoading || validLoading}
    >
      <Heading token="SUMMARY" as="h2" css={styles.heading} />
      <PaymentSummaryForm
        validSubscription={validSubscription}
        editing={edit}
        loading={mutationLoading}
        editCard={prev}
        resetFlow={setStep}
        resetStep={editPlanStep}
        user={userData?.userProfile}
        billingInfo={billingData?.subscriptionBillingInfo}
        submit={handleSubmit}
        subscription={data.selectedSubscripton}
        {...rest}
      />
    </Card>
  )
}

const styles = {
  root: {
    padding: 0,
  },
  heading: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: 0,
    paddingLeft: 5,
  },
}

export default SubscriptionSummary
