const HOLDING_ACCOUNT = 'TeamHoldingAccount'

export const nonNegative = (n) => (Number(n) > 0 ? Number(n) : 0)

export const getDecimal = (number) =>
  Number(number || 0)
    .toFixed(2)
    .toString()
    .split('.')[1]
    .replace('.', '')

export const getAmountPayload = (number) => ({
  basicUnit: String(Math.floor(number)),
  fraction: getDecimal(number),
})

export const sumCategoriesAllocate = (categories) =>
  getAmountPayload(
    Object.values(categories).reduce(
      (a, b) => nonNegative(a) + nonNegative(b),
      0
    )
  )

export const generatePayload = (formData, hasCategory) => {
  const { toAllocate, fromAllocate, amount, ...rest } = formData

  const categoryPayload = Array(6)
    .fill(0)
    .map((v, index) => {
      const categoryKey = `cat_00${index + 1}`
      return {
        categoryKey,
        ammountAllocated: { ...getAmountPayload(rest[categoryKey] || 0) },
      }
    })

  const amounts = hasCategory
    ? {
        categoryAllocations: categoryPayload,
        totalAmount: sumCategoriesAllocate(rest),
      }
    : {
        totalAmount: getAmountPayload(amount),
        amount: getAmountPayload(amount),
      }

  const source = !isTeamHoldingAccount(fromAllocate) && {
    sourceAccount: fromAllocate?.bankAccounts?.[0].bankAccountId,
  }

  return {
    ...source,
    targetAccount: toAllocate?.bankAccounts?.[0].bankAccountId,
    ...amounts,
  }
}

export const isTeamHoldingAccount = (member) =>
  member?.__typename === HOLDING_ACCOUNT
