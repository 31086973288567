import React from 'react'
import { useNavigate } from 'react-router-dom'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'

import ChooseOrderCardForm from '../../../components/form/forms/ChooseOrderCardForm'
import Text from '../../../components/typography/Text'

import FlowContext from '../../../context/FlowContext'

const OrderCardStep = ({ ...rest }) => {
  const navigate = useNavigate()
  const { currentData, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    if (formData?.orderCard === 'now') {
      navigate(`/cards/order/${formData?.owner}/`)
    } else {
      navigate('/accounts')
    }
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <Text token="ORDER_SIDEKICK_CARD_INFO" />
      <ChooseOrderCardForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default OrderCardStep
