import React from 'react'
import * as Yup from 'yup'
import { TPlainStyle } from '@emotion/react'
import Form from '../Form'

import Header from '../../typography/Header'
import Info from '../../Info'
import Text from '../../typography/Text'
import CurrencyField from '../fields/CurrencyField'

type CardTransactionLimitsFormProps = {}

const schema = Yup.object().shape({
  daily: Yup.number(),
  maximumTransactionAmount: Yup.number(),
  maximumSpendPerDay: Yup.number(),
  maximumSpendPerWeek: Yup.number(),
  maximumSpendPerMonth: Yup.number(),
  maximumWithdrawal: Yup.number(),
  maximumPerDay: Yup.number(),
  maximumPerWeek: Yup.number(),
  maximumPerMonth: Yup.number(),
})

const CardTransactionLimitsForm: React.FC<CardTransactionLimitsFormProps> = ({
  ...rest
}) => {
  return (
    <Form
      initialValues={{
        daily: 15,
        maximumTransactionAmount: 5000,
        maximumSpendPerDay: 5000,
        maximumSpendPerWeek: 10000,
        maximumSpendPerMonth: 40000,
        maximumWithdrawal: 500,
        maximumPerDay: 500,
        maximumPerWeek: 2000,
        maximumPerMonth: 10000,
      }}
      onSubmit={() => {}}
      disabled={true}
      validationSchema={schema}
      css={styles.root}
      {...rest}
    >
      <Info token="THESE_LIMITS_CANNOT_BE_ADJUSTED" />
      <Header css={styles.headline} token="TRANSACTION_LIMITS" />

      <CurrencyField
        prefix="$ "
        label={<Text token="DAILY_TRANSACTION_LIMIT" />}
        name="daily"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_TRANSACTION_AMOUNT" />}
        name="maximumTransactionAmount"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_SPEND_PER_DAY" />}
        name="maximumSpendPerDay"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_SPEND_PER_WEEK" />}
        name="maximumSpendPerWeek"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_SPEND_PER_MONTH" />}
        name="maximumSpendPerMonth"
      />
      <Header css={styles.headline} token="ATM_WITHDRAWAL_LIMITS" />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_WITHDRAWAL" />}
        name="maximumWithdrawal"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_PER_DAY" />}
        name="maximumPerDay"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_PER_WEEK" />}
        name="maximumPerWeek"
      />
      <CurrencyField
        prefix="$ "
        label={<Text token="MAXIMUM_PER_MONTH" />}
        name="maximumPerMonth"
      />
    </Form>
  )
}

const styles: TPlainStyle = {
  root: {
    width: '100%',
  },
  headline: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
}

export default CardTransactionLimitsForm
