import React from 'react'

import SelectField from './SelectField'
import Text from '../../typography/Text'

const GenderSelectField = ({ ...rest }) => {
  return (
    <SelectField {...rest}>
      <option value="MALE">
        <Text token="MALE" />
      </option>
      <option value="FEMALE">
        <Text token="FEMALE" />
      </option>
      <option value="NON_BINARY_NON_CONFORMING">
        <Text token="NON_BINARY" />
      </option>
      <option value="SOMETHING_ELSE">
        <Text token="SOMETHING_ELSE" />
      </option>
      <option value="PREFER_NOT_TO_SAY">
        <Text token="PREFER_NOT_TO_SAY" />
      </option>
    </SelectField>
  )
}

export default GenderSelectField
