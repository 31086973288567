import React from 'react'
import FlowStepLayout from '../../components/flows/FlowStepLayout'

import FundSelectFundingMethodForm from '../../components/form/forms/FundSelectFundingMethodForm'

import FlowContext from '../../context/FlowContext'

const FundAccountInputAmountStep = ({ ...rest }) => {
  const { setFlowData, next } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <FundSelectFundingMethodForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default FundAccountInputAmountStep
