import React from 'react'

import FlowCardLayout, {
  FlowStep,
} from '../../../components/layout/FlowCardLayout'
import SubscriptionSelect from './flows/subscriptions-select'
import { flowName } from './subscriptions-scene'
import useSubscriptions from '../../../hooks/useSubscriptions'
import SubscriptionPayment from './flows/subscriptions-payment'
import SubscriptionSummary from './flows/subscriptions-summary'
import { useDispatch } from 'react-redux'

enum FLOW_VARIANTS {
  NEW_FLOW = 'new',
  EDIT_FLOW = 'edit',
  FRESH_NEW_FLOW = 'freshNew',
}

enum STEPS {
  FIRST_STEP = 0,
  SECOND_STEP = 1,
}

export const FLOW_DICT = {
  [FLOW_VARIANTS.NEW_FLOW]: {
    index: STEPS.SECOND_STEP,
    title: 'SELECT_PLAN',
    isEditing: false,
  },
  [FLOW_VARIANTS.FRESH_NEW_FLOW]: {
    index: STEPS.FIRST_STEP,
    title: 'SELECT_PLAN',
    isEditing: false,
  },
  [FLOW_VARIANTS.EDIT_FLOW]: {
    index: STEPS.FIRST_STEP,
    title: 'EDIT_PLAN',
    isEditing: true,
  },
}

type FlowStepDataType = {
  index: number
  title: string | JSX.Element
  isEditing: boolean
}

type SubscriptionsFlowProps = {
  title: string | JSX.Element
  edit?: boolean
  freshStart?: boolean
  fromMobile?: boolean
}
const SubscriptionsFlow: React.FC<SubscriptionsFlowProps> = ({
  freshStart,
  fromMobile,
  ...rest
}) => {
  const [flow, setFlow] = React.useState<FlowStepDataType>()
  const { data: subscription, loading } = useSubscriptions()
  const dispatch = useDispatch()

  const setFlowEditable = async (value) => {
    await dispatch.flow.SET_FLOW_DATA(flowName, {
      edit: value || flow?.isEditing,
    })
  }

  React.useEffect(() => {
    if (!loading) {
      const flowKey = !subscription?.subscription
        ? freshStart
          ? FLOW_VARIANTS.FRESH_NEW_FLOW
          : FLOW_VARIANTS.NEW_FLOW
        : FLOW_VARIANTS.EDIT_FLOW
      const currentFlow = FLOW_DICT[flowKey]
      ;(async () => {
        await setFlow(currentFlow)
        await setFlowEditable(currentFlow.isEditing)
      })()
    }
  }, [loading, freshStart, subscription])

  return (
    <FlowCardLayout
      loading={loading && !!flow}
      cancel="/settings/subscriptions"
      name={flowName}
      start={flow?.index || 0}
      {...rest}
      back={undefined}
    >
      <FlowStep
        element={() => (
          <SubscriptionSelect
            fromMobile={fromMobile}
            flowName={flowName}
            edit={flow?.isEditing}
          />
        )}
      />
      <FlowStep element={SubscriptionPayment} />
      <FlowStep element={SubscriptionSummary} />
    </FlowCardLayout>
  )
}

export default SubscriptionsFlow
