import React from 'react'

import Actions from '../../components/Actions'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import MainLayoutLink from '../../components/layout/MainLayoutLink'
import AccountCard from '../../components/cards/AccountCard'

import useTeamMembers from '../../hooks/useTeamMembers'
import ResendEmailNotification from '../../components/ResendEmailNotification'

const AccountsScene = ({ ...rest }) => {
  const { loading, teamMembers, error } = useTeamMembers(true, true)

  return (
    <MainLayoutLink loading={loading} error={error} {...rest}>
      <Grid gap="1rem">
        <Actions feature="products.add">
          <Button
            token="ADD_PRODUCT"
            to="/products/new"
            icon="plus"
            iconColor="white"
            size={1}
          />
        </Actions>

        {teamMembers &&
          teamMembers.reduce((acc, { userProfile, bankAccounts }) => {
            if (bankAccounts.length > 0)
              acc.push(
                <AccountCard
                  key={userProfile.id}
                  user={userProfile}
                  bankAccount={bankAccounts?.[0]}
                  to={`/accounts/${userProfile.id}`}
                />
              )
            return acc
          }, [])}

        {teamMembers &&
          teamMembers.reduce((acc, { userProfile }) => {
            if (!userProfile.hasAcceptedInvitation) {
              acc.push(
                <ResendEmailNotification dismissable user={userProfile} />
              )
            }
            return acc
          }, [])}
      </Grid>
    </MainLayoutLink>
  )
}

export default AccountsScene
