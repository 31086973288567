import { TComputeStyles, TSizes, TTheme, useTheme } from '@emotion/react'
import clsx from 'clsx'
import React from 'react'
import Flex from '../../Flex'
import IconCircle from '../../IconCircle'
import Text from '../../typography/Text'

import RadioField, { RadioFieldProps } from './RadioField'

type FundingMethodOptionFieldProps = {
  value?: string
  label?: string | JSX.Element
  funding?: any
  className?: string
  loading?: boolean
} & RadioFieldProps

const brandIconMapping = {
  default: 'card',
  mastercard: 'mastercardMono',
  visa: 'visaMono',
}

const FundingMethodOptionField: React.FC<FundingMethodOptionFieldProps> = ({
  funding,
  value,
  className,
  loading,
  ...rest
}) => {
  const { theme, sizes } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme, sizes), [theme])

  const classNames = clsx({
    [className]: className,
  })

  const { brand, network_type, last_four_digits, name } = funding || {}

  return (
    <RadioField
      css={styles.root}
      className={classNames}
      value={value}
      {...rest}
    >
      <IconCircle
        css={styles.image}
        icon={brandIconMapping[brand || network_type || 'default']}
        size={56}
        iconWidth={32}
        color={theme.FUNDING_METHOD_OPTION_ICON_BACKGROUND}
        iconColor={theme.FUNDING_METHOD_OPTION_ICON_COLOR}
      />
      <Flex css={styles.content} vertical centerX>
        <Text
          loading={loading}
          css={styles.label}
          token={brand || network_type || 'CARD'}
        />
        <Text
          loading={loading}
          css={styles.label}
          token="HIDDEN_CARD_NUMBER"
          interpolation={{ lastDigits: last_four_digits }}
        />
        <Text
          loading={loading}
          css={styles.label}
          token="FUNDER_PERSONALIZED"
          interpolation={{ name: name }}
        />
      </Flex>
    </RadioField>
  )
}

const computeStyles: TComputeStyles = (
  { FIELD_BORDER, TEXT_PRIMARY }: TTheme,
  {
    FIELD_PADDING,
    FIELD_LABEL_FONT_SIZE,
    FIELD_FONT_WEIGHT,
    FIELD_BORDER_RADIUS,
  }: TSizes
) => ({
  root: {
    gridTemplateColumns: '60px 1fr 13px',
    padding: FIELD_PADDING,

    color: TEXT_PRIMARY,
    fontSize: FIELD_LABEL_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,

    '&.is-radio': {
      border: `2px solid ${FIELD_BORDER}`,
      borderRadius: FIELD_BORDER_RADIUS,
      display: 'grid',
      gridTemplateColumns: '60px 1fr 20px',
    },
    '& > input': {
      gridColumnStart: -2,
    },
  },
  image: {
    alignSelf: 'center',
    justifySelf: 'center',
    gridColumnStart: 1,
    gridRowStart: 1,
  },
  content: {
    gridColumnStart: 2,
    gridRowStart: 1,
    fontStyle: 'normal',
    fontWeight: FIELD_FONT_WEIGHT,
  },
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
  },
})

export default FundingMethodOptionField
