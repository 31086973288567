import React from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { AnimatePresence } from 'framer-motion'

import Element, { ElementProps } from '../Element'
import Portal from '../Portal'
import Fullscreen from '../Fullscreen'
import { TPlainStyle } from '@emotion/react'

type DrawerProps = {
  handleClickOutside?: (e: MouseEvent) => void
  isOpened?: boolean
  backdrop?: boolean
}

export type Props = DrawerProps & ElementProps

const Drawer: React.FC<Props> = ({
  handleClickOutside = () => {},
  isOpened,
  backdrop = true,
  children,
  ...rest
}) => {
  const ref = useDetectClickOutside({
    onTriggered: handleClickOutside,
    disableClick: true,
    disableTouch: true,
  })

  return (
    <AnimatePresence>
      {isOpened && (
        <Portal>
          <Fullscreen
            backdrop={backdrop}
            horizontalAlignment="center"
            verticalAlignment="flex-end"
            onClick={handleClickOutside}
          >
            <Element
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ ease: 'easeInOut', duration: 0.1 }}
              css={styles.root}
              innerRef={ref}
              {...rest}
            >
              {children}
            </Element>
          </Fullscreen>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const styles: TPlainStyle = {
  root: {
    width: '100%',
    maxWidth: 1200,
  },
}

export default Drawer
