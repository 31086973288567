import React from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AnimatedSpinner from '../../components/AnimatedSpinner'
import Flex from '../../components/Flex'
import { Theme, useTheme } from '@emotion/react'

const LogoutScene = () => {
  const dispatch = useDispatch()

  const theme = useTheme()
  const styles = React.useMemo(() => computeStyles(theme.theme), [theme])

  const user = useSelector((state) => state.auth.user)
  React.useEffect(() => {
    dispatch.auth.signOut()
  })

  return (
    <Flex center css={styles.root}>
      <AnimatedSpinner />
      {!user && <Navigate to="/auth/login" />}
    </Flex>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: {
    minWidth: '100vw',
    minHeight: '100vh',
    padding: 0,
    backgroundColor: theme.BACKGROUND,
  },
})

export default LogoutScene
