import { Theme, useTheme } from '@emotion/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import Paragraph from '../../../components/typography/Paragraph'
import FlowContext from '../../../context/FlowContext'

const VerifyEmail: React.FC = () => {
  const { currentData } = React.useContext(FlowContext)
  const dispatch = useDispatch()
  const theme = useTheme()
  const styles = React.useMemo(() => computeStyles(theme.theme), [theme])
  const registeredUsername = useSelector(
    (state: { auth: { [key: string]: string } }) => state.auth.username
  )
  const handleResendInvitation = async () => {
    await dispatch.auth.resendInvitation({ username: registeredUsername })
  }

  return (
    <FlowStepLayout>
      <Flex vertical>
        <Paragraph
          css={styles.email}
          token="EMAIL_WE_SENT_TO"
          interpolation={{ email: currentData?.data?.email }}
        />
        <Paragraph token="EMAIL_ADVICE" />
        <Button
          button
          css={styles.resend}
          onClick={handleResendInvitation}
          token="RESEND_EMAIL"
        />
      </Flex>
    </FlowStepLayout>
  )
}

const computeStyles = ({ LINK }: Theme['theme']) => ({
  email: {
    wordBreak: 'break-all',
  },
  resend: {
    background: 'none',
    color: LINK,
    width: 'min-content',
    padding: 0,
  },
})

export default VerifyEmail
