import React from 'react'
import { TComputeStyles, useTheme } from '@emotion/react'

import Card from './Card'
import Icon from '../Icon'
import { TranslationToken } from '../typography/Text'

type InfoHeadingCardProps = {
  title?: string
  back?: Function
  token?: TranslationToken
} & React.PropsWithChildren

const InfoHeadingCard: React.FC<InfoHeadingCardProps> = ({
  back,
  children,
  ...rest
}) => {
  const { theme, colors } = useTheme()

  const styles = React.useMemo(
    () => computeStyles({ ...theme, ...colors }),
    [theme]
  )

  return (
    <>
      <Card outsideLabel css={styles.root} {...rest}>
        {back && <Icon css={styles.back} onClick={() => back()} icon="edit" />}
        {children}
      </Card>
    </>
  )
}

const computeStyles: TComputeStyles = ({ BLACK_200 }) => ({
  root: {
    display: 'block',
    position: 'relative',
    background: 'white',
    border: `1px solid ${BLACK_200}`,
    padding: 20,
    borderRadius: 20,
  },
  back: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
})

export default InfoHeadingCard
