import React from 'react'
import isFunction from 'lodash/isFunction'
import {
  Formik,
  Form as FormikForm,
  FormikFormProps,
  FormikProps,
} from 'formik'

import FormContext from '../../context/FormContext'
import { TypeOfShape } from 'yup/lib/object'
import { CSSObject } from '@emotion/react'

export type FormProps = {
  children?: any
  disabled?: boolean
  loading?: boolean
  onSubmit: any
  initialValues?: { [key: string]: any }
  validationSchema?: TypeOfShape<any>
  css?: CSSObject
  handleFieldUpdate?: Function
  formRef?: any
  innerRef?: any
} & Partial<FormikProps<FormikFormProps>>

const Form: React.FC<FormProps> = ({
  children,
  disabled,
  loading,
  handleFieldUpdate,
  formRef,
  ...rest
}) => {
  const childrenIsFunction = isFunction(children)

  return (
    <Formik {...rest}>
      {(formProps) => (
        <FormContext.Provider
          value={{ disabled, loading, handleFieldUpdate, ...formProps }}
        >
          <FormikForm css={styles.root} ref={formRef}>
            {childrenIsFunction && children(formProps)}
            {!childrenIsFunction && children}

            <button type="submit" hidden />
          </FormikForm>
        </FormContext.Provider>
      )}
    </Formik>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridGap: '1rem',
  },
}

export default Form
