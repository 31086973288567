import React from 'react'
import * as Yup from 'yup'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Form from '../Form'

import Text from '../../typography/Text'
import Flex from '../../Flex'
import FromToField from '../fields/FromToField'
import AmountsField from '../fields/AmountsField'
import Detail from '../../typography/Detail'
import FrecuencySelectField, {
  AllowanceFrequency,
} from '../fields/FrecuencySelectField'
import DayOfWeekSelectField, { daysDict } from '../fields/DayOfWeekSelectField'
import DayOfMonthSelectField from '../fields/DayOfMonthSelectField'
import Heading from '../../typography/Heading'
import DateField from '../fields/DateField'
import InfoAlert from '../../InfoAlert'
import SwitchField from '../fields/SwitchField'
import FormFooter from '../FormFooter'
import FormButton from '../Button'
import { isTeamHoldingAccount } from '../../../utils/allocate'

type RecurringAllowanceFormProps = {
  loading?: boolean
  submit?: Function
  handleFieldUpdate?: Function
  error?: boolean
  teamMembers?: any
  data?: any
  innerRef?: any
  disabled?: boolean
  categoryAllocate?: boolean
}

const RecurringAllowanceForm: React.FC<RecurringAllowanceFormProps> = ({
  loading,
  submit,
  teamMembers,
  categoryAllocate,
  data = {},
  ...rest
}) => {
  const validationSchema = Yup.object().shape({
    fromAllocate: Yup.object().required('THIS_IS_A_REQUIRED_FIELD'),
    toAllocate: Yup.object().required('THIS_IS_A_REQUIRED_FIELD'),
    amount: Yup.number().when([], {
      is: () => categoryAllocate,
      then: Yup.number(),
      otherwise: Yup.number().moreThan(0).required('THIS_IS_A_REQUIRED_FIELD'),
    }),
    cat_001: Yup.number().when(
      ['cat_002', 'cat_003', 'cat_004', 'cat_005', 'cat_006'],
      {
        is: (cat_002 = 0, cat_003 = 0, cat_004 = 0, cat_005 = 0, cat_006 = 0) =>
          !categoryAllocate ||
          cat_002 + cat_003 + cat_004 + cat_005 + cat_006 > 0,
        then: Yup.number(),
        otherwise: Yup.number()
          .moreThan(0, 'CATEGORIES_GREATER_THAN_0')
          .required('CATEGORIES_GREATER_THAN_0'),
      }
    ),
    cat_002: Yup.number(),
    cat_003: Yup.number(),
    cat_004: Yup.number(),
    cat_005: Yup.number(),
    cat_006: Yup.number(),
    frequency: Yup.string().required('THIS_IS_A_REQUIRED_FIELD'),
    repeatsEvery: Yup.number().integer().required('THIS_IS_A_REQUIRED_FIELD'),
    endsAfter: Yup.string().when(['neverEnds'], {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .required('THIS_IS_A_REQUIRED_FIELD')
        .test('DOB', 'END_DATE_CANT_BE_LESS_THAN_TOMORROW', (value) => {
          return DateTime.fromISO(value).diffNow('days').days >= 0
        }),
    }),
    neverEnds: Yup.boolean(),
  })
  const { t } = useTranslation()
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const fromAllocate = teamMembers.filter((member) =>
    isTeamHoldingAccount(member)
  )[0]

  const toAllocate =
    teamMembers.find(
      (sk) =>
        !isTeamHoldingAccount(sk) &&
        sk?.bankAccounts?.[0]?.bankAccountId === data?.targetBankAccount
    ) || teamMembers?.find((sk) => sk?.__typename === 'TeamMember')

  const initialValues = {
    fromAllocate,
    toAllocate,
    frequency: data.frequency || AllowanceFrequency.Monthly,
    repeatsEvery: data.repeatsEvery || 1,
    endsAfter: data.endsAfter || '',
    neverEnds: data.endsAfter === null,
    cat_001:
      Number(
        `${data.categories?.[0]?.ammountAllocated?.basicUnit}.${data.categories?.[0]?.ammountAllocated?.fraction}`
      ) || 0,
    cat_002:
      Number(
        `${data.categories?.[1]?.ammountAllocated?.basicUnit}.${data.categories?.[1]?.ammountAllocated?.fraction}`
      ) || 0,
    cat_003:
      Number(
        `${data.categories?.[2]?.ammountAllocated?.basicUnit}.${data.categories?.[2]?.ammountAllocated?.fraction}`
      ) || 0,
    cat_004:
      Number(
        `${data.categories?.[3]?.ammountAllocated?.basicUnit}.${data.categories?.[3]?.ammountAllocated?.fraction}`
      ) || 0,
    cat_005:
      Number(
        `${data.categories?.[4]?.ammountAllocated?.basicUnit}.${data.categories?.[4]?.ammountAllocated?.fraction}`
      ) || 0,
    cat_006:
      Number(
        `${data.categories?.[5]?.ammountAllocated?.basicUnit}.${data.categories?.[5]?.ammountAllocated?.fraction}`
      ) || 0,
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submit}
      disabled={loading}
      loading={loading}
      css={styles.root}
      validationSchema={validationSchema}
      {...rest}
    >
      {({ values, setFieldValue }) => {
        if (
          values.frequency === AllowanceFrequency.Weekly &&
          values.repeatsEvery > 7
        )
          setFieldValue('repeatsEvery', 1)
        return (
          <>
            <Flex vertical gap="1rem">
              <FromToField
                teamMembers={teamMembers}
                to={values?.toAllocate}
                from={values?.fromAllocate}
                onlyFromHero
              />
              <Detail css={styles.heading} token={'ALLOWANCE_AMOUNT'} />

              <AmountsField
                categories={
                  categoryAllocate &&
                  values?.toAllocate?.bankAccounts?.[0]?.categories
                }
                hideAvailable
              />

              <FrecuencySelectField name="frequency" />
              {values.frequency === AllowanceFrequency.Monthly ? (
                <>
                  <DayOfMonthSelectField name="repeatsEvery" />
                  <Detail
                    token="TRANSACTIONS_WILL_OCCUR_ON"
                    interpolation={{
                      ordinalDay: t(`ORDINAL_${values.repeatsEvery}`),
                    }}
                  />
                </>
              ) : (
                <>
                  <DayOfWeekSelectField name="repeatsEvery" />
                  <Detail
                    token="TRANSACTIONS_WILL_OCCUR_ON_WEEKLY"
                    interpolation={{
                      day: t(daysDict[values.repeatsEvery - 1]),
                    }}
                  />
                </>
              )}
              <Flex>
                <Heading
                  token="END_RECURRING_ALLOWANCE_ON"
                  css={styles.heading}
                />
                <Detail token="END_RECURRING_ALLOWANCE_ON_DETAIL" />
              </Flex>
              <DateField
                disabled={values.neverEnds === true}
                css={styles.field}
                label={<Text token="END_PAYMENT_ON" />}
                name="endsAfter"
                defaultValue={values.endsAfter}
              />
              <SwitchField
                label={
                  <Heading
                    token={'PAYMENT_NEVER_ENDS'}
                    css={styles.sectionToggleLabel}
                  />
                }
                name="neverEnds"
                css={styles.sectionToggle}
                labelIsSufix
              />
              <InfoAlert token="RECURRING_ALLOWANCE_WILL_NOT_BE_PROCESSED" />
            </Flex>
            <FormFooter css={styles.footer}>
              <FormButton token="DONE" loading={loading} />
            </FormFooter>
          </>
        )
      }}
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
  BACKGROUND_ALT,
}: TTheme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  sectionToggle: {
    border: 'none',
    padding: '0 16px',
  },
  sectionToggleLabel: {
    color: TEXT_PRIMARY,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1.71,
    margin: '0 9px',
  },
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default RecurringAllowanceForm
