import React from 'react'
import * as Yup from 'yup'
import Form from '../Form'
import { Theme, useTheme } from '@emotion/react'
import DeliveryMethodOptionField from '../fields/DeliveryMethodOptionField'
import { SHIPPING_METHOD } from './ShippingAddressForm'

type ChooseCardDeliveryMethodFormProps = {
  submit?: Function
  handleFieldUpdate?: Function
  loading?: boolean
  disabled?: boolean
  data?: object
}
const schema = Yup.object().shape({ shippingMethod: Yup.string().required() })

const defaultInitialValues = { shippingMethod: SHIPPING_METHOD.FREE }

const ChooseCardDeliveryMethodForm: React.FC<
  ChooseCardDeliveryMethodFormProps
> = ({ submit, loading, disabled, data, ...rest }) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      onSubmit={submit}
      disabled={disabled}
      loading={loading}
      validationSchema={schema}
      initialValues={{ ...defaultInitialValues, ...data }}
      {...rest}
    >
      <>
        <DeliveryMethodOptionField
          css={styles.field}
          value={SHIPPING_METHOD.FREE}
          name="shippingMethod"
        />
        <DeliveryMethodOptionField
          css={styles.field}
          value={SHIPPING_METHOD.RUSH_TRACK}
          name="shippingMethod"
          disabled
        />
      </>
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  field: {
    backgroundColor: theme.BACKGROUND_ALT,
    '&.is-disabled.is-radio': {
      backgroundColor: theme.DISABLED_BACKGROUND,
    },
  },
})

export default ChooseCardDeliveryMethodForm
