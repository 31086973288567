import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useTheme } from '@emotion/react'

import Icon from '../../components/Icon'
import LoginForm from '../../components/form/forms/LoginForm'
import Grid from '../../components/Grid'
import Heading from '../../components/typography/Heading'

const LoginScene = () => {
  const [search] = useSearchParams()

  const email = search.get('email')
  const decodedEmail = decodeURIComponent(email || '')

  const dispatch = useDispatch()
  const { theme } = useTheme()
  const user = useSelector(
    (state: { auth: { user: object } }) => state.auth.user
  )
  const error = useSelector(
    (state: { auth: { error: { message: string } } }) => state.auth.error
  )
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
      dispatch.auth.CLEAR_ERROR()
    }
  }, [error])

  const handleFormSubmit = async (values) => {
    setLoading(true)
    for (let key in values) {
      values[key] = values[key].trim()
    }
    await dispatch.auth.signIn(values)
    setLoading(false)
  }

  return (
    <Grid gap="1rem" padding="1rem" css={styles.root}>
      <Icon
        icon="brand_gradient"
        height={40.14}
        css={styles.brand}
        color={theme.BRAND_PRIMARY}
      />
      <Heading token="LOGIN" />

      <LoginForm
        loading={loading}
        submit={handleFormSubmit}
        data={{ email: decodedEmail }}
      />

      {user && <Navigate to={'/'} />}
    </Grid>
  )
}

const styles = {
  root: {
    width: '100%',
    maxWidth: 450,
  },
  brand: {
    marginBottom: 32,
    padding: 8,
  },
}

export default LoginScene
