import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { Theme, useTheme } from '@emotion/react'
import { cleanNullProps } from '../../../utils/functions'
import Text from '../../typography/Text'
import Header from '../../typography/Header'

export type OrderCardSummaryFormProps = {
  data?: any
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
}

const DEFAULT_COUNTRY = 'Canada'

export const SHIPPING_METHOD = {
  FREE: 'Regular',
  RUSH_TRACK: 'Rush',
}

const schema = Yup.object().shape({
  shippingAddress: Yup.object().shape({
    addressLine1: Yup.string().trim().required(),
    addressLine2: Yup.string().optional(),
    city: Yup.string().required(),
    postalCode: Yup.string().length(6).required(),
    region: Yup.string().required(),
    country: Yup.string().required(),
  }),
  shippingMethod: Yup.string().required(),
  simcard: Yup.boolean(),
})

const OrderCardSummaryForm: React.FC<OrderCardSummaryFormProps> = ({
  data = {},
  submit,
  loading,
  disabled,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const { shippingAddress, simcard, shippingMethod, sk } = data
  const { country, addressLine1, addressLine2, city, postalCode, region } =
    shippingAddress

  const defaultInitialValues = {
    shippingAddress: {
      addressLine1,
      addressLine2,
      city,
      postalCode,
      region,
      country: country || DEFAULT_COUNTRY,
    },
    shippingMethod,
    simcard: simcard || false,
  }

  const fullName = `${sk?.givenName} ${sk?.familyName}`

  const handleSubmit = (formData) => submit(cleanNullProps(formData))

  return (
    <Form
      css={styles.form}
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={defaultInitialValues}
      validationSchema={schema}
      {...rest}
    >
      <Header as="h3" css={styles.headline} token="TEAM_MEMBER" />
      <Text css={styles.information}>{fullName}</Text>
      <Header as="h3" css={styles.headline} token="CARD_TYPE" />
      <Text css={styles.information} token="SIDEKICK_CARD_CANADA" />
      <Header as="h3" css={styles.headline} token="SHIPPING_ADDRESS" />
      <Text css={styles.information}>
        <Text as="div">{addressLine1}</Text>
        {addressLine2 && <Text as="div">{addressLine2}</Text>}
        <Text as="div">{city}</Text>
        <Text as="div">{`${region}, ${country}, ${postalCode}`}</Text>
        <Text
          as="div"
          token={
            shippingMethod === SHIPPING_METHOD.FREE
              ? 'FREE_DELIVERY'
              : 'RUSH_DELIVERY_WITH_TRACKING'
          }
        />
      </Text>

      <FormFooter css={styles.footer}>
        <FormButton loading={loading} token="CONFIRM_ORDER" />
      </FormFooter>
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  information: {
    backgroundColor: theme.BACKGROUND_ALT,
    borderRadius: 16,
    fontSize: '14px',
    margin: 0,
    padding: 24,
  },
  headline: {
    color: theme.TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '1px',
    margin: 0,
    padding: '0 16px',
    textTransform: 'uppercase',
  },
  form: {
    gridGap: 18,
  },
  label: {
    color: theme.TEXT_PRIMARY_DARK,
    fontSize: '14px',
    letterSpacing: '-.25px',
    lineHeight: '20px',
  },
  footer: {
    backgroundColor: theme.BACKGROUND,
  },
})

export default OrderCardSummaryForm
