import React from 'react'
import { useNavigate } from 'react-router-dom'
import FlowContext from '../../../../../context/FlowContext'
import { ID_VERIFICATION_STATUS } from '../../../../../hooks/useTrulioo'
import Button from '../../../../../components/Button'
import Detail from '../../../../../components/typography/Detail'
import Icon from '../../../../../components/Icon'
import Flex from '../../../../../components/Flex'

const CONTENT_DICT = {
  [ID_VERIFICATION_STATUS.NOMATCH]: {
    icon: 'error',
    iconSize: 4.167,
    iconColor: 'ERROR_ALT',
    description: 'IDENTITY_VERIFICATION_FAILED',
  },
  [ID_VERIFICATION_STATUS.MATCH]: {
    icon: 'check',
    iconSize: 10,
    iconColor: 'PRIMARY_ALT',
    description: 'YOUR_IDENTITY_WAS_VERIFIED_SUCCESSFULLY',
  },
  [ID_VERIFICATION_STATUS.PENDING]: {
    icon: 'transaction',
    iconSize: 5,
    iconColor: 'PRIMARY',
    description: 'IDENTITY_VERIFICATION_PENDING',
  },
}

type KYCFinishProps = {
  status: keyof typeof ID_VERIFICATION_STATUS
}

const KYCFlowStepFinish: React.FC<KYCFinishProps> = () => {
  const navigation = useNavigate()

  const { prev, currentData, setFlowMeta } = React.useContext(FlowContext)

  const status = currentData?.data?.status || ID_VERIFICATION_STATUS.PENDING
  const noMatching = status === ID_VERIFICATION_STATUS.NOMATCH
  const content = CONTENT_DICT[status]

  React.useEffect(() => {
    setFlowMeta({ completed: !noMatching })
  }, [])

  const handleSubmit = async () => {
    if (noMatching) return prev()
    navigation('/settings')
  }

  return (
    <Flex
      css={styles.root}
      vertical
      horizontalAlignment="center"
      verticalAlignment="center"
    >
      <Icon
        css={styles.icon}
        icon={content.icon}
        color={content.iconColor}
        size={content.iconSize}
      />
      <Detail
        css={styles.copy}
        capitalize={false}
        token={content.description}
      ></Detail>
      <Button css={styles.btn} button onClick={handleSubmit} token="OK" />
    </Flex>
  )
}

const styles = {
  root: {
    marginTop: 20,
  },
  btn: {
    maxWidth: '320px',
    width: '100%',
  },
  copy: {
    textAlign: 'center',
    maxWidth: '320px',
    width: '100%',
    marginBottom: 30,
  },
  icon: {
    margin: '40px 0',
  },
}

export default KYCFlowStepFinish
