import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'
import CitizenshipForm from '../../../components/form/forms/CitizenshipForm'
import Info from '../../../components/Info'
import FlowContext from '../../../context/FlowContext'

const AreYou: React.FC<any> = ({
  data = {},
  submit,
  disabled,
  loading,
  error,
  ...rest
}) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })
  return (
    <FlowStepLayout>
      <CitizenshipForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        {...rest}
      />
      <Info token="INFO_PERSONALIZE_YOUR_HERO_EXPERIENCE" />
    </FlowStepLayout>
  )
}

export default AreYou
