import { Amplify } from 'aws-amplify'


export const appsyncConfig = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_API_URL,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE,
  },
}

Amplify.configure(appsyncConfig)

export default Amplify