export const HERO = 'HERO'
export const SIDEKICK = 'SIDEKICK'

const FEATURES = {
  firstSteps: [HERO],
  mainHoldingAccount: [HERO],

  sections: {
    dashboard: [HERO, SIDEKICK],
    accounts: [HERO, SIDEKICK],
    cards: [HERO, SIDEKICK],
    products: [HERO],
  },

  settings: {
    manageMyTeam: [HERO],
    manageMySubscription: [HERO],
    permissions: [HERO],
  },

  dashboard: {},

  cards: {},

  products: {
    add: [HERO],
  },

  funds: {
    add: [HERO],
    allocate: [HERO],
    manage: [HERO],
  },
}

export type FeatureType = keyof typeof FEATURES

export default FEATURES
