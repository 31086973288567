import React from 'react'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import * as Yup from 'yup'

import Field from '../Field'
import FormButton from '../Button'
import Form from '../Form'
import Text from '../../typography/Text'
import Header from '../../typography/Header'
import Link from '../../Link'

type LoginDetailsFormProps = {
  loading?: boolean
  submit: Function
  defaultValues?: { email: string }
  id?: string
}

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
})

const LoginDetailsForm: React.FC<LoginDetailsFormProps> = ({
  loading,
  submit,
  defaultValues,
  id,
}) => {
  const { theme } = useTheme()

  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form
      initialValues={{
        email: defaultValues?.email || '',
      }}
      onSubmit={submit}
      disabled={true}
      validationSchema={schema}
      css={styles.root}
    >
      <Header css={styles.headline} token="LOGIN_DETAILS" />

      <Field
        css={styles.field}
        label={<Text token="EMAIL_ADDRESS" />}
        name="email"
        required
        disabled
      />

      <Link
        to={
          id
            ? `/auth/reset-password?from=/settings/team/${id}/login-details&email=${encodeURIComponent(
                defaultValues?.email
              )}`
            : `/settings/profile/change-password?from=/settings/profile/login-details`
        }
        css={styles.link}
      >
        <Text token={id ? 'RESET_PASSWORD' : 'CHANGE_PASSWORD'} />
      </Link>

      <FormButton
        label={<Text token="DONE" />}
        css={styles.submit}
        loading={loading}
        disabled
      />
    </Form>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  PRIMARY,
}: TTheme) => ({
  root: { width: '100%', flex: 1 },
  submit: { marginTop: 30 },
  headline: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  link: {
    fontSize: 14,
    color: PRIMARY,
    textDecoration: 'none',
    padding: '12px 16px',
  },
  field: {
    backgroundColor: BACKGROUND_ALT,
  },
})

export default LoginDetailsForm
