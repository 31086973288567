import React from 'react'
import { useTheme } from '@emotion/react'

import ComponentCard from './ComponentCard'
import IconCircle, { IconCircleProps } from '../IconCircle'
import Flex from '../Flex'
import Grid from '../Grid'
import Text from '../typography/Text'
import Detail from '../typography/Detail'
import { TranslationToken } from '../typography/Text'

type NotificationCardType = {
  notificationType: string
  code: string
  timestamp: any
  transparent?: boolean
}

type NotificationDictType = {
  type: {
    [key: string]: Pick<IconCircleProps, 'color' | 'iconColor'> & {
      token?: TranslationToken
    }
  }
  code: {
    [key: string]: Pick<IconCircleProps, 'icon' | 'color'> & {
      token?: TranslationToken
    }
  }
}

const NotificationDict: NotificationDictType = {
  type: {
    info: {
      color: 'BLUE_300',
      iconColor: 'BLACK_900',
    },
    warning: {
      color: 'ORANGE_600',
      iconColor: 'BLACK_900',
    },
    error: {
      color: 'RED_FROLI',
      iconColor: 'BLACK_900',
    },
  },
  code: {
    ProfileUpdated: {
      icon: 'user',
      token: 'notification_ProfileUpdated',
    },
    PaymentProfileDocumentVerified: {
      icon: 'id',
      color: 'TURQUOISE_400',
      token: 'notification_PaymentProfileDocumentVerified',
    },
    DocumentDeclined: {
      icon: 'alert',
      color: 'RED_FROLI',
      token: 'notification_DocumentDeclined',
    },
    FundsTransferInitiated: {
      icon: 'transaction',
      color: 'TURQUOISE_400',
      token: 'notification_FundsTransferInitiated',
    },
    DocumentVerified: {
      icon: 'checkCircle',
      color: 'TURQUOISE_400',
      token: 'notification_DocumentVerified',
    },
    PaymentProfileDocumentDeclined: {
      icon: 'id',
      token: 'notification_PaymentProfileDocumentDeclined',
    },
    AdminFundsTransferCompleted: {
      icon: 'checkCircle',
      token: 'notification_AdminFundsTransferCompleted',
      color: 'TURQUOISE_400',
    },
    FundsTransferCompleted: {
      icon: 'checkCircle',
      token: 'notification_AdminFundsTransferCompleted',
      color: 'TURQUOISE_400',
    },
    FundingSourceCreated: {
      icon: 'card',
      token: 'notification_FundingSourceCreated',
    },
    CardRegistered: {
      icon: 'card',
      token: 'notification_CardRegistered',
    },
    CardReplaced: {
      icon: 'card',
      token: 'notification_CardReplaced',
    },
    CardReplacing: {
      icon: 'card',
      token: 'notification_CardReplacing',
    },
    CardUnlocked: {
      icon: 'card',
      token: 'notification_CardUnlocked',
    },
    CardLocked: {
      icon: 'card',
      token: 'notification_CardLocked',
    },
    CardOrderCanceled: {
      icon: 'card',
      token: 'notification_CardOrderCanceled',
    },
    FundsTransferFailed: {
      icon: 'alert',
      token: 'notification_FundsTransferFailed',
    },
    InvalidActivationCode: {
      icon: 'card',
      token: 'notification_InvalidActivationCode',
    },
    SubscriptionUpdated: {
      icon: 'switch',
      token: 'notification_SubscriptionUpdated',
    },
  },
}

const NotificationCard: React.FC<NotificationCardType> = ({
  notificationType,
  code,
  timestamp,
  ...rest
}) => {
  const { colors } = useTheme()
  const styles = React.useMemo(() => computeStyles({ ...colors }), [colors])

  const typeProps = NotificationDict.type[notificationType]
  const codeProps = NotificationDict.code[code]

  return (
    <ComponentCard {...rest}>
      <Flex centerY gap="1rem">
        <IconCircle
          icon="info"
          size={48}
          scale={1.32}
          {...typeProps}
          {...codeProps}
        />

        <Grid gap={5} vertical stretch>
          <Text token={codeProps?.token}>{code}</Text>
          <Detail
            date={timestamp}
            css={styles.detail}
            dateIsTimestamp
            dateIsRelative
            uppercase
          />
        </Grid>
      </Flex>
    </ComponentCard>
  )
}

const computeStyles = ({ BLACK_900, BLACK_700 }) => ({
  detail: {
    fontSize: 12,
    letterSpacing: 1,
    color: BLACK_700,
  },
  textColor: {
    color: BLACK_900,
  },
})

export default NotificationCard
