import React from 'react'

import Link from '../../Link'
import Icon from '../../Icon'
import Flex from '../../Flex'

import Text from '../../typography/Text'
import AvatarInitials from '../../AvatarInitials'
import { useTheme } from '@emotion/react'

import { ColorType } from '../../../utils/themes'
import clsx from 'clsx'

type UserItemProps = {
  subtitle?: string
  bankAccount?: any
  user: any
  to: string
  color?: ColorType
  disableLink?: boolean
  size?: number
  className?: string
}

const UserItem: React.FC<UserItemProps> = ({
  user,
  color = 'TURQUOISE_300',
  to,
  disableLink,
  size = 40,
  className,
}) => {
  const { colors } = useTheme()
  const styles = React.useMemo(() => computeStyles({ ...colors }), [colors])
  const classNames = clsx({
    [className]: className,
  })

  return (
    <Link to={to} disableLink={disableLink}>
      <Flex centerY gap="1rem" className={classNames}>
        <AvatarInitials
          user={user}
          color={color}
          size={size}
          css={{ fontSize: 12 }}
        />

        <Text css={styles.textColor} stretch>
          {user.givenName} {user.familyName}
        </Text>

        {!disableLink && <Icon icon="chevron" color="BLACK_700" />}
      </Flex>
    </Link>
  )
}

const computeStyles = ({ BLACK_900, BLACK_700 }) => ({
  detail: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 1,
    color: BLACK_700,
  },
  textColor: {
    color: BLACK_900,
  },
})

export default UserItem
