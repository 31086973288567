import React, { useState } from 'react'
import { FormikContextType, FormikValues } from 'formik'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import { isTeamHoldingAccount } from '../../../utils/allocate'
import FormContext from '../../../context/FormContext'
import Detail from '../../typography/Detail'
import Heading from '../../typography/Heading'
import DrawerCard from '../../drawers/DrawerCard'
import AccountCard from '../../cards/AccountCard'
import Field from '../Field'

const compareMembers = (firstMember, secondMember) => {
  const firstId = firstMember?.userProfile?.id
  const secondId = secondMember?.userProfile?.id
  const { __typename: t1 } = firstMember || {}
  const { __typename: t2 } = secondMember || {}

  if ((!firstId || !secondId) && (!t1 || !t2)) return false
  if (t1 === 'TeamHoldingAccount') return t1 === t2
  return firstId === secondId
}

enum FieldType {
  To = 'toAllocate',
  From = 'fromAllocate',
}

type FromToFieldProps = {
  allowToHero?: boolean
  onChange?: Function
  onlyFromHero?: boolean
  teamMembers: Array<any>
  toName?: string
  to?: any
  from?: any
  disabled?: boolean
} & React.PropsWithChildren

const FromToField: React.FC<FromToFieldProps> = ({
  allowToHero = false,
  onChange = () => {},
  onlyFromHero = false,
  to,
  from,
  disabled,
  teamMembers,
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const form = React.useContext(FormContext) as FormikContextType<FormikValues>

  const [drawer, setDrawer] = useState({
    visible: false,
    fieldType: FieldType.From,
  })

  const [members, setMembers] = useState(teamMembers)

  const fieldCondition = drawer.fieldType === FieldType.From

  const selectMember = async (member) => {
    const oppositeField = fieldCondition ? to : from
    const field = fieldCondition ? from : to

    if (compareMembers(member, oppositeField)) {
      const oppositeName = fieldCondition ? FieldType.To : FieldType.From
      form.setFieldValue(oppositeName, field)
      onChange(oppositeName, field)
    }

    form.setFieldValue(drawer.fieldType, member)
    onChange(drawer.fieldType, member)

    setDrawer({
      visible: false,
      fieldType: drawer.fieldType,
    })
  }

  const handleDrawer = async (visible, fieldType) => {
    if (disabled) return
    if (visible) {
      const condition = fieldType === FieldType.To
      await setMembers(
        teamMembers.filter(
          (member) => !(condition && isTeamHoldingAccount(member))
        )
      )
    }
    await setDrawer({
      visible,
      fieldType,
    })
  }

  return (
    <>
      <Detail css={styles.heading} token="FROM_ACCOUNT" />
      <AccountCard
        disabled={disabled}
        hero={isTeamHoldingAccount(from)}
        onClick={
          !onlyFromHero ? () => handleDrawer(true, FieldType.From) : undefined
        }
        disableLink={onlyFromHero}
        user={from?.userProfile}
        bankAccount={from?.bankAccounts?.[0]}
      />
      <Detail css={styles.heading} token="TO_ACCOUNT" />
      <AccountCard
        disabled={disabled}
        hero={isTeamHoldingAccount(to)}
        onClick={() => handleDrawer(true, FieldType.To)}
        user={to.userProfile}
        bankAccount={to?.bankAccounts?.[0]}
      />
      <DrawerCard
        handleClickOutside={() => handleDrawer(false, drawer.fieldType)}
        isOpened={drawer.visible}
      >
        <Heading
          token={fieldCondition ? 'FROM_ACCOUNT' : 'TO_ACCOUNT'}
          css={styles.drawerHeading}
        />
        {members?.map((member, index) => {
          const fieldMember = fieldCondition ? from : to
          const oppositeFieldMember = fieldCondition ? to : from
          const isSame = compareMembers(member, fieldMember)
          const disabled =
            fieldCondition &&
            compareMembers(member, oppositeFieldMember) &&
            !allowToHero

          return (
            <AccountCard
              disableLink
              disabled={disabled}
              css={isSame && styles.selectedMember}
              hero={isTeamHoldingAccount(member)}
              key={member?.userProfile?.id || `HoldingAccount-${index}`}
              user={member?.userProfile}
              bankAccount={member?.bankAccounts?.[0]}
              onClick={() => !disabled && selectMember(member)}
              bottomElement={
                <Field
                  type="radio"
                  css={styles.option}
                  name={`member-${
                    member?.userProfile?.id || 'HoldingAccount'
                  }-${drawer.fieldType}`}
                  value={false}
                  checked={isSame}
                />
              }
            />
          )
        })}
      </DrawerCard>
    </>
  )
}

const computeStyles: TComputeStyles = ({ PRIMARY_ALT }: TTheme) => ({
  heading: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  drawerHeading: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '24px',
  },
  option: {
    padding: 0,
    margin: 0,
    gap: 0,
    width: 25,
  },
  selectedMember: {
    borderRadius: 10,
    border: `1px solid ${PRIMARY_ALT}`,
  },
})

export default FromToField
