import React from 'react'
import AccountCard from './AccountCard'
import withFeature from '../../hocs/withFeature'
import useTeamHoldingAccountFunds from '../../hooks/useTeamHoldingAccountFunds'

const MainAccountBalanceCard = ({ refresh }) => {
  const { data, loading } = useTeamHoldingAccountFunds(!refresh)

  return (
    <AccountCard
      to="/account"
      loading={loading}
      hero={true}
      bankAccount={data?.teamHoldingAccount}
    />
  )
}

export default withFeature(MainAccountBalanceCard, 'mainHoldingAccount')
