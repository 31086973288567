import React from 'react'
import { useQuery } from '@apollo/client'
import { Outlet } from 'react-router-dom'

import AnimatedSpinner from './AnimatedSpinner'

import { getUserProfile, teamMembers } from '../queries'
import withMobile from '../hocs/withMobile'
import InitialMobileCall from './InitialMobileCall'

export const InitialCall = () => {
  // We do this so the cache is already filled with these information
  // So hooks such as useCurrentUser just consume the cache instead of having
  // to load the neccessary information to know what role the current user is

  const { loading: userProfileLoading } = useQuery(getUserProfile)
  const { loading: teamMembersLoading } = useQuery(teamMembers)

  const loading = userProfileLoading || teamMembersLoading

  return (
    <>
      {loading && (
        <div css={styles.root}>
          <AnimatedSpinner />
        </div>
      )}
      {!loading && <Outlet />}
    </>
  )
}

const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default withMobile(InitialMobileCall, InitialCall)
