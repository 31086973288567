import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import Grid from '../Grid'
import Text from '../typography/Text'
import AvatarInitials from '../AvatarInitials'

const SmallUserLink: React.FC<LinkProps> = ({ user, to, ...rest }) => (
  <Link to={to} css={styles.root}>
    <Grid centerX gap="0.5rem">
      <AvatarInitials user={user} color="YELLOW" size={50} />
      <Text>{user?.givenName}</Text>
    </Grid>
  </Link>
)

const styles = {
  root: {
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',

    textDecoration: 'none',
    color: 'black',

    width: 70,
  },
}

export default SmallUserLink
