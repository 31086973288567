import React from 'react'
import { getIconSizeFactor } from '../../../utils/functions'
import { IconProps } from '../icons'

const DinerIcon: React.FC<IconProps> = ({ size = 0.05, color = '#0079BE', logoColor = "#fff", width, height }) => {
    const defaultWidth = 780
    const defaultHeight = 500
    let factor = getIconSizeFactor({
        size,
        width,
        height,
        defaultWidth,
        defaultHeight,
    })

    return (
        <svg
            width={defaultWidth * factor}
            height={defaultHeight * factor}
            enableBackground={`new 0 0 ${defaultWidth} ${defaultHeight}`}
            viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
            version="1.1"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <rect width={defaultWidth} height={defaultHeight} fill={color} />
            <path d="m599.93 251.45c0-99.415-82.98-168.13-173.9-168.1h-78.242c-92.003-0.033-167.73 68.705-167.73 168.1 0 90.93 75.727 165.64 167.73 165.2h78.242c90.914 0.436 173.9-74.294 173.9-165.2z" fill={logoColor} />
            <path d="m348.28 97.43c-84.07 0.027-152.19 68.308-152.21 152.58 0.02 84.258 68.144 152.53 152.21 152.56 84.09-0.027 152.23-68.303 152.24-152.56-0.011-84.272-68.149-152.55-152.24-152.58z" fill="#fff" />
            <path d="m252.07 249.6c0.08-41.181 25.746-76.297 61.94-90.25v180.48c-36.194-13.948-61.861-49.045-61.94-90.23zm131 90.274v-180.53c36.207 13.92 61.914 49.057 61.979 90.257-0.065 41.212-25.772 76.322-61.979 90.269z" fill={logoColor} />
        </svg>
    )
}

export default DinerIcon



