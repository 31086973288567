import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import React from 'react'

import Button from '../Button'
import ButtonGroup from '../ButtonGroup'
import DrawerCard, { Props as DrawerCardProps } from '../drawers/DrawerCard'
import Text, { TranslationToken } from '../typography/Text'

export type ConfirmModalProps = {
  confirm: Function
  cancel: Function
  content?: TranslationToken | JSX.Element
  confirmLabel?: TranslationToken
  cancelLabel?: TranslationToken
  title?: TranslationToken | JSX.Element
  titleInterpolation?: object
  contentInterpolation?: object
  isOpened?: boolean
} & DrawerCardProps

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  content,
  confirm,
  cancel,
  confirmLabel = 'CONFIRM',
  cancelLabel = 'CANCEL',
  titleInterpolation = {},
  contentInterpolation = {},
  title,
  isOpened,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const titleIsString = typeof title === 'string'
  const titleComponent = titleIsString ? (
    <Text css={styles.title} token={title} interpolation={titleInterpolation} />
  ) : (
    title
  )
  const hasTitle = !!title

  const contentIsString = typeof content === 'string'
  const contentComponent = contentIsString ? (
    <Text
      css={styles.content}
      token={content}
      interpolation={contentInterpolation}
    />
  ) : (
    content
  )
  const hasContent = !!content

  return (
    <DrawerCard isOpened={isOpened} {...rest}>
      {hasTitle && titleComponent}
      {hasContent && contentComponent}

      <ButtonGroup css={styles.actions}>
        {cancel && (
          <Button
            token={cancelLabel}
            button
            onClick={cancel}
            css={styles.cancel}
          />
        )}
        <Button
          token={confirmLabel}
          button
          onClick={confirm}
          css={styles.confirm}
        />
      </ButtonGroup>
    </DrawerCard>
  )
}

const computeStyles: TComputeStyles = ({
  NEGATIVE_ACTION_BUTTON_PRIMARY,
  NEGATIVE_ACTION_BUTTON_SECONDARY,
  NEGATIVE_ACTION_BUTTON_SECONDARY_TEXT,
  TEXT_PRIMARY,
  TEXT_PRIMARY_DARK,
}: TTheme) => ({
  root: {},
  title: {
    color: TEXT_PRIMARY_DARK,
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  content: {
    color: TEXT_PRIMARY,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '24px',
  },
  actions: {
    justifyContent: 'end',
  },
  confirm: {
    backgroundColor: NEGATIVE_ACTION_BUTTON_PRIMARY,
    flex: 0,
  },
  cancel: {
    backgroundColor: NEGATIVE_ACTION_BUTTON_SECONDARY,
    color: NEGATIVE_ACTION_BUTTON_SECONDARY_TEXT,
    flex: 0,
  },
})

export default ConfirmModal
