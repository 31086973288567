import React from 'react'
import Form from '../Form'

import Header from '../../typography/Header'
import { Theme, useTheme } from '@emotion/react'
import SwitchField from '../fields/SwitchField'
import Text from '../../typography/Text'
import Flex from '../../Flex'

type PermissionsFormProps = {
  loading?: boolean
  submit?: Function
  handleFieldUpdate?: Function
  error?: boolean
  teamMembers?: any
  innerRef?: any
  disabled?: boolean
}

const PermissionsForm: React.FC<PermissionsFormProps> = ({
  loading,
  submit,
  teamMembers,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  const initial = React.useMemo(() => {
    return teamMembers.reduce((acc, sk) => {
      const { id } = sk.userProfile || {}
      const { canOrderCards } = sk.settings || {}
      acc[id] = canOrderCards
      return acc
    }, {})
  }, [teamMembers])

  return (
    <Form
      initialValues={initial}
      onSubmit={submit}
      disabled={loading}
      css={styles.root}
      {...rest}
    >
      <Flex vertical gap="1rem">
        {teamMembers.map((sk) => {
          const { givenName, familyName, id } = sk.userProfile || {}
          return (
            <React.Fragment key={`sk_${id}_permissions`}>
              <Text css={styles.headline}>{`${givenName} ${familyName}`}</Text>
              <SwitchField
                label={
                  <Header
                    css={styles.sectionToggleLabel}
                    token="CAN_ORDER_SIDEKICK_CARDS"
                  />
                }
                name={id}
                css={styles.sectionToggle}
                labelIsSufix
              />
            </React.Fragment>
          )
        })}
      </Flex>
    </Form>
  )
}

const computeStyles = ({ TEXT_PRIMARY }: Theme['theme']) => ({
  root: { width: '100%' },
  headline: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
    margin: 0,
  },
  sectionToggle: {
    border: 'none',
    padding: '0 16px',
  },
  sectionToggleLabel: {
    color: TEXT_PRIMARY,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1.71,
    margin: '0 9px',
  },
})

export default PermissionsForm
