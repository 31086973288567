import React from 'react'
import { TPlainStyle } from '@emotion/react'

import Button, { ButtonProps } from './Button'
import Tooltip from './Tooltip'
import { TranslationToken } from './typography/Text'

type TMoreInfo = {
  tooltip?: TranslationToken | JSX.Element
} & ButtonProps

const MoreInfo: React.FC<TMoreInfo> = ({ tooltip, ...rest }) => {
  const isTooltipText = typeof tooltip === 'string'
  return (
    <Tooltip
      tooltip={!isTooltipText ? tooltip : null}
      placement="top"
      message={isTooltipText ? tooltip : null}
    >
      <Button icon="moreInfo" size={1} css={styles.root} {...rest} />
    </Tooltip>
  )
}

const styles: TPlainStyle = {
  root: {
    background: 'none',
    padding: 0,
  },
}

export default MoreInfo
