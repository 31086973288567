import { useIsValidSubscription } from './useSubscriptions'
import isEmpty from 'lodash/isEmpty'
import useTeamMembers from './useTeamMembers'

type allowTypes = {
  categoryAllocate?: boolean
  generalAllowance?: boolean
  recurringAllowance?: boolean
}

enum ALLOWANCE_FEATURES {
  ALLOW_CATEGORY_FEATURE = 'Budgeting',
  RECURRING_ALLOWANCE_FEATURE = 'Allowance',
}
const useSubscriptionsAllowance = () => {
  const {
    subscription,
    loading: subscriptionLoading,
    refetch: subscriptionRefetch,
    error: subscriptionError,
  } = useIsValidSubscription()
  const {
    teamMembers,
    refetch: teamMemberRefetch,
    loading: teamMembersLoading,
    error: teamMembersError,
  } = useTeamMembers(true, true)

  const currentSubsciption = subscription

  let allow: boolean | allowTypes = false

  if (!isEmpty(currentSubsciption) && teamMembers?.length) {
    const { subscriptionPlan } = currentSubsciption
    const features = subscriptionPlan?.features || []

    allow = {
      generalAllowance: true,
      categoryAllocate: features.some(
        (feat) => feat === ALLOWANCE_FEATURES.ALLOW_CATEGORY_FEATURE
      ),
      recurringAllowance: features.some(
        (feat) => feat === ALLOWANCE_FEATURES.RECURRING_ALLOWANCE_FEATURE
      ),
    }
  }

  const refetch = async () =>
    await Promise.all([subscriptionRefetch(), teamMemberRefetch()])

  return {
    allow,
    data: currentSubsciption,
    loading: subscriptionLoading || teamMembersLoading,
    refetch,
    error: subscriptionError || teamMembersError,
  }
}

export default useSubscriptionsAllowance
