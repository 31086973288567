import { useQuery } from '@apollo/client'
import find from 'lodash/find'
import { apiClientWithoutErrorHandler } from '../config/api'

import { getUserProfile, teamMembers } from '../queries'

const useCurrentUser = (cache = true, userId?, avoidErrors?) => {
  const fetchPolicy = cache ? 'cache-first' : 'no-cache'
  const queryOptions = { fetchPolicy, notifyOnNetworkStatusChange: true }

  if (avoidErrors) queryOptions.client = apiClientWithoutErrorHandler

  const {
    loading: userProfileLoading,
    data: userProfileData,
    refetch: userProfileDataRefetch,
  } = useQuery(getUserProfile, queryOptions)

  const {
    loading: teamMembersLoading,
    data: teamMembersData,
    refetch: teamMembersRefetch,
  } = useQuery(teamMembers, queryOptions)

  const refetch = async () =>
    await Promise.all([userProfileDataRefetch(), teamMembersRefetch()])
  const loading = userProfileLoading || teamMembersLoading

  const currentUser = find(
    teamMembersData?.teamMembers,
    (member) =>
      member.userProfile?.id === (userId || userProfileData?.userProfile?.id)
  )

  return {
    data: currentUser || userProfileData || null,
    loading,
    refetch,
    teamMembersData,
  }
}

export default useCurrentUser
