import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@emotion/react'

import Button, { ButtonProps } from './Button'

import { TabsContext } from './Tabs'

export type TabProps = {
  id: string
  element: React.ReactNode,
  primaryActiveColor?: boolean
} & Omit<ButtonProps, 'to'>

const Tab: React.FC<TabProps> = ({ id, element, className, primaryActiveColor, ...rest }) => {
  const { colors, theme } = useTheme()
  const { register, selectedTab, setSelectedTab } =
    React.useContext(TabsContext)

  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme, primaryActiveColor }),
    [theme]
  )

  const classNames = clsx({
    [className]: className,
    'is-selected': selectedTab === id,
  })

  React.useEffect(() => {
    register(id, element)
  }, [])

  return (
    <Button
      onClick={() => setSelectedTab(id)}
      button
      css={styles.root}
      className={classNames}
      {...rest}
    />
  )
}

const computeStyles = ({ WHITE, BLACK_900, BLACK_800, BLACK_200, BACKGROUND, TURQUOISE_300, primaryActiveColor }) => ({
  root: {
    backgroundColor: primaryActiveColor ? BACKGROUND : WHITE,
    color: BLACK_800,
    border: `1px solid ${BLACK_200}`,

    '&.is-selected': {
      border: 0,
      color: BLACK_900,
      backgroundColor: primaryActiveColor ? TURQUOISE_300 : WHITE,
      fontWeight: 600,
    },
  },
})

export default Tab
