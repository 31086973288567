import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import appsyncMiddleware from './appsync-middleware'
import errorMiddleware from './error-middleware'
import retryMiddleware from './retry-middleware'
import subscriptionsMiddleware from './subscriptions-middleware'
import httpMiddleware from './http-middleware'

const httpLink = (hasErrorHandler?) =>
  ApolloLink.from([
    errorMiddleware(hasErrorHandler),
    appsyncMiddleware,
    retryMiddleware,
    subscriptionsMiddleware(httpMiddleware),
  ])
const cache = new InMemoryCache()

const apiClient = new ApolloClient({
  link: httpLink(true),
  cache,
  credentials: 'include',
})

export const apiClientWithoutErrorHandler = new ApolloClient({
  link: httpLink(),
  cache,
  credentials: 'include',
})

export default apiClient
