import { RetryLink } from 'apollo-link-retry'

const retryLink = new RetryLink({
  delay: {
    initial: 600,
    max: 1200,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (error, operation) =>
      operation.getContext()?.response?.status === 401,
  },
})

export default retryLink
