import clsx from 'clsx'
import React, { Children } from 'react'

const ButtonGroup = ({ children, className = '' }) => {
  const classNames = clsx({
    [className]: className,
  })
  const childCount = Children.count(children)

  const styles = React.useMemo(() => computeStyles({ childCount }), [children])

  return (
    <div css={styles.root} className={classNames}>
      {children}
    </div>
  )
}

const computeStyles = ({ childCount }) => ({
  root: {
    display: 'flex',

    '& > *': {
      flex: 1,
      marginLeft: '1rem',

      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
})

export default ButtonGroup
