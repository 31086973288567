import * as COLORS from '../colors'
import * as BREAKPOINTS from '../breakpoints'
import { Theme } from '@emotion/react'

const DefaultTheme: Theme = {
  theme: {
    PRIMARY: COLORS.BLUE_500,
    PRIMARY_ALT: COLORS.TURQUOISE_400,
    SECONDARY: COLORS.RED_FROLI,
    SECONDARY_ALT: COLORS.YELLOW,
    TEXT_PRIMARY: COLORS.BLACK_800,
    TEXT_PRIMARY_LIGHT: COLORS.BLACK_700,
    TEXT_PRIMARY_DARK: COLORS.BLACK_900,
    TEXT_SECONDARY: COLORS.WHITE,
    TEXT_CURRENCY: COLORS.TURQUOISE_500,
    FIELD_BORDER: COLORS.BLACK_400,
    FIELD_DISABLED_BACKGROUND: COLORS.BLACK_300,
    FIELD_DISABLED_BACKGROUND_DARK: COLORS.BLACK_500,
    FIELD_RADIO_SELECTED: COLORS.TURQUOISE_500,
    FIELD_RADIO_UNSELECTED: COLORS.BLACK_900,
    CARD_BORDER: COLORS.BLACK_200,
    CARD_TEXT: COLORS.WHITE,
    CARD_BACKGROUND: COLORS.BLUE_500,
    CARD_MENU_ITEM_ICON_COLOR: COLORS.BLUE_400,
    CARD_MENU_ITEM_ICON_BACKGROUND: COLORS.BLUE_100,
    CARD_MENU_ITEM_ICON_COLOR_ALT: COLORS.RED_500,
    CARD_MENU_ITEM_ICON_BACKGROUND_ALT: COLORS.RED_200,
    LINK: COLORS.BLUE_500,
    ERROR: COLORS.RED_500,
    ERROR_ALT: COLORS.RED_FROLI_600,
    WARNING: COLORS.ORANGE_500,
    SUCCESS: COLORS.GREEN_500,
    INFO: COLORS.BLUE_500,
    PENDING: COLORS.ORANGE_700,
    BACKGROUND: COLORS.BACKGROUND,
    BACKGROUND_ALT: COLORS.WHITE,
    BRAND_PRIMARY: COLORS.TURQUOISE_400,
    DISABLED: COLORS.BLACK_600,
    DISABLED_BACKGROUND: COLORS.BLACK_400,
    DISABLED_ICON_BACKGROUND: COLORS.BLACK_600,
    CARD_GRADIENT: `radial-gradient(231.72% 127.98% at 49.87% 127.98%, ${
      COLORS.TURQUOISE_400
    } 0%, ${'#4F76A1'} 59.8%, ${'#0F3C70'} 100%)`,
    PASSWORD_STRENGTH_BACKGROUND: COLORS.BLACK_400,
    PASSWORD_STRENGTH_TOO_WEAK: COLORS.RED_FROLI,
    PASSWORD_STRENGTH_WEAK: COLORS.ORANGE_500,
    PASSWORD_STRENGTH_MODERATE: COLORS.YELLOW,
    PASSWORD_STRENGTH_SECURE: COLORS.TURQUOISE_300,
    FULL_CONTRAST: COLORS.BLACK,
    SECTION_TITLE: COLORS.BLACK,
    PILL_DEFAULT_BACKGROUND: COLORS.YELLOW,
    PILL_PRIMARY_ALT: COLORS.TURQUOISE_200,
    INVITATION_PENDING: COLORS.ORANGE_500,
    INVITATION_ACCEPTED: COLORS.GREEN_500,
    INVITATION_RESEND_NOTIFICATION: COLORS.ORANGE,
    FUNDING_METHOD_OPTION_ICON_COLOR: COLORS.BLACK_900,
    FUNDING_METHOD_OPTION_ICON_BACKGROUND: COLORS.BLUE_50,
    GET_STARTED_ICON_COLOR: COLORS.BLACK_900,
    GET_STARTED_BACKGROUND: COLORS.BLUE_50,
    GET_STARTED_ADD_PRODUCT: COLORS.TURQUOISE_450,
    GET_STARTED_IDENTIFY: COLORS.CATEGORY_CYAN,
    GET_STARTED_ADD_FUNDS: COLORS.CATEGORY_SKY,
    GET_STARTED_FIRST_ALLOCATION: COLORS.BLUE_300,
    TEACH_KIDS: COLORS.BLUE_400,
    TEACH_KIDS_BUTTON: COLORS.BLUE_100,
    NAV_SELECTED_BORDER: COLORS.TURQUOISE_450,
    NAV_BORDER: COLORS.BLACK_200,
    NAV_PRIMARY_BACKGROUND: COLORS.TURQUOISE_300,
    BUTTON_ACTIVE_BACKGROUND: COLORS.BLACK_100,
    TRANSFER_ACTIVITY_ICON_COLOR_DEfAULT: COLORS.BLACK_900,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_DEfAULT: COLORS.TURQUOISE_300,
    TRANSFER_ACTIVITY_ICON_COLOR_ADD_FUNDS: COLORS.BLACK_900,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ADD_FUNDS: COLORS.TURQUOISE_300,
    TRANSFER_ACTIVITY_ICON_COLOR_ALLOCATION: COLORS.BLACK_900,
    TRANSFER_ACTIVITY_ICON_BACKGROUND_COLOR_ALLOCATION: COLORS.TURQUOISE_300,
    SESSION_TIMEOUT_NOTIFICATION_SHADOW: COLORS.BLACK_800,
    NEGATIVE_ACTION_BUTTON_PRIMARY: COLORS.RED_FROLI,
    NEGATIVE_ACTION_BUTTON_SECONDARY: COLORS.WHITE,
    NEGATIVE_ACTION_BUTTON_SECONDARY_TEXT: COLORS.BLUE_500,
    DRAWER_HANDLE: COLORS.BLACK_200,
    RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR: COLORS.BLACK_900,
    RECURRING_ALLOWANCE_MENU_ITEM_ICON_BACKGROUND: COLORS.BLUE_100,
    PROMOTION_PILL_TEXT_COLOR: COLORS.BLUE_700,
    CARD_PIN: COLORS.TURQUOISE_500,
  },
  colors: COLORS,
  breakpoints: BREAKPOINTS,
  sizes: {
    FIELD_PADDING: '12px 16px',
    RADIO_FIELD_PADDING: '12px 16px',
    FIELD_GAP: '0.5rem',
    FIELD_FONT_SIZE: 17,
    FIELD_LABEL_FONT_SIZE: 13,
    FIELD_FONT_WEIGHT: 500,
    FIELD_BORDER_RADIUS: 16,
    FIELD_STATUS_MIN_HEIGHT: 22,
  },
}

export default DefaultTheme
