import React, { useMemo, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Theme, useTheme } from '@emotion/react'
import { Outlet } from 'react-router-dom'
import clsx from 'clsx'

import SceneZoomInAnimation from '../animations/SceneZoomInAnimation'
import AnimatedSpinner from '../AnimatedSpinner'
import ApplicationContainer from '../layout/ApplicationContainer'

import LayoutContext from '../../context/LayoutContext'
import Flex from '../Flex'

const PublicLayout = () => {
  const theme = useTheme()
  const ref = useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const styles = useMemo(() => computeStyles(theme), [theme])

  const cardClassNames = clsx({
    'is-loading': loading,
    'is-error': error,
  })

  return (
    <LayoutContext.Provider value={{ setLoading, setError }}>
      <ApplicationContainer css={styles.applicationContainer}>
        <SceneZoomInAnimation css={styles.root} className={cardClassNames}>
          <Flex centerX padding="64px 16px 0px">
            <Flex
              centerX
              css={styles.container}
              ref={ref}
              className={cardClassNames}
            >
              <Outlet />
            </Flex>

            {loading && (
              <Flex center stretchSelf>
                <AnimatePresence>
                  {loading && <AnimatedSpinner />}
                </AnimatePresence>
              </Flex>
            )}
          </Flex>
        </SceneZoomInAnimation>
      </ApplicationContainer>
    </LayoutContext.Provider>
  )
}

const computeStyles = ({ breakpoints, theme }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },

  padded: {
    backgroundColor: theme.BACKGROUND,
    flex: 1,
    gridTemplateAreas: '"left content right"',
    gridTemplateColumns: 'auto',

    [breakpoints.BREAKPOINT_MOBILE]: {
      gridTemplateAreas: '"content"',
    },
  },

  container: {
    width: '100%',

    '&.is-loading,&.is-error': {
      display: 'none',
    },
  },

  applicationContainer: {
    backgroundColor: theme.BACKGROUND,
    minHeight: '100vh',
  },
})

export default PublicLayout
