import React from 'react'

import Header from '../typography/Header'

import FlowContext from '../../context/FlowContext'
import LayoutContext from '../../context/LayoutContext'

type FlowStepLayoutProps = {
  loading?: boolean
} & React.PropsWithChildren

const FlowStepLayout: React.FC<FlowStepLayoutProps> = ({
  children,
  loading,
}) => {
  const { currentStep } = React.useContext(FlowContext)
  const { setLoading } = React.useContext(LayoutContext)

  React.useEffect(() => {
    setLoading(loading)
  }, [loading])

  return (
    <>
      {currentStep?.title && (
        <Header css={styles.headline}>{currentStep?.title}</Header>
      )}
      {children}
    </>
  )
}

const styles = {
  headline: {
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 24,
    margin: '40px 0 0',
  },
}

export default FlowStepLayout
