import React, { useMemo, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Theme, useTheme } from '@emotion/react'
import { Outlet } from 'react-router-dom'
import clsx from 'clsx'

import Header from '../Header'
import MainNav from '../MainNav'
import SceneZoomInAnimation from '../animations/SceneZoomInAnimation'
import AnimatedSpinner from '../AnimatedSpinner'
import Status from '../statuses/Status'
import ApplicationContainer from '../layout/ApplicationContainer'

import LayoutContext from '../../context/LayoutContext'

const MainLayout = () => {
  const { theme, breakpoints } = useTheme()
  const ref = useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const styles = useMemo(() => computeStyles(theme, breakpoints), [theme])

  const cardClassNames = clsx({
    'is-loading': loading,
    'is-error': error,
  })

  return (
    <LayoutContext.Provider value={{ setLoading, setError }}>
      <ApplicationContainer>
        <SceneZoomInAnimation css={styles.root} className={cardClassNames}>
          <div css={styles.padded}>
            <Header />
            <MainNav />
            <div css={styles.container} ref={ref} className={cardClassNames}>
              <Outlet />
            </div>

            {(loading || error) && (
              <div css={styles.fullscreenContainer}>
                <AnimatePresence>
                  {loading && <AnimatedSpinner />}
                  {error && <Status error={error} />}
                </AnimatePresence>
              </div>
            )}
          </div>
        </SceneZoomInAnimation>
      </ApplicationContainer>
    </LayoutContext.Provider>
  )
}

const computeStyles = (
  { BACKGROUND, BACKGROUND_ALT }: Theme['theme'],
  { BREAKPOINT_MOBILE }: Theme['breakpoints']
) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    '&.is-loading': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  padded: {
    backgroundColor: BACKGROUND_ALT,
    padding: '53px 16px 0px 16px',
    display: 'grid',
    gridGap: '1rem',
    flex: 1,

    gridTemplateAreas: '"header header" "nav content"',
    gridTemplateRows: '4rem auto',
    gridTemplateColumns: '15rem auto',

    [BREAKPOINT_MOBILE]: {
      gridTemplateAreas: '"header" "nav" "content"',
      gridTemplateRows: '4rem max-content auto',
      gridTemplateColumns: 'auto',
    },
  },

  container: {
    backgroundColor: BACKGROUND,
    margin: '-16px -16px 0 0',
    padding: '16px 16px 0 16px',
    flex: 1,
    display: 'grid',
    gridGap: '1rem',
    gridArea: 'content',
    gridTemplateRows: 'min-content',
    height: 'auto',
    paddingBottom: '1rem',
    [BREAKPOINT_MOBILE]: {
      margin: '0px -16px 0 -16px',
      padding: '16px 16px 16px',
      borderRadius: '16px 16px 0 0',
    },

    '&.is-loading,&.is-error': {
      display: 'none',
    },
  },

  header: {
    height: 30,
    width: '100%',
  },

  fullscreenContainer: {
    flex: 1,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default MainLayout
