import React from 'react'
import uuid from 'react-uuid'

import PhysicalCard from './PhysicalCard'
import PhysicalCardMenu from './PhysicalCardMenu'
import Expandable, { ExpandableAction, ExpandableOutlet } from './Expandable'

type ExpandablePhysicalCardProps = {
  card: any
  allowExpand?: boolean
  layout?: any
}

const ExpandablePhysicalCard: React.FC<ExpandablePhysicalCardProps> = ({
  card,
  allowExpand,
  ...rest
}) => {
  const instanceId = uuid()
  const id = instanceId + card.id

  return (
    <Expandable {...rest}>
      <ExpandableAction allowExpand={allowExpand}>
        <PhysicalCard
          card={card}
          hasMenu={allowExpand}
          layoutId={id}
          css={styles.card}
        />
      </ExpandableAction>

      <ExpandableOutlet>
        <PhysicalCardMenu card={card} layoutId={`${id}-menu`} />
      </ExpandableOutlet>
    </Expandable>
  )
}

const styles = {
  card: {
    position: 'relative',
    zIndex: 2,
  },
}

export default ExpandablePhysicalCard
