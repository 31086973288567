import React from 'react'
import Text from '../../../components/typography/Text'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Actions from '../../../components/Actions'
import Button from '../../../components/Button'
import { TComputeStyles, TTheme, useTheme } from '@emotion/react'
import Card from '../../../components/cards/Card'
import DrawerCard from '../../../components/drawers/DrawerCard'
import List from '../../../components/lists/List'
import BigMenuItem from '../../../components/lists/items/BigMenuItem'
import ConfirmModal from '../../../components/modals/ConfirmModal'
import Flex from '../../../components/Flex'
import { useMutation, useQuery } from '@apollo/client'
import {
  deleteRecurringAllowance as deleteRecurringAllowanceMutation,
  recurringAllowances as recurringAllowancesQuery,
} from '../../../queries'
import useTeamMembers from '../../../hooks/useTeamMembers'

enum AllowanceFrequency {
  Weekly,
  Monthly,
}

enum CategoryKey {
  cat_001,
  cat_002,
  cat_003,
  cat_004,
  cat_005,
  cat_006,
}

enum CurrencyKey {
  CAD,
}

type Currency = {
  basicUnit: string
  fraction: string
  currencyKey: CurrencyKey
}

type CategoryAllocations = {
  categoryKey: CategoryKey
  ammountAllocated: Currency
}

export type RecurringAllowance = {
  id: number | string
  targetBankAccount: string
  frequency: AllowanceFrequency
  repeatsEvery: number
  amount: number
  endsAfter: string
  categories: CategoryAllocations[]
  nextScheduledAllowance: string
}

type RecurringAllowanceSceneProps = {
  title: JSX.Element
  back: string
}

const RecurringAllowancesScene: React.FC<RecurringAllowanceSceneProps> = ({
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  const [showMoreOption, setShowMoreOptions] = React.useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')

  const handleShowConfirm = () => {
    setShowMoreOptions(false)
    setIsDeleteConfirmOpen(true)
  }

  const handleShowOption = (id) => {
    setSelectedId(id)
    setShowMoreOptions(true)
  }

  const [deleteRecurringAllowance, { loading: deleting }] = useMutation(
    deleteRecurringAllowanceMutation
  )

  const { loading: loadingMembers, teamMembers } = useTeamMembers(true, true)

  const { data, loading, refetch } = useQuery(recurringAllowancesQuery)

  const { recurringAllowances } = data || {}

  const handleDelete = async () => {
    setIsDeleteConfirmOpen(false)
    selectedId &&
      (await deleteRecurringAllowance({
        variables: {
          id: selectedId,
        },
      }))
    await refetch()
    setSelectedId('')
  }

  return (
    <CardLayoutLink
      loading={loading || loadingMembers}
      processing={deleting}
      processingLabel={<Text token={'DELETING'} />}
      {...rest}
    >
      <Actions nowrap css={styles.root}>
        <Button
          iconColor={theme.BACKGROUND_ALT}
          size={1}
          icon="repeat"
          token="ADD_RECURRING_ALLOWANCE"
          to={`/settings/recurring-allowance/new`}
        />
      </Actions>
      {recurringAllowances?.map(
        ({ id, targetBankAccount, amount, frequency, endsAfter }, index) => {
          const targetSk = teamMembers.find(
            (sk) => sk?.bankAccounts?.[0]?.bankAccountId === targetBankAccount
          )?.userProfile

          const targetName = `${targetSk.givenName} ${targetSk.familyName}`

          return (
            <Card
              key={`recurring_allowance_${id}_${index}`}
              css={styles.recurringAllowanceCard}
            >
              {
                <Flex spread verticalAlignment="flex-start">
                  <Flex vertical css={styles.details} gap="1rem">
                    <Flex vertical>
                      <Text token="FROM_ACCOUNT" css={styles.detailsLabel} />
                      <Text token="HERO" />
                    </Flex>
                    <Flex vertical>
                      <Text token="TO_ACCOUNT" css={styles.detailsLabel} />
                      <Text>{targetName}</Text>
                    </Flex>
                    <Flex vertical>
                      <Text token="AMOUNT" css={styles.detailsLabel} />
                      <Text
                        currencyProps={{
                          currency: 'USD',
                          maximumFractionDigits: 2,
                        }}
                        currency={Number(
                          `${amount.basicUnit}.${amount.fraction}`
                        )}
                      />
                    </Flex>
                    <Flex vertical>
                      <Text token="FREQUENCY" css={styles.detailsLabel} />
                      <Text>{frequency}</Text>
                    </Flex>
                    <Flex vertical>
                      <Text token="END_DATE" css={styles.detailsLabel} />
                      <Text>{endsAfter === null ? '-' : endsAfter}</Text>
                    </Flex>
                  </Flex>
                  <Button
                    button
                    icon="moreVertical"
                    iconColor={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR}
                    size={1}
                    onClick={() => handleShowOption(id)}
                    css={styles.moreOptions}
                  />
                </Flex>
              }
            </Card>
          )
        }
      )}
      <DrawerCard
        isOpened={showMoreOption}
        handleClickOutside={() => setShowMoreOptions(false)}
      >
        <List padding="10px" gap="1.5rem">
          <BigMenuItem
            label={<Text token="DELETE" />}
            icon="trashCan"
            color={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_BACKGROUND}
            iconColor={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR}
            onClick={handleShowConfirm}
          />
          <BigMenuItem
            label={<Text token="EDIT" />}
            to={`/settings/recurring-allowance/${selectedId}`}
            icon="edit"
            color={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_BACKGROUND}
            iconColor={theme.RECURRING_ALLOWANCE_MENU_ITEM_ICON_COLOR}
          />
        </List>
      </DrawerCard>
      <ConfirmModal
        title="CONFIRM_RECURRING_ALLOWANCE_DELETION"
        content="CONFIRM_RECURRING_ALLOWANCE_DELETION_DETAIL"
        confirmLabel="DELETE"
        cancelLabel="CANCEL"
        confirm={handleDelete}
        cancel={() => setIsDeleteConfirmOpen(false)}
        handleClickOutside={() => setIsDeleteConfirmOpen(false)}
        isOpened={isDeleteConfirmOpen}
      />
    </CardLayoutLink>
  )
}

const computeStyles: TComputeStyles = ({
  BACKGROUND_ALT,
  TEXT_PRIMARY,
}: TTheme) => ({
  root: {},
  content: {
    boxSizing: 'border-box',
    maxWidth: 630,
    width: '100%',
  },
  recurringAllowanceCard: {
    backgroundColor: BACKGROUND_ALT,
  },
  details: {
    color: TEXT_PRIMARY,
    padding: '12px 16px',
  },
  detailsLabel: {
    fontSize: '14px',
  },
  moreOptions: {
    background: 'none',
  },
})

export default RecurringAllowancesScene
