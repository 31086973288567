import React, { useMemo } from 'react'
import { useTheme } from '@emotion/react'
import clsx from 'clsx'
import { CheckIcon } from './icons'
import uniqueId from 'lodash/uniqueId'

export type StepsProps = {
  steps: any[]
  step: number
  checked?: boolean
}

const Steps: React.FC<StepsProps> = ({ steps, step, checked = false }) => {
  const { theme, colors } = useTheme()
  const styles = React.useMemo(
    () => computeStyles({ ...colors, ...theme }),
    [theme]
  )

  const keys = useMemo(() => steps.map(() => uniqueId('step-key')), [steps])

  return (
    <div css={styles.root}>
      {steps.map((currentStep, index) => {
        const classNames = clsx({
          'is-active': index <= step,
        })

        const isChecked = index <= step - 1 && checked

        return (
          <React.Fragment key={`${keys[index]}-dash`}>
            {index !== 0 && (
              <div css={styles.dash} className={classNames}></div>
            )}
            <div
              key={`${keys[index]}-pill`}
              css={styles.pill}
              className={classNames}
            >
              {!isChecked ? index + 1 : <CheckIcon size={1.75} />}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const computeStyles = ({ BLUE_600, TURQUOISE_300 }) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 25,
    height: 25,
    backgroundColor: BLUE_600,
    color: TURQUOISE_300,
    fontSize: '.9rem',

    borderRadius: '100%',

    '&.is-active': {
      backgroundColor: TURQUOISE_300,
      color: BLUE_600,
    },
  },

  dash: {
    display: 'flex',
    alignItems: 'center',

    flex: 1,

    '&::before': {
      content: '""',
      height: 3,
      flex: 1,

      backgroundColor: BLUE_600,
    },

    '&.is-active': {
      '&::before': {
        content: '""',
        height: 3,
        flex: 1,

        backgroundColor: TURQUOISE_300,
      },
    },
  },
})

export default Steps
