import React from 'react'
import Icon from '../../Icon'

import Field from '../Field'

const PasswordField = ({ ...rest }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  return (
    <Field
      css={styles.root}
      type={showPassword ? 'text' : 'password'}
      {...rest}
    >
      <button
        type="button"
        css={styles.showHidePasswordToggle}
        onClick={() => setShowPassword(!showPassword)}
      >
        <Icon icon="eye" />
      </button>
    </Field>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 32px',
    gridTemplateRows: 'auto, 1fr',
  },
  showHidePasswordToggle: {
    alignSelf: 'center',
    background: 'none',
    border: 'none',
    gridArea: '1 / 2 / 3 / 3',
    height: 'fit-content',
    justifySelf: 'end',
    padding: 0,
    width: 'fit-content',
    ':focus-visible': {
      outline: 'none',
    },
  },
}

export default PasswordField
