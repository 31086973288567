import React from 'react'
import FormButton from '../Button'
import Form from '../Form'
import FormFooter from '../FormFooter'

import { Theme, useTheme } from '@emotion/react'

export type ThanksOrderFormProps = {
  submit: any
  disabled?: boolean
  loading?: boolean
  error?: any
  handleFieldUpdate?: Function
}

const ThanksOrderForm: React.FC<ThanksOrderFormProps> = ({
  submit,
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])

  return (
    <Form onSubmit={submit} initialValues={{}} {...rest}>
      <FormFooter css={styles.footer}>
        <FormButton token="DONE" />
      </FormFooter>
    </Form>
  )
}

const computeStyles = ({ BACKGROUND }: Theme['theme']) => ({
  footer: {
    backgroundColor: BACKGROUND,
  },
})

export default ThanksOrderForm
