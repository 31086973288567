import { SafeArea } from 'capacitor-plugin-safe-area'
import { Capacitor } from '@capacitor/core'

export type TInsets = {
  top?: number
  bottom?: number
  left?: number
  right?: number
}

export type TSAFE_AREA = {
  insets?: TInsets
  statusBarHeight?: number
}
const SAFE_AREA: TSAFE_AREA = {
  insets: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  statusBarHeight: 0,
}

export const getSafeArea = async () => {
  if (Capacitor.isNativePlatform()) {
    const { insets } = await SafeArea.getSafeAreaInsets()
    const { statusBarHeight } = await SafeArea.getStatusBarHeight()
    SAFE_AREA.insets = insets
    SAFE_AREA.statusBarHeight = statusBarHeight
  }
  return SAFE_AREA
}
