import React from 'react'
import sortBy from 'lodash/sortBy'
import CategoryAmountField from '../fields/CategoryAmountField'

type AmounstFieldProps = {
  fundsAvailable?: any
  categories?: Array<any>
  disabled?: boolean
  hideAvailable?: boolean
}

const AmountsField: React.FC<AmounstFieldProps> = ({
  categories,
  fundsAvailable,
  disabled,
  hideAvailable,
}) => {
  const showCategory = categories && categories?.length
  const sortedCategories = sortBy(categories, 'key')

  return showCategory ? (
    <>
      {sortedCategories.map((category) => (
        <CategoryAmountField
          disabled={disabled}
          key={`keyAmount-${category.key}`}
          category={category}
          hideAvailable={hideAvailable}
        />
      ))}
    </>
  ) : (
    <CategoryAmountField
      disabled={disabled}
      fundsAvailable={fundsAvailable}
      name="amount"
      hideAvailable={hideAvailable}
    />
  )
}

export default AmountsField
