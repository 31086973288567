import React from 'react'
import { useTheme } from '@emotion/react'
import Spinner from 'react-svg-spinner'

export type SpinnerProps = {
  color?: any
  size?: number
  thickness?: number
  gap?: number
  speed?: 'fast' | 'slow'
}

const HeroSpinner: React.FC<SpinnerProps> = ({
  size = 70,
  thickness = 4,
  speed = 'fast',
  ...rest
}) => {
  const { colors } = useTheme()

  return (
    <Spinner
      size={`${size}`}
      color={colors.CATEGORY_TURQUOISE}
      thickness={thickness}
      speed={speed}
      {...rest}
    />
  )
}

export default HeroSpinner
