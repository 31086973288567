import React from 'react'
import CardLayoutLink from '../../../components/layout/CardLayoutLink'
import Heading from '../../../components/typography/Heading'
import Detail from '../../../components/typography/Detail'
import UnsubscribeForm from '../../../components/form/forms/UnsubscribeForm'
import { useUnsubscribe } from '../../../hooks/useSubscriptions'
import { useNavigate } from 'react-router-dom'

const SubscriptionsCancel = ({ ...rest }) => {
  const [mutateUnsubscribe, { loading }] = useUnsubscribe()
  const navigate = useNavigate()
  const handleSubmit = async () => {
    await mutateUnsubscribe()
    navigate('/settings/subscriptions')
  }

  return (
    <CardLayoutLink {...rest}>
      <Heading token="ARE_YOU_SURE_UNSUBSCRIBE" />
      <ul css={styles.list}>
        <li>
          <Detail token="NOT_CHARGED_FOR_CANCELING" />
        </li>
        <li>
          <Detail token="SERVICES_WILL_REMAIN_AVAILABLE" />
        </li>
        <li>
          <Detail token="MOVED_BACK_TO_GENERAL_BALANCE" />
        </li>
        <li>
          <Detail token="NOT_BE_ABLE_TO_ALLOCATE_FUNDS" />
        </li>
      </ul>
      <UnsubscribeForm
        loading={loading}
        disabled={loading}
        submit={handleSubmit}
      />
    </CardLayoutLink>
  )
}

const styles = {
  list: {
    margin: 0,
    padding: '0 0 0 15px',
  },
}

export default SubscriptionsCancel
