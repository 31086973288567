import React from 'react'
import { useQuery } from '@apollo/client'
import FlowStepLayout from '../../../../components/flows/FlowStepLayout'
import ShippingAddressForm, {
  SHIPPING_METHOD,
} from '../../../../components/form/forms/ShippingAddressForm'

import Text from '../../../../components/typography/Text'

import FlowContext from '../../../../context/FlowContext'
import { teamMembers } from '../../../../queries'

const ShippingAddressStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)
  const { data: teamMembersData } = useQuery(teamMembers)

  const sk = teamMembersData.teamMembers.find(
    (acc) => acc.userProfile?.id === currentData?.data?.forUser
  )
  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  return (
    <FlowStepLayout>
      <Text token="ENTER_THE_ADDRESS_TO_WHICH_YOU_WISH" />
      <ShippingAddressForm
        submit={handleSubmit}
        hideShippingMethod
        data={{
          shippingAddress: sk?.userProfile?.principalAddress || {},
          ...currentData?.data,
          shippingMethod: SHIPPING_METHOD.RUSH_TRACK,
        }}
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default ShippingAddressStep
