import React from 'react'

import FlowCardLayout, {
  FlowStep,
} from '../../../../../components/layout/FlowCardLayout'

import KYCFlowStepData from './kyc-step-data'
import KYCFlowStepSuccess from './kyc-step-finish'

const KYC = ({ ...rest }) => {
  return (
    <FlowCardLayout name="kyc" cancel="/settings" {...rest}>
      <FlowStep element={KYCFlowStepData} />
      <FlowStep element={KYCFlowStepSuccess} />
    </FlowCardLayout>
  )
}

export default KYC
