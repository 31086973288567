import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const BankNoteIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 22
  const defaultHeight = 14
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V11C2 11.5523 2.44772 12 3 12H19C19.5523 12 20 11.5523 20 11V3C20 2.44772 19.5523 2 19 2H3ZM0 3C0 1.34315 1.34315 0 3 0H19C20.6569 0 22 1.34315 22 3V11C22 12.6569 20.6569 14 19 14H3C1.34315 14 0 12.6569 0 11V3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6ZM8 7C8 5.34315 9.34315 4 11 4C12.6569 4 14 5.34315 14 7C14 8.65685 12.6569 10 11 10C9.34315 10 8 8.65685 8 7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C4 6.44772 4.44772 6 5 6H5.01C5.56229 6 6.01 6.44772 6.01 7C6.01 7.55228 5.56229 8 5.01 8H5C4.44772 8 4 7.55228 4 7ZM16 7C16 6.44772 16.4477 6 17 6H17.01C17.5623 6 18.01 6.44772 18.01 7C18.01 7.55228 17.5623 8 17.01 8H17C16.4477 8 16 7.55228 16 7Z"
        fill={color}
      />
    </svg>
  )
}

export default BankNoteIcon
