import React from 'react'
import FlowStepLayout from '../../../components/flows/FlowStepLayout'

import SelectSidekickForm from '../../../components/form/forms/SelectSidekickForm'

import FlowContext from '../../../context/FlowContext'

const SelectSidekickStep = ({ ...rest }) => {
  const { currentData, next, setFlowData } = React.useContext(FlowContext)

  const handleSubmit = async (formData) => {
    await setFlowData(formData)
    next()
  }

  const handleFieldUpdate = async (name, value) =>
    await setFlowData({ [name]: value })

  return (
    <FlowStepLayout>
      <SelectSidekickForm
        submit={handleSubmit}
        handleFieldUpdate={handleFieldUpdate}
        data={{ ...currentData?.data }}
        fieldName="owner"
        {...rest}
      />
    </FlowStepLayout>
  )
}

export default SelectSidekickStep
