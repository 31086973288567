import React from 'react'
import * as Yup from 'yup'
import FormButton from '../Button'
import Form from '../Form'

import Header from '../../typography/Header'
import Field from '../Field'
import Detail from '../../typography/Detail'
import { Theme, useTheme } from '@emotion/react'

type CardActivationFormProps = {
  loading?: boolean
  submit: Function
  submitLabel?: string
}

const schema = Yup.object().shape({
  activationCode: Yup.string().length(10).required(),
})

const CardActivationForm: React.FC<CardActivationFormProps> = ({
  loading,
  submit,
  submitLabel = 'Next',
  ...rest
}) => {
  const { theme } = useTheme()
  const styles = React.useMemo(() => computeStyles(theme), [theme])
  return (
    <Form
      initialValues={{
        activationCode: '',
      }}
      onSubmit={submit}
      disabled={loading}
      validationSchema={schema}
      css={styles.root}
      {...rest}
    >
      <Header css={styles.headline} token="ENTER_ACTIVATION_CODE" />
      <Detail token="PLEASE_ENTER_THE_ACTIVATION_CODE" />

      <Field name="activationCode" fieldStyles={styles.field} />

      <FormButton label={submitLabel} loading={loading} />
    </Form>
  )
}

const computeStyles = (theme: Theme['theme']) => ({
  root: { width: '100%' },
  // submit: { marginTop: 30 },
  headline: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '-.25px',
  },
  field: {
    textAlign: 'center',
    fontSize: 28,
    letterSpacing: 10,
  },
})

export default CardActivationForm
