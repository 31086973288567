import React from 'react'
import { useSearchParams } from 'react-router-dom'

export const TabsContext = React.createContext<{
  selectedElement?: React.ReactNode
  setSelectedElement?: Function
  selectedTab?: string
  setSelectedTab?: Function
  register?: Function
}>({})

export type TabsProps = {
  defaultTab?: string
  routed?: boolean
} & React.PropsWithChildren

export const TabsOutlet = () => {
  const { selectedElement } = React.useContext(TabsContext)

  return selectedElement && selectedElement
}

const Tabs: React.FC<TabsProps> = ({ defaultTab, routed = true, children }) => {
  const [search, setSearch] = useSearchParams()
  const tab = (routed ? search.get('tab') : false) || defaultTab

  const [tabs, setTabs] = React.useState({})
  const [selectedTab, setSelectedTab] = React.useState(tab)
  const [selectedElement, setSelectedElement] = React.useState()

  const register = (id, element) =>
    setTabs((prevTabs) => ({ ...prevTabs, [id]: element }))

  React.useEffect(() => {
    setSelectedElement(tabs[selectedTab])

    if (routed) setSearch({ tab: selectedTab })
  }, [selectedTab, tabs])

  return (
    <TabsContext.Provider
      value={{
        selectedElement,
        setSelectedElement,
        selectedTab,
        setSelectedTab,
        register,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}

export default Tabs
