import React from 'react'
import { getIconSizeFactor } from '../../utils/functions'
import { IconProps } from './icons'

const MoneyWithdrawalIcon: React.FC<IconProps> = ({
  size = 1,
  color = '#464E58',
  width,
  height,
}) => {
  const defaultWidth = 20
  const defaultHeight = 22
  let factor = getIconSizeFactor({
    size,
    width,
    height,
    defaultWidth,
    defaultHeight,
  })

  return (
    <svg
      width={defaultWidth * factor}
      height={defaultHeight * factor}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V10H21C21.2652 10 21.5196 9.89464 21.7071 9.70711C21.8946 9.51957 22 9.26522 22 9V1C22 0.734784 21.8946 0.48043 21.7071 0.292893C21.5196 0.105357 21.2652 0 21 0ZM6 18V16C6.53043 16 7.03914 16.2107 7.41421 16.5858C7.78929 16.9609 8 17.4696 8 18H6ZM16 18H14C14 17.4696 14.2107 16.9609 14.5858 16.5858C14.9609 16.2107 15.4696 16 16 16V18ZM16 14C14.9391 14 13.9217 14.4214 13.1716 15.1716C12.4214 15.9217 12 16.9391 12 18H10C10 16.9391 9.57857 15.9217 8.82843 15.1716C8.07828 14.4214 7.06087 14 6 14V6H16V14ZM20 8H18V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V8H2V2H20V8ZM11 13C11.5933 13 12.1734 12.8241 12.6667 12.4944C13.1601 12.1648 13.5446 11.6962 13.7716 11.1481C13.9987 10.5999 14.0581 9.99667 13.9424 9.41473C13.8266 8.83279 13.5409 8.29824 13.1213 7.87868C12.7018 7.45912 12.1672 7.1734 11.5853 7.05764C11.0033 6.94189 10.4001 7.0013 9.85195 7.22836C9.30377 7.45542 8.83524 7.83994 8.50559 8.33329C8.17595 8.82664 8 9.40666 8 10C8 10.7956 8.31607 11.5587 8.87868 12.1213C9.44129 12.6839 10.2044 13 11 13ZM11 9C11.1978 9 11.3911 9.05865 11.5556 9.16853C11.72 9.27841 11.8482 9.43459 11.9239 9.61732C11.9996 9.80004 12.0194 10.0011 11.9808 10.1951C11.9422 10.3891 11.847 10.5673 11.7071 10.7071C11.5673 10.847 11.3891 10.9422 11.1951 10.9808C11.0011 11.0194 10.8 10.9996 10.6173 10.9239C10.4346 10.8482 10.2784 10.72 10.1685 10.5556C10.0586 10.3911 10 10.1978 10 10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9Z"
        fill={color}
      />
    </svg>
  )
}

export default MoneyWithdrawalIcon
