import React from 'react'
import semver from 'semver'
import { useQuery } from '@apollo/client'
import useNativeVersion from '../hooks/useNativeVersion'
import AnimatedSpinner from './AnimatedSpinner'
import { InitialCall } from './InitialCall'
import { mobileAppMetadata } from '../queries'
import { Capacitor } from '@capacitor/core'
import Flex from './Flex'
import Text from './typography/Text'
import Heading from './typography/Heading'
import Element from './Element'
import Button from './Button'
import Icon from './Icon'
import { useTheme } from '@emotion/react'

const MIN_VERSION = {
  android: 'androidVersion',
  ios: 'iosVersion',
}

const CTA_LINK = {
  android: 'androidBaseUrl',
  ios: 'iosBaseUrl',
}

const CONTENT_COPY = {
  android: 'androidContent',
  ios: 'iosContent',
}

const InitialMobileCall: React.FC<any> = () => {
  const { data, loading } = useQuery(mobileAppMetadata)
  const { data: appData, loading: appLoading } = useNativeVersion()
  const platform = Capacitor.getPlatform()
  const { theme } = useTheme()

  if (loading || appLoading) {
    return (
      <Element css={styles.root}>
        <AnimatedSpinner />
      </Element>
    )
  }

  const currentVersion = appData?.version
  const params = data?.metadata?.parameters || []

  const paramsObj = Object.assign(
    {},
    ...params.map((param) => ({ [param.key]: param.value }))
  )

  const beMinVersion = paramsObj[MIN_VERSION[platform]]

  if (beMinVersion) {
    if (
      !semver.gt(semver.coerce(currentVersion), semver.coerce(beMinVersion))
    ) {
      const cta = paramsObj[CTA_LINK[platform]]
      const content = paramsObj[CONTENT_COPY[platform]]

      return (
        <Flex
          css={styles.root}
          vertical
          verticalAlignment="center"
          horizontalAlignment="center"
        >
          <Element css={styles.wrapper}>
            <Flex vertical gap={5}>
              <Icon
                icon="brand_gradient"
                height={40.14}
                css={styles.brand}
                color={theme.BRAND_PRIMARY}
              />
              <Heading token="IMPORTANT_INFORMATION" />
              <Text token={content} />
              {cta && (
                <a
                  href={cta}
                  css={styles.ctaText}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    token="UPDATE_APP"
                    button
                    css={styles.ctaBtn}
                  ></Button>
                </a>
              )}
            </Flex>
          </Element>
        </Flex>
      )
    }
  }
  return <InitialCall />
}

const styles = {
  ctaText: {
    color: 'white',
    textDecoration: 'none',
    marginTop: 20,
  },
  ctaBtn: {
    width: '100%',
  },
  brand: {
    marginBottom: 20,
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    alignSelf: 'center',
    maxWidth: 300,
  },
}

export default InitialMobileCall
